import { DataName, dateFormat } from '@utils/constants';
import { IInvoiceReceipt, IInvoiceReceiptDetail } from '@interfaces/iinvoice-receipt';
import { goBack } from '@utils/history';
import { currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Divider, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import DetailTableComponent from './libraries/detail-table';
import { IInvoice } from '@interfaces/iinvoice';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchInvoiceReceipt: (code: string) => void;
  invoiceReceipt?: IInvoiceReceipt;
  isFetching: string[];
  match: any;
}

const title = 'Detail Tanda Terima Invoice';
export class PageInvoiceReceiptDetailComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public componentDidMount = () => {
    const { match, fetchInvoiceReceipt } = this.props;
    fetchInvoiceReceipt(match.params.code);
  };
  public render() {
    const { invoiceReceipt, isFetching } = this.props;

    const invoices: IInvoice[] = [];
    invoiceReceipt?.details?.map((item: IInvoiceReceiptDetail) => {
      invoices.push(item.invoice);
    });
    const loading = isLoading(isFetching, DataName.INVOICE_RECEIPT);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        {invoiceReceipt && (
          <PageHeader
            title={title}
            onBack={goBack}
            extra={[
              <Link
                key="2"
                to={`/invoice-receipt/form${invoiceReceipt && `/${invoiceReceipt.code}`}`}
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Ubah Tanda Terima Invoice
                </Button>
              </Link>,
            ]}
          />
        )}
        <Card bordered={false} loading={loading}>
          {invoiceReceipt && (
            <Fragment>
              <Descriptions
                title={`Tanda Terima Invoice: ${invoiceReceipt.code}`}
                layout="vertical"
                bordered={true}
                size="small"
              >
                <Descriptions.Item label="Tanggal">
                  {invoiceReceipt.date ? formatTime(moment(invoiceReceipt.date), dateFormat) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Total">
                  {invoiceReceipt.total ? currencyCase(invoiceReceipt.total) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Penerima Invoice">
                  {invoiceReceipt.invoiceReceiver}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Remarks"
                  className={!isEmpty(invoiceReceipt.remarks) ? 'warning' : ''}
                >
                  {invoiceReceipt.remarks || '-'}
                </Descriptions.Item>
              </Descriptions>
              <Divider orientation="left">Detail Muatan</Divider>
              <DetailTableComponent dataSource={invoices} />
            </Fragment>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageInvoiceReceiptDetailComponent;
