import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchVendorList } from './action';
import PageVendorComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  vendorList: state.PageMasterDataReducer.vendorList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVendorList: (params: any) => dispatch(fetchVendorList(params)),
});

export const PageVendorContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageVendorComponent);

export default PageVendorContainer;
