import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import {
  createDaftarMuatan,
  fetchDaftarMuatan,
  updateDaftarMuatan,
} from '@pages/page-account-payable/page-daftar-muatan/action';
import { fetchSuratMuatanList } from '@pages/page-account-receivable/page-surat-muatan/action';
import { fetchVehicleList } from '@pages/page-master/page-vehicle/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageDaftarMuatanFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  daftarMuatan: state.PageAccountPayableReducer.daftarMuatan,
  vehicleList: state.PageMasterDataReducer.vehicleList,
  suratMuatanList: state.PageAccountReceivableReducer.suratMuatanList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVehicleList: (params: any) => dispatch(fetchVehicleList(params)),
  fetchSuratMuatanList: (params: any) => dispatch(fetchSuratMuatanList(params)),
  fetchDaftarMuatan: (code: string) => dispatch(fetchDaftarMuatan(code)),
  createDaftarMuatan: (body: IDaftarMuatan) => dispatch(createDaftarMuatan(body)),
  updateDaftarMuatan: (code: string, body: IDaftarMuatan) =>
    dispatch(updateDaftarMuatan(code, body)),
});

export const PageDaftarMuatanFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDaftarMuatanFormComponent);

export default PageDaftarMuatanFormContainer;
