import { IUser } from '@interfaces/iuser';
import { fetchRoleList } from '@pages/page-administration/page-role/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchUserList, updateUser, deleteUser } from './action';
import PageEmployeeComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  roleList: state.PageAdministrationReducer.roleList,
  userList: state.PageAdministrationReducer.userList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchRoleList: (params?: any) => dispatch(fetchRoleList(params)),
  fetchUserList: (params?: any) => dispatch(fetchUserList(params)),
  updateUser: (code: string, body: IUser) => dispatch(updateUser(code, body)),
  deleteUser: (code: string) => dispatch(deleteUser(code)),
});

export const PageEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageEmployeeComponent);

export default PageEmployeeContainer;
