import { ICashFlow, ICashFlowRecap } from '@interfaces/icash-flow';

const urlCashFlow = 'cash_flows';
const urlCashFlowRecap = 'cash_flows_recaps';
const urlCoa = 'coa_lists';

export const getCashFlow = (code: string) => ({
  method: 'GET',
  url: `${urlCashFlow}/${code}`,
});

export const getCashFlows = (params: any) => ({
  method: 'GET',
  params,
  url: urlCashFlow,
});

export const createCashFlow = (body: ICashFlow) => ({
  body,
  method: 'POST',
  url: urlCashFlow,
});

export const updateCashFlow = (code: string, body: ICashFlow) => ({
  body,
  method: 'PUT',
  url: `${urlCashFlow}/${code}`,
});

export const deleteCashFlow = (code: string) => ({
  method: 'DELETE',
  url: `${urlCashFlow}/${code}`,
});

export const getCashFlowRecap = (code: string) => ({
  method: 'GET',
  url: `${urlCashFlowRecap}/${code}`,
});

export const getCashFlowRecaps = (params: any) => ({
  method: 'GET',
  params,
  url: urlCashFlowRecap,
});

export const createCashFlowRecap = (body: ICashFlowRecap) => ({
  body,
  method: 'POST',
  url: urlCashFlowRecap,
});

export const updateCashFlowRecap = (code: string, body: ICashFlowRecap) => ({
  body,
  method: 'PUT',
  url: `${urlCashFlowRecap}/${code}`,
});

export const deleteCashFlowRecap = (code: string) => ({
  method: 'DELETE',
  url: `${urlCashFlowRecap}/${code}`,
});

export const getCoas = (params?: any) => ({
  method: 'GET',
  params,
  url: urlCoa,
});

export default {
  getCashFlow,
  getCashFlows,
  createCashFlow,
  updateCashFlow,
  deleteCashFlow,
  getCashFlowRecap,
  getCashFlowRecaps,
  createCashFlowRecap,
  updateCashFlowRecap,
  deleteCashFlowRecap,
  getCoas,
};
