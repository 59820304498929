import { EmptyState } from '@components/molecules/empty-state';
import {
  dateFormat,
  defaultParams,
  pageSizeOptions,
  roleType,
  showSizeChanger,
  dateTimeFormat,
} from '@utils/constants';
import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import { IDriver } from '@interfaces/idriver';
import { IMeta } from '@interfaces/imeta';
import { IVehicle } from '@interfaces/ivehicle';
import { IVendor } from '@interfaces/ivendor';
import { getModule } from '@utils/request-api';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { formatTime } from '@utils/time';
import { Button, Divider, Popconfirm, Table, Checkbox, Tooltip } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  updateData: (code: string, body: IDaftarMuatan) => void;
  deleteData: (code: string) => void;
  dataSource?: any;
  meta: IMeta;
}

interface IState {
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParams: defaultParams,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params });
    fetchDataSource(params);
  };
  public handleDeleteData = async (code: string) => {
    const { fetchDataSource, deleteData } = this.props;
    const { tableParams } = this.state;
    await deleteData(code);
    fetchDataSource(tableParams);
  };

  public handleChangeIsPaidVendor = async (code: string, data: IDaftarMuatan) => {
    const { fetchDataSource, updateData } = this.props;
    const { tableParams } = this.state;
    const daftarMuatan = { ...data, isPaidVendor: !data.isPaidVendor };
    await updateData(code, daftarMuatan);
    fetchDataSource(tableParams);
  };

  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: (
          <Tooltip title="Pelunasan Vendor">
            <span>Paid</span>
          </Tooltip>
        ),
        dataIndex: 'isPaidVendor',
        sorter: true,
        render: (value: boolean, record: IDaftarMuatan) => (
          <Popconfirm
            title="Apakah Anda yakin untuk merubah pelunasan vendor atas daftar muatan ini?"
            onConfirm={() => record.code && this.handleChangeIsPaidVendor(record.code, record)}
            okText="Iya"
            cancelText="Tidak"
            placement="topRight"
          >
            <Checkbox checked={value} />
          </Popconfirm>
        ),
        fixed: 'left',
        width: 90,
      },
      {
        title: 'No DM',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        render: (text: string, record: IDaftarMuatan) => (
          <Link to={`/daftar-muatan/detail/${record.code}`}>{text}</Link>
        ),
        fixed: 'left',
        width: 120,
      },
      {
        title: 'Tanggal',
        dataIndex: 'dmDate',
        ...filter('dateRange'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
      },
      {
        title: 'Vendor',
        dataIndex: 'vehicle.driver.vendor',
        ...filter('vendor'),
        render: (record: IVendor) => (
          <Link to={`/vendor/detail/${record.code}`}>
            {companyTypeCase(record.type, record.name)}
          </Link>
        ),
      },
      {
        title: 'No Polisi',
        dataIndex: 'vehicle.policeNo',
        ...filter('policeNo'),
        render: (text: string, record: IVehicle) => (
          <Link to={`/vehicle/detail/${record.code}`}>{text}</Link>
        ),
      },
      {
        title: 'Tipe Kendaraan',
        dataIndex: 'vehicle.vehicle_type.name',
        ...filter('vehicleType'),
        width: 160,
      },
      {
        title: 'Nama Supir',
        dataIndex: 'vehicle.driver',
        ...filter('driverName'),
        render: (record: IDriver) => (
          <Link to={`/driver/detail/${record.code}`}>{record.fullName}</Link>
        ),
      },
      {
        title: 'Harga Vendor',
        dataIndex: 'totalVendorPrice',
        sorter: true,
        render: (text: any) => currencyCase(text),
      },
      {
        title: 'Uang Jalan',
        dataIndex: 'downPayment',
        sorter: true,
        render: (text: any) => currencyCase(text),
      },
      {
        title: 'Dibuat Oleh',
        dataIndex: 'created_by',
        ...filter('created_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: IDaftarMuatan) => (
          <Fragment>
            <Link to={`/daftar-muatan/form/${record.code}`}>Ubah</Link>
            {getModule(roleType.DIR) && (
              <Fragment>
                <Divider type="vertical" />
                <Popconfirm
                  title="Apakah Anda yakin untuk menghapus daftar muatan ini?"
                  onConfirm={() => record.code && this.handleDeleteData(record.code)}
                  okText="Iya"
                  cancelText="Tidak"
                >
                  <Button type="link">Hapus</Button>
                </Popconfirm>
              </Fragment>
            )}
          </Fragment>
        ),
        width: 200,
      },
    ];

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          scroll={{ x: 2000 }}
          rowKey="id"
        />
      </div>
    );
  }
}

export default TableComponent;
