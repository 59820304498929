import { EmptyState } from '@components/molecules/empty-state';
import {
  dateFormat,
  defaultParams,
  orderStatus,
  pageSizeOptions,
  paymentType,
  showSizeChanger,
  dateTimeFormat,
} from '@utils/constants';
import { ICustomer } from '@interfaces/icustomer';
import { IMeta } from '@interfaces/imeta';
import { IRecipient } from '@interfaces/irecipient';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { companyTypeCase, currencyCase, orderStatusCase } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { formatTime } from '@utils/time';
import { Button, Divider, Popconfirm, Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isDirection } from '@utils/get-role';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  deleteData: (code: string) => void;
  dataSource?: any;
  meta: IMeta;
}

interface IState {
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParams: {
        ...defaultParams,
        status: `[${orderStatus.SuratMuatan},${orderStatus.DaftarMuatan},${orderStatus.Invoice}]`,
      },
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params });
    fetchDataSource(params);
  };
  public handleDeleteData = async (code: string) => {
    const { fetchDataSource, deleteData } = this.props;
    const { tableParams } = this.state;
    await deleteData(code);
    fetchDataSource(tableParams);
  };
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: 'No SM',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        render: (text: string, record: ISuratMuatan) => (
          <Link to={`/surat-muatan/detail/${record.code}`}>{text}</Link>
        ),
        width: 120,
        fixed: 'left',
      },
      {
        title: 'No Polisi',
        dataIndex: 'vehicle.policeNo',
        ...filter('policeNo'),
        render: (text: string, record: ISuratMuatan) =>
          record.vehicle ? <Link to={`/vehicle/detail/${record.vehicle.code}`}>{text}</Link> : '-',
        width: 150,
      },
      {
        title: 'No DM',
        dataIndex: 'dmCode',
        ...filter('dmCode'),
        sorter: true,
        render: (text: string) =>
          text ? <Link to={`/daftar-muatan/detail/${text}`}>{text}</Link> : '-',
        width: 120,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        ...filter('customer'),
        render: (record: ICustomer) => (
          <Link to={`/customer/detail/${record.code}`}>
            {companyTypeCase(record.type, record.name)}
          </Link>
        ),
      },
      {
        title: 'Penerima',
        dataIndex: 'recipient',
        ...filter('recipient'),
        render: (record: IRecipient) => {
          return record ? <Link to={`/recipient/detail/${record.code}`}>{record.name}</Link> : '-';
        },
      },
      {
        title: 'Tanggal TT',
        dataIndex: 'orderDate',
        ...filter('orderDate'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 150,
      },
      {
        title: 'Tanggal SM',
        dataIndex: 'smDate',
        ...filter('smDate'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 150,
      },
      {
        title: 'Pembayaran',
        dataIndex: 'paymentType',
        ...filter('paymentType'),
        sorter: true,
        render: (text: any) => `${text} (${paymentType[text]})` || '-',
        width: 150,
      },
      {
        title: 'Pengiriman',
        dataIndex: 'deliveryType',
        ...filter('deliveryType'),
        sorter: true,
        render: (text: any) => text || '-',
        width: 150,
      },
      {
        title: 'Total Harga',
        dataIndex: 'invoicePrice',
        ...filter('invoicePrice'),
        sorter: true,
        render: (text: any) => currencyCase(text) || '-',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        ...filter('status'),
        sorter: true,
        render: (text: number) => orderStatusCase(text, true),
        width: 110,
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: ISuratMuatan) => (
          <Fragment>
            <Link to={`/surat-muatan/form/${record.code}`}>Ubah</Link>
            {isEmpty(record?.dmCode) && isDirection() && (
              <Fragment>
                <Divider type="vertical" />
                <Popconfirm
                  title="Apakah Anda yakin untuk menghapus surat muatan ini?"
                  onConfirm={() => record.code && this.handleDeleteData(record.code)}
                  okText="Iya"
                  cancelText="Tidak"
                >
                  <Button type="link" size="small">
                    Hapus
                  </Button>
                </Popconfirm>
              </Fragment>
            )}
          </Fragment>
        ),
        width: 120,
      },
    ];

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          scroll={{ x: 2200 }}
          rowClassName={(record: ISuratMuatan) => (record.status === 2 ? 'warning' : '')}
          rowKey="id"
        />
      </div>
    );
  }
}

export default TableComponent;
