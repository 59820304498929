import {
  DataName,
  dateFormat,
  emptyParams,
  FormType,
  orderStatus,
  paymentType,
} from '@utils/constants';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { ITandaTerima } from '@interfaces/itanda-terima';
import { IVehicle } from '@interfaces/ivehicle';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Divider, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import DetailTableComponent from '../detail/libraries/detail-table';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchTandaTerimaList: (params: any) => void;
  tandaTerimaList: ITandaTerima[];
  fetchVehicleList: (params: any) => void;
  vehicleList: IVehicle[];
  fetchSuratMuatan: (code: string) => void;
  suratMuatan?: ISuratMuatan;
  createSuratMuatan: (body: ISuratMuatan) => void;
  updateSuratMuatan: (code: string, body: ISuratMuatan) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
}

export class PageSuratMuatanFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      formType: code && code.substring(0, 2) === 'SM' ? 'edit' : 'add',
    };
  }
  public componentDidMount = () => {
    const { match, fetchSuratMuatan, fetchTandaTerimaList, fetchVehicleList } = this.props;
    const { formType } = this.state;
    const { code } = match.params;
    fetchSuratMuatan(code);
    if (formType && formType === 'add') {
      fetchTandaTerimaList({ ...emptyParams, status: `[${orderStatus.TandaTerima}]` });
    }
    fetchVehicleList(emptyParams);
  };
  public handleSubmit = () => {
    const { form, suratMuatan, createSuratMuatan, updateSuratMuatan } = this.props;
    const { formType } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataSuratMuatan: ISuratMuatan = {
          ...suratMuatan,
          smDate: formatTime(values.smDate, 'YYYY-MM-DD'),
          vehiclesId: values.vehicle,
          updated_by: currentUser,
        };

        if (formType === 'edit' && suratMuatan?.code) {
          await updateSuratMuatan(suratMuatan.code, dataSuratMuatan);
        } else {
          await createSuratMuatan({
            ...dataSuratMuatan,
            status: orderStatus.SuratMuatan,
            created_by: currentUser,
          });
        }
        history.push('/surat-muatan');
      }
    });
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public onSelectTandaTerima = (code: any) => {
    const { fetchSuratMuatan } = this.props;
    fetchSuratMuatan(code);
  };
  public handleValidateVehicle = (rule: any, value: number, callback: any) => {
    const { vehicleList } = this.props;
    const vehicle = vehicleList.find((item: IVehicle) => item.id === value);
    if (moment(vehicle?.oneYearTaxDate) < moment()) {
      callback('Masa pajak tahunan kendaraan sudah kadaluarsa!');
    }
    callback();
  };
  public render() {
    const { isFetching, match, form, suratMuatan, tandaTerimaList, vehicleList } = this.props;
    const { formType } = this.state;
    const { code } = match.params;
    const { getFieldDecorator } = form;
    const title = formType && formType === 'edit' ? 'Ubah Surat Muatan' : 'Buat Surat Muatan';
    const loading = isLoading(isFetching, DataName.SURAT_MUATAN);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                {code === undefined && (
                  <Form.Item label="Tanda Terima">
                    {getFieldDecorator('tandaTerima', {
                      initialValue: suratMuatan?.code,
                      rules: [{ message: 'Tanda Terima harus dipilih!', required: true }],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Select
                        showSearch={true}
                        placeholder="Pilih Tanda Terima"
                        optionFilterProp="children"
                        onSelect={this.onSelectTandaTerima}
                      >
                        {tandaTerimaList &&
                          tandaTerimaList.map((item: ITandaTerima) => (
                            <Option key={item.id} value={item.code}>
                              {`${item.code} | ${companyTypeCase(
                                item.customer?.type,
                                item.customer?.name,
                              )} | ${item.customer?.address}`}
                            </Option>
                          ))}
                      </Select>,
                    )}
                  </Form.Item>
                )}
                <Form.Item label="Customer">
                  {getFieldDecorator('customer', {
                    initialValue: companyTypeCase(
                      suratMuatan?.customer?.type,
                      suratMuatan?.customer?.name,
                    ),
                    rules: [{ message: 'Customer harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Customer" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Penerima">
                  {getFieldDecorator('recipient', {
                    initialValue: suratMuatan?.recipient?.name ?? '',
                    rules: [{ message: 'Penerima harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Penerima" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Tipe Pembayaran">
                  {getFieldDecorator('paymentType', {
                    initialValue: suratMuatan?.paymentType && paymentType[suratMuatan?.paymentType],
                    rules: [{ required: true, message: 'Tipe Pembayaran harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Tipe Pembayaran" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Gudang">
                  {getFieldDecorator('warehouse', {
                    initialValue: (suratMuatan?.warehouse && suratMuatan?.warehouse.code) ?? '-',
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Gudang" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Komisi">
                  {getFieldDecorator('commission', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: suratMuatan?.commission ?? '',
                    rules: [
                      { message: 'Komisi hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Komisi harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Komisi" disabled={true} />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Purchase Order">
                  {getFieldDecorator('purchaseOrder', {
                    initialValue: suratMuatan?.purchase_order?.code ?? '',
                    rules: [{ message: 'Purchase Order harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Purchase Order" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Tanggal Tanda Terima">
                  {getFieldDecorator('orderDate', {
                    initialValue: suratMuatan?.orderDate
                      ? moment(suratMuatan?.orderDate)
                      : moment(),
                    rules: [{ required: true, message: 'Tanggal Tanda Terima harus dipilih!' }],
                  })(<DatePicker format={dateFormat} disabled={true} />)}
                </Form.Item>
                <Form.Item label="Tanggal Surat Muatan">
                  {getFieldDecorator('smDate', {
                    initialValue: suratMuatan?.smDate ? moment(suratMuatan?.smDate) : moment(),
                    rules: [{ required: true, message: 'Tanggal Surat Muatan harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Jenis Pengiriman">
                  {getFieldDecorator('deliveryType', {
                    initialValue: suratMuatan?.deliveryType ?? '',
                    rules: [{ required: true, message: 'Jenis Pengiriman harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Jenis Pengiriman" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Kendaraan">
                  {getFieldDecorator('vehicle', {
                    initialValue: suratMuatan?.vehicle?.id,
                    rules: [
                      { message: 'Kendaraan harus dipilih!', required: true },
                      { validator: this.handleValidateVehicle },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Kendaraan"
                      optionFilterProp="children"
                      disabled={!suratMuatan}
                    >
                      {vehicleList &&
                        vehicleList.map((item: IVehicle) => (
                          <Option key={item.id} value={item.id}>
                            {`${item.policeNo} - ${item.driver?.fullName} - ${companyTypeCase(
                              item.driver?.vendor?.type,
                              item.driver?.vendor?.name,
                            )}`}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: suratMuatan?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
            </Row>
            {suratMuatan && (
              <Fragment>
                <Divider orientation="left">Detail Muatan</Divider>
                <DetailTableComponent
                  master={suratMuatan}
                  dataSource={suratMuatan.details}
                  showPrice={true}
                  isPrint={false}
                />
                <br />
              </Fragment>
            )}
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button
              className="action-btn"
              type="primary"
              onClick={this.handleSubmit}
              disabled={!suratMuatan}
            >
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageSuratMuatanFormComponent);
