import { ICustomer } from '@interfaces/icustomer';
import { IDriver } from '@interfaces/idriver';
import { IPriceList } from '@interfaces/iprice-list';
import { IRecipient } from '@interfaces/irecipient';
import { IVehicle, IVehicleType } from '@interfaces/ivehicle';
import { IVendor } from '@interfaces/ivendor';
import { IWarehouse } from '@interfaces/iwarehouse';
import ActionTypes from '@store/action-types';

export interface IMasterDataReducer {
  customerList: ICustomer[];
  customer?: ICustomer;
  driverList: IDriver[];
  driver?: IDriver;
  recipientList: IRecipient[];
  recipient?: IRecipient;
  vehicleList: IVehicle[];
  vehicle?: IVehicle;
  vehicleTypeList: IVehicleType[];
  vehicleType?: IVehicleType;
  vendorList: IVendor[];
  vendor?: IVendor;
  warehouseList: IWarehouse[];
  warehouse?: IWarehouse;
  priceLists: IPriceList[];
  priceList?: IPriceList;
}

const initialState: IMasterDataReducer = {
  customerList: [],
  driverList: [],
  recipientList: [],
  vehicleList: [],
  vehicleTypeList: [],
  vendorList: [],
  warehouseList: [],
  priceLists: [],
};

export const PageMasterDataReducer = (state: IMasterDataReducer = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.PAGE_CUSTOMER_SET_CUSTOMER:
      return { ...state, customer: action.customer };
    case ActionTypes.PAGE_CUSTOMER_SET_CUSTOMER_LIST:
      return { ...state, customerList: action.customerList };
    case ActionTypes.PAGE_DRIVER_SET_DRIVER:
      return { ...state, driver: action.driver };
    case ActionTypes.PAGE_DRIVER_SET_DRIVER_LIST:
      return { ...state, driverList: action.driverList };
    case ActionTypes.PAGE_RECIPIENT_SET_RECIPIENT:
      return { ...state, recipient: action.recipient };
    case ActionTypes.PAGE_RECIPIENT_SET_RECIPIENT_LIST:
      return { ...state, recipientList: action.recipientList };
    case ActionTypes.PAGE_VEHICLE_SET_VEHICLE:
      return { ...state, vehicle: action.vehicle };
    case ActionTypes.PAGE_VEHICLE_SET_VEHICLE_LIST:
      return { ...state, vehicleList: action.vehicleList };
    case ActionTypes.PAGE_VEHICLE_SET_VEHICLE_TYPE:
      return { ...state, vehicleType: action.vehicleType };
    case ActionTypes.PAGE_VEHICLE_SET_VEHICLE_TYPE_LIST:
      return { ...state, vehicleTypeList: action.vehicleTypeList };
    case ActionTypes.PAGE_VENDOR_SET_VENDOR:
      return { ...state, vendor: action.vendor };
    case ActionTypes.PAGE_VENDOR_SET_VENDOR_LIST:
      return { ...state, vendorList: action.vendorList };
    case ActionTypes.PAGE_WAREHOUSE_SET_WAREHOUSE:
      return { ...state, warehouse: action.warehouse };
    case ActionTypes.PAGE_WAREHOUSE_SET_WAREHOUSE_LIST:
      return { ...state, warehouseList: action.warehouseList };
    case ActionTypes.PAGE_PRICE_LIST_SET_PRICE_LIST:
      return { ...state, priceList: action.priceList };
    case ActionTypes.PAGE_PRICE_LIST_SET_PRICE_LISTS:
      return { ...state, priceLists: action.priceLists };
    default:
      return state;
  }
};

export default PageMasterDataReducer;
