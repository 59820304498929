import { fetchUser } from '@pages/page-administration/page-employee/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageEmployeeDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  user: state.PageAdministrationReducer.user,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchUser: (code: string) => dispatch(fetchUser(code)),
});

export const PageEmployeeDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageEmployeeDetailComponent);

export default PageEmployeeDetailContainer;
