import { IInvoice } from '@interfaces/iinvoice';
import {
  createInvoice,
  fetchInvoice,
  updateInvoice,
} from '@pages/page-account-receivable/page-invoice/action';
import { fetchSuratMuatanList } from '@pages/page-account-receivable/page-surat-muatan/action';
import { fetchCustomerList } from '@pages/page-master/page-customer/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageInvoiceFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  invoice: state.PageAccountReceivableReducer.invoice,
  customerList: state.PageMasterDataReducer.customerList,
  suratMuatanList: state.PageAccountReceivableReducer.suratMuatanList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCustomerList: (params: any) => dispatch(fetchCustomerList(params)),
  fetchSuratMuatanList: (params: any) => dispatch(fetchSuratMuatanList(params)),
  fetchInvoice: (code: string) => dispatch(fetchInvoice(code)),
  createInvoice: (body: IInvoice) => dispatch(createInvoice(body)),
  updateInvoice: (code: string, body: IInvoice) => dispatch(updateInvoice(code, body)),
});

export const PageInvoiceFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageInvoiceFormComponent);

export default PageInvoiceFormContainer;
