import { fetchWarehouse } from '@pages/page-master/page-warehouse/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageWarehouseDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  warehouse: state.PageMasterDataReducer.warehouse,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchWarehouse: (code: string) => dispatch(fetchWarehouse(code)),
});

export const PageWarehouseDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageWarehouseDetailComponent);

export default PageWarehouseDetailContainer;
