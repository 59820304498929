import { DataName, dateFormat, defaultParams, TabType } from '@utils/constants';
import { IMeta } from '@interfaces/imeta';
import { IVehicle, IVehicleType } from '@interfaces/ivehicle';
import { Button, Card, Icon, PageHeader, Tabs } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableVehicleComponent from './libraries/table-vehicle';
import TableVehicleTypeComponent from './libraries/table-vehicle-type';
import { isDirection } from '@utils/get-role';
import { CSVLink } from 'react-csv';
import { isLoading } from '@utils/loading';
import { formatTime } from '@utils/time';
const { TabPane } = Tabs;

interface IProps {
  fetchVehicleTypeList: (params: any) => void;
  vehicleTypeList: IVehicleType[];
  createVehicleType: (body: IVehicleType) => void;
  updateVehicleType: (code: string, body: IVehicleType) => void;
  fetchVehicleList: (params: any) => void;
  vehicleList: IVehicle[];
  isFetching: string[];
  metaData: IMeta;
}

interface IState {
  activeTab: string;
}

const excelHeaders = [
  { label: 'No Polisi', key: 'policeNo' },
  { label: 'Tipe Kendaraan', key: 'vehicleType' },
  { label: 'Supir', key: 'driver' },
  { label: 'Vendor', key: 'vendor' },
  { label: 'Pajak 1 Tahun', key: 'oneYearTaxDate' },
  { label: 'Pajak 5 Tahun', key: 'fiveYearTaxDate' },
];

const title = 'Kelola Kendaraan';
const subTitle = 'Daftar Kendaraan';
const direksi = isDirection();
export class PageVehicleComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activeTab: TabType.All,
    };
  }
  public handleUpdateVehicleType = async (code: string, body: IVehicleType) => {
    const { updateVehicleType, fetchVehicleList } = this.props;
    await updateVehicleType(code, body);
    fetchVehicleList(defaultParams);
  };

  public render() {
    const {
      createVehicleType,
      fetchVehicleTypeList,
      fetchVehicleList,
      vehicleTypeList,
      vehicleList,
      isFetching,
      metaData,
    } = this.props;
    const { activeTab } = this.state;
    const loading = isLoading(isFetching, DataName.VEHICLE);
    const tableProps = {
      isFetching: loading,
      dataSource: vehicleList,
      meta: metaData,
      fetchDataSource: fetchVehicleList,
    };

    const excelSource = vehicleList?.map((data) => ({
      policeNo: data.policeNo,
      vehicleType: data.vehicle_type?.name,
      driver: data.driver?.fullName,
      vendor: data.driver?.vendor?.name,
      oneYearTaxDate: formatTime(data.oneYearTaxDate, dateFormat),
      fiveYearTaxDate: formatTime(data.fiveYearTaxDate, dateFormat),
    }));

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <TableVehicleTypeComponent
          isFetching={loading}
          fetchDataSource={fetchVehicleTypeList}
          dataSource={vehicleTypeList}
          createVehicleType={createVehicleType}
          updateVehicleType={this.handleUpdateVehicleType}
          meta={metaData}
        />
        <br />
        <Card
          title="Daftar Kendaraan"
          bordered={false}
          extra={[
            <Link key="1" to={`/vehicle/form`} style={{ marginRight: direksi ? 8 : 0 }}>
              <Button type="primary">
                <Icon type="plus" />
                Tambah Kendaraan
              </Button>
            </Link>,
            direksi && (
              <CSVLink
                key="2"
                data={excelSource}
                headers={excelHeaders}
                filename={`${subTitle}.xls`}
                target="_blank"
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Export to Excel
                </Button>
              </CSVLink>
            ),
          ]}
        >
          <Tabs
            defaultActiveKey={TabType.All}
            animated={false}
            onChange={(activeKey) => this.setState({ activeTab: activeKey })}
          >
            <TabPane tab="Semua Kendaraan" key={TabType.All}>
              <TableVehicleComponent
                {...tableProps}
                type={TabType.All}
                isActive={activeTab === TabType.All}
              />
            </TabPane>
            <TabPane tab="Kendaraan Expired" key={TabType.Expired}>
              <TableVehicleComponent
                {...tableProps}
                type={TabType.Expired}
                isActive={activeTab === TabType.Expired}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Fragment>
    );
  }
}
export default PageVehicleComponent;
