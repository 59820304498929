import Invoice from '@adaptors-api/invoice-api';
import { IInvoice } from '@interfaces/iinvoice';
import { IMeta } from '@interfaces/imeta';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.INVOICE));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.INVOICE));
};

export const setInvoice = (invoice?: IInvoice) => ({
  invoice,
  type: ActionTypes.PAGE_INVOICE_SET_INVOICE,
});

export const setInvoiceList = (invoiceList: IInvoice[]) => ({
  invoiceList,
  type: ActionTypes.PAGE_INVOICE_SET_INVOICE_LIST,
});

export const fetchInvoice = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setInvoice(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(Invoice.getInvoice(code))
    .then((response: any) => {
      const { data } = response.data;
      const invoice: IInvoice = data;
      dispatch(setInvoice(invoice));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchInvoiceList = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Invoice.getInvoices(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const invoiceList: IInvoice[] = data;
      const metaData: IMeta = meta;
      dispatch(setInvoiceList(invoiceList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchAlertInvoiceList = () => {
  return RequestAPI(Invoice.getInvoices({ isAlert: true }))
    .then((response: any) => {
      const { data } = response.data;
      return Boolean(data);
    })
    .catch((e: Error) => {
      return undefined;
    });
};

export const createInvoice = (body: IInvoice) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Invoice.createInvoice(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Invoice ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Invoice Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateInvoice = (code: string, body: IInvoice) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Invoice.updateInvoice(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Invoice ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Invoice Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteInvoice = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Invoice.deleteInvoice(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Invoice ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Invoice Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
