import { IDriver } from '@interfaces/idriver';
import { createDriver, fetchDriver, updateDriver } from '@pages/page-master/page-driver/action';
import { fetchVendorList } from '@pages/page-master/page-vendor/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageDriverFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  driver: state.PageMasterDataReducer.driver,
  vendorList: state.PageMasterDataReducer.vendorList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVendorList: (params: any) => dispatch(fetchVendorList(params)),
  fetchDriver: (code: string) => dispatch(fetchDriver(code)),
  createDriver: (body: IDriver) => dispatch(createDriver(body)),
  updateDriver: (code: string, body: IDriver) => dispatch(updateDriver(code, body)),
});

export const PageDriverFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDriverFormComponent);

export default PageDriverFormContainer;
