import { IPurchaseOrder } from '@interfaces/ipurchase-order';
import {
  createPurchaseOrder,
  fetchPurchaseOrder,
  updatePurchaseOrder,
} from '@pages/page-account-receivable/page-purchase-order/action';
import { fetchEmployeeList } from '@pages/page-administration/page-employee/action';
import { fetchCustomerList } from '@pages/page-master/page-customer/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PagePurchaseOrderFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  purchaseOrder: state.PageAccountReceivableReducer.purchaseOrder,
  customerList: state.PageMasterDataReducer.customerList,
  employeeList: state.PageAdministrationReducer.employeeList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchEmployeeList: (params: any) => dispatch(fetchEmployeeList(params)),
  fetchCustomerList: (params: any) => dispatch(fetchCustomerList(params)),
  fetchPurchaseOrder: (code: string) => dispatch(fetchPurchaseOrder(code)),
  createPurchaseOrder: (body: IPurchaseOrder) => dispatch(createPurchaseOrder(body)),
  updatePurchaseOrder: (code: string, body: IPurchaseOrder) =>
    dispatch(updatePurchaseOrder(code, body)),
});

export const PagePurchaseOrderFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PagePurchaseOrderFormComponent);

export default PagePurchaseOrderFormContainer;
