import { IRole } from '@interfaces/irole';
import { IUser } from '@interfaces/iuser';

export enum urlAdministration {
  employees = 'employees',
  users = 'users',
  roles = 'roles',
}

export const getEmployee = (code: string) => ({
  method: 'GET',
  url: `${urlAdministration.employees}/${code}`,
});

export const getEmployees = (params?: any) => ({
  method: 'GET',
  params,
  url: urlAdministration.employees,
});

export const getUser = (code: string) => ({
  method: 'GET',
  url: `${urlAdministration.users}/${code}`,
});

export const getUsers = (params?: any) => ({
  method: 'GET',
  params,
  url: urlAdministration.users,
});

export const updateUser = (code: string, body: IUser) => ({
  body,
  method: 'PUT',
  url: `${urlAdministration.users}/${code}`,
});

export const deleteUser = (code: string) => ({
  method: 'DELETE',
  url: `${urlAdministration.users}/${code}`,
});

export const getRoles = (params?: any) => ({
  method: 'GET',
  params,
  url: urlAdministration.roles,
});

export const updateRole = (roleId: number, body: IRole) => ({
  body,
  method: 'PUT',
  url: `${urlAdministration.roles}/${roleId}`,
});

export default {
  getEmployee,
  getEmployees,
  getUser,
  getUsers,
  updateUser,
  deleteUser,
  getRoles,
  updateRole,
};
