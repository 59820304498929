import {
  DataName,
  dateFormat,
  defaultUploadImage,
  emptyParams,
  fileType,
  FormType,
} from '@utils/constants';
import { IDriver } from '@interfaces/idriver';
import { IVendor } from '@interfaces/ivendor';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Icon, Input, PageHeader, Row, Select, Upload } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import InputMask from 'react-input-mask';
import { getBase64, getUploadedFile } from '@utils/upload';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchVendorList: (params: any) => void;
  vendorList: IVendor[];
  fetchDriver: (code: string) => void;
  driver?: IDriver;
  createDriver: (body: IDriver) => void;
  updateDriver: (code: string, body: IDriver) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
  imageKTP: string;
  imageSIM: string;
  imageNPWP: string;
}

export class PageDriverFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { code } = props.match.params;
    this.state = {
      imageKTP: '',
      imageSIM: '',
      imageNPWP: '',
      formType: code ? 'edit' : 'add',
    };
  }
  public componentDidMount = () => {
    const { match, fetchDriver, fetchVendorList } = this.props;
    const { code } = match.params;
    fetchDriver(code);
    fetchVendorList(emptyParams);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.driver !== this.props.driver) {
      const { driver } = this.props;
      this.setState({
        imageKTP: driver?.imageKTP || '',
        imageSIM: driver?.imageSIM || '',
        imageNPWP: driver?.imageNPWP || '',
      });
    }
  };
  public handleSubmit = () => {
    const { form, driver, createDriver, updateDriver } = this.props;
    const { formType, imageKTP, imageSIM, imageNPWP } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataDriver: IDriver = {
          ...driver,
          fullName: values.fullName,
          address: values.address,
          phone: values.phoneNumber,
          vendorsId: values.vendor,
          KTP: values.ktp,
          imageKTP,
          SIM: values.sim,
          imageSIM,
          expiryDateSIM: formatTime(values.expiryDateSIM, 'YYYY-MM-DD'),
          NPWP: values.npwp ? values.npwp.toString().replace(/-|\./g, '') : undefined,
          imageNPWP,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && driver?.code) {
          await updateDriver(driver.code, dataDriver);
        } else {
          await createDriver({ ...dataDriver, created_by: currentUser });
        }
        history.push('/driver');
      }
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const isValidDocument: boolean = defaultUploadImage.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG or PNG file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public handleUpload = async (option: any, type: string) => {
    try {
      getBase64(option.file, (fileBase64) => {
        switch (type) {
          case fileType.KTP:
            this.setState({ imageKTP: fileBase64 });
            break;
          case fileType.SIM:
            this.setState({ imageSIM: fileBase64 });
            break;
          case fileType.NPWP:
            this.setState({ imageNPWP: fileBase64 });
            break;
        }
        option.onSuccess(fileBase64, option.file);
      });
    } catch (e) {
      option.onError(e, option.file);
    }
  };
  public handleNPWPValidation = (rule: any, value: any, callback: any) => {
    if (value) {
      const fomattedValue: string = value.replace(/[^\d\s]/g, '');
      if (fomattedValue.length < 15) {
        callback('NPWP number minimal 15 digits!');
      }
    }
    callback();
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { isFetching, form, driver, vendorList } = this.props;
    const { formType } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const title = formType && formType === 'edit' ? 'Ubah Supir' : 'Tambah Supir';
    const loading = isLoading(isFetching, DataName.DRIVER);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Vendor">
                  {getFieldDecorator('vendor', {
                    initialValue: driver?.vendor?.id ?? '',
                    rules: [{ message: 'Vendor harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Vendor"
                      optionFilterProp="children"
                    >
                      {vendorList &&
                        vendorList.map((item: IVendor) => (
                          <Option key={item.code} value={item.id}>
                            {companyTypeCase(item.type, item.name)}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Nama Supir">
                  {getFieldDecorator('fullName', {
                    initialValue: driver?.fullName ?? '',
                    rules: [{ required: true, message: 'Nama supir harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nama Supir" />)}
                </Form.Item>
                <Form.Item label="Nomor SIM">
                  {getFieldDecorator('sim', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: driver?.SIM ?? '',
                    rules: [
                      { min: 5, message: 'Nomor SIM minimal 5 digit!' },
                      { max: 16, message: 'Nomor SIM maksimal 16 digit!' },
                      { message: 'Nomor SIM hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nomor SIM" maxLength={16} />)}
                </Form.Item>
                <Form.Item label="SIM File">
                  {getFieldDecorator('imageSIM', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.SIM, driver?.imageSIM),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png, .pdf"
                      customRequest={(option) => this.handleUpload(option, fileType.SIM)}
                      onRemove={() => {
                        this.setState({ imageSIM: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageSIM') !== undefined &&
                          getFieldValue('imageSIM').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Masa Berlaku SIM">
                  {getFieldDecorator('expiryDateSIM', {
                    initialValue: driver?.expiryDateSIM ? moment(driver?.expiryDateSIM) : moment(),
                    rules: [{ required: true, message: 'Masa Berlaku SIM harus diisi!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Alamat">
                  {getFieldDecorator('address', {
                    initialValue: driver?.address ?? '',
                    rules: [
                      { min: 3, message: 'Alamat minimal 3 karakter!' },
                      { max: 250, message: 'Alamat maksimal 250 karakter!' },
                      { required: true, message: 'Alamat harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Alamat Kantor, Nama Jalan, Kode Pos, lain-lain." />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Nomor KTP">
                  {getFieldDecorator('ktp', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: driver?.KTP ?? '',
                    rules: [
                      { min: 5, message: 'Nomor KTP minimal 5 digit!' },
                      { max: 16, message: 'Nomor KTP maksimal 16 digit!' },
                      { message: 'Nomor KTP hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nomor KTP" maxLength={16} />)}
                </Form.Item>
                <Form.Item label="KTP File">
                  {getFieldDecorator('imageKTP', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.KTP, driver?.imageKTP),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png, .pdf"
                      customRequest={(option) => this.handleUpload(option, fileType.KTP)}
                      onRemove={() => {
                        this.setState({ imageKTP: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageKTP') !== undefined &&
                          getFieldValue('imageKTP').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Nomor NPWP">
                  {getFieldDecorator('npwp', {
                    initialValue: driver?.NPWP ?? '',
                    rules: [{ validator: this.handleNPWPValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <InputMask
                      mask="99.999.999.9-999.999"
                      className="ant-input"
                      placeholder="Nomor NPWP"
                    />,
                  )}
                </Form.Item>
                <Form.Item label="NPWP File">
                  {getFieldDecorator('imageNPWP', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.NPWP, driver?.imageNPWP),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png, .pdf"
                      customRequest={(option) => this.handleUpload(option, fileType.NPWP)}
                      onRemove={() => {
                        this.setState({ imageNPWP: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageNPWP') !== undefined &&
                          getFieldValue('imageNPWP').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Telepon">
                  {getFieldDecorator('phoneNumber', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: driver?.phone ?? '',
                    rules: [
                      { min: 5, message: 'Telepon minimal 5 digit!' },
                      { max: 15, message: 'Telepon maksimal 15 digit!' },
                      { message: 'Telepon hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Telepon harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: driver?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageDriverFormComponent);
