import { IDashboard } from '@interfaces/idashboard';
import ActionTypes from '@store/action-types';

export interface IDashboardReducer {
  dashboard?: IDashboard;
}

export const PageDashboardReducer = (state: IDashboardReducer = {}, action: any) => {
  switch (action.type) {
    case ActionTypes.PAGE_DASHBOARD_SET_DASHBOARD:
      return { ...state, dashboard: action.dashboard };
    default:
      return state;
  }
};

export default PageDashboardReducer;
