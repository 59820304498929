import { IEmployee } from '@interfaces/iemployee';
import { IRole } from '@interfaces/irole';
import { IUser } from '@interfaces/iuser';
import ActionTypes from '@store/action-types';

export interface IAdministrationReducer {
  roleList: IRole[];
  user?: IUser;
  userList: IUser[];
  employee?: IEmployee;
  employeeList: IEmployee[];
}

const initialState: IAdministrationReducer = {
  roleList: [],
  userList: [],
  employeeList: [],
};

export const PageAdministrationReducer = (
  state: IAdministrationReducer = initialState,
  action: any,
) => {
  switch (action.type) {
    case ActionTypes.PAGE_EMPLOYEE_SET_EMPLOYEE:
      return {
        ...state,
        employee: action.employee,
      };
    case ActionTypes.PAGE_EMPLOYEE_SET_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.employeeList,
      };
    case ActionTypes.PAGE_EMPLOYEE_SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case ActionTypes.PAGE_EMPLOYEE_SET_USER_LIST:
      return {
        ...state,
        userList: action.userList,
      };
    case ActionTypes.PAGE_EMPLOYEE_SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.roleList,
      };
    case ActionTypes.PAGE_ROLE_SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.roleList,
      };
    default:
      return state;
  }
};

export default PageAdministrationReducer;
