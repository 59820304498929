import Driver from '@adaptors-api/driver-api';
import { IDriver } from '@interfaces/idriver';
import { IMeta } from '@interfaces/imeta';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.DRIVER));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.DRIVER));
};

export const setDriver = (driver?: IDriver) => ({
  driver,
  type: ActionTypes.PAGE_DRIVER_SET_DRIVER,
});

export const setDriverList = (driverList: IDriver[]) => ({
  driverList,
  type: ActionTypes.PAGE_DRIVER_SET_DRIVER_LIST,
});

export const fetchDriver = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setDriver(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(Driver.getDriver(code))
    .then((response: any) => {
      const { data } = response.data;
      const driver: IDriver = data;
      dispatch(setDriver(driver));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchDriverList = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Driver.getDrivers(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const driverList: IDriver[] = data;
      const metaData: IMeta = meta;
      dispatch(setDriverList(driverList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createDriver = (body: IDriver) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Driver.createDriver(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Supir ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Supir Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateDriver = (code: string, body: IDriver) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Driver.updateDriver(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Supir ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Supir Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
