import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { deleteInvoice, fetchInvoiceList } from './action';
import PageInvoiceComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  invoiceList: state.PageAccountReceivableReducer.invoiceList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchInvoiceList: (params: any) => dispatch(fetchInvoiceList(params)),
  deleteInvoice: (code: string) => dispatch(deleteInvoice(code)),
});

export const PageInvoiceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageInvoiceComponent);

export default PageInvoiceContainer;
