import { IVendor } from '@interfaces/ivendor';
import { fetchEmployeeList } from '@pages/page-administration/page-employee/action';
import { createVendor, fetchVendor, updateVendor } from '@pages/page-master/page-vendor/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageVendorFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  vendor: state.PageMasterDataReducer.vendor,
  employeeList: state.PageAdministrationReducer.employeeList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchEmployeeList: (params: any) => dispatch(fetchEmployeeList(params)),
  fetchVendor: (code: string) => dispatch(fetchVendor(code)),
  createVendor: (body: IVendor) => dispatch(createVendor(body)),
  updateVendor: (code: string, body: IVendor) => dispatch(updateVendor(code, body)),
});

export const PageVendorFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageVendorFormComponent);

export default PageVendorFormContainer;
