import { IMeta } from '@interfaces/imeta';
import INotification from '@interfaces/inotification';
import { loadingType } from '@reducers/default-reducer';
import ActionTypes from '@store/action-types';

export const setNotification = (notification: INotification) => ({
  type: ActionTypes.SET_BASE_NOTIFICATION,
  notification,
});

export const setLoader = (loader: boolean) => ({
  type: ActionTypes.SET_BASE_LOADER,
  loader,
});

export const clearNotification = () => ({
  type: ActionTypes.CLEAR_BASE_NOTIFICATION,
});

export const isFetchingData = (command: loadingType, data: string) => ({
  command,
  data,
  type: ActionTypes.SET_IS_FETCH_DATA,
});

export const setMetaData = (metaData: IMeta) => ({
  metaData,
  type: ActionTypes.SET_META_DATA,
});

export const setMetaDataSecondary = (metaDataSecondary: IMeta) => ({
  metaDataSecondary,
  type: ActionTypes.SET_META_DATA_SECONDARY,
});
