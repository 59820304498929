import CashFlow from '@adaptors-api/cash-flow-api';
import { ICashFlow, ICashFlowRecap, ICoa } from '@interfaces/icash-flow';
import { IMeta } from '@interfaces/imeta';
import {
  isFetchingData,
  setMetaData,
  setNotification,
  setMetaDataSecondary,
} from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { DataName, EnumLoading } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.CASH_FLOW));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.CASH_FLOW));
};
const setLoadingRecap = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.CASH_FLOW));
};
const unsetLoadingRecap = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.CASH_FLOW));
};

export const setCashFlow = (cashFlow?: ICashFlow) => ({
  cashFlow,
  type: ActionTypes.PAGE_CASH_FLOW_SET_CASH_FLOW,
});

export const setCashFlowList = (cashFlowList: ICashFlow[]) => ({
  cashFlowList,
  type: ActionTypes.PAGE_CASH_FLOW_SET_CASH_FLOW_LIST,
});

export const setCashFlowRecap = (cashFlowRecap?: ICashFlowRecap) => ({
  cashFlowRecap,
  type: ActionTypes.PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP,
});

export const setCashFlowRecapDetail = (cashFlowRecapDetail?: ICashFlow[]) => ({
  cashFlowRecapDetail,
  type: ActionTypes.PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_DETAIL,
});

export const setCashFlowRecapList = (cashFlowRecapList: ICashFlowRecap[]) => ({
  cashFlowRecapList,
  type: ActionTypes.PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_LIST,
});

export const setCoaList = (coaList: ICoa[]) => ({
  coaList,
  type: ActionTypes.PAGE_COA_SET_COA_LIST,
});

export const fetchCashFlow = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setCashFlow(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(CashFlow.getCashFlow(code))
    .then((response: any) => {
      const { data } = response.data;
      const cashFlow: ICashFlow = data;
      dispatch(setCashFlow(cashFlow));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchCashFlowList = (
  params?: any,
  isDetail: boolean = false,
  isSetMeta: boolean = true,
) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(CashFlow.getCashFlows(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const cashFlowList: ICashFlow[] = data;
      const metaData: IMeta = meta;
      if (!isDetail) {
        dispatch(setCashFlowList(cashFlowList));

        if (isSetMeta) {
          dispatch(setMetaData(metaData));
        }
      } else {
        dispatch(setCashFlowRecapDetail(cashFlowList));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createCashFlow = (body: ICashFlow) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(CashFlow.createCashFlow(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Cash Flow ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Cash Flow Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateCashFlow = (code: string, body: ICashFlow) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(CashFlow.updateCashFlow(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Cash Flow ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Cash Flow Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteCashFlow = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(CashFlow.deleteCashFlow(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Cash Flow ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Cash Flow Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const fetchCashFlowRecap = (code: string) => (dispatch: any) => {
  setLoadingRecap(dispatch);

  if (!code) {
    dispatch(setCashFlowRecap(undefined));
    unsetLoadingRecap(dispatch);
    return;
  }

  return RequestAPI(CashFlow.getCashFlowRecap(code))
    .then((response: any) => {
      const { data } = response.data;
      const cashFlowRecap: ICashFlowRecap = data;
      dispatch(setCashFlowRecap(cashFlowRecap));
      unsetLoadingRecap(dispatch);
    })
    .catch((e: Error) => {
      unsetLoadingRecap(dispatch);
    });
};

export const fetchCashFlowRecapList = (params?: any, isSetMeta: boolean = true) => (
  dispatch: any,
) => {
  setLoadingRecap(dispatch);

  return RequestAPI(CashFlow.getCashFlowRecaps(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const cashFlowRecapList: ICashFlowRecap[] = data;
      const metaDataSecondary: IMeta = meta;
      dispatch(setCashFlowRecapList(cashFlowRecapList));
      if (isSetMeta) {
        dispatch(setMetaDataSecondary(metaDataSecondary));
      }
      unsetLoadingRecap(dispatch);
    })
    .catch((e: Error) => {
      unsetLoadingRecap(dispatch);
    });
};

export const createCashFlowRecap = (body: ICashFlowRecap) => (dispatch: any) => {
  setLoadingRecap(dispatch);

  return RequestAPI(CashFlow.createCashFlowRecap(body))
    .then((response: any) => {
      unsetLoadingRecap(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Cash FlowRecap ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: any) => {
      unsetLoadingRecap(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Rekap Cash Flow Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateCashFlowRecap = (code: string, body: ICashFlowRecap) => (dispatch: any) => {
  setLoadingRecap(dispatch);

  return RequestAPI(CashFlow.updateCashFlowRecap(code, body))
    .then((response: any) => {
      unsetLoadingRecap(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Rekap Cash Flow ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoadingRecap(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Rekap Cash Flow Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteCashFlowRecap = (code: string) => (dispatch: any) => {
  setLoadingRecap(dispatch);

  return RequestAPI(CashFlow.deleteCashFlowRecap(code))
    .then((response: any) => {
      unsetLoadingRecap(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Rekap Cash Flow ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoadingRecap(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Cash Flow Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const fetchCoaList = (params?: any) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(CashFlow.getCoas(params))
    .then((response: any) => {
      const { data } = response.data;
      const coaList: ICoa[] = data;
      dispatch(setCoaList(coaList));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};
