import { DocumentHeader } from '@components/atoms/document-header';
import { dateFormat, paymentTypeInverse } from '@utils/constants';
import { ITandaTerima, ITandaTerimaDetail } from '@interfaces/itanda-terima';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Col, Descriptions, Modal, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import DetailTableComponent from './detail-table';

interface IProps {
  title: string;
  show: boolean;
  hide: () => void;
  data: ITandaTerima;
}

export class ModalPrintComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const { title, show, hide, data } = this.props;
    const { code, orderDate, customer, recipient, warehouse, details } = data;
    let total: number = 0;
    details.map((item: ITandaTerimaDetail) => {
      total += item.total;
    });
    const { type, name, phone, city } = customer || {};
    const companyName = companyTypeCase(type, name);
    return (
      <Modal
        title={title}
        visible={show}
        width={1100}
        okText="Cetak"
        cancelText="Batal"
        onCancel={hide}
        onOk={() => window.print()}
      >
        <DocumentHeader title={title} />
        <br />
        <Descriptions column={2} size="small" className="doc-header-info small">
          <Descriptions.Item label="Tanggal">
            {formatTime(moment(orderDate), dateFormat)}
          </Descriptions.Item>
          <Descriptions.Item label="No" className="bt-text-right">
            {code}
          </Descriptions.Item>
          <Descriptions.Item label="Kepada Yth">
            {recipient?.picPhone
              ? `${recipient?.name} (${recipient?.picPhone})`
              : `${recipient?.name}`}
            <br />
            {recipient?.address && `${recipient?.address}. `} {recipient?.city}
            <br />
          </Descriptions.Item>
          <Descriptions.Item label="Pengirim">
            {phone ? `${companyName} (${phone})` : `${companyName}`}
            <br /> {city}
            <br />
          </Descriptions.Item>
        </Descriptions>
        <DetailTableComponent
          master={data}
          dataSource={details}
          showPrice={false}
          isPrint={true}
          description={warehouse?.address && `Gudang ${warehouse?.address}`}
        />
        <div className="doc-footer">
          <Row justify="space-between">
            <Col xs={12} className="hide">
              Tanggal diterima:
            </Col>
            <Col xs={8} className="hide bt-text-right">
              Total:
            </Col>
            <Col xs={4} className="bt-text-right">
              {data.paymentType === paymentTypeInverse.Cash ? `${currencyCase(total)}` : '-'}
            </Col>
            <Col xs={24} className="hide bt-text-right">
              Hormat Kami,
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 44 }}>
            <Col xs={12} className="hide">
              <b>Nama, tanda tangan, dan cap stempel</b>
            </Col>
            <Col xs={12} className="bt-text-right">
              {data.created_by}
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default ModalPrintComponent;
