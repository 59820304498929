import loginImageUrl from '@assets/images/login-background.png';
import LogoImage from '@assets/images/logo.svg';
import { LayoutLogin } from '@components/templates/page-login';
import { IParamsResetPassword } from '@interfaces/iparams';
import history from '@utils/history';
import { Alert, Button, Form, Icon, Input, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/es/form/FormItem';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';

interface IProps extends FormComponentProps {
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  successMessage: string;
  setSuccessMessage: (success: string) => void;
  resetPasswordFind: (token: string) => any;
  resetPassword: (body: IParamsResetPassword) => any;
  resetPasswordInfo?: IParamsResetPassword;
  isSubmit: boolean;
  match: any;
}

interface IState {
  confirmDirty: boolean;
  email: string;
  token: string;
}

export class PageResetPasswordComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      confirmDirty: false,
      email: '',
      token: '',
    };
  }
  public componentDidMount = () => {
    const { match, resetPasswordFind } = this.props;
    const { token } = match.params;
    resetPasswordFind(token);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.resetPasswordInfo !== this.props.resetPasswordInfo) {
      const { resetPasswordInfo } = this.props;
      if (resetPasswordInfo) {
        this.setState({ email: resetPasswordInfo.email, token: resetPasswordInfo.token });
      }
    }
  };
  public handleCloseAlert = () => {
    const { setErrorMessage, errorMessage, setSuccessMessage, successMessage } = this.props;
    if (errorMessage) {
      setErrorMessage('');
    }
    if (successMessage) {
      setSuccessMessage('');
    }
  };
  public validateToNextPassword = (rule: any, value: any, callback: () => void) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;

    if (value && confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };
  public compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Konfirmasi password tidak cocok!');
    } else {
      callback();
    }
  };
  public backToLogin = () => {
    this.handleCloseAlert();
    history.push('/auth');
  };
  public handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const {
      form: { validateFields, resetFields },
      resetPassword,
      successMessage,
      resetPasswordInfo,
    } = this.props;
    validateFields(async (error: any, values: any) => {
      if (!error && resetPasswordInfo) {
        const { email, token } = this.state;
        const params: IParamsResetPassword = {
          email,
          token,
          password: values.password,
          password_confirmation: values.confirmPassword,
        };
        await resetPassword(params);
        if (successMessage && successMessage !== '') {
          resetFields();
        }
        history.push('/auth');
      }
    });
  };
  public render() {
    const { errorMessage, successMessage, isSubmit, resetPasswordInfo } = this.props;
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { email } = this.state;

    return (
      <LayoutLogin
        loginLogo={LogoImage}
        loginTitleName="Minofa System"
        loginTitleDesc=""
        loginImageUrl={loginImageUrl}
      >
        <Spin spinning={isSubmit}>
          <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - Reset Password`} />
          {errorMessage && errorMessage !== '' && (
            <Alert
              message={errorMessage}
              type="error"
              closable={true}
              afterClose={this.handleCloseAlert}
            />
          )}
          {successMessage && successMessage !== '' && (
            <Alert
              message={successMessage}
              type="success"
              closable={true}
              afterClose={this.handleCloseAlert}
            />
          )}
          {resetPasswordInfo ? (
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator('email', {
                  initialValue: email,
                  rules: [
                    { message: 'Email tidak valid!', type: 'email' },
                    { message: 'Email harus diisi!', required: true },
                  ],
                })(
                  <Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                    disabled={true}
                  />,
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Password harus diisi!' },
                    { validator: this.validateToNextPassword },
                  ],
                })(
                  <Input.Password
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                  />,
                )}
              </FormItem>
              <Fragment>
                <FormItem>
                  {getFieldDecorator('confirmPassword', {
                    rules: [
                      { message: 'Konfirmasi password harus diisi!', required: true },
                      { validator: this.compareToFirstPassword },
                    ],
                  })(
                    <Input.Password
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Konfirmasi Password"
                    />,
                  )}
                </FormItem>
              </Fragment>
              <FormItem>
                <Button type="primary" htmlType="submit" className="login-form-button" block={true}>
                  Reset Password
                </Button>
              </FormItem>
            </Form>
          ) : (
            <Button block={true} onClick={this.backToLogin}>
              Kembali Ke Login
            </Button>
          )}
        </Spin>
      </LayoutLogin>
    );
  }
}
export default Form.create<IProps>()(PageResetPasswordComponent);
