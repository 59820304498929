import { fetchVehicle } from '@pages/page-master/page-vehicle/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageVehicleDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  vehicle: state.PageMasterDataReducer.vehicle,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVehicle: (code: string) => dispatch(fetchVehicle(code)),
});

export const PageVehicleDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageVehicleDetailComponent);

export default PageVehicleDetailContainer;
