export const StringIsNumber = (value: any) => isNaN(Number(value)) === false;

// Turn enum into array
export const enumToArray = (enumme: { [x: string]: any }) => {
  const notNumber = Object.keys(enumme)
    .filter(StringIsNumber)
    .map((key) => enumme[key]);

  if (!notNumber || notNumber?.length === 0) {
    return Object.keys(enumme).map((key) => enumme[key]);
  } else {
    return notNumber;
  }
};
