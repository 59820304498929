import { DataName, dateFormat } from '@utils/constants';
import { IVehicle } from '@interfaces/ivehicle';
import { goBack } from '@utils/history';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchVehicle: (code: string) => void;
  vehicle?: IVehicle;
  isFetching: string[];
  match: any;
}

const title = 'Detail Kendaraan';
export class PageVehicleDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchVehicle } = this.props;
    fetchVehicle(match.params.code);
  };
  public render() {
    const { vehicle, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.VEHICLE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/vehicle/form${vehicle && `/${vehicle.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Vehicle
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {vehicle && (
            <Descriptions
              title={`Kendaraan: ${vehicle.code}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Tipe Kendaraan">
                {vehicle.vehicle_type?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Nomor Polisi">{vehicle.policeNo || '-'}</Descriptions.Item>
              <Descriptions.Item label="Vendor">
                {vehicle.driver &&
                  vehicle.driver.vendor &&
                  companyTypeCase(vehicle.driver.vendor.type, vehicle.driver.vendor.name)}
              </Descriptions.Item>
              <Descriptions.Item label="Nama Supir">
                {vehicle.driver?.fullName || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Telepon Supir">
                {vehicle.driver?.phone || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="STNK">
                {vehicle.imageSTNK ? (
                  <a target="_blank" href={vehicle.imageSTNK}>
                    {vehicle.STNK}
                  </a>
                ) : (
                  vehicle.STNK || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Pajak Satu Tahun">
                {formatTime(vehicle.oneYearTaxDate, dateFormat) || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Pajak Lima Tahun">
                {formatTime(vehicle.fiveYearTaxDate, dateFormat) || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="KIR">{vehicle.KIR || '-'}</Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(vehicle.remarks) ? 'warning' : ''}
                span={3}
              >
                {vehicle.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageVehicleDetailComponent;
