import { fetchCustomer } from '@pages/page-master/page-customer/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageCustomerDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  customer: state.PageMasterDataReducer.customer,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCustomer: (code: string) => dispatch(fetchCustomer(code)),
});

export const PageCustomerDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCustomerDetailComponent);

export default PageCustomerDetailContainer;
