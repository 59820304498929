import { IUser } from '@interfaces/iuser';
import { fetchUser, updateUser } from '@pages/page-administration/page-employee/action';
import { fetchRoleList } from '@pages/page-administration/page-role/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageEmployeeFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  roleList: state.PageAdministrationReducer.roleList,
  user: state.PageAdministrationReducer.user,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchRoleList: (params?: any) => dispatch(fetchRoleList(params)),
  fetchUser: (code: string) => dispatch(fetchUser(code)),
  updateUser: (code: string, body: IUser) => dispatch(updateUser(code, body)),
});

export const PageEmployeeFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageEmployeeFormComponent);

export default PageEmployeeFormContainer;
