import { IPurchaseOrder } from '@interfaces/ipurchase-order';
import { DataName } from '@utils/constants';
import { goBack } from '@utils/history';
import { isLoading } from '@utils/loading';
import { companyTypeCase, currencyCase, orderStatusCase } from '@utils/string-case';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

interface IProps {
  fetchPurchaseOrder: (code: string) => void;
  purchaseOrder?: IPurchaseOrder;
  isFetching: string[];
  match: any;
}

const title = 'Detail Purchase Order';
export class PagePurchaseOrderDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchPurchaseOrder } = this.props;
    fetchPurchaseOrder(match.params.code);
  };
  public render() {
    const { purchaseOrder, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.PURCHASE_ORDER);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/purchase-order/form${purchaseOrder && `/${purchaseOrder.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Purchase Order
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {purchaseOrder && (
            <Descriptions
              title={`Purchase Order: ${purchaseOrder.code}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Customer" span={3}>
                {purchaseOrder.customer &&
                  companyTypeCase(purchaseOrder.customer.type, purchaseOrder.customer.name)}
              </Descriptions.Item>
              <Descriptions.Item label="Tanggal Muat">{purchaseOrder.orderDate}</Descriptions.Item>
              <Descriptions.Item label="Jenis Muatan">{purchaseOrder.orderType}</Descriptions.Item>
              <Descriptions.Item label="Tonase">{`${purchaseOrder.tonase} ${purchaseOrder.tonaseType}`}</Descriptions.Item>
              <Descriptions.Item label="Harga Vendor">
                {purchaseOrder.vendorPrice ? currencyCase(purchaseOrder.vendorPrice) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Down Payment Vendor">
                {purchaseOrder.vendorDownPayment
                  ? currencyCase(purchaseOrder.vendorDownPayment)
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Jenis Mobil">
                {purchaseOrder.vehicleType || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="No Polisi Mobil">
                {purchaseOrder.policeNo || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Nama Supir">
                {purchaseOrder.driverName || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Telepon Supir">
                {purchaseOrder.driverPhone || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Jenis Pengiriman">
                {purchaseOrder.deliveryType}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat Pickup">
                {purchaseOrder.pickupAddress || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat Tujuan">
                {purchaseOrder.destinationAddress || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Sales Marketing">
                {purchaseOrder.salesMarketing}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {orderStatusCase(purchaseOrder.status)}
              </Descriptions.Item>
              <Descriptions.Item label="File PO">
                {purchaseOrder.filePO ? (
                  <a target="_blank" href={purchaseOrder.filePO}>
                    Lihat File
                  </a>
                ) : (
                  '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(purchaseOrder.remarks) ? 'warning' : ''}
              >
                {purchaseOrder.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PagePurchaseOrderDetailComponent;
