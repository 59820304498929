import { IMeta } from '@interfaces/imeta';
import { IRole } from '@interfaces/irole';
import { IUser } from '@interfaces/iuser';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { Card, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import TableComponent from './libraries/table';

interface IProps {
  fetchUserList: (params?: any) => void;
  userList?: IUser[];
  fetchRoleList: (params?: any) => void;
  roleList: IRole[];
  isFetching: string[];
  updateUser: (code: string, body: IUser) => void;
  deleteUser: (code: string) => void;
  metaData?: IMeta;
}

const title = 'Kelola Karyawan';
export class PageEmployeeComponent extends Component<IProps, any> {
  public render() {
    const {
      fetchUserList,
      userList,
      isFetching,
      updateUser,
      deleteUser,
      fetchRoleList,
      roleList,
      metaData,
    } = this.props;
    const loading = isLoading(isFetching, DataName.EMPLOYEE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card title="Karyawan List" bordered={false}>
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchUserList}
            dataSource={userList}
            updateUser={updateUser}
            deleteUser={deleteUser}
            fetchRoleList={fetchRoleList}
            roleList={roleList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageEmployeeComponent;
