import { Button, Result } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class PageNotFoundComponent extends Component {
  public render() {
    return (
      <Result
        style={{ paddingTop: '10%' }}
        status={404}
        title="404"
        subTitle="Maaf, halaman yang kamu cari tidak ditemukan."
        extra={
          <Link to="/dashboard">
            <Button type="primary">Kembali ke Dashboard</Button>
          </Link>
        }
      />
    );
  }
}
export default PageNotFoundComponent;
