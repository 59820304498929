import { IMeta } from '@interfaces/imeta';
import { IInvoiceReceipt } from '@interfaces/iinvoice-receipt';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';
import { isLoading } from '@utils/loading';
import { DataName } from '@utils/constants';

interface IProps {
  fetchInvoiceReceiptList: (params: any) => void;
  deleteInvoiceReceipt: (code: string) => void;
  invoiceReceiptList: IInvoiceReceipt[];
  isFetching: string[];
  metaData: IMeta;
}

const title = 'Kelola Tanda Terima Invoice';
export class PageInvoiceReceiptComponent extends Component<IProps, any> {
  public render() {
    const {
      fetchInvoiceReceiptList,
      deleteInvoiceReceipt,
      invoiceReceiptList,
      isFetching,
      metaData,
    } = this.props;
    const loading = isLoading(isFetching, DataName.INVOICE_RECEIPT);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Daftar Tanda Terima Invoice"
          bordered={false}
          extra={
            <Link to={`/invoice-receipt/form`}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Tanda Terima Invoice
              </Button>
            </Link>
          }
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchInvoiceReceiptList}
            deleteData={deleteInvoiceReceipt}
            dataSource={invoiceReceiptList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageInvoiceReceiptComponent;
