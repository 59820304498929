import { IWarehouse } from '@interfaces/iwarehouse';
import { fetchCustomerList } from '@pages/page-master/page-customer/action';
import {
  createWarehouse,
  fetchWarehouse,
  updateWarehouse,
} from '@pages/page-master/page-warehouse/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageWarehouseFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  warehouse: state.PageMasterDataReducer.warehouse,
  customerList: state.PageMasterDataReducer.customerList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCustomerList: (params: any) => dispatch(fetchCustomerList(params)),
  fetchWarehouse: (code: string) => dispatch(fetchWarehouse(code)),
  createWarehouse: (body: IWarehouse) => dispatch(createWarehouse(body)),
  updateWarehouse: (code: string, body: IWarehouse) => dispatch(updateWarehouse(code, body)),
});

export const PageWarehouseFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageWarehouseFormComponent);

export default PageWarehouseFormContainer;
