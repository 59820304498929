import { fetchDaftarMuatan } from '@pages/page-account-payable/page-daftar-muatan/action';
import { fetchTransitCostList } from '@pages/page-account-payable/page-transit-cost/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageDaftarMuatanDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  daftarMuatan: state.PageAccountPayableReducer.daftarMuatan,
  transitCostList: state.PageAccountPayableReducer.transitCostList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchDaftarMuatan: (code: string) => dispatch(fetchDaftarMuatan(code)),
  fetchTransitCostList: (params: any) => dispatch(fetchTransitCostList(params)),
});

export const PageDaftarMuatanDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDaftarMuatanDetailComponent);

export default PageDaftarMuatanDetailContainer;
