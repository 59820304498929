import { IInvoiceReceipt } from '@interfaces/iinvoice-receipt';
import {
  createInvoiceReceipt,
  fetchInvoiceReceipt,
  updateInvoiceReceipt,
} from '@pages/page-account-receivable/page-invoice-receipt/action';
import { connect } from 'react-redux';
import PageInvoiceReceiptFormComponent from './component';
import { fetchInvoiceList } from '@pages/page-account-receivable/page-invoice/action';
import { fetchEmployeeList } from '@pages/page-administration/page-employee/action';
import { IStateReducer } from '@reducers/state';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  invoiceList: state.PageAccountReceivableReducer.invoiceList,
  invoiceReceipt: state.PageAccountReceivableReducer.invoiceReceipt,
  employeeList: state.PageAdministrationReducer.employeeList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchEmployeeList: (params: any) => dispatch(fetchEmployeeList(params)),
  fetchInvoiceList: (params: any) => dispatch(fetchInvoiceList(params)),
  fetchInvoiceReceipt: (code: string) => dispatch(fetchInvoiceReceipt(code)),
  createInvoiceReceipt: (body: IInvoiceReceipt) => dispatch(createInvoiceReceipt(body)),
  updateInvoiceReceipt: (code: string, body: IInvoiceReceipt) =>
    dispatch(updateInvoiceReceipt(code, body)),
});

export const PageInvoiceReceiptFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageInvoiceReceiptFormComponent);

export default PageInvoiceReceiptFormContainer;
