import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { deleteInvoiceReceipt, fetchInvoiceReceiptList } from './action';
import PageInvoiceReceiptComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  invoiceReceiptList: state.PageAccountReceivableReducer.invoiceReceiptList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchInvoiceReceiptList: (params: any) => dispatch(fetchInvoiceReceiptList(params)),
  deleteInvoiceReceipt: (code: string) => dispatch(deleteInvoiceReceipt(code)),
});

export const PageInvoiceReceiptContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageInvoiceReceiptComponent);

export default PageInvoiceReceiptContainer;
