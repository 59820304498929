import { connect } from 'react-redux';
import {
  deleteCashFlow,
  fetchCashFlowList,
  fetchCashFlowRecapList,
  deleteCashFlowRecap,
  createCashFlowRecap,
  updateCashFlowRecap,
} from './action';
import PageCashFlowComponent from './component';
import { ICashFlowRecap } from '@interfaces/icash-flow';
import { IStateReducer } from '@reducers/state';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  metaDataSecondary: state.DefaultReducer.metaDataSecondary,
  cashFlowList: state.PageAccountPayableReducer.cashFlowList,
  cashFlowRecapList: state.PageAccountPayableReducer.cashFlowRecapList,
  cashFlowRecapDetail: state.PageAccountPayableReducer.cashFlowRecapDetail,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCashFlowList: (params: any, isDetail: boolean) =>
    dispatch(fetchCashFlowList(params, isDetail)),
  deleteCashFlow: (code: string) => dispatch(deleteCashFlow(code)),
  fetchCashFlowRecapList: (params: any) => dispatch(fetchCashFlowRecapList(params)),
  createCashFlowRecap: (body: ICashFlowRecap) => dispatch(createCashFlowRecap(body)),
  updateCashFlowRecap: (code: string, body: ICashFlowRecap) =>
    dispatch(updateCashFlowRecap(code, body)),
  deleteCashFlowRecap: (code: string) => dispatch(deleteCashFlowRecap(code)),
});

export const PageCashFlowContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCashFlowComponent);

export default PageCashFlowContainer;
