import { ITransitCost } from '@interfaces/itransit-cost';

const urlTransitCost = 'transit_costs';

export const getTransitCost = (code: string) => ({
  method: 'GET',
  url: `${urlTransitCost}/${code}`,
});

export const getTransitCosts = (params: any) => ({
  method: 'GET',
  params,
  url: urlTransitCost,
});

export const createTransitCost = (body: ITransitCost) => ({
  body,
  method: 'POST',
  url: `${urlTransitCost}`,
});

export const updateTransitCost = (code: string, body: ITransitCost) => ({
  body,
  method: 'PUT',
  url: `${urlTransitCost}/${code}`,
});

export const deleteTransitCost = (code: string) => ({
  method: 'DELETE',
  url: `${urlTransitCost}/${code}`,
});

export default {
  getTransitCost,
  getTransitCosts,
  createTransitCost,
  updateTransitCost,
  deleteTransitCost,
};
