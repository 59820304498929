import { fetchVendor } from '@pages/page-master/page-vendor/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageVendorDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  vendor: state.PageMasterDataReducer.vendor,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVendor: (code: string) => dispatch(fetchVendor(code)),
});

export const PageVendorDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageVendorDetailComponent);

export default PageVendorDetailContainer;
