import { ISuratMuatan } from '@interfaces/isurat-muatan';
import {
  createSuratMuatan,
  fetchSuratMuatan,
  updateSuratMuatan,
} from '@pages/page-account-receivable/page-surat-muatan/action';
import { fetchTandaTerimaList } from '@pages/page-account-receivable/page-tanda-terima/action';
import { fetchVehicleList } from '@pages/page-master/page-vehicle/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageSuratMuatanFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  suratMuatan: state.PageAccountReceivableReducer.suratMuatan,
  tandaTerimaList: state.PageAccountReceivableReducer.tandaTerimaList,
  vehicleList: state.PageMasterDataReducer.vehicleList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchTandaTerimaList: (params: any) => dispatch(fetchTandaTerimaList(params)),
  fetchVehicleList: (params: any) => dispatch(fetchVehicleList(params)),
  fetchSuratMuatan: (code: string) => dispatch(fetchSuratMuatan(code)),
  createSuratMuatan: (body: ISuratMuatan) => dispatch(createSuratMuatan(body)),
  updateSuratMuatan: (code: string, body: ISuratMuatan) => dispatch(updateSuratMuatan(code, body)),
});

export const PageSuratMuatanFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageSuratMuatanFormComponent);

export default PageSuratMuatanFormContainer;
