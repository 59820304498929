import SuratMuatan from '@adaptors-api/surat-muatan-api';
import TandaTerima from '@adaptors-api/tanda-terima-api';
import { IMeta } from '@interfaces/imeta';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { DataName, EnumLoading } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.SURAT_MUATAN));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.SURAT_MUATAN));
};

export const setSuratMuatan = (suratMuatan?: ISuratMuatan) => ({
  suratMuatan,
  type: ActionTypes.PAGE_SURAT_MUATAN_SET_SURAT_MUATAN,
});

export const setSuratMuatanList = (suratMuatanList: ISuratMuatan[]) => ({
  suratMuatanList,
  type: ActionTypes.PAGE_SURAT_MUATAN_SET_SURAT_MUATAN_LIST,
});

export const fetchSuratMuatan = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setSuratMuatan(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(
    code.substring(0, 2) === 'TT'
      ? TandaTerima.getTandaTerima(code)
      : SuratMuatan.getSuratMuatan(code),
  )
    .then((response: any) => {
      const { data } = response.data;
      const suratMuatan: ISuratMuatan = data;
      dispatch(setSuratMuatan(suratMuatan));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchSuratMuatanList = (params?: any, isSetMeta: boolean = true) => (
  dispatch: any,
) => {
  setLoading(dispatch);

  return RequestAPI(SuratMuatan.getSuratMuatans(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const suratMuatanList: ISuratMuatan[] = data;
      const metaData: IMeta = meta;
      dispatch(setSuratMuatanList(suratMuatanList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createSuratMuatan = (body: any) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(SuratMuatan.createSuratMuatan(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Surat Muatan ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Surat Muatan Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateSuratMuatan = (code: string, body: ISuratMuatan) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(SuratMuatan.updateSuratMuatan(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Surat Muatan ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Surat Muatan Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteSuratMuatan = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(SuratMuatan.deleteSuratMuatan(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Surat Muatan ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Surat Muatan Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
