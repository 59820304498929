import { IMeta } from '@interfaces/imeta';
import { IRecipient } from '@interfaces/irecipient';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchRecipientList: (params: any) => void;
  deleteRecipient: (code: string) => void;
  recipientList: IRecipient[];
  isFetching: string[];
  metaData: IMeta;
}

const title = 'Kelola Penerima';
export class PageRecipientComponent extends Component<IProps, any> {
  public render() {
    const { fetchRecipientList, deleteRecipient, recipientList, isFetching, metaData } = this.props;
    const loading = isLoading(isFetching, DataName.RECIPIENT);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Daftar Penerima"
          bordered={false}
          extra={
            <Link to={`/recipient/form`}>
              <Button type="primary">
                <Icon type="plus" />
                Tambah Penerima
              </Button>
            </Link>
          }
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchRecipientList}
            deleteData={deleteRecipient}
            dataSource={recipientList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageRecipientComponent;
