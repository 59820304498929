import { ICustomer } from '@interfaces/icustomer';
import { DataName } from '@utils/constants';
import { goBack } from '@utils/history';
import { isLoading } from '@utils/loading';
import { companyTypeCase } from '@utils/string-case';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

interface IProps {
  fetchCustomer: (code: string) => void;
  customer?: ICustomer;
  isFetching: string[];
  match: any;
}

const title = 'Detail Customer';
export class PageCustomerDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchCustomer } = this.props;
    fetchCustomer(match.params.code);
  };
  public render() {
    const { customer, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.CUSTOMER);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/customer/form${customer && `/${customer.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Customer
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {customer && (
            <Descriptions
              title={`Customer: ${customer.code}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Nama Customer" span={3}>
                {companyTypeCase(customer.type, customer.name)}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat" span={3}>
                {customer.address || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Kota">{customer.city || '-'}</Descriptions.Item>
              <Descriptions.Item label="Email">{customer.email || '-'}</Descriptions.Item>
              <Descriptions.Item label="Fax">{customer.fax || '-'}</Descriptions.Item>
              <Descriptions.Item label="Nama PIC">{customer.picName || '-'}</Descriptions.Item>
              <Descriptions.Item label="Telepon PIC">{customer.picPhone || '-'}</Descriptions.Item>
              <Descriptions.Item label="Sales Marketing">
                {customer.salesMarketing}
              </Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(customer.remarks) ? 'warning' : ''}
                span={3}
              >
                {customer.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageCustomerDetailComponent;
