import { IInvoice } from '@interfaces/iinvoice';
import { IVehicle } from '@interfaces/ivehicle';
import { fetchAlertInvoiceList } from '@pages/page-account-receivable/page-invoice/action';
import { fetchExpiredVehicleList } from '@pages/page-master/page-vehicle/action';
import { roleType } from '@utils/constants';
import { getModule } from '@utils/request-api';
import { Icon, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  match?: any;
  location?: any;
}

interface IState {
  selectedKey: string[];
  openKeys: string[];
  isExpiredVehicle: boolean;
  isAlertInvoice: boolean;
}
export class MenuSidebarComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedKey: ['dashboard'],
      openKeys: [],
      isExpiredVehicle: false,
      isAlertInvoice: false,
    };
  }

  public componentDidMount = () => {
    this.setMenuActive();
    fetchExpiredVehicleList()
      .then((data: IVehicle) => this.setState({ isExpiredVehicle: Boolean(data) }))
      .catch(this.setState({ isExpiredVehicle: false }));

    fetchAlertInvoiceList()
      .then((data: IInvoice) => this.setState({ isAlertInvoice: Boolean(data) }))
      .catch(this.setState({ isAlertInvoice: false }));
  };
  public onOpenChange = (openKeys: string[]) => {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
    this.setState({
      openKeys: latestOpenKey ? [latestOpenKey] : [],
    });
  };
  public onClickMenu = (key: string) => {
    const arrKey: any[] = [];
    arrKey.push(key);

    this.setState({ selectedKey: arrKey });
  };
  public onClickSideBar = (value: any) => {
    const key: string = value.key;

    this.onClickMenu(key);
  };
  public setMenuActive = () => {
    const pathname: string = location.pathname;
    const selectedKey: string = pathname.split('/')[1];

    this.onClickMenu(selectedKey);
  };
  public render() {
    const { openKeys, selectedKey, isExpiredVehicle, isAlertInvoice } = this.state;
    return (
      <Menu
        defaultSelectedKeys={['dashboard']}
        mode="inline"
        theme="dark"
        openKeys={openKeys}
        onOpenChange={this.onOpenChange}
        selectedKeys={selectedKey}
        onClick={this.onClickSideBar}
        className="bt-sidebar-menu"
      >
        <Menu.Item key="dashboard">
          <Link to="/dashboard">
            <Icon type="dashboard" theme="outlined" />
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="customer">
          <Link to="/customer">
            <Icon type="user" theme="outlined" />
            <span>Customer</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="recipient">
          <Link to="/recipient">
            <Icon type="solution" theme="outlined" />
            <span>Penerima</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="warehouse">
          <Link to="/warehouse">
            <Icon type="home" theme="outlined" />
            <span>Gudang</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="vendor">
          <Link to="/vendor">
            <Icon type="team" theme="outlined" />
            <span>Vendor</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="driver">
          <Link to="/driver">
            <Icon type="idcard" theme="outlined" />
            <span>Supir</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="vehicle" className={isExpiredVehicle ? 'menu-warning' : ''}>
          <Link to="/vehicle">
            <Icon type="car" theme="outlined" />
            <span>Kendaraan</span>
          </Link>
        </Menu.Item>
        {getModule(roleType.DIR) && (
          <Menu.Item key="price-list">
            <Link to="/price-list">
              <Icon type="dollar" theme="outlined" />
              <span>Daftar Harga</span>
            </Link>
          </Menu.Item>
        )}
        <SubMenu
          key="account-receivable"
          title={
            <span>
              <Icon type="import" />
              <span>Account Receivable</span>
            </span>
          }
        >
          <Menu.Item key="purchase-order">
            <Link to="/purchase-order">Purchase Order</Link>
          </Menu.Item>
          <Menu.Item key="tanda-terima">
            <Link to="/tanda-terima">Tanda Terima</Link>
          </Menu.Item>
          <Menu.Item key="surat-muatan">
            <Link to="/surat-muatan">Surat Muatan</Link>
          </Menu.Item>
          <Menu.Item key="invoice" className={isAlertInvoice ? 'menu-warning' : ''}>
            <Link to="/invoice">Invoice</Link>
          </Menu.Item>
          <Menu.Item key="invoice-receipt">
            <Link to="/invoice-receipt">Tanda Terima Invoice</Link>
          </Menu.Item>
          <Menu.Item key="invoice-payment">Invoice Payment</Menu.Item>
          <Menu.Item key="sales-tax">Sales Tax</Menu.Item>
          <Menu.Item key="account-receivable-report">Report</Menu.Item>
        </SubMenu>
        <SubMenu
          key="account-payable"
          title={
            <span>
              <Icon type="export" />
              <span>Account Payable</span>
            </span>
          }
        >
          <Menu.Item key="daftar-muatan">
            <Link to="/daftar-muatan">Daftar Muatan</Link>
          </Menu.Item>
          <Menu.Item key="transit-cost">
            <Link to="/transit-cost">Transit Cost</Link>
          </Menu.Item>
          <Menu.Item key="cash-flow">
            <Link to="/cash-flow">Cash Flow</Link>
          </Menu.Item>
          <Menu.Item key="vendor-payment">Vendor Payment</Menu.Item>
          <Menu.Item key="vehicle-cost">Vehicle Cost</Menu.Item>
          <Menu.Item key="tax-invoice">Tax Invoice</Menu.Item>
          <Menu.Item key="account-payable-report">Report</Menu.Item>
        </SubMenu>
        {getModule(roleType.DIR) && (
          <SubMenu
            key="administration"
            title={
              <span>
                <Icon type="setting" />
                <span>Administration</span>
              </span>
            }
          >
            <Menu.Item key="employee">
              <Link to="/employee">Karyawan</Link>
            </Menu.Item>
            <Menu.Item key="role">
              <Link to="/role">Role</Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    );
  }
}
export default MenuSidebarComponent;
