import {
  dateFormat,
  emptyParams,
  FormType,
  orderStatus,
  paymentTypeInverse,
  invoiceStatus,
  invoicePaymentStatus,
  paymentMethod,
  DataName,
} from '@utils/constants';
import { ICustomer } from '@interfaces/icustomer';
import { IInvoice, IInvoiceDetail } from '@interfaces/iinvoice';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Divider, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import DetailTableComponent from '../detail/libraries/detail-table';
import TextArea from 'antd/lib/input/TextArea';
import { enumToArray } from '@utils/enum-to-array';
import { isLoading } from '@utils/loading';
const { Option } = Select;
const InputGroup = Input.Group;

interface IProps extends FormComponentProps {
  fetchCustomerList: (params: any) => void;
  customerList: ICustomer[];
  fetchSuratMuatanList: (params: any) => void;
  suratMuatanList: ISuratMuatan[];
  fetchInvoice: (code: string) => void;
  invoice?: IInvoice;
  createInvoice: (body: IInvoice) => void;
  updateInvoice: (code: string, body: IInvoice) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
  suratMuatanList: ISuratMuatan[];
  suratMuatans: ISuratMuatan[];
  statusPaymentList: string[];
  paymentMethodList: string[];
}

export class PageInvoiceFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      formType: code ? 'edit' : 'add',
      suratMuatanList: [],
      suratMuatans: [],
      statusPaymentList: enumToArray(invoicePaymentStatus),
      paymentMethodList: enumToArray(paymentMethod),
    };
  }
  public componentDidMount = () => {
    const { match, fetchInvoice, fetchCustomerList, fetchSuratMuatanList } = this.props;
    const { code } = match.params;
    fetchInvoice(code);
    fetchCustomerList(emptyParams);
    fetchSuratMuatanList({
      ...emptyParams,
      status: `[${orderStatus.DaftarMuatan}]`,
    });
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps !== this.props) {
      const { invoice } = this.props;
      if (invoice) {
        const suratMuatans: ISuratMuatan[] = [];
        invoice.details?.map((item: IInvoiceDetail) => {
          suratMuatans.push(item.surat_muatan);
        });
        this.setState({ suratMuatans });
      }
    }
  };
  public handleSubmit = () => {
    const { form, invoice, createInvoice, updateInvoice } = this.props;
    const { formType, suratMuatans } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;

        const currentDate = formatTime(moment(new Date()), 'YYYY-MM-DD HH:mm:ss');
        const payMethodPaid =
          values.payMethodPaid !== undefined ? Number(values.payMethodPaid) : undefined;
        const datePaid = invoice?.datePaid
          ? invoice?.datePaid
          : payMethodPaid !== undefined
          ? currentDate
          : undefined;
        const payMethodDp =
          values.payMethodDp !== undefined ? Number(values.payMethodDp) : undefined;
        const dateDp = invoice?.dateDp
          ? invoice?.dateDp
          : payMethodDp !== undefined
          ? currentDate
          : undefined;

        const dataInvoice: IInvoice = {
          ...invoice,
          customersId: values.customer,
          billedTo: values.billedTo,
          invoiceDate: formatTime(values.invoiceDate, 'YYYY-MM-DD'),
          pph: Number(values.pph),
          total: Number(values.total),
          downPayment: Number(values.downPayment),
          remarks: values.remarks,
          suratMuatans,
          updated_by: currentUser,
          status: invoiceStatus['Belum Kirim'],
          statusPayment: Number(values.statusPayment),
          payMethodPaid,
          datePaid,
          payMethodDp,
          dateDp,
        };

        if (formType === 'edit' && invoice?.code) {
          await updateInvoice(invoice.code, dataInvoice);
        } else {
          await createInvoice({
            ...dataInvoice,
            created_by: currentUser,
          });
        }
        history.push('/invoice');
      }
    });
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public onSelectCustomer = (value: any) => {
    const { suratMuatanList, customerList, form } = this.props;
    const { setFieldsValue } = form;

    const customer = customerList.find((item: ICustomer) => item.id === value);
    const customerName = customer ? `${companyTypeCase(customer.type, customer.name)}` : '';
    const suratMuatans = suratMuatanList.filter(
      (item: ISuratMuatan) =>
        value === item.customer?.id &&
        item.paymentType &&
        [
          paymentTypeInverse.Franco.toString(),
          paymentTypeInverse['Loco Tempo'].toString(),
        ].includes(item.paymentType),
    );
    this.setState({ suratMuatanList: suratMuatans, suratMuatans: [] });
    setFieldsValue({ suratMuatan: [], billedTo: customerName });
  };
  public onSelectSuratMuatan = (value: any) => {
    const { suratMuatanList, form } = this.props;
    const { setFieldsValue } = form;
    const suratMuatans = suratMuatanList.filter((item: ISuratMuatan) => value.includes(item.id));
    let total = 0;
    suratMuatans.map((item: ISuratMuatan) => {
      total += item.invoicePrice || 0;
    });
    const pph = (2 * total) / 100;

    this.setState({ suratMuatans });
    setFieldsValue({ total, pph });
  };
  public handleValidateDP = (rule: any, value: string, callback: any) => {
    const { form } = this.props;
    const { getFieldValue } = form;
    const downPayment = Number(getFieldValue('downPayment'));
    const total = Number(getFieldValue('total'));
    if (downPayment >= total) {
      callback('DP harus lebih kecil dari total harga invoice!');
    }
    callback();
  };
  public handleChangePPH = (e: any) => {
    const { form } = this.props;
    const { getFieldValue, setFieldsValue } = form;
    const pph = Number(e.target.value);
    const total = getFieldValue('total');
    const pphValue = (pph * total) / 100;
    setFieldsValue({ pph: pphValue });
  };
  public handleValidatePPH = (rule: any, value: string, callback: any) => {
    const pph = Number(value);
    if (pph < 0 || pph > 2) {
      callback('PPH maksimal 2%!');
    }
    callback();
  };
  public render() {
    const { isFetching, form, invoice, customerList } = this.props;
    const { formType, suratMuatanList, suratMuatans, statusPaymentList, paymentMethodList } =
      this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const title = formType && formType === 'edit' ? 'Ubah Invoice' : 'Buat Invoice';
    const loading = isLoading(isFetching, DataName.INVOICE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Customer">
                  {getFieldDecorator('customer', {
                    initialValue: invoice?.customer?.id ?? '',
                    rules: [{ message: 'Customer harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Customer"
                      optionFilterProp="children"
                      onChange={this.onSelectCustomer}
                      disabled={formType === 'edit'}
                    >
                      {customerList &&
                        customerList.map((item: ICustomer) => (
                          <Option key={item.code} value={item.id}>
                            {companyTypeCase(item.type, item.name)}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Ditagih Kepada">
                  {getFieldDecorator('billedTo', {
                    initialValue: invoice?.billedTo ?? '',
                    rules: [
                      { min: 3, message: 'Ditagih Kepada minimal 3 karakter!' },
                      { max: 50, message: 'Ditagih Kepada maksimal 50 karakter!' },
                      { required: true, message: 'Ditagih Kepada harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Ditagih Kepada" />)}
                </Form.Item>
                <Form.Item label="Uang Muka">
                  {getFieldDecorator('downPayment', {
                    initialValue: invoice?.downPayment ?? '',
                    getValueFromEvent: this.onChangeNumber,
                    rules: [
                      { message: 'Uang Muka hanya berupa angka!', pattern: /^[0-9]+$/ },
                      {
                        required:
                          getFieldValue('statusPayment') === invoicePaymentStatus['Uang Muka'],
                        message: 'Uang Muka harus diisi!',
                      },
                      { validator: this.handleValidateDP },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Input
                      prefix="Rp"
                      placeholder="Uang Muka"
                      disabled={
                        getFieldValue('statusPayment') !== invoicePaymentStatus['Uang Muka']
                      }
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Jumlah">
                  {getFieldDecorator('total', {
                    initialValue: invoice?.total ?? '',
                    getValueFromEvent: this.onChangeNumber,
                    rules: [
                      { message: 'Jumlah hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Jumlah harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Jumlah" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: invoice?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Surat Muatan">
                  {getFieldDecorator('suratMuatan', {
                    initialValue: suratMuatans?.map((suratMuatan) => suratMuatan.code),
                    rules: [{ message: 'Surat Muatan harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      mode="multiple"
                      showSearch={true}
                      placeholder="Pilih Surat Muatan"
                      optionFilterProp="children"
                      onChange={this.onSelectSuratMuatan}
                      disabled={formType === 'edit' || !getFieldValue('customer')}
                    >
                      {suratMuatanList &&
                        suratMuatanList.map((item: ISuratMuatan) => (
                          <Option key={item.code} value={item.id}>
                            {item.code}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Status Pembayaran">
                  {getFieldDecorator('statusPayment', {
                    initialValue: invoice?.statusPayment ?? '',
                    rules: [{ required: true, message: 'Status Pembayaran harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Status Pembayaran" showSearch={true}>
                      {statusPaymentList &&
                        statusPaymentList.map((item: string) => (
                          <Option key={item} value={invoicePaymentStatus[item]}>
                            {item}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                {getFieldValue('statusPayment') === invoicePaymentStatus['Uang Muka'] && (
                  <Form.Item label="Metode Pembayaran Uang Muka">
                    {getFieldDecorator('payMethodDp', {
                      initialValue: invoice?.payMethodDp ?? '',
                      rules: [{ required: true, message: 'Metode Pembayaran harus dipilih!' }],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Select placeholder="Pilih Metode Pembayaran" showSearch={true}>
                        {paymentMethodList &&
                          paymentMethodList.map((item: string) => (
                            <Option key={item} value={paymentMethod[item]}>
                              {item}
                            </Option>
                          ))}
                      </Select>,
                    )}
                  </Form.Item>
                )}
                {getFieldValue('statusPayment') === invoicePaymentStatus['Lunas'] && (
                  <Form.Item label="Metode Pembayaran Lunas">
                    {getFieldDecorator('payMethodPaid', {
                      initialValue: invoice?.payMethodPaid ?? '',
                      rules: [{ required: true, message: 'Metode Pembayaran harus dipilih!' }],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Select placeholder="Pilih Metode Pembayaran" showSearch={true}>
                        {paymentMethodList &&
                          paymentMethodList.map((item: string) => (
                            <Option key={item} value={paymentMethod[item]}>
                              {item}
                            </Option>
                          ))}
                      </Select>,
                    )}
                  </Form.Item>
                )}
                <Form.Item label="PPH (Maksimal 2%)">
                  <InputGroup compact={true}>
                    {getFieldDecorator('pphPercent', {
                      initialValue: invoice ? (invoice.pph / invoice.total) * 100 : '',
                      getValueFromEvent: this.onChangeNumber,
                      rules: [
                        { message: 'PPH hanya berupa angka!', pattern: /^[0-9]+$/ },
                        { required: true, message: 'PPH harus diisi!' },
                        { validator: this.handleValidatePPH },
                      ],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Input
                        style={{ width: '10%' }}
                        suffix="%"
                        onChange={this.handleChangePPH}
                        maxLength={1}
                      />,
                    )}
                    {getFieldDecorator('pph', {
                      initialValue: invoice?.pph ?? '',
                      getValueFromEvent: this.onChangeNumber,
                      rules: [
                        { message: 'PPH hanya berupa angka!', pattern: /^[0-9.]+$/ },
                        { required: true, message: 'PPH harus diisi!' },
                      ],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Input
                        style={{ width: '90%' }}
                        prefix="Rp"
                        placeholder="PPH"
                        disabled={true}
                      />,
                    )}
                  </InputGroup>
                </Form.Item>
                <Form.Item label="Tanggal Invoice">
                  {getFieldDecorator('invoiceDate', {
                    initialValue: invoice ? moment(invoice.invoiceDate) : moment(),
                    rules: [{ required: true, message: 'Tanggal Invoice harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
              </Col>
            </Row>
            {suratMuatans.length > 0 && (
              <Fragment>
                <Divider orientation="left">Detail Muatan</Divider>
                <DetailTableComponent dataSource={suratMuatans} />
                <br />
              </Fragment>
            )}
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}

export default Form.create<IProps>()(PageInvoiceFormComponent);
