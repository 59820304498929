import { IPriceList } from '@interfaces/iprice-list';

const urlPriceList = 'price_lists';

export const getPriceList = (id: number) => ({
  method: 'GET',
  url: `${urlPriceList}/${id}`,
});

export const getPriceLists = (params: any) => ({
  method: 'GET',
  params,
  url: urlPriceList,
});

export const createPriceList = (body: IPriceList) => ({
  body,
  method: 'POST',
  url: `${urlPriceList}`,
});

export const updatePriceList = (id: number, body: IPriceList) => ({
  body,
  method: 'PUT',
  url: `${urlPriceList}/${id}`,
});

export default { getPriceList, getPriceLists, createPriceList, updatePriceList };
