import { fetchPriceList } from '@pages/page-master/page-price-list/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PagePriceListDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  priceList: state.PageMasterDataReducer.priceList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchPriceList: (id: number) => dispatch(fetchPriceList(id)),
});

export const PagePriceListDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PagePriceListDetailComponent);

export default PagePriceListDetailContainer;
