import Dashboard from '@adaptors-api/dashboard-api';
import { IDashboard } from '@interfaces/idashboard';
import { isFetchingData } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.DASHBOARD));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.DASHBOARD));
};

export const setDashboard = (dashboard?: IDashboard) => ({
  dashboard,
  type: ActionTypes.PAGE_DASHBOARD_SET_DASHBOARD,
});

export const fetchDashboard = () => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Dashboard.getDashboards())
    .then((response: any) => {
      const { data } = response.data;
      const dashboard: IDashboard = data;
      dispatch(setDashboard(dashboard));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};
