import { IInvoice } from '@interfaces/iinvoice';

const urlInvoice = 'invoices';

export const getInvoice = (code: string) => ({
  method: 'GET',
  url: `${urlInvoice}/${code}`,
});

export const getInvoices = (params: any) => ({
  method: 'GET',
  params,
  url: urlInvoice,
});

export const createInvoice = (body: IInvoice) => ({
  body,
  method: 'POST',
  url: `${urlInvoice}`,
});

export const updateInvoice = (code: string, body: IInvoice) => ({
  body,
  method: 'PUT',
  url: `${urlInvoice}/${code}`,
});

export const deleteInvoice = (code: string) => ({
  method: 'DELETE',
  url: `${urlInvoice}/${code}`,
});

export default { getInvoice, getInvoices, createInvoice, updateInvoice, deleteInvoice };
