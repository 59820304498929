import { fetchPurchaseOrder } from '@pages/page-account-receivable/page-purchase-order/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PagePurchaseOrderDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  purchaseOrder: state.PageAccountReceivableReducer.purchaseOrder,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchPurchaseOrder: (code: string) => dispatch(fetchPurchaseOrder(code)),
});

export const PagePurchaseOrderDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PagePurchaseOrderDetailComponent);

export default PagePurchaseOrderDetailContainer;
