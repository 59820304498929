import { IMeta } from '@interfaces/imeta';
import { ITandaTerima } from '@interfaces/itanda-terima';
import { DataName, dateFormat, paymentType } from '@utils/constants';
import { isDirection } from '@utils/get-role';
import { isLoading } from '@utils/loading';
import { orderStatusCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import { CSVLink } from 'react-csv';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchTandaTerimaList: (params: any) => void;
  tandaTerimaList: ITandaTerima[];
  isFetching: string[];
  metaData: IMeta;
}

const excelHeaders = [
  { label: 'No Tanda Terima', key: 'code' },
  { label: 'No Polisi', key: 'policeNo' },
  { label: 'No Daftar Muatan', key: 'dmCode' },
  { label: 'No Invoice', key: 'invCode' },
  { label: 'Customer', key: 'customer' },
  { label: 'Kota Asal', key: 'from' },
  { label: 'Penerima', key: 'recipient' },
  { label: 'Kota Tujuan', key: 'to' },
  { label: 'Jenis Kendaraan', key: 'vehicleType' },
  { label: 'Tipe Pembayaran', key: 'paymentType' },
  { label: 'Tanggal', key: 'date' },
  { label: 'Total Harga', key: 'totalPrice' },
  { label: 'Status', key: 'status' },
];

const title = 'Kelola Tanda Terima';
const subTitle = 'Daftar Tanda Terima';
const direksi = isDirection();
export class PageTandaTerimaComponent extends Component<IProps, any> {
  public render() {
    const { fetchTandaTerimaList, tandaTerimaList, isFetching, metaData } = this.props;
    const loading = isLoading(isFetching, DataName.TANDA_TERIMA);

    const excelSource = tandaTerimaList?.map((data) => ({
      code: data.code,
      policeNo: data.vehicle?.policeNo || '-',
      dmCode: data.dmCode || '-',
      invCode: data.invCode || '-',
      customer: data.customer?.name,
      from: data.warehouse?.city || '-',
      recipient: data.recipient?.name,
      to: data.recipient?.city || '-',
      vehicleType: data.vehicle?.vehicle_type?.name || '-',
      paymentType: `${data.paymentType} (${paymentType[data.paymentType]})` || '-',
      date: formatTime(data.orderDate, dateFormat),
      totalPrice: data.invoicePrice,
      status: orderStatusCase(data.status),
    }));

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Daftar Tanda Terima"
          bordered={false}
          extra={[
            <Link key="1" to={`/tanda-terima/form`} style={{ marginRight: direksi ? 8 : 0 }}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Tanda Terima
              </Button>
            </Link>,
            direksi && (
              <CSVLink
                key="2"
                data={excelSource}
                headers={excelHeaders}
                filename={`${subTitle}.xls`}
                target="_blank"
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Export to Excel
                </Button>
              </CSVLink>
            ),
          ]}
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchTandaTerimaList}
            dataSource={tandaTerimaList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageTandaTerimaComponent;
