import DaftarMuatan from '@adaptors-api/daftar-muatan-api';
import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import { IMeta } from '@interfaces/imeta';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.DAFTAR_MUATAN));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.DAFTAR_MUATAN));
};
export const setDaftarMuatan = (daftarMuatan?: IDaftarMuatan) => ({
  daftarMuatan,
  type: ActionTypes.PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN,
});

export const setDaftarMuatanList = (daftarMuatanList: IDaftarMuatan[]) => ({
  daftarMuatanList,
  type: ActionTypes.PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN_LIST,
});

export const fetchDaftarMuatan = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setDaftarMuatan(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(DaftarMuatan.getDaftarMuatan(code))
    .then((response: any) => {
      const { data } = response.data;
      const daftarMuatan: IDaftarMuatan = data;
      dispatch(setDaftarMuatan(daftarMuatan));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchDaftarMuatanList = (params?: any, isSetMeta: boolean = true) => (
  dispatch: any,
) => {
  setLoading(dispatch);

  return RequestAPI(DaftarMuatan.getDaftarMuatans(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const daftarMuatanList: IDaftarMuatan[] = data;
      const metaData: IMeta = meta;
      dispatch(setDaftarMuatanList(daftarMuatanList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createDaftarMuatan = (body: IDaftarMuatan) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(DaftarMuatan.createDaftarMuatan(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Daftar Muatan ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Daftar Muatan Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateDaftarMuatan = (code: string, body: IDaftarMuatan) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(DaftarMuatan.updateDaftarMuatan(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Daftar Muatan ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Daftar Muatan Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteDaftarMuatan = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(DaftarMuatan.deleteDaftarMuatan(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Daftar Muatan ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Daftar Muatan Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
