import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchPurchaseOrderList } from './action';
import PagePurchaseOrderComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  purchaseOrderList: state.PageAccountReceivableReducer.purchaseOrderList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchPurchaseOrderList: (params: any) => dispatch(fetchPurchaseOrderList(params)),
});

export const PagePurchaseOrderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PagePurchaseOrderComponent);

export default PagePurchaseOrderContainer;
