import { IWarehouse } from '@interfaces/iwarehouse';
import { DataName } from '@utils/constants';
import { goBack } from '@utils/history';
import { isLoading } from '@utils/loading';
import { companyTypeCase } from '@utils/string-case';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

interface IProps {
  fetchWarehouse: (code: string) => void;
  warehouse?: IWarehouse;
  isFetching: string[];
  match: any;
}

const title = 'Detail Gudang';
export class PageWarehouseDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchWarehouse } = this.props;
    fetchWarehouse(match.params.code);
  };
  public render() {
    const { warehouse, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.WAREHOUSE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/warehouse/form${warehouse && `/${warehouse.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Gudang
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {warehouse && (
            <Descriptions
              title={`Gudang: ${warehouse.code}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Customer">
                {warehouse.customer &&
                  companyTypeCase(warehouse.customer.type, warehouse.customer.name)}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat" span={3}>
                {warehouse.address || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Kota">{warehouse.city || '-'}</Descriptions.Item>
              <Descriptions.Item label="Nama PIC">{warehouse.picName || '-'}</Descriptions.Item>
              <Descriptions.Item label="Telepon PIC">{warehouse.picPhone || '-'}</Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(warehouse.remarks) ? 'warning' : ''}
                span={3}
              >
                {warehouse.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageWarehouseDetailComponent;
