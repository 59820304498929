import { IInvoiceReceipt } from '@interfaces/iinvoice-receipt';

const urlInvoiceReceipt = 'invoice_receipts';

export const getInvoiceReceipt = (code: string) => ({
  method: 'GET',
  url: `${urlInvoiceReceipt}/${code}`,
});

export const getInvoiceReceipts = (params: any) => ({
  method: 'GET',
  params,
  url: urlInvoiceReceipt,
});

export const createInvoiceReceipt = (body: IInvoiceReceipt) => ({
  body,
  method: 'POST',
  url: `${urlInvoiceReceipt}`,
});

export const updateInvoiceReceipt = (code: string, body: IInvoiceReceipt) => ({
  body,
  method: 'PUT',
  url: `${urlInvoiceReceipt}/${code}`,
});

export const deleteInvoiceReceipt = (code: string) => ({
  method: 'DELETE',
  url: `${urlInvoiceReceipt}/${code}`,
});

export default {
  getInvoiceReceipt,
  getInvoiceReceipts,
  createInvoiceReceipt,
  updateInvoiceReceipt,
  deleteInvoiceReceipt,
};
