export enum ActionTypes {
  SET_BASE_NOTIFICATION = 'SET_BASE_NOTIFICATION',
  SET_BASE_LOADER = 'SET_BASE_LOADER',
  CLEAR_BASE_NOTIFICATION = 'CLEAR_BASE_NOTIFICATION',
  SET_IS_FETCH_DATA = 'SET_IS_FETCH_DATA',
  SET_META_DATA = 'SET_META_DATA',
  SET_META_DATA_SECONDARY = 'SET_META_DATA_SECONDARY',

  // DASHBOARD
  PAGE_DASHBOARD_SET_DASHBOARD = 'PAGE_DASHBOARD_SET_DASHBOARD',

  // AUTH
  PAGE_AUTH_SET_ERROR_MESSAGE = 'PAGE_AUTH_SET_ERROR_MESSAGE',
  PAGE_AUTH_SET_SUCCESS_MESSAGE = 'PAGE_AUTH_SET_SUCCESS_MESSAGE',
  PAGE_AUTH_SET_LOADING = 'PAGE_AUTH_SET_LOADING',
  PAGE_AUTH_SET_RESET_PASSWORD_INFO = 'PAGE_AUTH_SET_RESET_PASSWORD_INFO',

  // MASTER DATA
  PAGE_CUSTOMER_SET_CUSTOMER = 'PAGE_CUSTOMER_SET_CUSTOMER',
  PAGE_CUSTOMER_SET_CUSTOMER_LIST = 'PAGE_CUSTOMER_SET_CUSTOMER_LIST',
  PAGE_VENDOR_SET_VENDOR = 'PAGE_VENDOR_SET_VENDOR',
  PAGE_VENDOR_SET_VENDOR_LIST = 'PAGE_VENDOR_SET_VENDOR_LIST',
  PAGE_DRIVER_SET_DRIVER = 'PAGE_DRIVER_SET_DRIVER',
  PAGE_DRIVER_SET_DRIVER_LIST = 'PAGE_DRIVER_SET_DRIVER_LIST',
  PAGE_VEHICLE_SET_VEHICLE = 'PAGE_VEHICLE_SET_VEHICLE',
  PAGE_VEHICLE_SET_VEHICLE_LIST = 'PAGE_VEHICLE_SET_VEHICLE_LIST',
  PAGE_VEHICLE_SET_VEHICLE_TYPE = 'PAGE_VEHICLE_SET_VEHICLE_TYPE',
  PAGE_VEHICLE_SET_VEHICLE_TYPE_LIST = 'PAGE_VEHICLE_SET_VEHICLE_TYPE_LIST',
  PAGE_WAREHOUSE_SET_WAREHOUSE = 'PAGE_WAREHOUSE_SET_WAREHOUSE',
  PAGE_WAREHOUSE_SET_WAREHOUSE_LIST = 'PAGE_WAREHOUSE_SET_WAREHOUSE_LIST',
  PAGE_RECIPIENT_SET_RECIPIENT = 'PAGE_RECIPIENT_SET_RECIPIENT',
  PAGE_RECIPIENT_SET_RECIPIENT_LIST = 'PAGE_RECIPIENT_SET_RECIPIENT_LIST',
  PAGE_PRICE_LIST_SET_PRICE_LIST = 'PAGE_PRICE_LIST_SET_PRICE_LIST',
  PAGE_PRICE_LIST_SET_PRICE_LISTS = 'PAGE_PRICE_LIST_SET_PRICE_LISTS',

  // ACCOUNT RECEIVABLE
  PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER = 'PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER',
  PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER_LIST = 'PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER_LIST',
  PAGE_TANDA_TERIMA_SET_TANDA_TERIMA = 'PAGE_TANDA_TERIMA_SET_TANDA_TERIMA',
  PAGE_TANDA_TERIMA_SET_TANDA_TERIMA_LIST = 'PAGE_TANDA_TERIMA_SET_TANDA_TERIMA_LIST',
  PAGE_SURAT_MUATAN_SET_SURAT_MUATAN = 'PAGE_SURAT_MUATAN_SET_SURAT_MUATAN',
  PAGE_SURAT_MUATAN_SET_SURAT_MUATAN_LIST = 'PAGE_SURAT_MUATAN_SET_SURAT_MUATAN_LIST',
  PAGE_INVOICE_SET_INVOICE = 'PAGE_INVOICE_SET_INVOICE',
  PAGE_INVOICE_SET_INVOICE_LIST = 'PAGE_INVOICE_SET_INVOICE_LIST',
  PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT = 'PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT',
  PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT_LIST = 'PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT_LIST',

  // ACCOUNT PAYABLE
  PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN = 'PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN',
  PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN_LIST = 'PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN_LIST',
  PAGE_TRANSIT_COST_SET_TRANSIT_COST = 'PAGE_TRANSIT_COST_SET_TRANSIT_COST',
  PAGE_TRANSIT_COST_SET_TRANSIT_COST_LIST = 'PAGE_TRANSIT_COST_SET_TRANSIT_COST_LIST',
  PAGE_CASH_FLOW_SET_CASH_FLOW = 'PAGE_CASH_FLOW_SET_CASH_FLOW',
  PAGE_CASH_FLOW_SET_CASH_FLOW_LIST = 'PAGE_CASH_FLOW_SET_CASH_FLOW_LIST',
  PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP = 'PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP',
  PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_DETAIL = 'PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_DETAIL',
  PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_LIST = 'PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_LIST',
  PAGE_COA_SET_COA_LIST = 'PAGE_COA_SET_COA_LIST',

  // ADMINISTRATION
  PAGE_EMPLOYEE_SET_EMPLOYEE = 'PAGE_EMPLOYEE_SET_EMPLOYEE',
  PAGE_EMPLOYEE_SET_EMPLOYEE_LIST = 'PAGE_EMPLOYEE_SET_EMPLOYEE_LIST',
  PAGE_EMPLOYEE_SET_USER = 'PAGE_EMPLOYEE_SET_USER',
  PAGE_EMPLOYEE_SET_USER_LIST = 'PAGE_EMPLOYEE_SET_USER_LIST',
  PAGE_EMPLOYEE_SET_ROLE_LIST = 'PAGE_EMPLOYEE_SET_ROLE_LIST',
  PAGE_ROLE_SET_ROLE_LIST = 'PAGE_ROLE_SET_ROLE_LIST',
}
export default ActionTypes;
