import { fetchDriver } from '@pages/page-master/page-driver/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageDriverDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  driver: state.PageMasterDataReducer.driver,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchDriver: (code: string) => dispatch(fetchDriver(code)),
});

export const PageDriverDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDriverDetailComponent);

export default PageDriverDetailContainer;
