import { EmptyState } from '@components/molecules/empty-state';
import {
  dateFormat,
  defaultParams,
  pageSizeOptions,
  roleType,
  showSizeChanger,
  dateTimeFormat,
  invoicePaymentStatus,
  TabType,
} from '@utils/constants';
import { ICustomer } from '@interfaces/icustomer';
import { IInvoice } from '@interfaces/iinvoice';
import { IMeta } from '@interfaces/imeta';
import { getModule } from '@utils/request-api';
import {
  companyTypeCase,
  currencyCase,
  getInvoiceTT,
  getPaymentDate,
  getPaymentMethod,
} from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { formatTime } from '@utils/time';
import { Button, Divider, Popconfirm, Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  deleteData: (code: string) => void;
  dataSource?: any;
  meta: IMeta;
  type: 'all' | 'pending';
  isActive: boolean;
}

interface IState {
  tableParamsAll: any;
  tableParamsPending: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParamsAll: defaultParams,
      tableParamsPending: { ...defaultParams, isAlert: true },
    };
  }
  isAll = Boolean(this.props.type === TabType.All);
  public getParams = () => {
    const { tableParamsAll, tableParamsPending } = this.state;
    return this.isAll ? tableParamsAll : tableParamsPending;
  };
  public setParams = (params: any) => {
    return this.isAll
      ? this.setState({ tableParamsAll: params })
      : this.setState({ tableParamsPending: params });
  };
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    fetchDataSource(this.getParams());
  };
  public componentDidUpdate(prevProps: any) {
    const { fetchDataSource, isActive } = this.props;
    if (isActive !== prevProps.isActive && isActive) {
      fetchDataSource(this.getParams());
    }
  }
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParamsAll, tableParamsPending } = this.state;

    const params = tableChangeParams(
      pagination,
      filters,
      sorter,
      this.isAll ? tableParamsAll : tableParamsPending,
    );
    this.setParams(params);
    fetchDataSource(params);
  };
  public handleDeleteData = (code: string) => {
    const { fetchDataSource, deleteData } = this.props;
    deleteData(code);
    fetchDataSource(this.getParams());
  };
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: 'No Invoice',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        render: (text: string, record: IInvoice) => (
          <Link to={`/invoice/detail/${record.code}`}>{text}</Link>
        ),
        fixed: 'left',
        width: 180,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        ...filter('customer'),
        render: (record: ICustomer) => (
          <Link to={`/customer/detail/${record.code}`}>
            {companyTypeCase(record.type, record.name)}
          </Link>
        ),
        width: 200,
      },
      {
        title: 'No Tanda Terima',
        dataIndex: 'details',
        render: (text: string, record: IInvoice) => getInvoiceTT(record, true),
        width: 170,
      },
      {
        title: 'Tanggal',
        dataIndex: 'invoiceDate',
        ...filter('dateRange'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 160,
      },
      {
        title: 'Total',
        dataIndex: 'total',
        ...filter('total'),
        sorter: true,
        render: (text: any) => currencyCase(text),
        width: 160,
      },
      {
        title: 'Status Pembayaran',
        dataIndex: 'statusPayment',
        ...filter('statusPayment'),
        key: 'statusPayment',
        sorter: true,
        render: (text: any) => invoicePaymentStatus[text] || '-',
        width: 200,
      },
      {
        title: 'Metode Pembayaran',
        dataIndex: 'statusPayment',
        key: 'payMethod',
        sorter: true,
        render: (text: any, record: IInvoice) => getPaymentMethod(record),
        width: 220,
      },
      {
        title: 'Tanggal Pembayaran',
        dataIndex: 'statusPayment',
        key: 'datePay',
        sorter: true,
        render: (text: any, record: IInvoice) => getPaymentDate(record),
        width: 220,
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: IInvoice) => (
          <Fragment>
            <Link to={`/invoice/form/${record.code}`}>Ubah</Link>
            {getModule(roleType.DIR) && (
              <Fragment>
                <Divider type="vertical" />
                <Popconfirm
                  title="Apakah Anda yakin untuk menghapus invoice ini?"
                  onConfirm={() => record.code && this.handleDeleteData(record.code)}
                  okText="Iya"
                  cancelText="Tidak"
                >
                  <Button type="link" size="small">
                    Hapus
                  </Button>
                </Popconfirm>
              </Fragment>
            )}
          </Fragment>
        ),
        width: 180,
      },
    ];

    const checkInvoiceAlert = (invoice: IInvoice) => {
      const { type } = this.props;
      const { invoiceDate, statusPayment } = invoice;
      if (type === 'all') {
        if (
          moment(invoiceDate) < moment().subtract(30, 'days') &&
          (!statusPayment || statusPayment === 0)
        ) {
          return 'warning';
        }
      }
      return '';
    };

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          rowClassName={(record: IInvoice) => checkInvoiceAlert(record)}
          scroll={{ x: 1800 }}
          rowKey="id"
        />
      </div>
    );
  }
}

export default TableComponent;
