import { IMeta } from '@interfaces/imeta';
import { IVendor } from '@interfaces/ivendor';
import { companyType, DataName } from '@utils/constants';
import { isDirection } from '@utils/get-role';
import { isLoading } from '@utils/loading';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import { CSVLink } from 'react-csv';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchVendorList: (params: any) => void;
  vendorList: IVendor[];
  isFetching: string[];
  metaData: IMeta;
}

const excelHeaders = [
  { label: 'Kode Vendor', key: 'code' },
  { label: 'Nama Vendor', key: 'name' },
  { label: 'Alamat', key: 'address' },
  { label: 'Kota', key: 'city' },
  { label: 'No Telepon', key: 'phone' },
  { label: 'Nama PIC', key: 'picName' },
  { label: 'No Telepon PIC', key: 'picPhone' },
  { label: 'PIC Operasional', key: 'picOperational' },
  { label: 'NPWP', key: 'npwp' },
];

const title = 'Kelola Vendor';
const subTitle = 'Daftar Vendor';
const direksi = isDirection();
export class PageVendorComponent extends Component<IProps, any> {
  public render() {
    const { fetchVendorList, vendorList, isFetching, metaData } = this.props;
    const loading = isLoading(isFetching, DataName.VENDOR);

    const excelSource = vendorList?.map((data) => ({
      code: data.code,
      name: data.type !== companyType.P ? `${data.type} ${data.name}` : data.name,
      address: data.address || '-',
      city: data.city || '-',
      phone: data.phone || '-',
      picName: data.picName || '-',
      picPhone: data.picPhone || '-',
      picOperational: data.picOperational || '-',
      npwp: data.NPWP || '-',
    }));

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title={subTitle}
          bordered={false}
          extra={[
            <Link key="1" to={`/vendor/form`} style={{ marginRight: direksi ? 8 : 0 }}>
              <Button type="primary">
                <Icon type="plus" />
                Tambah Vendor
              </Button>
            </Link>,
            direksi && (
              <CSVLink
                key="2"
                data={excelSource}
                headers={excelHeaders}
                filename={`${subTitle}.xls`}
                target="_blank"
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Export to Excel
                </Button>
              </CSVLink>
            ),
          ]}
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchVendorList}
            dataSource={vendorList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageVendorComponent;
