import { IPriceList } from '@interfaces/iprice-list';
import {
  createPriceList,
  fetchPriceList,
  updatePriceList,
} from '@pages/page-master/page-price-list/action';
import { fetchVehicleTypeList } from '@pages/page-master/page-vehicle/action';
import { fetchVendorList } from '@pages/page-master/page-vendor/action';
import { fetchCustomerList } from '@pages/page-master/page-customer/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PagePriceListFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  priceList: state.PageMasterDataReducer.priceList,
  vehicleTypeList: state.PageMasterDataReducer.vehicleTypeList,
  vendorList: state.PageMasterDataReducer.vendorList,
  customerList: state.PageMasterDataReducer.customerList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVehicleTypeList: (params: any) => dispatch(fetchVehicleTypeList(params)),
  fetchVendorList: (params: any) => dispatch(fetchVendorList(params)),
  fetchCustomerList: (params: any) => dispatch(fetchCustomerList(params)),
  fetchPriceList: (id: number) => dispatch(fetchPriceList(id)),
  createPriceList: (body: IPriceList) => dispatch(createPriceList(body)),
  updatePriceList: (id: number, body: IPriceList) => dispatch(updatePriceList(id, body)),
});

export const PagePriceListFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PagePriceListFormComponent);

export default PagePriceListFormContainer;
