import Customer from '@adaptors-api/customer-api';
import { ICustomer } from '@interfaces/icustomer';
import { IMeta } from '@interfaces/imeta';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.CUSTOMER));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.CUSTOMER));
};

export const setCustomer = (customer?: ICustomer) => ({
  customer,
  type: ActionTypes.PAGE_CUSTOMER_SET_CUSTOMER,
});

export const setCustomerList = (customerList: ICustomer[]) => ({
  customerList,
  type: ActionTypes.PAGE_CUSTOMER_SET_CUSTOMER_LIST,
});

export const fetchCustomer = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setCustomer(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(Customer.getCustomer(code))
    .then((response: any) => {
      const { data } = response.data;
      const customer: ICustomer = data;
      dispatch(setCustomer(customer));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchCustomerList = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Customer.getCustomers(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const customerList: ICustomer[] = data;
      const metaData: IMeta = meta;
      dispatch(setCustomerList(customerList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createCustomer = (body: ICustomer) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Customer.createCustomer(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Customer ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Customer Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateCustomer = (code: string, body: ICustomer) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Customer.updateCustomer(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Customer ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Customer Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
