export const getBase64 = (file: any, callback: (result: any) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

export const getMultipleFile = (files: string = '', type: string) => {
  if (files && files !== '') {
    const list = files.split('|');
    return list?.map((file) => ({
      uid: `${type}-file`,
      name: `${type}-file`,
      status: 'done',
      url: file,
    }));
  } else {
    return [];
  }
};

export const getUploadedFile = (fileType: string, fileUrl?: string) =>
  fileUrl && fileUrl !== ''
    ? [
        {
          uid: `${fileType}-file`,
          name: `${fileType}-file`,
          status: 'done',
          url: fileUrl,
        },
      ]
    : [];
