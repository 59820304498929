import { EmptyState } from '@components/molecules/empty-state';
import {
  defaultParams,
  pageSizeOptions,
  showSizeChanger,
  dateTimeFormat,
  dateFormat,
  TabType,
} from '@utils/constants';
import { IDriver } from '@interfaces/idriver';
import { IMeta } from '@interfaces/imeta';
import { IVehicle } from '@interfaces/ivehicle';
import { IVendor } from '@interfaces/ivendor';
import { companyTypeCase } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { formatTime } from '@utils/time';
import moment from 'moment';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  dataSource?: any;
  meta: IMeta;
  type: 'all' | 'expired';
  isActive: boolean;
}

interface IState {
  tableParamsAll: any;
  tableParamsExpired: any;
}

export class TableVehicleComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParamsAll: defaultParams,
      tableParamsExpired: { ...defaultParams, isExpired: true },
    };
  }
  isAll = Boolean(this.props.type === TabType.All);
  public getParams = () => {
    const { tableParamsAll, tableParamsExpired } = this.state;
    return this.isAll ? tableParamsAll : tableParamsExpired;
  };
  public setParams = (params: any) => {
    return this.isAll
      ? this.setState({ tableParamsAll: params })
      : this.setState({ tableParamsExpired: params });
  };
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    fetchDataSource(this.getParams());
  };
  public componentDidUpdate(prevProps: any) {
    const { fetchDataSource, isActive } = this.props;
    if (isActive !== prevProps.isActive && isActive) {
      fetchDataSource(this.getParams());
    }
  }
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParamsAll, tableParamsExpired } = this.state;
    const params = tableChangeParams(
      pagination,
      filters,
      sorter,
      this.isAll ? tableParamsAll : tableParamsExpired,
    );
    this.setParams(params);
    fetchDataSource(params);
  };
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columnsVehicle: any = [
      {
        title: 'Kode Kendaraan',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        fixed: 'left',
        render: (text: string) => <Link to={`/vehicle/detail/${text}`}>{text}</Link>,
        width: 180,
      },
      {
        title: 'No Polisi',
        dataIndex: 'policeNo',
        ...filter('policeNo'),
        sorter: true,
        width: 150,
      },
      {
        title: 'Tipe Kendaraan',
        dataIndex: 'vehicleType',
        ...filter('vehicleType'),
        render: (text: string, record: IVehicle) => (
          <Fragment>{record.vehicle_type?.name}</Fragment>
        ),
      },
      {
        title: 'Supir',
        dataIndex: 'driver',
        ...filter('driver'),
        render: (record: IDriver) => (
          <Link to={`/driver/detail/${record.code}`}>{record.fullName}</Link>
        ),
      },
      {
        title: 'Vendor',
        dataIndex: 'driver.vendor',
        ...filter('vendor'),
        render: (record: IVendor) => (
          <Link to={`/vendor/detail/${record.code}`}>
            {companyTypeCase(record.type, record.name)}
          </Link>
        ),
      },
      {
        title: 'Pajak 1 Tahun',
        dataIndex: 'oneYearTaxDate',
        ...filter('oneYearTaxDate'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 200,
      },
      {
        title: 'Pajak 5 Tahun',
        dataIndex: 'fiveYearTaxDate',
        ...filter('fiveYearTaxDate'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 200,
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: IVehicle) => (
          <Fragment>
            <Link to={`/vehicle/form/${record.code}`}>Ubah</Link>
            {/* <Divider type="vertical" />
            <Button type="link">Hapus</Button> */}
          </Fragment>
        ),
        width: 100,
      },
    ];

    const checkVehicleTaxExpired = (vehicle: IVehicle) => {
      const currentDate = moment();
      const { oneYearTaxDate, fiveYearTaxDate } = vehicle;
      if (this.isAll) {
        if (moment(oneYearTaxDate) < currentDate || moment(fiveYearTaxDate) < currentDate) {
          return 'warning';
        }
      }
      return '';
    };

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columnsVehicle}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          rowClassName={(record: IVehicle) => checkVehicleTaxExpired(record)}
          rowKey="id"
          scroll={{ x: 1700 }}
        />
      </div>
    );
  }
}

export default TableVehicleComponent;
