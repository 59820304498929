import { IDriver } from '@interfaces/idriver';
import { IMeta } from '@interfaces/imeta';
import { DataName, dateFormat } from '@utils/constants';
import { isDirection } from '@utils/get-role';
import { isLoading } from '@utils/loading';
import { formatTime } from '@utils/time';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import { CSVLink } from 'react-csv';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchDriverList: (params: any) => void;
  driverList: IDriver[];
  isFetching: string[];
  metaData: IMeta;
}

const excelHeaders = [
  { label: 'Kode Supir', key: 'code' },
  { label: 'Nama Supir', key: 'name' },
  { label: 'Vendor', key: 'vendor' },
  { label: 'No Telepon', key: 'phone' },
  { label: 'Alamat', key: 'address' },
  { label: 'KTP', key: 'ktp' },
  { label: 'SIM', key: 'sim' },
  { label: 'Masa berlaku SIM', key: 'expiryDateSIM' },
  { label: 'NPWP', key: 'npwp' },
];

const title = 'Kelola Supir';
const subTitle = 'Daftar Supir';
const direksi = isDirection();
export class PageDriverComponent extends Component<IProps, any> {
  public render() {
    const { fetchDriverList, driverList, isFetching, metaData } = this.props;
    const loading = isLoading(isFetching, DataName.DRIVER);

    const excelSource = driverList?.map((data) => ({
      code: data.code,
      name: data.fullName,
      vendor: data.vendor?.name || '-',
      phone: data.phone || '-',
      address: data.address || '-',
      ktp: data.KTP || '-',
      sim: data.SIM || '-',
      expiryDateSIM: formatTime(data.expiryDateSIM, dateFormat),
      npwp: data.NPWP || '-',
    }));

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title={subTitle}
          bordered={false}
          extra={[
            <Link key="1" to={`/driver/form`} style={{ marginRight: direksi ? 8 : 0 }}>
              <Button type="primary">
                <Icon type="plus" />
                Tambah Supir
              </Button>
            </Link>,
            direksi && (
              <CSVLink
                key="2"
                data={excelSource}
                headers={excelHeaders}
                filename={`${subTitle}.xls`}
                target="_blank"
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Export to Excel
                </Button>
              </CSVLink>
            ),
          ]}
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchDriverList}
            dataSource={driverList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageDriverComponent;
