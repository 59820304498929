import { IParamsChangePassword } from '@interfaces/iparams';
import { changePassword } from '@pages/page-auth/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageChangePasswordComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isSubmit: state.PageAuthReducer.isSubmit,
});

export const mapDispatchToProps = (dispatch: any) => ({
  changePassword: (body: IParamsChangePassword) => dispatch(changePassword(body)),
});

export const PageChangePasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageChangePasswordComponent);

export default PageChangePasswordContainer;
