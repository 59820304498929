import { ICashFlow, ICashFlowRecap } from '@interfaces/icash-flow';
import { IMeta } from '@interfaces/imeta';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';
import TableRecapComponent from './libraries/table-recap';

interface IProps {
  fetchCashFlowList: (params: any, isDetail: boolean) => void;
  deleteCashFlow: (code: string) => void;
  cashFlowList: ICashFlow[];
  metaData: IMeta;
  fetchCashFlowRecapList: (params: any) => void;
  createCashFlowRecap: (body: ICashFlowRecap) => void;
  updateCashFlowRecap: (code: string, body: ICashFlowRecap) => void;
  deleteCashFlowRecap: (code: string) => void;
  cashFlowRecapList: ICashFlowRecap[];
  cashFlowRecapDetail: ICashFlow[];
  metaDataSecondary: IMeta;
  isFetching: string[];
}

const title = 'Kelola Cash Flow';
export class PageCashFlowComponent extends Component<IProps, any> {
  public render() {
    const {
      fetchCashFlowList,
      deleteCashFlow,
      cashFlowList,
      fetchCashFlowRecapList,
      createCashFlowRecap,
      updateCashFlowRecap,
      deleteCashFlowRecap,
      cashFlowRecapList,
      cashFlowRecapDetail,
      isFetching,
      metaData,
      metaDataSecondary,
    } = this.props;
    const loading = isLoading(isFetching, DataName.CASH_FLOW);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Cash Flow"
          bordered={false}
          extra={
            <Link to={`/cash-flow/form`}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Cash Flow
              </Button>
            </Link>
          }
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchCashFlowList}
            deleteData={deleteCashFlow}
            dataSource={cashFlowList}
            meta={metaData}
          />
        </Card>

        <br />

        <TableRecapComponent
          isFetching={loading}
          fetchDataSource={fetchCashFlowRecapList}
          fetchDetail={fetchCashFlowList}
          createData={createCashFlowRecap}
          updateData={updateCashFlowRecap}
          deleteData={deleteCashFlowRecap}
          dataSource={cashFlowRecapList}
          dataDetail={cashFlowRecapDetail}
          meta={metaDataSecondary}
        />
      </Fragment>
    );
  }
}
export default PageCashFlowComponent;
