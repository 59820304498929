import cityList from '@data/list_location.json';
import {
  companyType,
  DataName,
  defaultUploadImage,
  emptyParams,
  fileType,
  FormType,
} from '@utils/constants';
import { ICity } from '@interfaces/icity';
import { IEmployee } from '@interfaces/iemployee';
import { IVendor } from '@interfaces/ivendor';
import { enumToArray } from '@utils/enum-to-array';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { Button, Card, Col, Icon, Input, PageHeader, Row, Select, Upload } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import InputMask from 'react-input-mask';
import { getBase64, getUploadedFile } from '@utils/upload';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchEmployeeList: (params: any) => void;
  employeeList: IEmployee[];
  fetchVendor: (code: string) => void;
  vendor?: IVendor;
  createVendor: (body: IVendor) => void;
  updateVendor: (code: string, body: IVendor) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  companyTypeList: string[];
  selectedCompanyType: string;
  formType: FormType;
  imageNPWP: string;
  fileMOU: string;
}

export class PageVendorFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { code } = props.match.params;
    const companyTypeList = enumToArray(companyType);
    this.state = {
      companyTypeList,
      selectedCompanyType: companyTypeList[0],
      imageNPWP: '',
      fileMOU: '',
      formType: code ? 'edit' : 'add',
    };
  }
  public componentDidMount = () => {
    const { match, fetchVendor, fetchEmployeeList } = this.props;
    const { code } = match.params;
    fetchVendor(code);
    fetchEmployeeList(emptyParams);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.vendor !== this.props.vendor) {
      const { vendor } = this.props;
      this.setState({
        imageNPWP: vendor?.imageNPWP || '',
        fileMOU: vendor?.fileMOU || '',
      });
    }
  };
  public handleSubmit = () => {
    const { form, vendor, createVendor, updateVendor } = this.props;
    const { selectedCompanyType, formType, imageNPWP, fileMOU } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataVendor: IVendor = {
          ...vendor,
          name: values.name,
          type: selectedCompanyType,
          address: values.address,
          city: values.city,
          phone: values.phoneNumber,
          picName: values.picName,
          picPhone: values.picPhone,
          picOperational: values.picOperational,
          NPWP: values.npwp ? values.npwp.toString().replace(/-|\./g, '') : null,
          imageNPWP,
          fileMOU,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && vendor?.code) {
          await updateVendor(vendor.code, dataVendor);
        } else {
          await createVendor({ ...dataVendor, created_by: currentUser });
        }
        history.push('/vendor');
      }
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const types = rule?.field.includes('image') ? defaultUploadImage : ['application/pdf'];
        const isValidDocument: boolean = types.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG or PNG file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public handleUpload = async (option: any, type: string = 'image') => {
    try {
      getBase64(option.file, (fileBase64) => {
        if (type === 'image') {
          this.setState({ imageNPWP: fileBase64 });
        } else {
          this.setState({ fileMOU: fileBase64 });
        }
        option.onSuccess(fileBase64, option.file);
      });
    } catch (e) {
      option.onError(e, option.file);
    }
  };
  public handleNPWPValidation = (rule: any, value: any, callback: any) => {
    if (value) {
      const fomattedValue: string = value.replace(/[^\d\s]/g, '');
      if (fomattedValue.length < 15) {
        callback('NPWP number minimum 15 digits!');
      }
    }
    callback();
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { isFetching, form, vendor, employeeList } = this.props;
    const { companyTypeList, selectedCompanyType, formType } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const title = formType && formType === 'edit' ? 'Ubah Vendor' : 'Tambah Vendor';
    const loading = isLoading(isFetching, DataName.VENDOR);

    const selectVendorType = (
      <Select
        defaultValue={vendor && vendor.type ? vendor.type : selectedCompanyType}
        placeholder="Select Vendor Type"
        style={{ width: 100 }}
        onChange={(value: any) => this.setState({ selectedCompanyType: value })}
      >
        {companyTypeList &&
          companyTypeList.map((item: string) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
      </Select>
    );

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Nama Vendor">
                  {getFieldDecorator('name', {
                    initialValue: vendor?.name ?? '',
                    rules: [{ required: true, message: 'Nama vendor harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input addonBefore={selectVendorType} placeholder="Nama Vendor" />)}
                </Form.Item>
                <Form.Item label="Telepon">
                  {getFieldDecorator('phoneNumber', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: vendor?.phone ?? '',
                    rules: [
                      { min: 5, message: 'Telepon minimal 5 digit!' },
                      { max: 15, message: 'Telepon maksimal 15 digit!' },
                      { message: 'Telepon hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Telepon harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Nomor NPWP">
                  {getFieldDecorator('npwp', {
                    initialValue: vendor?.NPWP ?? '',
                    rules: [{ validator: this.handleNPWPValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <InputMask
                      mask="99.999.999.9-999.999"
                      className="ant-input"
                      placeholder="Nomor NPWP"
                    />,
                  )}
                </Form.Item>
                <Form.Item label="File NPWP">
                  {getFieldDecorator('imageNPWP', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.NPWP, vendor?.imageNPWP),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png"
                      customRequest={(option) => this.handleUpload(option)}
                      onRemove={() => {
                        this.setState({ imageNPWP: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageNPWP') !== undefined &&
                          getFieldValue('imageNPWP').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Alamat">
                  {getFieldDecorator('address', {
                    initialValue: vendor?.address ?? '',
                    rules: [
                      { min: 3, message: 'Alamat minimal 3 karakter!' },
                      { max: 250, message: 'Alamat maksimal 250 karakter!' },
                      { required: true, message: 'Alamat harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Alamat Kantor, Nama Jalan, Kode Pos, lain-lain." />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: vendor?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="PIC Operasional">
                  {getFieldDecorator('picOperational', {
                    initialValue: vendor?.picOperational ?? '',
                    rules: [{ message: 'PIC operasional harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih PIC Operasional"
                      optionFilterProp="children"
                    >
                      {employeeList &&
                        employeeList.map((item: IEmployee) => (
                          <Option key={item.code} value={item.fullName}>
                            {item.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Nama PIC">
                  {getFieldDecorator('picName', {
                    initialValue: vendor?.picName ?? '',
                    rules: [{ message: 'PIC Vendor harus diisi!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nama PIC" />)}
                </Form.Item>
                <Form.Item label="Telepon PIC">
                  {getFieldDecorator('picPhone', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: vendor?.picPhone ?? '',
                    rules: [
                      { required: true, message: 'Telepon PIC harus diisi!' },
                      { min: 5, message: 'Telepon PIC minimal 5 digit!' },
                      { max: 15, message: 'Telepon PIC maksimal 15 digit!' },
                      { message: 'Telepon PIC hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon PIC" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="File MOU">
                  {getFieldDecorator('fileMOU', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.MOU, vendor?.fileMOU),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".pdf"
                      customRequest={(option) => this.handleUpload(option, 'pdf')}
                      onRemove={() => {
                        this.setState({ fileMOU: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('fileMOU') !== undefined &&
                          getFieldValue('fileMOU').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Kota">
                  {getFieldDecorator('city', {
                    initialValue: vendor?.city ?? '',
                    rules: [{ required: true, message: 'Kota harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Kota" showSearch={true}>
                      {cityList &&
                        cityList.data.map((item: ICity) => (
                          <Option key={item.kabko} value={item.kabko}>
                            {item.kabko}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageVendorFormComponent);
