import { ISuratMuatan } from '@interfaces/isurat-muatan';

const urlSuratMuatan = 'surat_muatan';

export const getSuratMuatan = (code: string) => ({
  method: 'GET',
  url: `${urlSuratMuatan}/${code}`,
});

export const getSuratMuatans = (params: any) => ({
  method: 'GET',
  params,
  url: urlSuratMuatan,
});

export const createSuratMuatan = (body: ISuratMuatan) => ({
  body,
  method: 'POST',
  url: `${urlSuratMuatan}`,
});

export const updateSuratMuatan = (code: string, body: ISuratMuatan) => ({
  body,
  method: 'PUT',
  url: `${urlSuratMuatan}/${code}`,
});

export const deleteSuratMuatan = (code: string) => ({
  method: 'DELETE',
  url: `${urlSuratMuatan}/${code}`,
});

export default {
  getSuratMuatan,
  getSuratMuatans,
  createSuratMuatan,
  updateSuratMuatan,
  deleteSuratMuatan,
};
