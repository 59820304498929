import { IPriceList } from '@interfaces/iprice-list';
import { DataName, dateFormat } from '@utils/constants';
import { goBack } from '@utils/history';
import { isLoading } from '@utils/loading';
import { currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

interface IProps {
  fetchPriceList: (id: number) => void;
  priceList?: IPriceList;
  isFetching: string[];
  match: any;
}

const title = 'Detail Daftar Harga';
export class PagePriceListDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchPriceList } = this.props;
    fetchPriceList(match.params.id);
  };
  public render() {
    const { priceList, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.PRICELIST);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/price-list/form${priceList && `/${priceList.id}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Daftar Harga
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {priceList && (
            <Descriptions title="Daftar Harga" layout="vertical" bordered={true} size="small">
              <Descriptions.Item label="Tanggal">
                {priceList.date ? formatTime(moment(priceList.date), dateFormat) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Asal">{priceList.deliveryFrom}</Descriptions.Item>
              <Descriptions.Item label="Tujuan">{priceList.deliveryTo}</Descriptions.Item>
              <Descriptions.Item label="Pengiriman">{priceList.deliveryType}</Descriptions.Item>
              <Descriptions.Item label="Armada">{priceList.vehicle_type?.name}</Descriptions.Item>
              <Descriptions.Item label="Harga">{currencyCase(priceList.price)}</Descriptions.Item>
              <Descriptions.Item label="Biaya">{currencyCase(priceList.cost)}</Descriptions.Item>
              <Descriptions.Item label="Vendor">{priceList.vendor}</Descriptions.Item>
              <Descriptions.Item label="Customer">{priceList.customer}</Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(priceList.remarks) ? 'warning' : ''}
              >
                {priceList.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PagePriceListDetailComponent;
