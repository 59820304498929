import {
  DataName,
  dateFormat,
  defaultUploadDocument,
  deliveryType,
  emptyParams,
  FormType,
  orderStatus,
  paymentType,
  paymentTypeInverse,
  priceType,
  quantityType,
  volumeType,
} from '@utils/constants';
import { ICustomer } from '@interfaces/icustomer';
import { IPurchaseOrder } from '@interfaces/ipurchase-order';
import { IRecipient } from '@interfaces/irecipient';
import { ITandaTerima, ITandaTerimaDetail } from '@interfaces/itanda-terima';
import { IWarehouse } from '@interfaces/iwarehouse';
import { enumToArray } from '@utils/enum-to-array';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Divider, Icon, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { isLoading } from '@utils/loading';
import { EmptyState } from '@components/molecules/empty-state';
import { isEmpty } from 'lodash';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchPurchaseOrderList: (params: any) => void;
  purchaseOrderList: IPurchaseOrder[];
  fetchCustomerList: (params: any) => void;
  customerList: ICustomer[];
  fetchRecipientList: (params: any) => void;
  recipientList: IRecipient[];
  fetchWarehouseList: (params: any) => void;
  warehouseList: IWarehouse[];
  fetchTandaTerima: (code: string) => void;
  tandaTerima?: ITandaTerima;
  createTandaTerima: (body: ITandaTerima) => void;
  updateTandaTerima: (code: string, body: ITandaTerima) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  deliveryTypeList: string[];
  paymentTypeList: string[];
  quantityTypeList: string[];
  volumeTypeList: string[];
  priceTypeList: string[];
  formType: FormType;
  grandTotal: number;
  tandaTerimaDetails: ITandaTerimaDetail[];
}

const initialDetailValue = {
  itemName: '',
  price: 0,
  priceType: '',
  quantity: 0,
  quantityType: '',
  total: 0,
  volume: 0,
  volumeType: '',
};

export class PageTandaTerimaFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      deliveryTypeList: enumToArray(deliveryType),
      paymentTypeList: enumToArray(paymentType),
      quantityTypeList: enumToArray(quantityType),
      volumeTypeList: enumToArray(volumeType),
      priceTypeList: enumToArray(priceType),
      formType: code ? 'edit' : 'add',
      grandTotal: 0,
      tandaTerimaDetails: [initialDetailValue],
    };
  }
  public componentDidMount = () => {
    const {
      match,
      fetchTandaTerima,
      fetchPurchaseOrderList,
      fetchCustomerList,
      fetchRecipientList,
      fetchWarehouseList,
    } = this.props;
    const { code } = match.params;
    fetchTandaTerima(code);
    fetchPurchaseOrderList({ ...emptyParams, status: `[${orderStatus.PurchaseOrder}]` });
    fetchCustomerList(emptyParams);
    fetchRecipientList(emptyParams);
    fetchWarehouseList(emptyParams);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    const { tandaTerima } = this.props;
    if (prevProps.tandaTerima !== tandaTerima && tandaTerima) {
      this.setState({
        tandaTerimaDetails: tandaTerima.details,
        grandTotal: tandaTerima?.invoicePrice,
      });
    }
  };
  public handleSubmit = () => {
    const { form, tandaTerima, updateTandaTerima, createTandaTerima } = this.props;
    const { formType } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const details = values.detail
          ? values.detail
              ?.filter((detail: ITandaTerimaDetail) => !isEmpty(detail.itemName))
              ?.map((item: ITandaTerimaDetail) => ({
                itemName: item.itemName,
                quantity: Number(item.quantity),
                quantityType: item.quantityType,
                volume: Number(item.volume),
                volumeType: item.volumeType,
                priceType: item.priceType,
                price: Number(item.price),
                total: Number(item.total),
                vendorPrice: Number(item.total),
              }))
          : [];

        const dataTandaTerima: ITandaTerima = {
          ...tandaTerima,
          purchaseOrderId: tandaTerima ? tandaTerima?.purchase_order?.id : values.purchaseOrder,
          customersId: values.customer,
          orderDate: formatTime(values.orderDate, 'YYYY-MM-DD'),
          paymentType: paymentTypeInverse[values.paymentType],
          deliveryType: values.deliveryType,
          warehousesId: values.warehouse,
          recipientsId: values.recipient,
          invoicePrice: Number(values.invoicePrice),
          commission: Number(values.commission),
          fileSuratJalan: '',
          remarks: values.remarks,
          details,
          updated_by: currentUser,
        };

        if (formType === 'edit' && tandaTerima?.code) {
          await updateTandaTerima(tandaTerima.code, dataTandaTerima);
        } else {
          await createTandaTerima({
            ...dataTandaTerima,
            status: orderStatus.TandaTerima,
            created_by: currentUser,
          });
        }
        history.push('/tanda-terima');
      }
    });
  };
  public handleSelectPO = (value: any) => {
    const { form, purchaseOrderList } = this.props;
    const { setFieldsValue } = form;

    const purchaseOrder = purchaseOrderList.filter((item: IPurchaseOrder) => item.id === value)[0];
    setFieldsValue({
      customer: purchaseOrder.customer?.id,
      orderDate: moment(purchaseOrder.orderDate),
      deliveryType: purchaseOrder.deliveryType,
    });
  };
  public handleSelectAddress = (id: any) => {
    const { warehouseList, form } = this.props;
    const { setFieldsValue } = form;
    const selectedWarehouse = warehouseList?.find((warehouse) => warehouse.id === id);

    setFieldsValue({
      warehouseAddress: selectedWarehouse?.address,
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const isValidDocument: boolean = defaultUploadDocument.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG, PNG, or PDF file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public handleValidateCommission = (rule: any, value: string, callback: any) => {
    const { grandTotal } = this.state;
    const maxCommission = (20 * grandTotal) / 100;
    const commission = Number(value);
    if (commission > maxCommission) {
      callback('Komisi tidak bisa lebih besar dari 20% Harga Total!');
    }
    callback();
  };

  public getDetailValue = (index: any, type: string) => {
    const { form } = this.props;
    const { getFieldValue } = form;

    const value = getFieldValue(`detail[${index}]${type}`);
    if (value === undefined || isNaN(value)) {
      return 0;
    }

    return Number(value);
  };
  public handleChangeDetail = (index: any, field: string, value: string) => {
    const { form } = this.props;
    const { getFieldValue, setFieldsValue } = form;
    const numberValue = value.startsWith('0') ? 0 : Number(value);

    const pricingType = field === 'priceType' ? value : getFieldValue(`detail[${index}]priceType`);
    const quantity =
      field === 'quantity' ? numberValue : Number(this.getDetailValue(index, 'quantity') ?? 0);
    const volume =
      field === 'volume' ? numberValue : Number(this.getDetailValue(index, 'volume') ?? 0);
    const price =
      field === 'price' ? numberValue : Number(this.getDetailValue(index, 'price') ?? 0);

    let total = 0;
    switch (pricingType) {
      case priceType.FIX:
        total = price;
        break;
      case priceType.QTY:
        total = price * quantity;
        break;
      case priceType.VOL:
        total = price * volume;
        break;
    }

    setFieldsValue({
      [`detail[${index}]total`]: total ?? 0,
    });

    const details = getFieldValue('detail');
    let grandTotal: number = 0;
    details.forEach((detail: ITandaTerimaDetail) => {
      grandTotal += detail.total;
    });

    setFieldsValue({
      invoicePrice: grandTotal ?? 0,
    });
    this.setState({ grandTotal: Number(grandTotal) ?? 0 });
  };
  public onChangeNumber = (e: any, isDecimal: boolean = false) => {
    return e.target.value.startsWith('0')
      ? '0'
      : isDecimal
      ? e.target.value.replace(/\D./, '')
      : e.target.value.replace(/\D/, '');
  };
  public addDetail = () => {
    const { tandaTerimaDetails } = this.state;

    const details = [...tandaTerimaDetails, initialDetailValue];
    this.setState({ tandaTerimaDetails: details });
  };
  public removeDetail = (key: number) => {
    const { tandaTerimaDetails } = this.state;

    const details = tandaTerimaDetails.filter((data, index) => index !== key);
    this.setState({ tandaTerimaDetails: details });
  };
  public render() {
    const {
      isFetching,
      form,
      tandaTerima,
      purchaseOrderList,
      customerList,
      recipientList,
      warehouseList,
    } = this.props;
    const {
      tandaTerimaDetails,
      deliveryTypeList,
      paymentTypeList,
      quantityTypeList,
      volumeTypeList,
      priceTypeList,
      formType,
      grandTotal,
    } = this.state;
    const { getFieldDecorator } = form;
    const title = formType && formType === 'edit' ? 'Ubah Tanda Terima' : 'Buat Tanda Terima';
    const loading = isLoading(isFetching, DataName.TANDA_TERIMA);

    let detailItems: any = <EmptyState emptyText="Detail muatan tidak ditemukan" />;
    if (tandaTerimaDetails && tandaTerimaDetails.length > 0) {
      detailItems = tandaTerimaDetails?.map((detail: ITandaTerimaDetail, index: number) => (
        <div key={index} className="ant-form ant-form-inline">
          <Form.Item label={index === 0 && 'Nama Barang'}>
            {getFieldDecorator(`detail[${index}]itemName`, {
              initialValue: detail.itemName ?? '',
              rules: [{ required: true, message: 'Nama Barang harus diisi!' }],
              validateTrigger: ['onChange', 'onBlur'],
            })(<Input placeholder="Nama Barang" style={{ width: 200 }} />)}
          </Form.Item>
          <Form.Item label={index === 0 && 'Jumlah'} className="input-quantity">
            {getFieldDecorator(`detail[${index}]quantity`, {
              getValueFromEvent: this.onChangeNumber,
              initialValue: detail.quantity || '',
              rules: [
                { message: 'Jumlah hanya berupa angka!', pattern: /^[0-9]+$/ },
                { required: true, message: 'Jumlah harus diisi!' },
              ],
              validateTrigger: ['onChange', 'onBlur'],
            })(
              <Input
                placeholder="Jumlah"
                style={{ width: 120 }}
                onChange={(e) => this.handleChangeDetail(index, 'quantity', e.target.value)}
              />,
            )}
          </Form.Item>
          <Form.Item style={index === 0 ? { marginTop: 39 } : {}} className="select-quantity-Type">
            {getFieldDecorator(`detail[${index}]quantityType`, {
              initialValue: detail.quantityType || quantityTypeList[0],
              rules: [{ required: true, message: 'Tipe Jumlah harus dipilih!' }],
              validateTrigger: ['onChange', 'onBlur'],
            })(
              <Select placeholder="Pilih Tipe Jumlah">
                {quantityTypeList &&
                  quantityTypeList.map((item: string, indexOption: number) => (
                    <Option key={indexOption} value={quantityType[item]}>
                      {item}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label={index === 0 && 'Volume'} className="input-volume">
            {getFieldDecorator(`detail[${index}]volume`, {
              getValueFromEvent: (e) => this.onChangeNumber(e, true),
              initialValue: detail.volume || '',
              rules: [
                { message: 'Volume hanya berupa angka!', pattern: /^[0-9]+$/ },
                { required: true, message: 'Volume harus diisi!' },
              ],
              validateTrigger: ['onChange', 'onBlur'],
            })(
              <Input
                placeholder="Volume"
                style={{ width: 120 }}
                onChange={(e) => this.handleChangeDetail(index, 'volume', e.target.value)}
              />,
            )}
          </Form.Item>
          <Form.Item style={index === 0 ? { marginTop: 39 } : {}} className="select-volume-Type">
            {getFieldDecorator(`detail[${index}]volumeType`, {
              initialValue: detail.volumeType || volumeTypeList[0],
              rules: [{ required: true, message: 'Tipe Volume harus dipilih!' }],
              validateTrigger: ['onChange', 'onBlur'],
            })(
              <Select placeholder="Pilih Tipe Volume">
                {volumeTypeList &&
                  volumeTypeList.map((item: string, indexOption: number) => (
                    <Option key={indexOption} value={volumeType[item]}>
                      {item}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label={index === 0 && 'Tipe Harga'}>
            {getFieldDecorator(`detail[${index}]priceType`, {
              initialValue: detail.priceType || priceTypeList[0],
              rules: [{ required: true, message: 'Tipe Harga harus dipilih!' }],
              validateTrigger: ['onChange', 'onBlur'],
            })(
              <Select
                placeholder="Pilih Tipe Harga"
                style={{ minWidth: 100 }}
                onChange={(value) => this.handleChangeDetail(index, 'priceType', value.toString())}
              >
                {priceTypeList &&
                  priceTypeList.map((item: string, indexOption: number) => (
                    <Option key={indexOption} value={priceType[item]}>
                      {item}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item label={index === 0 && 'Harga'}>
            {getFieldDecorator(`detail[${index}]price`, {
              getValueFromEvent: this.onChangeNumber,
              initialValue: detail.price || '',
              rules: [
                { message: 'Harga hanya berupa angka!', pattern: /^[0-9]+$/ },
                { required: true, message: 'Harga harus diisi!' },
              ],
              validateTrigger: ['onChange', 'onBlur'],
            })(
              <Input
                addonBefore="Rp"
                placeholder="Harga"
                onChange={(e) => this.handleChangeDetail(index, 'price', e.target.value)}
                className="input-price"
              />,
            )}
          </Form.Item>
          <Form.Item label={index === 0 && 'Total'}>
            {getFieldDecorator(`detail[${index}]total`, {
              initialValue: detail.total || '',
              rules: [
                { message: 'Total hanya berupa angka!', pattern: /^[0-9]+$/ },
                { required: true, message: 'Total harus diisi!' },
              ],
              validateTrigger: ['onChange', 'onBlur'],
            })(
              <Input
                addonBefore="Rp"
                placeholder="Total"
                readOnly={true}
                className="input-price"
              />,
            )}
          </Form.Item>
          {tandaTerimaDetails?.length > 1 ? (
            <Icon
              style={index === 0 ? { marginTop: 40 } : {}}
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={() => this.removeDetail(index)}
            />
          ) : null}
          <Divider dashed={true} />
        </div>
      ));
    }

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Form.Item label="Purchase Order">
              {getFieldDecorator('purchaseOrder', {
                initialValue: tandaTerima?.purchase_order?.code,
                rules: [{ message: 'Purchase Order harus dipilih!', required: true }],
                validateTrigger: ['onChange', 'onBlur'],
              })(
                <Select
                  showSearch={true}
                  placeholder="Pilih Purchase Order"
                  optionFilterProp="children"
                  onChange={this.handleSelectPO}
                  disabled={formType === 'edit'}
                >
                  {purchaseOrderList &&
                    purchaseOrderList.map((item: IPurchaseOrder) => (
                      <Option key={item.code} value={item.id}>
                        {`${item.code} | ${companyTypeCase(
                          item.customer?.type,
                          item.customer?.name,
                        )}`}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Customer">
                  {getFieldDecorator('customer', {
                    initialValue: tandaTerima?.customer?.id,
                    rules: [{ message: 'Customer harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Customer"
                      optionFilterProp="children"
                      disabled={true}
                    >
                      {customerList &&
                        customerList.map((item: ICustomer) => (
                          <Option key={item.code} value={item.id}>
                            {`${companyTypeCase(item.type, item.name)} | ${item.address}`}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Tanggal">
                  {getFieldDecorator('orderDate', {
                    initialValue: tandaTerima?.orderDate
                      ? moment(tandaTerima?.orderDate)
                      : moment(),
                    rules: [{ required: true, message: 'Tanggal harus dipilih!' }],
                  })(<DatePicker format={dateFormat} disabled={true} />)}
                </Form.Item>
                <Form.Item label="Jenis Pengiriman">
                  {getFieldDecorator('deliveryType', {
                    initialValue: tandaTerima?.deliveryType,
                    rules: [{ required: true, message: 'Jenis Pengiriman harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Jenis Pengiriman" showSearch={true} disabled={true}>
                      {deliveryTypeList &&
                        deliveryTypeList.map((item: string) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Harga Total (Invoice)">
                  {getFieldDecorator('invoicePrice', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: tandaTerima?.invoicePrice ?? '',
                    rules: [
                      { message: 'Harga Total hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Harga Total harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Harga Total" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: tandaTerima?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Penerima">
                  {getFieldDecorator('recipient', {
                    initialValue: tandaTerima?.recipient?.id,
                    rules: [{ message: 'Penerima harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Penerima"
                      optionFilterProp="children"
                    >
                      {recipientList &&
                        recipientList.map((item: IRecipient) => (
                          <Option key={item.code} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Tipe Pembayaran">
                  {getFieldDecorator('paymentType', {
                    initialValue: tandaTerima?.paymentType,
                    rules: [{ required: true, message: 'Tipe Pembayaran harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Tipe Pembayaran" showSearch={true}>
                      {paymentTypeList &&
                        paymentTypeList.map((item: string) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Gudang">
                  {getFieldDecorator('warehouse', {
                    initialValue: tandaTerima?.warehouse?.id,
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Gudang"
                      onSelect={this.handleSelectAddress}
                      optionFilterProp="children"
                    >
                      {warehouseList &&
                        warehouseList.map((item: IWarehouse) => (
                          <Option key={item.code} value={item.id}>
                            {`${item.code} - ${companyTypeCase(
                              item.customer?.type,
                              item.customer?.name,
                            )} - ${item.city}`}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Alamat Gudang">
                  {getFieldDecorator('warehouseAddress', {
                    initialValue: tandaTerima?.warehouse?.address ?? '',
                  })(<TextArea placeholder="Detail alamat gudang" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Komisi (Maksimal 20% dari harga total)">
                  {getFieldDecorator('commission', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: tandaTerima?.commission ?? '',
                    rules: [
                      { message: 'Komisi hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Komisi harus diisi!' },
                      { validator: this.handleValidateCommission },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Komisi" />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">Detail Muatan</Divider>
            <div className="item-detail">
              {/* <Form.Item>{getFieldDecorator('detail')(<>{detailItems}</>)}</Form.Item> */}
              {detailItems}
            </div>
            <Form.Item>
              <Row gutter={24}>
                <Col lg={15}>
                  <Button type="dashed" onClick={this.addDetail}>
                    <Icon type="plus" /> Tambah Detail Muatan
                  </Button>
                </Col>
                <Col lg={9}>Grand Total: {currencyCase(grandTotal)}</Col>
              </Row>
            </Form.Item>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageTandaTerimaFormComponent);
