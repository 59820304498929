import { IPriceList } from '@interfaces/iprice-list';
import { IMeta } from '@interfaces/imeta';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchPriceLists: (params: any) => void;
  priceLists: IPriceList[];
  isFetching: string[];
  metaData: IMeta;
}

const title = 'Kelola Daftar Harga';
const subTitle = 'Daftar Harga';
export class PagePriceListComponent extends Component<IProps, any> {
  public render() {
    const { fetchPriceLists, priceLists, isFetching, metaData } = this.props;
    const loading = isLoading(isFetching, DataName.PRICELIST);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title={subTitle}
          bordered={false}
          extra={[
            <Link key="1" to={`/price-list/form`}>
              <Button type="primary">
                <Icon type="plus" />
                Tambah Daftar Harga
              </Button>
            </Link>,
          ]}
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchPriceLists}
            dataSource={priceLists}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PagePriceListComponent;
