import { isLoggedIn } from '@routes/index';
import { Button, Result } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class PageUnAuthorizedComponent extends Component<any> {
  public render() {
    return (
      <Result
        style={{ paddingTop: '10%' }}
        status={403}
        title="403"
        subTitle="Maaf, Kamu tidak memiliki akses untuk membuka halaman ini."
        extra={
          isLoggedIn ? (
            <Link to="/dashboard">
              <Button type="primary">Kembali ke Dashboard</Button>
            </Link>
          ) : (
            <Link to="/auth">
              <Button type="primary">Login</Button>
            </Link>
          )
        }
      />
    );
  }
}
export default PageUnAuthorizedComponent;
