import Administration from '@adaptors-api/administration-api';
import { IMeta } from '@interfaces/imeta';
import { IRole } from '@interfaces/irole';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.ROLE));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.ROLE));
};

export const setRoleList = (roleList: IRole[]) => ({
  roleList,
  type: ActionTypes.PAGE_ROLE_SET_ROLE_LIST,
});

export const fetchRoleList = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Administration.getRoles(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const RoleList: IRole[] = data;
      const metaData: IMeta = meta;
      dispatch(setRoleList(RoleList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const updateRole = (roleId: number, body: IRole) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Administration.updateRole(roleId, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Role ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Role Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
