import { EmptyState } from '@components/molecules/empty-state';
import { EditableCell } from '@components/organisms/editable-cell';
import { EditableContext } from '@components/organisms/editable-cell/component';
import {
  dateTimeFormat,
  defaultParams,
  editableCellType,
  pageSizeOptions,
  showSizeChanger,
} from '@utils/constants';
import { IMeta } from '@interfaces/imeta';
import { IRole } from '@interfaces/irole';
import { filter, tableChangeParams } from '@utils/table';
import { currentTime, formatTime } from '@utils/time';
import { Button, Card, Divider, Form, Popconfirm, Table } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';

interface IProps extends FormComponentProps {
  isFetching: boolean;
  fetchDataSource: (params?: any) => void;
  dataSource?: any;
  updateRole: (roleId: number, body: IRole) => void;
  meta: IMeta;
}

interface IState {
  editingKey: string;
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editingKey: '',
      tableParams: defaultParams,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    delete tableParams.orderBy;
    delete tableParams.sort;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params, editingKey: '' });
    fetchDataSource(params);
  };
  public handleUpdateRole = async (roleId: number, role: IRole) => {
    const { fetchDataSource, updateRole } = this.props;
    await updateRole(roleId, role);
    await fetchDataSource(defaultParams);
    this.handleCancel();
  };
  public handleDelete = (role: IRole) => {
    role.deleted_at = currentTime();
    this.handleUpdateRole(role.id, role);
  };
  public handleCancel = () => {
    this.setState({ editingKey: '' });
  };
  public handleSave(role: IRole, form: any) {
    form.validateFields((error: any, row: any) => {
      if (error) {
        return;
      }
      this.handleUpdateRole(role.id, { ...role, description: row.description });
    });
  }
  public handleEdit(role: IRole) {
    this.setState({ editingKey: role.id.toString() });
  }
  public isEditing = (record: { id: number }) => record.id.toString() === this.state.editingKey;
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns: any = [
      {
        title: 'Nama Role',
        dataIndex: 'name',
        ...filter('name'),
        sorter: true,
      },
      {
        title: 'Deskripsi',
        dataIndex: 'description',
        ...filter('description'),
        sorter: true,
        render: (text: any) => text || '-',
        editable: true,
        inputType: editableCellType.text,
        mandatory: false,
      },
      {
        title: 'Dihapus Pada',
        dataIndex: 'deleted_at',
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        sorter: true,
      },
      {
        title: 'Action',
        render: (text: any, record: IRole) => {
          const { editingKey } = this.state;
          const editing = this.isEditing(record);

          return editing ? (
            <Fragment>
              <EditableContext.Consumer>
                {(form) => (
                  <Popconfirm
                    title="Apakah Anda yakin untuk menyimpan perubahan ini?"
                    onConfirm={() => this.handleSave(record, form)}
                    okText="Iya"
                    cancelText="Tidak"
                  >
                    <Button type="link">Simpan</Button>
                  </Popconfirm>
                )}
              </EditableContext.Consumer>
              <Divider type="vertical" />
              <Button type="link" onClick={this.handleCancel}>
                Batal
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                type="link"
                onClick={() => this.handleEdit(record)}
                disabled={editingKey !== ''}
              >
                Ubah
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                onClick={() => this.handleDelete(record)}
                disabled={editingKey !== ''}
              >
                Hapus
              </Button>
            </Fragment>
          );
        },
      },
    ];

    const renderedColumns = columns.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          rowKey: 'id',
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          isMandatory: col.mandatory,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <Card title="Role List" bordered={false}>
        <div className="bt-table">
          <EditableContext.Provider value={this.props.form}>
            <Table
              size="small"
              onChange={this.onChangeTable}
              components={components}
              columns={renderedColumns}
              dataSource={dataSource}
              bordered={true}
              pagination={
                meta && {
                  current: meta.current_page,
                  pageSize: meta.per_page,
                  pageSizeOptions,
                  showSizeChanger,
                  total: meta.total,
                }
              }
              locale={{
                emptyText: tableEmptyState,
              }}
              loading={isFetching}
              rowKey="id"
            />
          </EditableContext.Provider>
        </div>
      </Card>
    );
  }
}

export default Form.create<IProps>()(TableComponent);
