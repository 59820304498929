import { IParamsChangePassword } from '@interfaces/iparams';
import { Button, Form, Icon, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/es/form/FormItem';
import React, { Component } from 'react';

interface IProps extends FormComponentProps {
  changePassword: (body: IParamsChangePassword) => any;
  isSubmit: boolean;
  close: () => void;
}

interface IState {
  confirmDirty: boolean;
}

export class PageChangePasswordComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
  }
  public validateToNextPassword = (rule: any, value: any, callback: () => void) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;

    if (value && confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };
  public compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value === form.getFieldValue('password')) {
      callback('Password Lama dan Password Baru tidak boleh sama!');
    } else {
      callback();
    }
  };
  public handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const {
      form: { validateFields, resetFields },
      changePassword,
      close,
    } = this.props;
    validateFields(async (error: any, values: any) => {
      if (!error) {
        const body: IParamsChangePassword = {
          old_password: values.oldPassword,
          new_password: values.newPassword,
        };
        await changePassword(body);
        resetFields();
        close();
      }
    });
  };
  public render() {
    const {
      form: { getFieldDecorator },
      close,
      isSubmit,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="change-password-form">
        <FormItem label="Password Lama">
          {getFieldDecorator('oldPassword', {
            rules: [
              { required: true, message: 'Password Lama harus diisi!' },
              { validator: this.validateToNextPassword },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password Lama"
            />,
          )}
        </FormItem>
        <FormItem label="Password Baru">
          {getFieldDecorator('newPassword', {
            rules: [
              { message: 'Password Baru harus diisi!', required: true },
              { validator: this.compareToFirstPassword },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password Baru"
            />,
          )}
        </FormItem>
        <FormItem>
          <Button className="action-btn" type="default" htmlType="reset" onClick={() => close()}>
            Batal
          </Button>
          <Button
            className="action-btn"
            type="primary"
            loading={isSubmit}
            onClick={this.handleSubmit}
          >
            Simpan
          </Button>
        </FormItem>
      </Form>
    );
  }
}
export default Form.create<IProps>()(PageChangePasswordComponent);
