import { IInvoice } from '@interfaces/iinvoice';
import { ITransitCost } from '@interfaces/itransit-cost';
import { companyType, orderStatus, costStatus, paymentMethod, dateFormat } from '@utils/constants';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { formatTime } from './time';

export const titleCase = (value: string) => {
  return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
};

export const currencyCase = (n?: number, currency: string = 'Rp.', cent: number = 0) => {
  if (typeof n !== 'number') {
    return n;
  }

  const result = n
    ? n
        ?.toFixed(cent)
        ?.replace('.', ',')
        ?.replace(/./g, (c, i, a) => {
          const counter = a[0] === '-' ? 1 : 0;
          return i > counter && c !== ',' && (a.length - i) % 3 === 0 ? '.' + c : c;
        })
    : '0';

  if (!currency) {
    return Number(result.replace('.', ''));
  }
  return `${currency} ${result}`;
};

export const companyTypeCase = (type?: string, name?: string, max: number = 0) => {
  if (!type || !name) {
    return '';
  }

  if (type === companyType.P) {
    return name;
  }

  const companyName = `${type}. ${name}`;
  return max > 0 ? companyName.substring(0, max) : companyName;
};

export const orderStatusCase = (status?: number, short?: boolean) => {
  switch (status) {
    case orderStatus.PurchaseOrder:
      return short ? 'PO' : 'Purchase Order';
    case orderStatus.TandaTerima:
      return short ? 'TT' : 'Tanda Terima';
    case orderStatus.SuratMuatan:
      return short ? 'SM' : 'Surat Muatan';
    case orderStatus.DaftarMuatan:
      return short ? 'DM' : 'Daftar Muatan';
    case orderStatus.Invoice:
      return short ? 'INV' : 'Invoice';
    default:
      return '';
  }
};

export const costStatusCase = (status: number) => {
  switch (status) {
    case costStatus.Unpaid:
      return 'Belum Dibayar';
    case costStatus.Paid:
      return 'Sudah Dibayar';
    default:
      return '';
  }
};

export const numWord = (num: string) => {
  let sentence = '';
  const numbers = new Array(
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
  );
  const word = new Array(
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
  );
  const step = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun');
  const numLength = num.length;

  if (numLength > 15) {
    sentence = 'Diluar Batas';
  } else {
    for (let index = 1; index <= numLength; index++) {
      numbers[index] = num.substr(-index, 1);
    }

    let i = 1;
    let j = 0;

    while (i <= numLength) {
      let subSentence = '';
      let word1 = '';
      let word2 = '';
      let word3 = '';

      if (numbers[i + 2] !== '0') {
        word1 = numbers[i + 2] === '1' ? 'Seratus' : word[numbers[i + 2]] + ' Ratus';
      }

      if (numbers[i + 1] !== '0') {
        if (numbers[i + 1] === '1') {
          if (numbers[i] === '0') {
            word2 = 'Sepuluh';
          } else if (numbers[i] === '1') {
            word2 = 'Sebelas';
          } else {
            word2 = word[numbers[i]] + ' Belas';
          }
        } else {
          word2 = word[numbers[i + 1]] + ' Puluh';
        }
      }

      if (numbers[i] !== '0') {
        if (numbers[i + 1] !== '1') {
          word3 = word[numbers[i]];
        }
      }

      if (numbers[i] !== '0' || numbers[i + 1] !== '0' || numbers[i + 2] !== '0') {
        subSentence = word1 + ' ' + word2 + ' ' + word3 + ' ' + step[j] + ' ';
      }

      sentence = subSentence + sentence;
      i = i + 3;
      j = j + 1;
    }

    if (numbers[5] === '0' && numbers[6] === '0') {
      sentence = sentence.replace('Satu Ribu', 'Seribu');
    }
  }

  return sentence;
};

export const getPaymentMethod = (invoice: IInvoice) => {
  const status = invoice.statusPayment;
  if (!status) {
    return '-';
  } else {
    const payMethod = status === 2 ? invoice.payMethodPaid : invoice.payMethodDp;
    return payMethod !== undefined && payMethod !== null ? paymentMethod[payMethod] : '-';
  }
};

export const getPaymentDate = (invoice: IInvoice) => {
  const status = invoice.statusPayment;
  if (!status) {
    return '-';
  } else {
    const datePay = status === 2 ? invoice.datePaid : invoice.dateDp;
    return datePay ? formatTime(datePay, dateFormat) : '-';
  }
};

export const getInvoiceTT = (invoice: IInvoice, isRender: boolean = false) => {
  const invDetails = invoice.details;
  if (invDetails) {
    if (isRender) {
      const element: any[] = [];
      invDetails?.map((item, index: number) => {
        const ttCode = item?.surat_muatan?.code;
        element.push(
          <Fragment key={ttCode}>
            {index !== 0 && <br />}&bull;{' '}
            <Link to={`/tanda-terima/detail/${ttCode}`}>{ttCode}</Link>
          </Fragment>,
        );
      });
      return element;
    } else {
      return invDetails.map((item) => item.surat_muatan.code).join(', ');
    }
  } else {
    return '-';
  }
};

export const getTransitCostSM = (transitCost: ITransitCost, isRender: boolean = false) => {
  const smIds = transitCost.suratMuatanIds;
  if (smIds) {
    const suratMuatanIds = smIds?.split(`,`).map((x) => +x);
    const dmDetails = transitCost?.daftar_muatan?.details?.filter(
      (item) => item?.details?.id && suratMuatanIds?.includes(item?.details?.id),
    );

    if (isRender) {
      const element: any[] = [];
      dmDetails?.map((item, index: number) => {
        element.push(
          <Fragment key={item?.details?.id}>
            {dmDetails.length > 1 && index !== 0 && <br />}&bull;{' '}
            <Link to={`/surat-muatan/detail/${item?.details?.code}`}>{item?.details?.code}</Link>
          </Fragment>,
        );
      });
      return element;
    } else {
      return dmDetails?.map((item, index: number) => item.details.code).join(', ');
    }
  } else {
    return '-';
  }
};
