import Auth from '@adaptors-api/auth-api';
import { moduleType, roleType } from '@utils/constants';
import { IResponseNotif } from '@interfaces/iresponse-notif';
import { IRole } from '@interfaces/irole';
import { notification } from 'antd';
import axios from 'axios';

export interface IParamsRequestApi {
  method: string;
  url: string;
  params?: any;
  body?: any;
  customBaseUrl?: string;
  isReturnCallback?: boolean;
  callback?: any;
  additionalHeader?: any;
}

interface IHeaders {
  Accept: string;
  Authorization: string;
}

export const keyAccessToken =
  process.env.REACT_APP_CREDENTIAL_ACCESS_TOKEN || 'Minofa@Credential@accessToken';
export const keyRefreshToken =
  process.env.REACT_APP_CREDENTIAL_REFRESH_TOKEN || 'Minofa@Credential@refreshToken';
export const keyProfile = process.env.REACT_APP_CREDENTIAL_PROFILE || 'Minofa@Credential@profile';
export const keyMode = process.env.REACT_APP_MODE || 'localhost';

export const RequestAPI = (data: IParamsRequestApi) => {
  const {
    url,
    customBaseUrl,
    additionalHeader,
    method,
    params,
    body,
    isReturnCallback,
    callback,
  } = data;
  const URL: string = `${customBaseUrl ? customBaseUrl : process.env.REACT_APP_API_URL}/${url}`;
  const accessToken: string = getAccesshToken();
  const headers: IHeaders = {
    ...additionalHeader,
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  let action: any = null;
  let config: object = { method, headers, url: URL };

  config = method === 'GET' ? { ...config, params } : { ...config, data: body };

  action = axios(config);
  if (isReturnCallback) {
    action = action
      .then((response: any) => {
        const json = response.data;
        callback({ status: response.status, data: json, meta: response.data.meta });
        return { status: response.status, data: json, meta: response.data.meta };
      })
      .catch((error: any) => {
        if (error.response) {
          if (error.response.status === 401) {
            actionRefreshToken();
          } else {
            let jsonError: string = error.toString();
            if (error.response) {
              jsonError = error.response.data;
            }
            callback(jsonError);
          }
        }
      });
  }
  return action
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      if (error.response) {
        if (error.response.status === 401) {
          actionRefreshToken();
        } else {
          return error.response;
        }
      }
    });
};

export const getAccesshToken = (): string => {
  const accessToken = localStorage.getItem(keyAccessToken);
  return accessToken ? accessToken : '';
};

export const getRefreshToken = (): string => {
  const refreshToken = localStorage.getItem(keyRefreshToken);
  return refreshToken ? refreshToken : '';
};

export const getProfile = (): any => {
  const profile = localStorage.getItem(keyProfile);
  if (profile) {
    return JSON.parse(profile);
  }
  return {};
};

export const getModule = (module: string): any => {
  const profile = getProfile();
  if (profile && profile.roles) {
    const { roles } = profile;
    switch (module) {
      case moduleType.ALL:
        return true;
      case moduleType.DIR:
        return roles.find((item: IRole) => item.name === roleType.DIR);
      case moduleType.OPR:
        return roles.find((item: IRole) =>
          [roleType.OPR_HEAD.toString(), roleType.OPR_ADM.toString()].includes(item.name),
        );
      case moduleType.FIN:
        return roles.find((item: IRole) =>
          [roleType.FIN_HEAD.toString(), roleType.FIN_ADM.toString()].includes(item.name),
        );
      case moduleType.COL:
        return roles.find((item: IRole) =>
          [roleType.COL_HEAD.toString(), roleType.COL_ADM.toString()].includes(item.name),
        );
    }
  }
  return undefined;
};

export const actionRefreshToken = () => {
  const refreshToken = getRefreshToken();
  const body = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
  };
  return RequestAPI(Auth.login(body))
    .then((response: any) => {
      if (response.status === 200) {
        const credential = response.data;
        localStorage.setItem(keyAccessToken, credential.access_token);
        localStorage.setItem(keyRefreshToken, credential.refresh_token);
        const currentUrl = window.location.href;
        window.location.href = currentUrl;
      }
    })
    .catch((e: Error) => {
      localStorage.clear();
      window.location.href = '/auth';
    });
};

export const errorNotif = (response: IResponseNotif) => {
  const notif: any = {
    type: 'error',
    message: response.title ? response.title : 'Error',
    description: response.message ? response.message : 'Server Error!',
  };
  notification.open(notif);
};

export const successNotif = (response: IResponseNotif) => {
  const notif: any = {
    type: 'success',
    message: response.title ? response.title : 'Sukses',
    description: response.message ? response.message : 'Sukses',
  };
  notification.open(notif);
};

export default RequestAPI;
