import Administration from '@adaptors-api/administration-api';
import { IEmployee } from '@interfaces/iemployee';
import { IMeta } from '@interfaces/imeta';
import { IRole } from '@interfaces/irole';
import { IUser } from '@interfaces/iuser';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.EMPLOYEE));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.EMPLOYEE));
};

export const setEmployeeList = (employeeList: IEmployee[]) => ({
  employeeList,
  type: ActionTypes.PAGE_EMPLOYEE_SET_EMPLOYEE_LIST,
});

export const setUser = (user?: IUser) => ({
  user,
  type: ActionTypes.PAGE_EMPLOYEE_SET_USER,
});

export const setUserList = (userList: IUser[]) => ({
  userList,
  type: ActionTypes.PAGE_EMPLOYEE_SET_USER_LIST,
});

export const setRoleList = (roleList: IRole[]) => ({
  roleList,
  type: ActionTypes.PAGE_EMPLOYEE_SET_ROLE_LIST,
});

export const fetchEmployeeList = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Administration.getEmployees(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const employeeList: IEmployee[] = data;
      const metaData: IMeta = meta;
      dispatch(setEmployeeList(employeeList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchUser = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setUser(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(Administration.getUser(code))
    .then((response: any) => {
      const { data } = response.data;
      const user: IUser = data;
      dispatch(setUser(user));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchUserList = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Administration.getUsers(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const userList: IUser[] = data;
      const metaData: IMeta = meta;
      dispatch(setUserList(userList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const updateUser = (code: string, body: IUser) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Administration.updateUser(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah User ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah User Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteUser = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Administration.deleteUser(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Karyawan ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Karyawan Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
