import {
  DataName,
  dateFormat,
  defaultUploadImage,
  emptyParams,
  fileType,
  FormType,
} from '@utils/constants';
import { IDriver } from '@interfaces/idriver';
import { IVehicle, IVehicleType } from '@interfaces/ivehicle';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Icon, Input, PageHeader, Row, Select, Upload } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { getBase64, getUploadedFile } from '@utils/upload';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchDriverList: (params: any) => void;
  driverList: IDriver[];
  fetchVehicleTypeList: (params: any) => void;
  vehicleTypeList: IVehicleType[];
  fetchVehicle: (code: string) => void;
  vehicle?: IVehicle;
  createVehicle: (body: IVehicle) => void;
  updateVehicle: (code: string, body: IVehicle) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
  imageSTNK: string;
}

export class PagevehicleFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      formType: code ? 'edit' : 'add',
      imageSTNK: '',
    };
  }
  public componentDidMount = () => {
    const { match, fetchVehicle, fetchVehicleTypeList, fetchDriverList } = this.props;
    const { code } = match.params;
    fetchVehicle(code);
    fetchVehicleTypeList(emptyParams);
    fetchDriverList(emptyParams);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.vehicle !== this.props.vehicle) {
      const { vehicle } = this.props;
      this.setState({
        imageSTNK: vehicle?.imageSTNK || '',
      });
    }
  };
  public handleSubmit = () => {
    const { form, vehicle, createVehicle, updateVehicle } = this.props;
    const { formType, imageSTNK } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const datavehicle: IVehicle = {
          ...vehicle,
          vehicleTypesId: values.vehicleType,
          driversId: values.driver,
          policeNo: values.policeNo,
          STNK: values.stnk,
          imageSTNK,
          oneYearTaxDate: formatTime(values.oneYearTaxDate, 'YYYY-MM-DD'),
          fiveYearTaxDate: formatTime(values.fiveYearTaxDate, 'YYYY-MM-DD'),
          KIR: values.kir,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && vehicle?.code) {
          await updateVehicle(vehicle.code, datavehicle);
        } else {
          await createVehicle({ ...datavehicle, created_by: currentUser });
        }
        history.push('/vehicle');
      }
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const isValidDocument: boolean = defaultUploadImage.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG or PNG file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public handleUpload = async (option: any) => {
    try {
      getBase64(option.file, (fileBase64) => {
        this.setState({ imageSTNK: fileBase64 });
        option.onSuccess(fileBase64, option.file);
      });
    } catch (e) {
      option.onError(e, option.file);
    }
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { isFetching, form, vehicle, vehicleTypeList, driverList } = this.props;
    const { formType } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const title = formType && formType === 'edit' ? 'Ubah Kendaraan' : 'Tambah Kendaraan';
    const loading = isLoading(isFetching, DataName.VEHICLE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Nomor Polisi">
                  {getFieldDecorator('policeNo', {
                    initialValue: vehicle?.policeNo ?? '',
                    rules: [{ required: true, message: 'Nomor polisi harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nomor Polisi" />)}
                </Form.Item>
                <Form.Item label="Tipe Kendaraan">
                  {getFieldDecorator('vehicleType', {
                    initialValue: vehicle?.vehicle_type?.id ?? '',
                    rules: [{ message: 'Tipe Kendaraan harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Tipe Kendaraan"
                      optionFilterProp="children"
                    >
                      {vehicleTypeList &&
                        vehicleTypeList.map((item: IVehicleType) => (
                          <Option key={item.code} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Supir">
                  {getFieldDecorator('driver', {
                    initialValue: vehicle?.driver?.id ?? '',
                    rules: [{ message: 'Supir harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select showSearch={true} placeholder="Pilih Supir" optionFilterProp="children">
                      {driverList &&
                        driverList.map((item: IDriver) => (
                          <Option key={item.code} value={item.id}>
                            {item.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Tanggal Pajak Satu Tahun">
                  {getFieldDecorator('oneYearTaxDate', {
                    initialValue: vehicle?.oneYearTaxDate
                      ? moment(vehicle?.oneYearTaxDate)
                      : moment(),
                    rules: [{ message: 'Pajak tahunan harus dipilih!', required: true }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: vehicle?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Nomor STNK">
                  {getFieldDecorator('stnk', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: vehicle?.STNK ?? '',
                    rules: [
                      { min: 5, message: 'Nomor STNK minimal 5 digit!' },
                      { max: 16, message: 'Nomor STNK maksimal 16 digit!' },
                      { message: 'Nomor STNK hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nomor STNK" maxLength={16} />)}
                </Form.Item>
                <Form.Item label="STNK File">
                  {getFieldDecorator('imageSTNK', {
                    initialValue: getUploadedFile(fileType.STNK, vehicle?.imageSTNK),
                    valuePropName: 'fileList',
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png, .pdf"
                      customRequest={(option) => this.handleUpload(option)}
                      onRemove={() => {
                        this.setState({ imageSTNK: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageSTNK') !== undefined &&
                          getFieldValue('imageSTNK').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Nomor KIR">
                  {getFieldDecorator('kir', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: vehicle?.KIR ?? '',
                    rules: [
                      { min: 5, message: 'Nomor KIR minimal 5 digit!' },
                      { max: 16, message: 'Nomor KIR maksimal 16 digit!' },
                      { message: 'Nomor KIR hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nomor STNK" maxLength={16} />)}
                </Form.Item>
                <Form.Item label="Tanggal Pajak Lima Tahun">
                  {getFieldDecorator('fiveYearTaxDate', {
                    initialValue: vehicle?.fiveYearTaxDate
                      ? moment(vehicle?.fiveYearTaxDate)
                      : moment(),
                    rules: [{ message: 'Pajak lima tahunan harus dipilih!', required: true }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PagevehicleFormComponent);
