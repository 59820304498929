import { IVehicleType } from '@interfaces/ivehicle';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import {
  createVehicleType,
  fetchVehicleList,
  fetchVehicleTypeList,
  updateVehicleType,
} from './action';
import PageVehicleComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  vehicleTypeList: state.PageMasterDataReducer.vehicleTypeList,
  vehicleList: state.PageMasterDataReducer.vehicleList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVehicleTypeList: (params: any) => dispatch(fetchVehicleTypeList(params)),
  createVehicleType: (body: IVehicleType) => dispatch(createVehicleType(body)),
  updateVehicleType: (code: string, body: IVehicleType) => dispatch(updateVehicleType(code, body)),
  fetchVehicleList: (params: any) => dispatch(fetchVehicleList(params)),
});

export const PageVehicleContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageVehicleComponent);

export default PageVehicleContainer;
