import { ITandaTerima } from '@interfaces/itanda-terima';
import { fetchPurchaseOrderList } from '@pages/page-account-receivable/page-purchase-order/action';
import {
  createTandaTerima,
  fetchTandaTerima,
  updateTandaTerima,
} from '@pages/page-account-receivable/page-tanda-terima/action';
import { fetchCustomerList } from '@pages/page-master/page-customer/action';
import { fetchRecipientList } from '@pages/page-master/page-recipient/action';
import { fetchWarehouseList } from '@pages/page-master/page-warehouse/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageTandaTerimaFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  tandaTerima: state.PageAccountReceivableReducer.tandaTerima,
  purchaseOrderList: state.PageAccountReceivableReducer.purchaseOrderList,
  customerList: state.PageMasterDataReducer.customerList,
  recipientList: state.PageMasterDataReducer.recipientList,
  warehouseList: state.PageMasterDataReducer.warehouseList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchPurchaseOrderList: (params: any) => dispatch(fetchPurchaseOrderList(params)),
  fetchCustomerList: (params: any) => dispatch(fetchCustomerList(params)),
  fetchRecipientList: (params: any) => dispatch(fetchRecipientList(params)),
  fetchWarehouseList: (params: any) => dispatch(fetchWarehouseList(params)),
  fetchTandaTerima: (code: string) => dispatch(fetchTandaTerima(code)),
  createTandaTerima: (body: ITandaTerima) => dispatch(createTandaTerima(body)),
  updateTandaTerima: (code: string, body: ITandaTerima) => dispatch(updateTandaTerima(code, body)),
});

export const PageTandaTerimaFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageTandaTerimaFormComponent);

export default PageTandaTerimaFormContainer;
