import { IVehicle } from '@interfaces/ivehicle';
import { fetchDriverList } from '@pages/page-master/page-driver/action';
import {
  createVehicle,
  fetchVehicle,
  fetchVehicleTypeList,
  updateVehicle,
} from '@pages/page-master/page-vehicle/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageVehicleFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => {
  return {
    isFetching: state.DefaultReducer.isFetching,
    metaData: state.DefaultReducer.metaData,
    vehicle: state.PageMasterDataReducer.vehicle,
    driverList: state.PageMasterDataReducer.driverList,
    vehicleTypeList: state.PageMasterDataReducer.vehicleTypeList,
  };
};

export const mapDispatchToProps = (dispatch: any) => ({
  fetchDriverList: (params: any) => dispatch(fetchDriverList(params)),
  fetchVehicleTypeList: (params: any) => dispatch(fetchVehicleTypeList(params)),
  fetchVehicle: (code: string) => dispatch(fetchVehicle(code)),
  createVehicle: (body: IVehicle) => dispatch(createVehicle(body)),
  updateVehicle: (code: string, body: IVehicle) => dispatch(updateVehicle(code, body)),
});

export const PageVehicleFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageVehicleFormComponent);

export default PageVehicleFormContainer;
