import cityList from '@data/list_location.json';
import { companyType, DataName, emptyParams, FormType } from '@utils/constants';
import { ICity } from '@interfaces/icity';
import { ICustomer } from '@interfaces/icustomer';
import { IEmployee } from '@interfaces/iemployee';
import { enumToArray } from '@utils/enum-to-array';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { Button, Card, Col, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchEmployeeList: (params: any) => void;
  employeeList: IEmployee[];
  fetchCustomer: (code: string) => void;
  customer?: ICustomer;
  createCustomer: (body: ICustomer) => void;
  updateCustomer: (code: string, body: ICustomer) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  companyTypeList: string[];
  selectedCompanyType: string;
  formType: FormType;
}

export class PageCustomerFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { code } = props.match.params;
    const companyTypeList = enumToArray(companyType);
    this.state = {
      companyTypeList,
      selectedCompanyType: companyTypeList[0],
      formType: code ? 'edit' : 'add',
    };
  }
  public componentDidMount = () => {
    const { match, fetchCustomer, fetchEmployeeList } = this.props;
    const { code } = match.params;
    fetchCustomer(code);
    fetchEmployeeList(emptyParams);
  };
  public handleSubmit = () => {
    const { form, customer, createCustomer, updateCustomer } = this.props;
    const { selectedCompanyType, formType } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataCustomer: ICustomer = {
          ...customer,
          name: values.name,
          type: selectedCompanyType,
          address: values.address,
          city: values.city,
          email: values.email,
          phone: values.phoneNumber,
          fax: values.fax,
          picName: values.picName,
          picPhone: values.picPhone,
          salesMarketing: values.salesMarketing,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && customer?.code) {
          await updateCustomer(customer.code, dataCustomer);
        } else {
          await createCustomer({ ...dataCustomer, created_by: currentUser });
        }
        history.push('/customer');
      }
    });
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { isFetching, form, employeeList, customer } = this.props;
    const { companyTypeList, selectedCompanyType, formType } = this.state;
    const { getFieldDecorator } = form;
    const title = formType && formType === 'edit' ? 'Ubah Customer' : 'Tambah Customer';
    const loading = isLoading(isFetching, DataName.CUSTOMER);

    const selectCustomerType = (
      <Select
        defaultValue={customer && customer.type ? customer.type : selectedCompanyType}
        placeholder="Select Customer Type"
        style={{ width: 100 }}
        onChange={(value: any) => this.setState({ selectedCompanyType: value })}
      >
        {companyTypeList &&
          companyTypeList.map((item: string) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
      </Select>
    );

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Nama Customer">
                  {getFieldDecorator('name', {
                    initialValue: customer?.name ?? '',
                    rules: [{ required: true, message: 'Nama customer harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input addonBefore={selectCustomerType} placeholder="Nama Customer" />)}
                </Form.Item>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    initialValue: customer?.email ?? '',
                    rules: [{ message: 'Email tidak valid!', type: 'email' }],
                  })(<Input placeholder="Email" />)}
                </Form.Item>
                <Form.Item label="Telepon">
                  {getFieldDecorator('phoneNumber', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: customer?.phone ?? '',
                    rules: [
                      { min: 5, message: 'Telepon minimal 5 digit!' },
                      { max: 15, message: 'Telepon maksimal 15 digit!' },
                      { message: 'Telepon hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Telepon harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Fax">
                  {getFieldDecorator('fax', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: customer?.fax ?? '',
                    rules: [
                      { min: 5, message: 'Fax minimal 5 digit!' },
                      { max: 15, message: 'Fax maksimal 15 digit!' },
                      { message: 'Fax hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Fax Number" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Alamat">
                  {getFieldDecorator('address', {
                    initialValue: customer?.address ?? '',
                    rules: [
                      { min: 3, message: 'Alamat minimal 3 karakter!' },
                      { max: 250, message: 'Alamat maksimal 250 karakter!' },
                      { required: true, message: 'Alamat harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Alamat Kantor, Nama Jalan, Kode Pos, lain-lain." />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Sales Marketing">
                  {getFieldDecorator('salesMarketing', {
                    initialValue: customer?.salesMarketing ?? '',
                    rules: [{ message: 'Sales marketing harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Sales Marketing"
                      optionFilterProp="children"
                    >
                      {employeeList &&
                        employeeList.map((item: IEmployee) => (
                          <Option key={item.code} value={item.fullName}>
                            {item.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Nama PIC">
                  {getFieldDecorator('picName', {
                    initialValue: customer?.picName ?? '',
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nama PIC" />)}
                </Form.Item>
                <Form.Item label="Telepon PIC">
                  {getFieldDecorator('picPhone', {
                    initialValue: customer?.picPhone ?? '',
                    getValueFromEvent: this.onChangeNumber,
                    rules: [
                      { min: 5, message: 'Telepon PIC minimal 5 digit!' },
                      { max: 15, message: 'Telepon PIC maksimal 15 digit!' },
                      { message: 'Telepon PIC hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon PIC" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Kota">
                  {getFieldDecorator('city', {
                    initialValue: customer?.city ?? '',
                    rules: [{ required: true, message: 'Kota harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Kota" showSearch={true}>
                      {cityList &&
                        cityList.data.map((item: ICity) => (
                          <Option key={item.kabko} value={item.kabko}>
                            {item.kabko}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: customer?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageCustomerFormComponent);
