import Logo from '@assets/images/logo-only.png';
import { toUpper } from 'lodash';
import React, { Component } from 'react';

interface IProps {
  title: string;
  isHideHeader?: boolean;
  isShowCompanyDetail?: boolean;
}

export class DocumentHeaderComponent extends Component<IProps, any> {
  public render() {
    const { title, isHideHeader, isShowCompanyDetail } = this.props;
    const hideHeader = isHideHeader !== undefined ? isHideHeader : true;
    const showCompanyDetail = isShowCompanyDetail !== undefined ? isShowCompanyDetail : true;
    return (
      <div className={`doc-header ${hideHeader ? 'hide' : 'narrow'}`}>
        {showCompanyDetail && <img src={Logo} className="doc-header-image" />}
        <div className="doc-header-container">
          <div className="doc-header-company">
            <br />
            <div className="doc-header-company-name">PT MINOFA MULTI SUKSES</div>
            {showCompanyDetail && (
              <div className="doc-header-company-sub">Expedition . Cargo . Trucking</div>
            )}
            {showCompanyDetail && (
              <div className="doc-header-company-info">
                Jl Taman Harapan Indah, Blok HO 1 No.57, Bekasi, 17214 <br /> (021) 8888 6061/8888
                6351 | minofa.multisukses@gmail.com | 0812 9966 5216/0812 9966 5217
              </div>
            )}
            <hr />
          </div>
          <div className="doc-header-title">
            {!showCompanyDetail && (
              <div>
                <br />
              </div>
            )}
            <b>{toUpper(title)}</b>
          </div>
        </div>
        {showCompanyDetail && <br />}
      </div>
    );
  }
}
export default DocumentHeaderComponent;
