import { DataName, dateFormat, emptyParams, FormType, invoiceStatus } from '@utils/constants';
import { IInvoice } from '@interfaces/iinvoice';
import { IInvoiceReceipt, IInvoiceReceiptDetail } from '@interfaces/iinvoice-receipt';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Divider, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import DetailTableComponent from '@pages/page-account-receivable/page-invoice-receipt/libraries/detail/libraries/detail-table';
import { IEmployee } from '@interfaces/iemployee';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchEmployeeList: (params: any) => void;
  employeeList: IEmployee[];
  fetchInvoiceList: (params: any) => void;
  invoiceList: IInvoice[];
  fetchInvoiceReceipt: (code: string) => void;
  invoiceReceipt?: IInvoiceReceipt;
  createInvoiceReceipt: (body: IInvoiceReceipt) => void;
  updateInvoiceReceipt: (code: string, body: IInvoiceReceipt) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
  detailId: number;
  grandTotal: number;
  invoiceList: IInvoice[];
  invoices: IInvoice[];
}

export class PageInvoiceReceiptFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      invoiceList: [],
      invoices: [],
      formType: code ? 'edit' : 'add',
      detailId: 1,
      grandTotal: 0,
    };
  }
  public componentDidMount = () => {
    const { match, fetchInvoiceReceipt, fetchEmployeeList } = this.props;
    const { code } = match.params;
    fetchInvoiceReceipt(code);
    fetchEmployeeList(emptyParams);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    const { invoiceReceipt, invoiceList } = this.props;
    if (prevProps.invoiceReceipt !== invoiceReceipt && invoiceReceipt) {
      const invoices: IInvoice[] = [];
      const newList: IInvoice[] = invoiceList;
      invoiceReceipt.details?.map((item: IInvoiceReceiptDetail) => {
        invoices.push(item.invoice);

        const isExist = invoiceList.find((list) => list.code === item.invoice.code);
        if (!isExist) {
          newList.push(item.invoice);
        }
      });
      this.setState({
        invoices,
        invoiceList: newList,
        detailId: invoiceReceipt.details?.length || 0,
        grandTotal: invoiceReceipt?.total,
      });
    }
  };
  public handleSubmit = () => {
    const { form, invoiceReceipt, createInvoiceReceipt, updateInvoiceReceipt } = this.props;
    const { formType, invoices } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataInvoiceReceipt: IInvoiceReceipt = {
          ...invoiceReceipt,
          date: formatTime(values.date, 'YYYY-MM-DD'),
          total: Number(values.total),
          invoiceReceiver: values.invoiceReceiver,
          collector: values.collector,
          invoices,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && invoiceReceipt?.code) {
          await updateInvoiceReceipt(invoiceReceipt.code, dataInvoiceReceipt);
        } else {
          await createInvoiceReceipt({
            ...dataInvoiceReceipt,
            created_by: currentUser,
          });
        }
        history.push('/invoice-receipt');
      }
    });
  };
  public getDetailValue = (index: any, type: string) => {
    const { form } = this.props;
    const { getFieldValue } = form;

    const value = getFieldValue(`detail[${index}]${type}`);
    if (value === undefined || isNaN(value)) {
      return 0;
    }

    return Number(value);
  };
  public handleSearchInvoice = (value: string) => {
    if (value?.length > 6) {
      const { fetchInvoiceList } = this.props;
      fetchInvoiceList({
        ...emptyParams,
        status: `[${invoiceStatus['Belum Kirim']}]`,
        code: value,
      });
    }
  };
  public onSelectInvoice = (values: string[], option: any) => {
    const { invoiceList } = this.props;
    const { invoices } = this.state;

    let newInvoice: IInvoice[] = [];
    if (values?.length > invoices.length) {
      const newData = invoiceList.filter(
        (invoice) =>
          invoice.code &&
          values.includes(invoice.code) &&
          !invoices.find((item) => item.code === invoice.code),
      );
      newInvoice = [...invoices, ...newData];
    } else {
      newInvoice = invoices.filter((invoice) => invoice.code && values.includes(invoice.code));
    }

    let grandTotal = 0;
    newInvoice.map((item: IInvoice) => {
      grandTotal += item.total;
    });

    this.setState({ invoices: newInvoice, grandTotal });
  };
  public handleChangeStatus = (invoiceId: number, value: any) => {
    const { invoices } = this.state;
    const status = Number(invoiceStatus[value.toString()]);

    const data = invoices.map((item: IInvoice) => {
      if (item.id === invoiceId) {
        item.status = status;
      }
      return item;
    });

    this.setState({ invoices: data });
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { isFetching, form, invoiceReceipt, invoiceList, employeeList } = this.props;
    const { formType, grandTotal, invoices } = this.state;
    const { getFieldDecorator } = form;
    const title =
      formType && formType === 'edit' ? 'Ubah Tanda Terima Invoice' : 'Buat Tanda Terima Invoice';
    const loading = isLoading(isFetching, DataName.INVOICE_RECEIPT);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Tanggal">
                  {getFieldDecorator('date', {
                    initialValue: invoiceReceipt?.date ? moment(invoiceReceipt?.date) : moment(),
                    rules: [{ required: true, message: 'Tanggal harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Penerima Invoice">
                  {getFieldDecorator('invoiceReceiver', {
                    initialValue: invoiceReceipt?.invoiceReceiver ?? '',
                    rules: [{ required: true, message: 'Penerima Invoice harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Penerima Invoice" />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: invoiceReceipt?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Total (Invoice)">
                  {getFieldDecorator('total', {
                    initialValue: grandTotal,
                    getValueFromEvent: this.onChangeNumber,
                    rules: [
                      { message: 'Total hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Total harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Total" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Kolektor">
                  {getFieldDecorator('collector', {
                    initialValue: invoiceReceipt?.collector ?? '',
                    rules: [{ message: 'Kolektor harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Kolektor"
                      optionFilterProp="children"
                    >
                      {employeeList &&
                        employeeList.map((item: IEmployee) => (
                          <Option key={item.code} value={item.fullName}>
                            {item.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">Daftar Invoice</Divider>
            <Form.Item label="Invoice">
              <Select
                mode="multiple"
                showSearch={true}
                placeholder="Pilih Invoice"
                optionFilterProp="children"
                onChange={this.onSelectInvoice}
                onSearch={this.handleSearchInvoice}
              >
                {invoiceList &&
                  invoiceList.map((item: IInvoice) => (
                    <Option key={item.code} value={item.code}>
                      {item.code}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {invoices.length > 0 && (
              <DetailTableComponent
                dataSource={invoices}
                handleChangeStatus={this.handleChangeStatus}
              />
            )}
            <br />
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}

export default Form.create<IProps>()(PageInvoiceReceiptFormComponent);
