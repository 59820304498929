import { DocumentHeader } from '@components/atoms/document-header';
import { dateFormat } from '@utils/constants';
import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Col, Descriptions, Modal, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import DetailTableComponent from './detail-table';

interface IProps {
  title: string;
  show: boolean;
  hide: () => void;
  data: IDaftarMuatan;
  dataDetails: ISuratMuatan[];
}

export class ModalPrintComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const { title, show, hide, data, dataDetails } = this.props;
    const {
      code,
      dmDate,
      vehicle,
      totalPrice,
      totalVendorPrice,
      expeditionCommission,
      adminFee,
      downPayment,
      locoFee,
      created_by,
    } = data;

    const total = totalVendorPrice && totalVendorPrice > 0 ? totalVendorPrice : totalPrice;
    const unpaidFee = total - expeditionCommission - adminFee - downPayment - locoFee;

    return (
      <Modal
        title={title}
        visible={show}
        width={1300}
        okText="Cetak"
        cancelText="Batal"
        onCancel={hide}
        onOk={() => window.print()}
      >
        <DocumentHeader title={title} isHideHeader={false} isShowCompanyDetail={false} />
        <Descriptions column={3} size="small" className="doc-header-info small narrow">
          <Descriptions.Item label="No Daftar Muatan">{code}</Descriptions.Item>
          <Descriptions.Item label="Vendor">
            {companyTypeCase(vehicle?.driver?.vendor?.type, vehicle?.driver?.vendor?.name)}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal">
            {formatTime(moment(dmDate), dateFormat)}
          </Descriptions.Item>
          <Descriptions.Item label="Nomor Kendaraan">{vehicle?.policeNo}</Descriptions.Item>
          <Descriptions.Item label="Tipe Kendaraan">
            {vehicle?.vehicle_type?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Pengemudi">
            {vehicle?.driver?.fullName} ({vehicle?.driver?.phone})
          </Descriptions.Item>
        </Descriptions>
        <DetailTableComponent
          tableType="surat_muatan"
          dataSource={dataDetails}
          isPrint={true}
          isDaftarMuatan={true}
          vendorPrice={totalVendorPrice}
        />
        <div className="doc-footer narrow">
          <Row justify="space-between">
            <Col lg={10} offset={14}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>Jumlah</td>
                    <td>:</td>
                    <td className="bt-text-right">{currencyCase(total - expeditionCommission)}</td>
                  </tr>
                  <tr>
                    <td>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>Komisi Ekspedisi</td>
                    <td>:</td>
                    <td className="bt-text-right">{currencyCase(expeditionCommission)}</td>
                  </tr>
                  <tr>
                    <td>Biaya Administrasi</td>
                    <td>:</td>
                    <td className="bt-text-right">{currencyCase(adminFee)}</td>
                  </tr>
                  <tr>
                    <td>Uang Jalan</td>
                    <td>:</td>
                    <td className="bt-text-right">{currencyCase(downPayment)}</td>
                  </tr>
                  <tr>
                    <td>Ongkos Loco</td>
                    <td>:</td>
                    <td className="bt-text-right">{currencyCase(locoFee)}</td>
                  </tr>
                  <tr>
                    <td>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>Sisa DM</td>
                    <td>:</td>
                    <td className="bt-text-right">{currencyCase(unpaidFee)}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>Pengemudi</Col>
            <Col xs={12} className="bt-text-right">
              Hormat Kami,
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 14 }}>
            <Col xs={12}>
              <b>Nama, tanda tangan, dan cap stempel</b>
            </Col>
            <Col xs={12} className="bt-text-right">
              Dibuat oleh: {created_by}
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default ModalPrintComponent;
