import { ICustomer } from '@interfaces/icustomer';

const urlCustomer = 'customers';

export const getCustomer = (code: string) => ({
  method: 'GET',
  url: `${urlCustomer}/${code}`,
});

export const getCustomers = (params: any) => ({
  method: 'GET',
  params,
  url: urlCustomer,
});

export const createCustomer = (body: ICustomer) => ({
  body,
  method: 'POST',
  url: `${urlCustomer}`,
});

export const updateCustomer = (code: string, body: ICustomer) => ({
  body,
  method: 'PUT',
  url: `${urlCustomer}/${code}`,
});

export default { getCustomer, getCustomers, createCustomer, updateCustomer };
