import { moduleType } from '@utils/constants';
import { BaseWrapper } from '@pages/base';
import { PageDaftarMuatan } from '@pages/page-account-payable/page-daftar-muatan';
import { PageDaftarMuatanDetail } from '@pages/page-account-payable/page-daftar-muatan/libraries/detail';
import { PageDaftarMuatanForm } from '@pages/page-account-payable/page-daftar-muatan/libraries/form';
import { PageInvoice } from '@pages/page-account-receivable/page-invoice';
import { PageInvoiceDetail } from '@pages/page-account-receivable/page-invoice/libraries/detail';
import { PageInvoiceForm } from '@pages/page-account-receivable/page-invoice/libraries/form';
import { PagePurchaseOrder } from '@pages/page-account-receivable/page-purchase-order';
import { PagePurchaseOrderDetail } from '@pages/page-account-receivable/page-purchase-order/libraries/detail';
import { PagePurchaseOrderForm } from '@pages/page-account-receivable/page-purchase-order/libraries/form';
import { PageSuratMuatan } from '@pages/page-account-receivable/page-surat-muatan';
import { PageSuratMuatanDetail } from '@pages/page-account-receivable/page-surat-muatan/libraries/detail';
import { PageSuratMuatanForm } from '@pages/page-account-receivable/page-surat-muatan/libraries/form';
import { PageTandaTerima } from '@pages/page-account-receivable/page-tanda-terima';
import { PageTandaTerimaDetail } from '@pages/page-account-receivable/page-tanda-terima/libraries/detail';
import { PageTandaTerimaForm } from '@pages/page-account-receivable/page-tanda-terima/libraries/form';
import { PageEmployee } from '@pages/page-administration/page-employee';
import { PageEmployeeDetail } from '@pages/page-administration/page-employee/libraries/detail';
import { PageEmployeeForm } from '@pages/page-administration/page-employee/libraries/form';
import { PageRole } from '@pages/page-administration/page-role';
import { PageAuth } from '@pages/page-auth';
import { PageResetPassword } from '@pages/page-auth/library/reset-password';
import { PageDashboard } from '@pages/page-dashboard';
import { PageCustomer } from '@pages/page-master/page-customer';
import { PageCustomerDetail } from '@pages/page-master/page-customer/libraries/detail';
import { PageCustomerForm } from '@pages/page-master/page-customer/libraries/form';
import { PageDriver } from '@pages/page-master/page-driver';
import { PageDriverDetail } from '@pages/page-master/page-driver/libraries/detail';
import { PageDriverForm } from '@pages/page-master/page-driver/libraries/form';
import { PageRecipient } from '@pages/page-master/page-recipient';
import { PageRecipientDetail } from '@pages/page-master/page-recipient/libraries/detail';
import { PageRecipientForm } from '@pages/page-master/page-recipient/libraries/form';
import { PageVehicle } from '@pages/page-master/page-vehicle';
import { PageVehicleDetail } from '@pages/page-master/page-vehicle/libraries/detail';
import { PageVehicleForm } from '@pages/page-master/page-vehicle/libraries/form';
import { PageVendor } from '@pages/page-master/page-vendor';
import { PageVendorDetail } from '@pages/page-master/page-vendor/libraries/detail';
import { PageVendorForm } from '@pages/page-master/page-vendor/libraries/form';
import { PageWarehouse } from '@pages/page-master/page-warehouse';
import { PageWarehouseDetail } from '@pages/page-master/page-warehouse/libraries/detail';
import { PageWarehouseForm } from '@pages/page-master/page-warehouse/libraries/form';
import { PagePriceList } from '@pages/page-master/page-price-list';
import { PagePriceListDetail } from '@pages/page-master/page-price-list/libraries/detail';
import { PagePriceListForm } from '@pages/page-master/page-price-list/libraries/form';
import { PageTransitCost } from '@pages/page-account-payable/page-transit-cost';
import { PageTransitCostDetail } from '@pages/page-account-payable/page-transit-cost/libraries/detail';
import { PageTransitCostForm } from '@pages/page-account-payable/page-transit-cost/libraries/form';
import { PageCashFlow } from '@pages/page-account-payable/page-cash-flow';
import { PageCashFlowForm } from '@pages/page-account-payable/page-cash-flow/libraries/form';
import { PageCashFlowDetail } from '@pages/page-account-payable/page-cash-flow/libraries/detail';
import { PageCashFlowRecapDetail } from '@pages/page-account-payable/page-cash-flow/libraries/detail-recap';
import { PageInvoiceReceipt } from '@pages/page-account-receivable/page-invoice-receipt';
import { PageInvoiceReceiptForm } from '@pages/page-account-receivable/page-invoice-receipt/libraries/form';
import { PageInvoiceReceiptDetail } from '@pages/page-account-receivable/page-invoice-receipt/libraries/detail';
import { PageNotFound } from '@pages/page-not-found';
import { PageUnAuthorized } from '@pages/page-unauthorized';
import store from '@store/store';
import history from '@utils/history';
import { getModule, keyAccessToken } from '@utils/request-api';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

export const isLoggedIn = localStorage.getItem('isLoggedIn');
export const accessToken = localStorage.getItem(keyAccessToken);

export const requireAuth = (PrivateComponent: any, props: any = {}, moduleName: string) => {
  const module = getModule(moduleName);
  if (!module) {
    return <Redirect to="/unauthorized" />;
  } else if (isLoggedIn === 'true' && module) {
    return <PrivateComponent {...props} />;
  } else {
    return <Redirect to="/auth" />;
  }
};

export class AppRouter extends Component<any, any> {
  public render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact={true} path="/" component={PageAuth} />
            <Route exact={true} path="/auth" component={PageAuth} />
            <Route exact={true} path="/reset-password/:token" component={PageResetPassword} />
            <Route exact={true} path="/unauthorized" component={PageUnAuthorized} />
            <BaseWrapper>
              <Switch>
                {/* Dashboard */}
                <Route
                  exact={true}
                  path="/dashboard"
                  component={(props: any) => requireAuth(PageDashboard, props, moduleType.ALL)}
                />

                {/* Customer */}
                <Route
                  exact={true}
                  path="/customer"
                  component={(props: any) => requireAuth(PageCustomer, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/customer/detail/:code"
                  component={(props: any) => requireAuth(PageCustomerDetail, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/customer/form"
                  component={(props: any) => requireAuth(PageCustomerForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/customer/form/:code"
                  component={(props: any) => requireAuth(PageCustomerForm, props, moduleType.ALL)}
                />

                {/* Vendor */}
                <Route
                  exact={true}
                  path="/vendor"
                  component={(props: any) => requireAuth(PageVendor, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/vendor/detail/:code"
                  component={(props: any) => requireAuth(PageVendorDetail, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/vendor/form"
                  component={(props: any) => requireAuth(PageVendorForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/vendor/form/:code"
                  component={(props: any) => requireAuth(PageVendorForm, props, moduleType.ALL)}
                />

                {/* Driver */}
                <Route
                  exact={true}
                  path="/driver"
                  component={(props: any) => requireAuth(PageDriver, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/driver/detail/:code"
                  component={(props: any) => requireAuth(PageDriverDetail, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/driver/form"
                  component={(props: any) => requireAuth(PageDriverForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/driver/form/:code"
                  component={(props: any) => requireAuth(PageDriverForm, props, moduleType.ALL)}
                />

                {/* Vehicle */}
                <Route
                  exact={true}
                  path="/vehicle"
                  component={(props: any) => requireAuth(PageVehicle, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/vehicle/detail/:code"
                  component={(props: any) => requireAuth(PageVehicleDetail, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/vehicle/form"
                  component={(props: any) => requireAuth(PageVehicleForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/vehicle/form/:code"
                  component={(props: any) => requireAuth(PageVehicleForm, props, moduleType.ALL)}
                />

                {/* Warehouse */}
                <Route
                  exact={true}
                  path="/warehouse"
                  component={(props: any) => requireAuth(PageWarehouse, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/warehouse/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageWarehouseDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/warehouse/form"
                  component={(props: any) => requireAuth(PageWarehouseForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/warehouse/form/:code"
                  component={(props: any) => requireAuth(PageWarehouseForm, props, moduleType.ALL)}
                />

                {/* Price List */}
                <Route
                  exact={true}
                  path="/price-list"
                  component={(props: any) => requireAuth(PagePriceList, props, moduleType.DIR)}
                />
                <Route
                  exact={true}
                  path="/price-list/detail/:id"
                  component={(props: any) =>
                    requireAuth(PagePriceListDetail, props, moduleType.DIR)
                  }
                />
                <Route
                  exact={true}
                  path="/price-list/form"
                  component={(props: any) => requireAuth(PagePriceListForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/price-list/form/:id"
                  component={(props: any) => requireAuth(PagePriceListForm, props, moduleType.DIR)}
                />

                {/* Recipient */}
                <Route
                  exact={true}
                  path="/recipient"
                  component={(props: any) => requireAuth(PageRecipient, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/recipient/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageRecipientDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/recipient/form"
                  component={(props: any) => requireAuth(PageRecipientForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/recipient/form/:code"
                  component={(props: any) => requireAuth(PageRecipientForm, props, moduleType.ALL)}
                />

                {/* Purchase Order */}
                <Route
                  exact={true}
                  path="/purchase-order"
                  component={(props: any) => requireAuth(PagePurchaseOrder, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/purchase-order/detail/:code"
                  component={(props: any) =>
                    requireAuth(PagePurchaseOrderDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/purchase-order/form"
                  component={(props: any) =>
                    requireAuth(PagePurchaseOrderForm, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/purchase-order/form/:code"
                  component={(props: any) =>
                    requireAuth(PagePurchaseOrderForm, props, moduleType.ALL)
                  }
                />

                {/* Tanda Terima */}
                <Route
                  exact={true}
                  path="/tanda-terima"
                  component={(props: any) => requireAuth(PageTandaTerima, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/tanda-terima/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageTandaTerimaDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/tanda-terima/form"
                  component={(props: any) =>
                    requireAuth(PageTandaTerimaForm, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/tanda-terima/form/:code"
                  component={(props: any) =>
                    requireAuth(PageTandaTerimaForm, props, moduleType.ALL)
                  }
                />

                {/* Surat Muatan */}
                <Route
                  exact={true}
                  path="/surat-muatan"
                  component={(props: any) => requireAuth(PageSuratMuatan, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/surat-muatan/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageSuratMuatanDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/surat-muatan/form"
                  component={(props: any) =>
                    requireAuth(PageSuratMuatanForm, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/surat-muatan/form/:code"
                  component={(props: any) =>
                    requireAuth(PageSuratMuatanForm, props, moduleType.ALL)
                  }
                />

                {/* Invoice */}
                <Route
                  exact={true}
                  path="/invoice"
                  component={(props: any) => requireAuth(PageInvoice, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/invoice/detail/:code"
                  component={(props: any) => requireAuth(PageInvoiceDetail, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/invoice/form"
                  component={(props: any) => requireAuth(PageInvoiceForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/invoice/form/:code"
                  component={(props: any) => requireAuth(PageInvoiceForm, props, moduleType.ALL)}
                />

                {/* Invoice Receipt */}
                <Route
                  exact={true}
                  path="/invoice-receipt"
                  component={(props: any) => requireAuth(PageInvoiceReceipt, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/invoice-receipt/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageInvoiceReceiptDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/invoice-receipt/form"
                  component={(props: any) =>
                    requireAuth(PageInvoiceReceiptForm, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/invoice-receipt/form/:code"
                  component={(props: any) =>
                    requireAuth(PageInvoiceReceiptForm, props, moduleType.ALL)
                  }
                />

                {/* Daftar Muatan */}
                <Route
                  exact={true}
                  path="/daftar-muatan"
                  component={(props: any) => requireAuth(PageDaftarMuatan, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/daftar-muatan/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageDaftarMuatanDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/daftar-muatan/form"
                  component={(props: any) =>
                    requireAuth(PageDaftarMuatanForm, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/daftar-muatan/form/:code"
                  component={(props: any) =>
                    requireAuth(PageDaftarMuatanForm, props, moduleType.ALL)
                  }
                />

                {/* Transit Cost */}
                <Route
                  exact={true}
                  path="/transit-cost"
                  component={(props: any) => requireAuth(PageTransitCost, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/transit-cost/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageTransitCostDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/transit-cost/form"
                  component={(props: any) =>
                    requireAuth(PageTransitCostForm, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/transit-cost/form/:code"
                  component={(props: any) =>
                    requireAuth(PageTransitCostForm, props, moduleType.ALL)
                  }
                />

                {/* Cash Flow */}
                <Route
                  exact={true}
                  path="/cash-flow"
                  component={(props: any) => requireAuth(PageCashFlow, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/cash-flow/detail/:code"
                  component={(props: any) => requireAuth(PageCashFlowDetail, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/cash-flow-recap/detail/:code"
                  component={(props: any) =>
                    requireAuth(PageCashFlowRecapDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/cash-flow-recap/detail"
                  component={(props: any) =>
                    requireAuth(PageCashFlowRecapDetail, props, moduleType.ALL)
                  }
                />
                <Route
                  exact={true}
                  path="/cash-flow/form"
                  component={(props: any) => requireAuth(PageCashFlowForm, props, moduleType.ALL)}
                />
                <Route
                  exact={true}
                  path="/cash-flow/form/:code"
                  component={(props: any) => requireAuth(PageCashFlowForm, props, moduleType.ALL)}
                />

                {/* Employee */}
                <Route
                  exact={true}
                  path="/employee"
                  component={(props: any) => requireAuth(PageEmployee, props, moduleType.DIR)}
                />
                <Route
                  exact={true}
                  path="/employee/detail/:code"
                  component={(props: any) => requireAuth(PageEmployeeDetail, props, moduleType.DIR)}
                />
                <Route
                  exact={true}
                  path="/employee/form/:code"
                  component={(props: any) => requireAuth(PageEmployeeForm, props, moduleType.DIR)}
                />

                {/* Role */}
                <Route
                  exact={true}
                  path="/role"
                  component={(props: any) => requireAuth(PageRole, props, moduleType.DIR)}
                />
                <Route component={PageNotFound} />
              </Switch>
            </BaseWrapper>
          </Switch>
        </Router>
      </Provider>
    );
  }
}
export default AppRouter;
