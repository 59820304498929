import { IInvoice } from '@interfaces/iinvoice';
import { IInvoiceReceipt } from '@interfaces/iinvoice-receipt';
import { IPurchaseOrder } from '@interfaces/ipurchase-order';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { ITandaTerima } from '@interfaces/itanda-terima';
import ActionTypes from '@store/action-types';

export interface IAccountReceivableReducer {
  purchaseOrderList: IPurchaseOrder[];
  purchaseOrder?: IPurchaseOrder;
  tandaTerimaList: ITandaTerima[];
  tandaTerima?: ITandaTerima;
  suratMuatanList: ISuratMuatan[];
  suratMuatan?: ISuratMuatan;
  invoiceList: IInvoice[];
  invoice?: IInvoice;
  invoiceReceiptList: IInvoiceReceipt[];
  invoiceReceipt?: IInvoiceReceipt;
}

const initialState: IAccountReceivableReducer = {
  purchaseOrderList: [],
  tandaTerimaList: [],
  suratMuatanList: [],
  invoiceList: [],
  invoiceReceiptList: [],
};

export const PageAccountReceivableReducer = (
  state: IAccountReceivableReducer = initialState,
  action: any,
) => {
  switch (action.type) {
    case ActionTypes.PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER:
      return { ...state, purchaseOrder: action.purchaseOrder };
    case ActionTypes.PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER_LIST:
      return { ...state, purchaseOrderList: action.purchaseOrderList };
    case ActionTypes.PAGE_TANDA_TERIMA_SET_TANDA_TERIMA:
      return { ...state, tandaTerima: action.tandaTerima };
    case ActionTypes.PAGE_TANDA_TERIMA_SET_TANDA_TERIMA_LIST:
      return { ...state, tandaTerimaList: action.tandaTerimaList };
    case ActionTypes.PAGE_SURAT_MUATAN_SET_SURAT_MUATAN:
      return { ...state, suratMuatan: action.suratMuatan };
    case ActionTypes.PAGE_SURAT_MUATAN_SET_SURAT_MUATAN_LIST:
      return { ...state, suratMuatanList: action.suratMuatanList };
    case ActionTypes.PAGE_INVOICE_SET_INVOICE:
      return { ...state, invoice: action.invoice };
    case ActionTypes.PAGE_INVOICE_SET_INVOICE_LIST:
      return { ...state, invoiceList: action.invoiceList };
    case ActionTypes.PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT:
      return { ...state, invoiceReceipt: action.invoiceReceipt };
    case ActionTypes.PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT_LIST:
      return { ...state, invoiceReceiptList: action.invoiceReceiptList };
    default:
      return state;
  }
};

export default PageAccountReceivableReducer;
