import { IVehicle, IVehicleType } from '@interfaces/ivehicle';

const urlVehicle = 'vehicles';
const urlVehicleType = 'vehicle_types';

export const getVehicle = (code: string) => ({
  method: 'GET',
  url: `${urlVehicle}/${code}`,
});

export const getVehicleTypes = (params: any) => ({
  method: 'GET',
  params,
  url: urlVehicleType,
});

export const getVehicles = (params: any) => ({
  method: 'GET',
  params,
  url: urlVehicle,
});

export const createVehicleType = (body: IVehicleType) => ({
  body,
  method: 'POST',
  url: `${urlVehicleType}`,
});

export const createVehicle = (body: IVehicle) => ({
  body,
  method: 'POST',
  url: `${urlVehicle}`,
});

export const updateVehicleType = (code: string, body: IVehicleType) => ({
  body,
  method: 'PUT',
  url: `${urlVehicleType}/${code}`,
});

export const updateVehicle = (code: string, body: IVehicle) => ({
  body,
  method: 'PUT',
  url: `${urlVehicle}/${code}`,
});

export default {
  getVehicleTypes,
  getVehicle,
  getVehicles,
  createVehicleType,
  createVehicle,
  updateVehicleType,
  updateVehicle,
};
