import { Icon } from 'antd';
import React, { Component } from 'react';

interface IProps {
  emptyIcon?: string;
  emptyText?: any;
}

export class EmptyStateComponent extends Component<IProps, any> {
  public render() {
    const { emptyIcon, emptyText } = this.props;
    return (
      <div className="bt-empty__state">
        <Icon type={emptyIcon || 'frown'} />
        <h5>{emptyText}</h5>
      </div>
    );
  }
}
export default EmptyStateComponent;
