import { IDriver } from '@interfaces/idriver';

const urlDriver = 'drivers';

export const getDriver = (code: string) => ({
  method: 'GET',
  url: `${urlDriver}/${code}`,
});

export const getDrivers = (params: any) => ({
  method: 'GET',
  params,
  url: urlDriver,
});

export const createDriver = (body: IDriver) => ({
  body,
  method: 'POST',
  url: `${urlDriver}`,
});

export const updateDriver = (code: string, body: IDriver) => ({
  body,
  method: 'PUT',
  url: `${urlDriver}/${code}`,
});

export default { getDriver, getDrivers, createDriver, updateDriver };
