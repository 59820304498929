import { ITransitCost } from '@interfaces/itransit-cost';
import { IMeta } from '@interfaces/imeta';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';
import { isLoading } from '@utils/loading';
import { DataName, dateFormat } from '@utils/constants';
import { isDirection } from '@utils/get-role';
import { CSVLink } from 'react-csv';
import { costStatusCase, getTransitCostSM } from '@utils/string-case';
import { formatTime } from '@utils/time';

interface IProps {
  fetchTransitCostList: (params: any) => void;
  deleteTransitCost: (code: string) => void;
  transitCostList: ITransitCost[];
  isFetching: string[];
  metaData: IMeta;
}

const excelHeaders = [
  { label: 'No Transit Cost', key: 'code' },
  { label: 'No Daftar Muatan', key: 'dmCode' },
  { label: 'No Surat Muatan', key: 'smCode' },
  { label: 'Tanggal', key: 'date' },
  { label: 'No Polisi', key: 'policeNo' },
  { label: 'Supir', key: 'driver' },
  { label: 'Biaya', key: 'cost' },
  { label: 'Status', key: 'status' },
];

const title = 'Kelola Transit Cost';
const subTitle = 'Daftar Transit Cost';
const direksi = isDirection();
export class PageTransitCostComponent extends Component<IProps, any> {
  public render() {
    const {
      fetchTransitCostList,
      deleteTransitCost,
      transitCostList,
      isFetching,
      metaData,
    } = this.props;
    const loading = isLoading(isFetching, DataName.TRANSIT_COST);

    const excelSource = transitCostList?.map((data) => ({
      code: data.code,
      dmCode: data.dmCode,
      smCode: getTransitCostSM(data),
      date: formatTime(data.date, dateFormat),
      policeNo: data.vehicle?.policeNo,
      driver: data.vehicle?.driver?.fullName,
      cost: data.cost,
      status: costStatusCase(data.status as number),
    }));

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Transit Cost"
          bordered={false}
          extra={[
            <Link key="1" to={`/transit-cost/form`} style={{ marginRight: direksi ? 8 : 0 }}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Transit Cost
              </Button>
            </Link>,
            direksi && (
              <CSVLink
                key="2"
                data={excelSource}
                headers={excelHeaders}
                filename={`${subTitle}.xls`}
                target="_blank"
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Export to Excel
                </Button>
              </CSVLink>
            ),
          ]}
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchTransitCostList}
            deleteData={deleteTransitCost}
            dataSource={transitCostList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageTransitCostComponent;
