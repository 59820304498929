import { editableCellType } from '@utils/constants';
import { IRole } from '@interfaces/irole';
import { Form, Input, Select } from 'antd';
import React, { Component } from 'react';

export const EditableContext = React.createContext({} as any);
const { Option } = Select;
export class EditableCellComponent extends Component<any, any> {
  public getInput = () => {
    const { inputType, addonBefore, addonAfter, title, data } = this.props;
    if (inputType === editableCellType.selectMultiple) {
      return (
        <Select
          showSearch={true}
          mode="multiple"
          placeholder={`Choose ${title}`}
          optionFilterProp="children"
          onChange={this.onChangeSelect}
        >
          {data &&
            data.map((item: IRole) => (
              <Option key={item.name} value={item.id}>
                {item.description}
              </Option>
            ))}
        </Select>
      );
    }

    return <Input addonBefore={addonBefore} addonAfter={addonAfter} />;
  };
  public onChangeSelect = (value: any) => {
    const { feedback } = this.props;
    feedback(value);
  };
  public getValidationRules = () => {
    const { inputType, title, isMandatory } = this.props;
    if (inputType === editableCellType.number) {
      return [
        {
          message: `${title} is required!`,
          required: isMandatory,
        },
        {
          message: `${title} hanya berupa angka!`,
          pattern: /^[0-9]+$/,
        },
      ];
    }

    return [
      {
        message: `${title} is required!`,
        required: isMandatory,
      },
    ];
  };
  public getInitialValue = () => {
    const { record, dataIndex } = this.props;
    const data = record[dataIndex];
    if (data && typeof data === 'object') {
      const id: number[] = [];
      data.map((item: any) => {
        id.push(item.id);
      });
      return id.length > 0 ? id : undefined;
    }

    return data;
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public renderCell = ({ getFieldDecorator }: any) => {
    const {
      editing,
      dataIndex,
      data,
      inputType,
      title,
      record,
      index,
      feedback,
      children,
      addonBefore,
      addonAfter,
      isMandatory,
      rowKey,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              getValueFromEvent: inputType === editableCellType.number && this.onChangeNumber,
              initialValue: this.getInitialValue(),
              rules: this.getValidationRules(),
              validateTrigger: ['onChange', 'onBlur'],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  public render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

export default EditableCellComponent;
