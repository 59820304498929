import { EmptyState } from '@components/molecules/empty-state';
import {
  dateFormat,
  defaultParams,
  pageSizeOptions,
  showSizeChanger,
  dateTimeFormat,
  shortInvoicePaymentStatus,
} from '@utils/constants';
import { IMeta } from '@interfaces/imeta';
import { IInvoiceReceipt, IInvoiceReceiptDetail } from '@interfaces/iinvoice-receipt';
import { currencyCase } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { formatTime } from '@utils/time';
import { Button, Divider, Popconfirm, Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  deleteData: (code: string) => void;
  dataSource?: any;
  meta: IMeta;
}

interface IState {
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParams: defaultParams,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params });
    fetchDataSource(params);
  };
  public handleDeleteData = async (code: string) => {
    const { fetchDataSource, deleteData } = this.props;
    const { tableParams } = this.state;
    await deleteData(code);
    fetchDataSource(tableParams);
  };
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: 'No TT Invoice',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        render: (text: string, record: IInvoiceReceipt) => (
          <Link to={`/invoice-receipt/detail/${record.code}`}>{text}</Link>
        ),
        fixed: 'left',
        width: 180,
      },
      {
        title: 'No Invoice',
        dataIndex: 'details',
        render: (text: string, record: IInvoiceReceipt) => {
          if (text) {
            const element: any[] = [];
            record?.details?.map((item: IInvoiceReceiptDetail, index: number) => {
              const { code: invoiceCode, statusPayment: status } = item?.invoice || {};
              const displayText =
                status !== undefined
                  ? `${invoiceCode} (${shortInvoicePaymentStatus[status]})`
                  : invoiceCode;
              element.push(
                <Fragment key={invoiceCode}>
                  {index !== 0 && <br />}&bull;{' '}
                  <Link to={`/invoice/detail/${invoiceCode}`}>{displayText}</Link>
                </Fragment>,
              );
            });
            return element;
          } else {
            return '-';
          }
        },
        width: 250,
      },
      {
        title: 'Tanggal',
        dataIndex: 'date',
        ...filter('date'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 150,
      },
      {
        title: 'Total',
        dataIndex: 'total',
        ...filter('total'),
        sorter: true,
        render: (text: any) => currencyCase(text) || '-',
      },
      {
        title: 'Penerima Invoice',
        dataIndex: 'invoiceReceiver',
        ...filter('invoiceReceiver'),
        sorter: true,
      },
      {
        title: 'Kolektor',
        dataIndex: 'collector',
        ...filter('collector'),
        sorter: true,
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: IInvoiceReceipt) => (
          <Fragment>
            <Link to={`/invoice-receipt/form/${record.code}`}>Ubah</Link>
            <Divider type="vertical" />
            <Popconfirm
              title="Apakah Anda yakin untuk menghapus tanda terima invoice ini?"
              onConfirm={() => record.code && this.handleDeleteData(record.code)}
              okText="Iya"
              cancelText="Tidak"
            >
              <Button type="link" size="small">
                Hapus
              </Button>
            </Popconfirm>
          </Fragment>
        ),
        width: 80,
      },
    ];

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          rowKey="id"
          scroll={{ x: 1600 }}
        />
      </div>
    );
  }
}

export default TableComponent;
