import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { deleteSuratMuatan, fetchSuratMuatanList } from './action';
import PageSuratMuatanComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  suratMuatanList: state.PageAccountReceivableReducer.suratMuatanList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchSuratMuatanList: (params: any) => dispatch(fetchSuratMuatanList(params)),
  deleteSuratMuatan: (code: string) => dispatch(deleteSuratMuatan(code)),
});

export const PageSuratMuatanContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageSuratMuatanComponent);

export default PageSuratMuatanContainer;
