import { IWarehouse } from '@interfaces/iwarehouse';

const urlWarehouse = 'warehouses';

export const getWarehouse = (code: string) => ({
  method: 'GET',
  url: `${urlWarehouse}/${code}`,
});

export const getWarehouses = (params: any) => ({
  method: 'GET',
  params,
  url: urlWarehouse,
});

export const createWarehouse = (body: IWarehouse) => ({
  body,
  method: 'POST',
  url: `${urlWarehouse}`,
});

export const updateWarehouse = (code: string, body: IWarehouse) => ({
  body,
  method: 'PUT',
  url: `${urlWarehouse}/${code}`,
});

export default { getWarehouse, getWarehouses, createWarehouse, updateWarehouse };
