import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchDriverList } from './action';
import PageDriverComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  driverList: state.PageMasterDataReducer.driverList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchDriverList: (params: any) => dispatch(fetchDriverList(params)),
});

export const PageDriverContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDriverComponent);

export default PageDriverContainer;
