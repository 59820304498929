import { ITransitCost } from '@interfaces/itransit-cost';
import {
  createTransitCost,
  updateTransitCost,
  fetchTransitCostList,
} from '@pages/page-account-payable/page-transit-cost/action';
import { fetchDaftarMuatanList } from '@pages/page-account-payable/page-daftar-muatan/action';
import { fetchVehicleList } from '@pages/page-master/page-vehicle/action';
import { connect } from 'react-redux';
import PageTransitCostFormComponent from './component';
import { IStateReducer } from '@reducers/state';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  transitCostList: state.PageAccountPayableReducer.transitCostList,
  daftarMuatanList: state.PageAccountPayableReducer.daftarMuatanList,
  vehicleList: state.PageMasterDataReducer.vehicleList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVehicleList: (params: any) => dispatch(fetchVehicleList(params)),
  fetchDaftarMuatanList: (params: any) => dispatch(fetchDaftarMuatanList(params)),
  fetchTransitCostList: () => dispatch(fetchTransitCostList()),
  createTransitCost: (body: ITransitCost) => dispatch(createTransitCost(body)),
  updateTransitCost: (code: string, body: ITransitCost) => dispatch(updateTransitCost(code, body)),
});

export const PageTransitCostFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageTransitCostFormComponent);

export default PageTransitCostFormContainer;
