import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import ActionTypes from '@store/action-types';
import { ITransitCost } from '@interfaces/itransit-cost';
import { ICashFlow, ICashFlowRecap, ICoa } from '@interfaces/icash-flow';

export interface IAccountPayableReducer {
  daftarMuatanList: IDaftarMuatan[];
  daftarMuatan?: IDaftarMuatan;
  transitCostList: ITransitCost[];
  transitCost?: ITransitCost;
  cashFlowList: ICashFlow[];
  cashFlow?: ICashFlow;
  cashFlowRecapList: ICashFlowRecap[];
  cashFlowRecap?: ICashFlowRecap;
  cashFlowRecapDetail: ICashFlow[];
  coaList: ICoa[];
}

const initialState: IAccountPayableReducer = {
  daftarMuatanList: [],
  transitCostList: [],
  cashFlowList: [],
  cashFlowRecapList: [],
  cashFlowRecapDetail: [],
  coaList: [],
};

export const PageAccountPayableReducer = (
  state: IAccountPayableReducer = initialState,
  action: any,
) => {
  switch (action.type) {
    case ActionTypes.PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN:
      return { ...state, daftarMuatan: action.daftarMuatan };
    case ActionTypes.PAGE_DAFTAR_MUATAN_SET_DAFTAR_MUATAN_LIST:
      return { ...state, daftarMuatanList: action.daftarMuatanList };
    case ActionTypes.PAGE_TRANSIT_COST_SET_TRANSIT_COST:
      return { ...state, transitCost: action.transitCost };
    case ActionTypes.PAGE_TRANSIT_COST_SET_TRANSIT_COST_LIST:
      return { ...state, transitCostList: action.transitCostList };
    case ActionTypes.PAGE_CASH_FLOW_SET_CASH_FLOW:
      return { ...state, cashFlow: action.cashFlow };
    case ActionTypes.PAGE_CASH_FLOW_SET_CASH_FLOW_LIST:
      return { ...state, cashFlowList: action.cashFlowList };
    case ActionTypes.PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP:
      return { ...state, cashFlowRecap: action.cashFlowRecap };
    case ActionTypes.PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_DETAIL:
      return { ...state, cashFlowRecapDetail: action.cashFlowRecapDetail };
    case ActionTypes.PAGE_CASH_FLOW_RECAP_SET_CASH_FLOW_RECAP_LIST:
      return { ...state, cashFlowRecapList: action.cashFlowRecapList };
    case ActionTypes.PAGE_COA_SET_COA_LIST:
      return { ...state, coaList: action.coaList };
    default:
      return state;
  }
};

export default PageAccountPayableReducer;
