import { IVendor } from '@interfaces/ivendor';
import { DataName } from '@utils/constants';
import { goBack } from '@utils/history';
import { isLoading } from '@utils/loading';
import { companyTypeCase } from '@utils/string-case';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

interface IProps {
  fetchVendor: (code: string) => void;
  vendor?: IVendor;
  isFetching: string[];
  match: any;
}

const title = 'Detail Vendor';
export class PageVendorDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchVendor } = this.props;
    fetchVendor(match.params.code);
  };
  public render() {
    const { vendor, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.VENDOR);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/vendor/form${vendor && `/${vendor.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Vendor
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {vendor && (
            <Descriptions
              title={`Vendor: ${vendor.code}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Nama Vendor" span={3}>
                {companyTypeCase(vendor.type, vendor.name)}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat" span={3}>
                {vendor.address || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Kota">{vendor.city || '-'}</Descriptions.Item>
              <Descriptions.Item label="Telepon">{vendor.phone || '-'}</Descriptions.Item>
              <Descriptions.Item label="NPWP">
                {vendor.NPWP && vendor.imageNPWP ? (
                  <a target="_blank" href={vendor.imageNPWP}>
                    {vendor.NPWP}
                  </a>
                ) : (
                  vendor.NPWP || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="MOU">
                {vendor.fileMOU ? (
                  <a target="_blank" href={vendor.fileMOU}>
                    File MOU
                  </a>
                ) : (
                  '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Nama PIC">{vendor.picName || '-'}</Descriptions.Item>
              <Descriptions.Item label="Telepon PIC">{vendor.picPhone || '-'}</Descriptions.Item>
              <Descriptions.Item label="PIC Operasional">{vendor.picOperational}</Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(vendor.remarks) ? 'warning' : ''}
                span={3}
              >
                {vendor.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageVendorDetailComponent;
