export enum urlAuth {
  login = 'oauth/token',
  register = 'oauth/register',
  changePassword = 'oauth/changepassword',
  resetPassword = 'password/reset',
}

export const login = (body: any) => ({
  body,
  method: 'POST',
  url: urlAuth.login,
});

export const register = (body: any) => ({
  body,
  method: 'POST',
  url: urlAuth.register,
});

export const changePassword = (body: any) => ({
  body,
  method: 'POST',
  url: urlAuth.changePassword,
});

export const resetPasswordRequest = (body: any) => ({
  body,
  method: 'POST',
  url: `${urlAuth.resetPassword}/request`,
});

export const resetPasswordFind = (token: string) => ({
  method: 'GET',
  url: `${urlAuth.resetPassword}/find/${token}`,
});

export const resetPassword = (body: any) => ({
  body,
  method: 'POST',
  url: urlAuth.resetPassword,
});

export default {
  login,
  register,
  changePassword,
  resetPasswordRequest,
  resetPasswordFind,
  resetPassword,
};
