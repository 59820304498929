import DefaultReducer from '@reducers/default-reducer';
import PageAccountPayableReducer from '@reducers/page-account-payable-reducer';
import PageAccountReceivableReducer from '@reducers/page-account-receivable-reducer';
import PageAdministrationReducer from '@reducers/page-administration-reducer';
import PageAuthReducer from '@reducers/page-auth-reducer';
import PageDashboardReducer from '@reducers/page-dashboard-reducer';
import PageMasterDataReducer from '@reducers/page-master-data-reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

const reducers = combineReducers({
  DefaultReducer,
  PageDashboardReducer,
  PageAdministrationReducer,
  PageAuthReducer,
  PageMasterDataReducer,
  PageAccountReceivableReducer,
  PageAccountPayableReducer,
});
export const store = createStore(reducers, applyMiddleware(thunk));

export default store;
