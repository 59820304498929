import { IRole } from '@interfaces/irole';
import { PageChangePassword } from '@pages/page-auth/library/change-password';
import getRoles from '@utils/get-role';
import { Avatar, Button, Col, Dropdown, Modal, Row, Typography } from 'antd';
import React, { Component } from 'react';
const { Text } = Typography;

interface IProps {
  userName?: string;
  userEmail?: string;
  userRole?: IRole[];
  userLogout?: any;
}
interface IState {
  isShowModal: boolean;
}

export class UserMenuComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isShowModal: false,
    };
  }
  public actionChangePassword = () => {
    this.setState({ isShowModal: true });
  };
  public handleCloseModal = () => {
    this.setState({ isShowModal: false });
  };
  public render() {
    const { userName, userEmail, userRole, userLogout } = this.props;
    const { isShowModal } = this.state;
    const menu = (
      <div className="bt-user-menu__dropdown">
        <div className="bt-user-menu__info">
          <div className="bt-user-menu__image">
            <Avatar size={64} icon="user" />
          </div>
          <div className="bt-user-menu__text">
            <h3>{userName}</h3>
            <p>{userEmail}</p>
            <Text type="warning">{getRoles(userRole)}</Text>
          </div>
        </div>
        <div className="bt-user-menu__action">
          <Row justify="space-between">
            <Col lg={12}>
              <Button type="default" onClick={this.actionChangePassword}>
                Ganti Password
              </Button>
            </Col>
            <Col lg={12} className="bt-text-right">
              <Button type="default" onClick={userLogout}>
                Logout
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
    return (
      <div className="bt-user-menu">
        <div className="bt-user-welcome">Selamat datang, {userName}</div>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Avatar size={40} icon="user" style={{ margin: 10 }} />
        </Dropdown>
        <Modal title="Ganti Password" visible={isShowModal} closable={false} footer={null}>
          <PageChangePassword close={this.handleCloseModal} />
        </Modal>
      </div>
    );
  }
}
export default UserMenuComponent;
