import { DocumentHeader } from '@components/atoms/document-header';
import { dateFormat, invoicePaymentStatus } from '@utils/constants';
import { IInvoice } from '@interfaces/iinvoice';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { companyTypeCase, currencyCase, numWord } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Col, Descriptions, Modal, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import DetailTableComponent from './detail-table';

interface IProps {
  title: string;
  show: boolean;
  hide: () => void;
  data: IInvoice;
  dataDetails: ISuratMuatan[];
}

export class ModalPrintComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const { title, show, hide, data, dataDetails } = this.props;
    const {
      code,
      invoiceDate,
      total,
      downPayment,
      customer,
      billedTo,
      statusPayment,
      created_by,
    } = data;
    const unpaid =
      statusPayment === invoicePaymentStatus['Uang Muka'] ? total - downPayment : total;

    return (
      <Modal
        title={title}
        visible={show}
        width={1200}
        okText="Cetak"
        cancelText="Batal"
        onCancel={hide}
        onOk={() => window.print()}
      >
        <DocumentHeader title={title} />
        <Descriptions column={3} size="small" className="doc-header-info hide medium">
          <Descriptions.Item label="Sudah Terima Dari" span={2}>
            {billedTo ? billedTo : companyTypeCase(customer?.type, customer?.name)}
          </Descriptions.Item>
          <Descriptions.Item label="No" className="bt-text-right">
            {code}
          </Descriptions.Item>
          <Descriptions.Item label="Banyaknya Uang" span={2}>
            {currencyCase(unpaid)}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal" className="bt-text-right">
            {formatTime(moment(invoiceDate), dateFormat)}
          </Descriptions.Item>
          <Descriptions.Item label="Terbilang Rupiah" span={2}>
            {numWord(unpaid.toString())} Rupiah
          </Descriptions.Item>
          <Descriptions.Item>{` `}</Descriptions.Item>
        </Descriptions>
        <DetailTableComponent invoice={data} dataSource={dataDetails} />
        <br />
        <div className="doc-footer">
          <Row justify="space-between">
            <Col lg={12} className="hide">
              <small>*Surat Muatan terlampir</small>
            </Col>
            <Col lg={8} className="hide bt-text-right">
              Total:
            </Col>
            <Col lg={4} className="bt-text-right">
              {currencyCase(unpaid)}
            </Col>
          </Row>
          <br />
          <Row justify="space-between">
            <Col lg={4} className="hide">
              Dicetak oleh:
            </Col>
            <Col lg={8}>{created_by}</Col>
            <Col lg={12} className="hide bt-text-right">
              Hormat Kami,
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 18 }}>
            <Col lg={12} className="hide">
              Pembayaran di transfer ke:
              <br />
              <b>BANK BCA 08380816</b>
              <br />
              a.n. PT Minofa Multi Sukses
              <br />
              Cabang Pangeran Jayakarta
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default ModalPrintComponent;
