import { DataName, dateFormat } from '@utils/constants';
import { ICashFlow } from '@interfaces/icash-flow';
import { goBack } from '@utils/history';
import { currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchCashFlow: (code: string) => void;
  cashFlow?: ICashFlow;
  isFetching: string[];
  match: any;
}

const title = 'Detail Cash Flow';
export class PageCashFlowDetailComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public componentDidMount = () => {
    const { match, fetchCashFlow } = this.props;
    fetchCashFlow(match.params.code);
  };
  public render() {
    const { cashFlow, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.CASH_FLOW);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        {cashFlow && (
          <PageHeader
            title={title}
            onBack={goBack}
            extra={[
              <Link key="2" to={`/cash-flow/form${cashFlow && `/${cashFlow.code}`}`}>
                <Button type="primary">
                  <Icon type="edit" />
                  Ubah Cash Flow
                </Button>
              </Link>,
            ]}
          />
        )}

        <Card bordered={false} loading={loading}>
          {cashFlow && (
            <Fragment>
              <Descriptions
                title={`Cash Flow: ${cashFlow.code}`}
                layout="vertical"
                bordered={true}
                size="small"
              >
                <Descriptions.Item label="Kode Voucher">
                  {cashFlow.voucherNo || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Tanggal Pembayaran">
                  {cashFlow.paymentDate
                    ? formatTime(moment(cashFlow.paymentDate), dateFormat)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Tanggal Posting">
                  {cashFlow.postingDate
                    ? formatTime(moment(cashFlow.postingDate), dateFormat)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Cash on Account">
                  {`${cashFlow.coa?.code} | ${cashFlow.coa?.accountName}`}
                </Descriptions.Item>
                <Descriptions.Item label="Nomor Daftar Muatan">
                  {cashFlow.daftar_muatan?.code || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Subtotal">
                  {currencyCase(cashFlow.subtotal)}
                </Descriptions.Item>
                <Descriptions.Item label="Misc Charge">
                  {currencyCase(cashFlow.miscCharge)}
                </Descriptions.Item>
                <Descriptions.Item label="Potongan PPH">
                  {currencyCase(cashFlow.pph)}
                </Descriptions.Item>
                <Descriptions.Item label="Total">{currencyCase(cashFlow.total)}</Descriptions.Item>
                <Descriptions.Item label="Metode Pembayaran">
                  {cashFlow.paymentMethod}
                </Descriptions.Item>
                <Descriptions.Item label="Penerima Pembayaran">
                  {cashFlow.paymentReceiver}
                </Descriptions.Item>
                <Descriptions.Item label="Deskripsi">{cashFlow.description}</Descriptions.Item>
                <Descriptions.Item label="Kassa">{cashFlow.created_by}</Descriptions.Item>
                <Descriptions.Item
                  label="Remarks"
                  className={!isEmpty(cashFlow.remarks) ? 'warning' : ''}
                >
                  {cashFlow.remarks || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Diubah Oleh">
                  {cashFlow.created_by || '-'}
                </Descriptions.Item>
              </Descriptions>
            </Fragment>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageCashFlowDetailComponent;
