import { connect } from 'react-redux';
import { deleteDaftarMuatan, fetchDaftarMuatanList, updateDaftarMuatan } from './action';
import PageDaftarMuatanComponent from './component';
import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import { IStateReducer } from '@reducers/state';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  daftarMuatanList: state.PageAccountPayableReducer.daftarMuatanList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchDaftarMuatanList: (params: any) => dispatch(fetchDaftarMuatanList(params)),
  updateDaftarMuatan: (code: string, body: IDaftarMuatan) =>
    dispatch(updateDaftarMuatan(code, body)),
  deleteDaftarMuatan: (code: string) => dispatch(deleteDaftarMuatan(code)),
});

export const PageDaftarMuatanContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDaftarMuatanComponent);

export default PageDaftarMuatanContainer;
