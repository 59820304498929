import { DataName, dateFormat, paymentType } from '@utils/constants';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { goBack } from '@utils/history';
import { companyTypeCase, currencyCase, orderStatusCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Divider, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import DetailTableComponent from './libraries/detail-table';
import ModalPrintComponent from './libraries/modal';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchSuratMuatan: (code: string) => void;
  suratMuatan?: ISuratMuatan;
  isFetching: string[];
  match: any;
}

interface IState {
  showModal: boolean;
}

const title = 'Detail Surat Muatan';
export class PageSuratMuatanDetailComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  public componentDidMount = () => {
    const { match, fetchSuratMuatan } = this.props;
    fetchSuratMuatan(match.params.code);
  };
  public handleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };
  public render() {
    const { suratMuatan, isFetching } = this.props;
    const { showModal } = this.state;
    const loading = isLoading(isFetching, DataName.SURAT_MUATAN);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Button key="1" type="primary" onClick={this.handleModal}>
              <Icon type="printer" />
              Cetak Surat Muatan
            </Button>,
            <Link key="2" to={`/surat-muatan/form${suratMuatan && `/${suratMuatan.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Surat Muatan
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {suratMuatan && (
            <Fragment>
              <Descriptions
                title={`Surat Muatan: ${suratMuatan.code}`}
                layout="vertical"
                bordered={true}
                size="small"
              >
                <Descriptions.Item label="Tanggal Tanda Terina">
                  {suratMuatan.orderDate
                    ? formatTime(moment(suratMuatan.orderDate), dateFormat)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Tanggal Surat Muatan">
                  {suratMuatan.smDate ? formatTime(moment(suratMuatan.smDate), dateFormat) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Tipe Pembayaran">
                  {suratMuatan.paymentType && paymentType[suratMuatan.paymentType]}
                </Descriptions.Item>
                <Descriptions.Item label="Tipe Pengiriman">
                  {suratMuatan.deliveryType}
                </Descriptions.Item>
                <Descriptions.Item label="Customer">
                  {companyTypeCase(suratMuatan.customer?.type, suratMuatan.customer?.name)}
                </Descriptions.Item>
                <Descriptions.Item label="Penerima">
                  {suratMuatan.recipient?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Gudang">
                  {suratMuatan.warehouse
                    ? `${suratMuatan.warehouse.code} - ${suratMuatan.warehouse.address}, ${suratMuatan.warehouse.city}`
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Nomor Polisi Kendaraan">
                  {suratMuatan.vehicle?.policeNo}
                </Descriptions.Item>
                <Descriptions.Item label="Supir">
                  {suratMuatan.vehicle?.driver?.fullName}
                </Descriptions.Item>
                <Descriptions.Item label="Telepon Supir">
                  {suratMuatan.vehicle?.driver?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor">
                  {companyTypeCase(
                    suratMuatan.vehicle?.driver?.vendor?.type,
                    suratMuatan.vehicle?.driver?.vendor?.name,
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Total Harga">
                  {suratMuatan.invoicePrice ? currencyCase(suratMuatan.invoicePrice) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Komisi">
                  {suratMuatan.commission ? currencyCase(suratMuatan.commission) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {orderStatusCase(suratMuatan.status)}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Remarks"
                  className={!isEmpty(suratMuatan.remarks) ? 'warning' : ''}
                >
                  {suratMuatan.remarks || '-'}
                </Descriptions.Item>
              </Descriptions>
              <Divider orientation="left">Detail Muatan</Divider>
              <DetailTableComponent
                master={suratMuatan}
                dataSource={suratMuatan.details}
                showPrice={true}
                isPrint={false}
              />
            </Fragment>
          )}
        </Card>

        {suratMuatan && (
          <ModalPrintComponent
            title="Surat Muatan"
            show={showModal}
            hide={this.handleModal}
            data={suratMuatan}
          />
        )}
      </Fragment>
    );
  }
}
export default PageSuratMuatanDetailComponent;
