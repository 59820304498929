import {
  DataName,
  dateFormat,
  defaultUploadDocument,
  deliveryType,
  emptyParams,
  fileType,
  FormType,
  orderStatus,
  volumeType,
} from '@utils/constants';
import { ICustomer } from '@interfaces/icustomer';
import { IEmployee } from '@interfaces/iemployee';
import { IPurchaseOrder } from '@interfaces/ipurchase-order';
import { enumToArray } from '@utils/enum-to-array';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Icon, Input, PageHeader, Row, Select, Upload } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { getBase64, getUploadedFile } from '@utils/upload';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchEmployeeList: (params: any) => void;
  employeeList: IEmployee[];
  fetchCustomerList: (params: any) => void;
  customerList: ICustomer[];
  fetchPurchaseOrder: (code: string) => void;
  purchaseOrder?: IPurchaseOrder;
  createPurchaseOrder: (body: IPurchaseOrder) => void;
  updatePurchaseOrder: (code: string, body: IPurchaseOrder) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  deliveryTypeList: string[];
  volumeTypeList: string[];
  selectedTonaseType: string;
  formType: FormType;
  filePO: string;
}

export class PagePurchaseOrderFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    const deliveryTypeList = enumToArray(deliveryType);
    const volumeTypeList = enumToArray(volumeType);
    this.state = {
      deliveryTypeList,
      volumeTypeList,
      selectedTonaseType: volumeTypeList[0],
      formType: code ? 'edit' : 'add',
      filePO: '',
    };
  }
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.purchaseOrder !== this.props.purchaseOrder) {
      const { purchaseOrder } = this.props;
      if (purchaseOrder) {
        this.setState({
          filePO: purchaseOrder?.filePO || '',
        });
      }
    }
  };
  public componentDidMount = () => {
    const { match, fetchPurchaseOrder, fetchCustomerList, fetchEmployeeList } = this.props;
    const { code } = match.params;
    fetchPurchaseOrder(code);
    fetchCustomerList(emptyParams);
    fetchEmployeeList(emptyParams);
  };
  public handleSubmit = () => {
    const { form, purchaseOrder, createPurchaseOrder, updatePurchaseOrder } = this.props;
    const { formType, filePO, selectedTonaseType } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataPurchaseOrder: IPurchaseOrder = {
          ...purchaseOrder,
          customersId: values.customer,
          orderDate: formatTime(values.orderDate, 'YYYY-MM-DD'),
          orderType: values.jenisMuatan,
          tonase: Number(values.tonase),
          tonaseType: selectedTonaseType,
          vendorPrice: Number(values.vendorPrice),
          vendorDownPayment: Number(values.vendorDownPayment),
          vehicleType: values.vehicleType,
          policeNo: values.policeNo,
          driverName: values.driverName,
          driverPhone: values.driverPhone,
          deliveryType: values.deliveryType,
          pickupAddress: values.pickupAddress,
          destinationAddress: values.destinationAddress,
          salesMarketing: values.salesMarketing,
          remarks: values.remarks,
          filePO,
          updated_by: currentUser,
        };

        if (formType === 'edit' && purchaseOrder?.code) {
          await updatePurchaseOrder(purchaseOrder.code, dataPurchaseOrder);
        } else {
          await createPurchaseOrder({
            ...dataPurchaseOrder,
            status: orderStatus.PurchaseOrder,
            created_by: currentUser,
          });
        }
        history.push('/purchase-order');
      }
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const isValidDocument: boolean = defaultUploadDocument.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG, PNG, or PDF file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public handleUpload = async (option: any) => {
    try {
      getBase64(option.file, (fileBase64) => {
        this.setState({ filePO: fileBase64 });
        option.onSuccess(fileBase64, option.file);
      });
    } catch (e) {
      option.onError(e, option.file);
    }
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { isFetching, form, purchaseOrder, customerList, employeeList } = this.props;
    const { deliveryTypeList, volumeTypeList, selectedTonaseType, formType } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const title = formType && formType === 'edit' ? 'Ubah Purchase Order' : 'Buat Purchase Order';
    const loading = isLoading(isFetching, DataName.PURCHASE_ORDER);

    const selectTonaseType = (
      <Select
        defaultValue={
          purchaseOrder && purchaseOrder.tonaseType ? purchaseOrder.tonaseType : selectedTonaseType
        }
        placeholder="Select Tonase Type"
        style={{ width: 100 }}
        onChange={(value: any) => this.setState({ selectedTonaseType: value })}
      >
        {volumeTypeList &&
          volumeTypeList.map((item: string) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
      </Select>
    );

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Customer">
                  {getFieldDecorator('customer', {
                    initialValue: purchaseOrder?.customer?.id,
                    rules: [{ message: 'Customer harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Customer"
                      optionFilterProp="children"
                    >
                      {customerList &&
                        customerList.map((item: ICustomer) => (
                          <Option key={item.code} value={item.id}>
                            {companyTypeCase(item.type, item.name)}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Jenis Muatan">
                  {getFieldDecorator('jenisMuatan', {
                    initialValue: purchaseOrder?.orderType ?? '',
                    rules: [
                      { min: 3, message: 'Jenis Muatan minimal 3 karakter!' },
                      { max: 50, message: 'Jenis Muatan maksimal 50 karakter!' },
                      { required: true, message: 'Jenis Muatan harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Jenis Muatan" />)}
                </Form.Item>
                <Form.Item label="Harga Vendor">
                  {getFieldDecorator('vendorPrice', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: purchaseOrder?.vendorPrice ?? '',
                    rules: [{ message: 'Harga Vendor hanya berupa angka!', pattern: /^[0-9]+$/ }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Harga Vendor" />)}
                </Form.Item>
                <Form.Item label="Nomor Polisi">
                  {getFieldDecorator('policeNo', {
                    initialValue: purchaseOrder?.policeNo ?? '',
                  })(<Input placeholder="Nomor Polisi" />)}
                </Form.Item>
                <Form.Item label="Telepon Supir">
                  {getFieldDecorator('driverPhone', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: purchaseOrder?.driverPhone ?? '',
                    rules: [
                      { min: 5, message: 'Telepon Supir minimal 5 digit!' },
                      { max: 15, message: 'Telepon Supir maksimal 15 digit!' },
                      { message: 'Telepon Supir hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon Supir" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Jenis Pengiriman">
                  {getFieldDecorator('deliveryType', {
                    initialValue: purchaseOrder?.deliveryType ?? '',
                    rules: [{ required: true, message: 'Jenis Pengiriman harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Jenis Pengiriman" showSearch={true}>
                      {deliveryTypeList &&
                        deliveryTypeList.map((item: string) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Alamat Pickup">
                  {getFieldDecorator('pickupAddress', {
                    initialValue: purchaseOrder?.pickupAddress ?? '',
                    rules: [
                      { required: true, message: 'Alamat Pickup harus diisi!' },
                      { min: 3, message: 'Alamat Pickup minimal 3 karakter!' },
                      { max: 250, message: 'Alamat Pickup maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Alamat Pickup, Nama Jalan, Kode Pos, lain-lain." />)}
                </Form.Item>
                <Form.Item label="Dokumen Purchase Order">
                  {getFieldDecorator('filePO', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.PO, purchaseOrder?.filePO),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png, .pdf"
                      customRequest={(option) => this.handleUpload(option)}
                      onRemove={() => {
                        this.setState({ filePO: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('filePO') !== undefined &&
                          getFieldValue('filePO').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Tanggal Muat">
                  {getFieldDecorator('orderDate', {
                    initialValue: purchaseOrder?.orderDate
                      ? moment(purchaseOrder?.orderDate)
                      : moment(),
                    rules: [{ required: true, message: 'Tanggal Muat harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Tonase">
                  {getFieldDecorator('tonase', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: purchaseOrder?.tonase ?? '',
                    rules: [
                      { message: 'Tonase hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Tonase harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Tonase" addonAfter={selectTonaseType} />)}
                </Form.Item>
                <Form.Item label="Down Payment Vendor">
                  {getFieldDecorator('vendorDownPayment', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: purchaseOrder?.vendorDownPayment ?? '',
                    rules: [
                      { message: 'Down Payment Vendor hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Down Payment Vendor" />)}
                </Form.Item>
                <Form.Item label="Jenis Mobil">
                  {getFieldDecorator('vehicleType', {
                    initialValue: purchaseOrder?.vehicleType ?? '',
                  })(<Input placeholder="Jenis Mobil" />)}
                </Form.Item>
                <Form.Item label="Nama Supir">
                  {getFieldDecorator('driverName', {
                    initialValue: purchaseOrder?.driverName ?? '',
                  })(<Input placeholder="Nama Supir" />)}
                </Form.Item>
                <Form.Item label="Sales Marketing">
                  {getFieldDecorator('salesMarketing', {
                    initialValue: purchaseOrder?.salesMarketing ?? '',
                    rules: [{ message: 'Sales marketing harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Sales Marketing"
                      optionFilterProp="children"
                    >
                      {employeeList &&
                        employeeList.map((item: IEmployee) => (
                          <Option key={item.code} value={item.fullName}>
                            {item.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Alamat Tujuan">
                  {getFieldDecorator('destinationAddress', {
                    initialValue: purchaseOrder?.destinationAddress ?? '',
                    rules: [
                      { required: true, message: 'Alamat Tujuan harus diisi!' },
                      { min: 3, message: 'Alamat Tujuan minimal 3 karakter!' },
                      { max: 250, message: 'Alamat Tujuan maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Alamat Tujuan, Nama Jalan, Kode Pos, lain-lain." />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: purchaseOrder?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PagePurchaseOrderFormComponent);
