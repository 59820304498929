import { IInvoice } from '@interfaces/iinvoice';
import { IMeta } from '@interfaces/imeta';
import { DataName, dateFormat, invoicePaymentStatus, TabType } from '@utils/constants';
import { isDirection } from '@utils/get-role';
import { isLoading } from '@utils/loading';
import { getInvoiceTT, getPaymentDate, getPaymentMethod } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Icon, PageHeader, Tabs } from 'antd';
import React, { Component, Fragment } from 'react';
import { CSVLink } from 'react-csv';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';
const { TabPane } = Tabs;

interface IProps {
  fetchInvoiceList: (params: any) => void;
  deleteInvoice: (code: string) => void;
  invoiceList: IInvoice[];
  isFetching: string[];
  metaData: IMeta;
}

interface IState {
  activeTab: string;
}

const excelHeaders = [
  { label: 'No Invoice', key: 'code' },
  { label: 'Customer', key: 'customer' },
  { label: 'No Tanda Terima', key: 'ttCode' },
  { label: 'Tanggal', key: 'date' },
  { label: 'Total', key: 'total' },
  { label: 'Status Pembayaran', key: 'paymentStatus' },
  { label: 'Metode Pembayaran', key: 'paymentMethod' },
  { label: 'Tanggal Pembayaran', key: 'paymentDate' },
];

const title = 'Kelola Invoice';
const subTitle = 'Daftar Invoice';
const direksi = isDirection();
export class PageInvoiceComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activeTab: TabType.All,
    };
  }

  public render() {
    const { fetchInvoiceList, deleteInvoice, invoiceList, isFetching, metaData } = this.props;
    const { activeTab } = this.state;
    const loading = isLoading(isFetching, DataName.INVOICE);
    const tableProps = {
      isFetching: loading,
      deleteData: deleteInvoice,
      dataSource: invoiceList,
      meta: metaData,
      fetchDataSource: fetchInvoiceList,
    };

    const excelSource = invoiceList?.map((data) => ({
      code: data.code,
      customer: data.customer?.name,
      ttCode: getInvoiceTT(data),
      date: formatTime(data.invoiceDate, dateFormat),
      total: data.total,
      paymentStatus: invoicePaymentStatus[data.statusPayment],
      paymentMethod: getPaymentMethod(data),
      paymentDate: getPaymentDate(data),
    }));

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Daftar Invoice"
          bordered={false}
          extra={[
            <Link key="1" to={`/invoice/form`} style={{ marginRight: direksi ? 8 : 0 }}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Invoice
              </Button>
            </Link>,
            direksi && (
              <CSVLink
                key="2"
                data={excelSource}
                headers={excelHeaders}
                filename={`${subTitle}.xls`}
                target="_blank"
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Export to Excel
                </Button>
              </CSVLink>
            ),
          ]}
        >
          <Tabs
            defaultActiveKey={TabType.All}
            animated={false}
            onChange={(activeKey) => this.setState({ activeTab: activeKey })}
          >
            <TabPane tab="Semua Invoice" key={TabType.All}>
              <TableComponent
                {...tableProps}
                type={TabType.All}
                isActive={activeTab === TabType.All}
              />
            </TabPane>
            <TabPane tab="Invoice Pending" key={TabType.Pending}>
              <TableComponent
                {...tableProps}
                type={TabType.Pending}
                isActive={activeTab === TabType.Pending}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Fragment>
    );
  }
}
export default PageInvoiceComponent;
