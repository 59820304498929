import PriceList from '@adaptors-api/price-list-api';
import { IPriceList } from '@interfaces/iprice-list';
import { IMeta } from '@interfaces/imeta';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.PRICELIST));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.PRICELIST));
};

export const setPriceList = (priceList?: IPriceList) => ({
  priceList,
  type: ActionTypes.PAGE_PRICE_LIST_SET_PRICE_LIST,
});

export const setPriceLists = (priceLists: IPriceList[]) => ({
  priceLists,
  type: ActionTypes.PAGE_PRICE_LIST_SET_PRICE_LISTS,
});

export const fetchPriceList = (id: number) => (dispatch: any) => {
  setLoading(dispatch);

  if (!id) {
    dispatch(setPriceList(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(PriceList.getPriceList(id))
    .then((response: any) => {
      const { data } = response.data;
      const priceList: IPriceList = data;
      dispatch(setPriceList(priceList));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchPriceLists = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(PriceList.getPriceLists(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const priceLists: IPriceList[] = data;
      const metaData: IMeta = meta;
      dispatch(setPriceLists(priceLists));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createPriceList = (body: IPriceList) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(PriceList.createPriceList(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Daftar Harga ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Daftar Harga Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updatePriceList = (id: number, body: IPriceList) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(PriceList.updatePriceList(id, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah PriceList ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Daftar Harga Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
