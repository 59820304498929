import { IVendor } from '@interfaces/ivendor';

const urlVendor = 'vendors';

export const getVendor = (code: string) => ({
  method: 'GET',
  url: `${urlVendor}/${code}`,
});

export const getVendors = (params: any) => ({
  method: 'GET',
  params,
  url: urlVendor,
});

export const createVendor = (body: IVendor) => ({
  body,
  method: 'POST',
  url: `${urlVendor}`,
});

export const updateVendor = (code: string, body: IVendor) => ({
  body,
  method: 'PUT',
  url: `${urlVendor}/${code}`,
});

export default { getVendor, getVendors, createVendor, updateVendor };
