import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { deleteTransitCost, fetchTransitCostList } from './action';
import PageTransitCostComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  transitCostList: state.PageAccountPayableReducer.transitCostList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchTransitCostList: (params: any) => dispatch(fetchTransitCostList(params)),
  deleteTransitCost: (code: string) => dispatch(deleteTransitCost(code)),
});

export const PageTransitCostContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageTransitCostComponent);

export default PageTransitCostContainer;
