import { EmptyState } from '@components/molecules/empty-state';
import {
  dateFormat,
  defaultParams,
  pageSizeOptions,
  roleType,
  showSizeChanger,
  dateTimeFormat,
} from '@utils/constants';
import { ITransitCost } from '@interfaces/itransit-cost';
import { IDriver } from '@interfaces/idriver';
import { IMeta } from '@interfaces/imeta';
import { IVehicle } from '@interfaces/ivehicle';
import { getModule } from '@utils/request-api';
import { currencyCase, costStatusCase, getTransitCostSM } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { formatTime } from '@utils/time';
import { Button, Divider, Popconfirm, Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  deleteData: (code: string) => void;
  dataSource?: any;
  meta: IMeta;
}

interface IState {
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParams: defaultParams,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params });
    fetchDataSource(params);
  };
  public handleDeleteData = (code?: string) => {
    const { fetchDataSource, deleteData } = this.props;
    const { tableParams } = this.state;
    if (code) {
      deleteData(code);
    }
    fetchDataSource(tableParams);
  };
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: 'No Transit Cost',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        render: (text: string, record: ITransitCost) => (
          <Link to={`/transit-cost/detail/${record.code}`}>{text}</Link>
        ),
        fixed: 'left',
        width: 180,
      },
      {
        title: 'No Daftar Muatan',
        dataIndex: 'dmCode',
        ...filter('dmCode'),
        render: (text: string) => <Link to={`/daftar-muatan/detail/${text}`}>{text}</Link>,
        width: 200,
      },
      {
        title: 'No Surat Muatan',
        dataIndex: 'suratMuatanIds',
        render: (text: string, record: ITransitCost) => getTransitCostSM(record, true),
        ellipsis: true,
        width: 200,
      },
      {
        title: 'Tanggal',
        dataIndex: 'date',
        ...filter('date'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
      },
      {
        title: 'No Polisi',
        dataIndex: 'vehicle.policeNo',
        ...filter('policeNo'),
        render: (text: string, record: IVehicle) => (
          <Link to={`/vehicle/detail/${record.code}`}>{text}</Link>
        ),
      },
      {
        title: 'Nama Supir',
        dataIndex: 'vehicle.driver',
        ...filter('driverName'),
        render: (record: IDriver) => (
          <Link to={`/driver/detail/${record.code}`}>{record.fullName}</Link>
        ),
      },
      {
        title: 'Biaya',
        dataIndex: 'cost',
        sorter: true,
        render: (text: any) => currencyCase(text),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        ...filter('status'),
        sorter: true,
        render: (text: number) => costStatusCase(text),
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: ITransitCost) => (
          <Fragment>
            <Link to={`/transit-cost/form/${record.code}`}>Ubah</Link>
            {getModule(roleType.DIR) && (
              <Fragment>
                <Divider type="vertical" />
                <Popconfirm
                  title="Apakah Anda yakin untuk menghapus transit cost ini?"
                  onConfirm={() => this.handleDeleteData(record?.code)}
                  okText="Iya"
                  cancelText="Tidak"
                >
                  <Button type="link">Hapus</Button>
                </Popconfirm>
              </Fragment>
            )}
          </Fragment>
        ),
        width: 200,
      },
    ];

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          scroll={{ x: 2300 }}
          rowKey="id"
        />
      </div>
    );
  }
}

export default TableComponent;
