import moment, { Moment } from 'moment';
import 'moment/locale/id';

export const formatTime = (time: Moment | string, format: string = 'YYYY-MM-DD hh:mm:ss') => {
  if (typeof time === 'string') {
    return moment(time).locale('id').add(7, 'hours').format(format);
  }
  return time.format(format);
};

export const currentTime = () => {
  return formatTime(moment().locale('id').add(7, 'hours'));
};
