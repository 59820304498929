import { EmptyState } from '@components/molecules/empty-state';
import {
  defaultParams,
  pageSizeOptions,
  showSizeChanger,
  dateTimeFormat,
  dateFormat,
} from '@utils/constants';
import { IPriceList } from '@interfaces/iprice-list';
import { IMeta } from '@interfaces/imeta';
import { currencyCase } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { formatTime } from '@utils/time';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  dataSource?: any;
  meta: IMeta;
}

interface IState {
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParams: { ...defaultParams, orderBy: 'id' },
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params });
    fetchDataSource(params);
  };
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: 'Tanggal',
        dataIndex: 'date',
        ...filter('date'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 160,
      },
      {
        title: 'Asal',
        dataIndex: 'deliveryFrom',
        ...filter('deliveryFrom'),
        width: 200,
      },
      {
        title: 'Tujuan',
        dataIndex: 'deliveryTo',
        ...filter('deliveryTo'),
        width: 200,
      },
      {
        title: 'Pengiriman',
        dataIndex: 'deliveryType',
        ...filter('deliveryType'),
        sorter: true,
        width: 150,
      },
      {
        title: 'Armada',
        dataIndex: 'vehicle_type.name',
        ...filter('vehicleType'),
        sorter: true,
        width: 150,
      },
      {
        title: 'Harga',
        dataIndex: 'price',
        ...filter('price'),
        sorter: true,
        render: (text: any) => currencyCase(text) || '-',
        width: 200,
      },
      {
        title: 'Biaya',
        dataIndex: 'cost',
        ...filter('cost'),
        sorter: true,
        render: (text: any) => currencyCase(text) || '-',
        width: 200,
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        ...filter('vendor'),
        sorter: true,
        width: 250,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        ...filter('customer'),
        sorter: true,
        width: 250,
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: IPriceList) => (
          <Fragment>
            <Link to={`/price-list/form/${record.id}`}>Ubah</Link>
            {/* {/* <Divider type="vertical" />
            <Button type="link">Hapus</Button> */}
          </Fragment>
        ),
        width: 170,
      },
    ];

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          scroll={{ x: 2300 }}
          rowKey="id"
        />
      </div>
    );
  }
}

export default TableComponent;
