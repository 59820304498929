import { IDashboard, IDashboardData } from '@interfaces/idashboard';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { Card, Col, PageHeader, Row, Skeleton, Badge } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

interface IProps {
  fetchDashboard: () => void;
  dashboard?: IDashboard;
  isFetching: string[];
}

const title = 'Dashboard';
export class PageDashboardComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { fetchDashboard } = this.props;
    fetchDashboard();
  };

  public getData = (cardTitle: string, data: IDashboardData, pageLink: string) => {
    const { isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.DASHBOARD);

    if (data && !loading) {
      const { total, active, inactive } = data;

      return (
        <Link to={pageLink}>
          <Card size="small" title={cardTitle} extra={`Total: ${total}`}>
            Sudah Diproses:{' '}
            <Badge
              count={active}
              style={{ backgroundColor: '#52c41a' }}
              showZero={true}
              overflowCount={9999999999}
            />
            <br />
            Belum Diproses: <Badge count={inactive} showZero={true} overflowCount={9999999999} />
          </Card>
        </Link>
      );
    } else {
      return <Skeleton active={true} />;
    }
  };

  public render() {
    const { dashboard } = this.props;

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card title="Operasional">
          <Row gutter={[16, 16]}>
            <Col lg={6} md={12}>
              {dashboard && this.getData('Purchase Order', dashboard.PO, '/purchase-order')}
            </Col>
            <Col lg={6} md={12}>
              {dashboard && this.getData('Tanda Terima', dashboard.TT, '/tanda-terima')}
            </Col>
            <Col lg={6} md={12}>
              {dashboard && this.getData('Surat Muatan', dashboard.SM, '/surat-muatan')}
            </Col>
            <Col lg={6} md={12}>
              {dashboard && this.getData('Daftar Muatan', dashboard.DM, '/daftar-muatan')}
            </Col>
          </Row>
        </Card>

        <br />

        {/* <Card title="Keuangan">
          <Row gutter={[16, 16]}>
            <Col lg={8} md={12}>
              {dashboard && this.getData('Margin', dashboard.PO)}
            </Col>
            <Col lg={8} md={12}>
              {dashboard && this.getData('Profit', dashboard.TT)}
            </Col>
            <Col lg={8} md={12}>
              {dashboard && this.getData('Fixed Cost', dashboard.SM)}
            </Col>
          </Row>
        </Card> */}
      </Fragment>
    );
  }
}
export default PageDashboardComponent;
