import { fetchRecipient } from '@pages/page-master/page-recipient/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageRecipientDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  recipient: state.PageMasterDataReducer.recipient,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchRecipient: (code: string) => dispatch(fetchRecipient(code)),
});

export const PageRecipientDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageRecipientDetailComponent);

export default PageRecipientDetailContainer;
