import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { deleteRecipient, fetchRecipientList } from './action';
import PageRecipientComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  recipientList: state.PageMasterDataReducer.recipientList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchRecipientList: (params: any) => dispatch(fetchRecipientList(params)),
  deleteRecipient: (code: string) => dispatch(deleteRecipient(code)),
});

export const PageRecipientContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageRecipientComponent);

export default PageRecipientContainer;
