import { fetchInvoiceReceipt } from '@pages/page-account-receivable/page-invoice-receipt/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageInvoiceReceiptDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  invoiceReceipt: state.PageAccountReceivableReducer.invoiceReceipt,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchInvoiceReceipt: (code: string) => dispatch(fetchInvoiceReceipt(code)),
});

export const PageInvoiceReceiptDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageInvoiceReceiptDetailComponent);

export default PageInvoiceReceiptDetailContainer;
