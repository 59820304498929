import { DataName, dateFormat } from '@utils/constants';
import { IRole } from '@interfaces/irole';
import { IUser } from '@interfaces/iuser';
import getRoles from '@utils/get-role';
import { goBack } from '@utils/history';
import { currencyCase, titleCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchUser: (code: string) => void;
  user?: IUser;
  isFetching: string[];
  match: any;
}

const title = 'Detail Karyawan';
export class PageEmployeeDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchUser } = this.props;
    fetchUser(match.params.code);
  };
  public render() {
    const { user, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.EMPLOYEE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/employee/form${user && `/${user.employee?.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Karyawan
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {user && (
            <Descriptions
              title={`Karyawan: ${user.employee?.fullName}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Nama Karyawan">{user.employee?.fullName}</Descriptions.Item>
              <Descriptions.Item label="Kode Karyawan">{user.employee?.code}</Descriptions.Item>
              <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
              <Descriptions.Item label="Role">{getRoles(user.roles as IRole[])}</Descriptions.Item>
              <Descriptions.Item label="Alamat" span={2}>
                {user.employee?.address || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Telepon">{user.employee?.phone || '-'}</Descriptions.Item>
              <Descriptions.Item label="Status Pernikahan">
                {user.employee?.maritalStatus ? titleCase(user.employee?.maritalStatus) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Jumlah Tanggungan">
                {user.employee?.numOfDependence || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="KTP">
                {user.employee?.imageKTP ? (
                  <a target="_blank" href={user.employee?.imageKTP}>
                    {user.employee?.KTP}
                  </a>
                ) : (
                  user.employee?.KTP || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Kartu Keluarga">
                {user.employee?.imageKK ? (
                  <a target="_blank" href={user.employee?.imageKK}>
                    {user.employee?.KK}
                  </a>
                ) : (
                  user.employee?.KK || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="NPWP">
                {user.employee?.imageNPWP ? (
                  <a target="_blank" href={user.employee?.imageNPWP}>
                    {user.employee?.NPWP}
                  </a>
                ) : (
                  user.employee?.NPWP || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Kontrak">
                {user.employee?.contractStart && user.employee?.contractEnd
                  ? `${formatTime(
                      moment(user.employee?.contractStart),
                      dateFormat,
                    )} to ${formatTime(moment(user.employee?.contractEnd), dateFormat)}`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Hutang Pada Perusahaan">
                {currencyCase(user.employee?.debt) || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Pembayaran Cicilan Hutang">
                {user.employee?.tenor ? `${user.employee?.tenor} kali` : '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageEmployeeDetailComponent;
