import { DataName, dateFormat, invoicePaymentStatus } from '@utils/constants';
import { IInvoice, IInvoiceDetail } from '@interfaces/iinvoice';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { goBack } from '@utils/history';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Divider, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import DetailTableComponent from './libraries/detail-table';
import ModalPrintComponent from './libraries/modal';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchInvoice: (code: string) => void;
  invoice?: IInvoice;
  isFetching: string[];
  match: any;
}

interface IState {
  showModal: boolean;
  suratMuatans: ISuratMuatan[];
}

const title = 'Detail Invoice';
export class PageInvoiceDetailComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      suratMuatans: [],
    };
  }
  public componentDidMount = () => {
    const { match, fetchInvoice } = this.props;
    fetchInvoice(match.params.code);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps !== this.props) {
      const { invoice } = this.props;
      if (invoice) {
        const suratMuatans: ISuratMuatan[] = [];
        invoice.details?.map((item: IInvoiceDetail) => {
          suratMuatans.push(item.surat_muatan);
        });

        this.setState({ suratMuatans });
      }
    }
  };
  public handleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };
  public render() {
    const { invoice, isFetching } = this.props;
    const { showModal, suratMuatans } = this.state;
    const loading = isLoading(isFetching, DataName.INVOICE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        {invoice && (
          <PageHeader
            title={title}
            onBack={goBack}
            extra={[
              <Button key="1" type="primary" onClick={this.handleModal}>
                <Icon type="printer" />
                Cetak Invoice
              </Button>,
              <Link key="2" to={`/invoice/form${invoice && `/${invoice.code}`}`}>
                <Button type="primary">
                  <Icon type="edit" />
                  Ubah Invoice
                </Button>
              </Link>,
            ]}
          />
        )}
        <Card bordered={false} loading={loading}>
          {invoice && (
            <Fragment>
              <Descriptions
                title={`Invoice: ${invoice.code}`}
                layout="vertical"
                bordered={true}
                size="small"
              >
                <Descriptions.Item label="Customer">
                  {invoice.customer
                    ? companyTypeCase(invoice.customer.type, invoice.customer.name)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Ditagih Kepada">
                  {invoice.billedTo
                    ? invoice.billedTo
                    : companyTypeCase(invoice.customer?.type, invoice.customer?.name)}
                </Descriptions.Item>
                <Descriptions.Item label="Tanggal">
                  {invoice.invoiceDate ? formatTime(moment(invoice.invoiceDate), dateFormat) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="PPH">
                  {invoice.pph ? currencyCase(invoice.pph) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Jumlah">
                  {invoice.total ? currencyCase(invoice.total) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Uang Muka">
                  {invoice.downPayment ? currencyCase(invoice.downPayment) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Kurang Bayar">
                  {invoice.total && invoice.statusPayment !== invoicePaymentStatus.Lunas
                    ? currencyCase(invoice.total - invoice.downPayment)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Status Pembayaran">
                  <b>{invoice.statusPayment ? invoicePaymentStatus[invoice.statusPayment] : '-'}</b>
                </Descriptions.Item>
                <Descriptions.Item
                  label="Remarks"
                  className={!isEmpty(invoice.remarks) ? 'warning' : ''}
                >
                  {invoice.remarks || '-'}
                </Descriptions.Item>
              </Descriptions>
              <Divider orientation="left">Detail Muatan</Divider>
              <DetailTableComponent dataSource={suratMuatans} />
            </Fragment>
          )}
        </Card>

        {invoice && (
          <ModalPrintComponent
            title="Invoice"
            show={showModal}
            hide={this.handleModal}
            data={invoice}
            dataDetails={suratMuatans}
          />
        )}
      </Fragment>
    );
  }
}
export default PageInvoiceDetailComponent;
