import { EmptyState } from '@components/molecules/empty-state';
import { EditableCell } from '@components/organisms/editable-cell';
import { EditableContext } from '@components/organisms/editable-cell/component';
import {
  dateTimeFormat,
  defaultParams,
  editableCellType,
  pageSizeOptions,
  roleType,
  showSizeChanger,
  workingType,
} from '@utils/constants';
import { IMeta } from '@interfaces/imeta';
import { IRole } from '@interfaces/irole';
import { IUser } from '@interfaces/iuser';
import getRoles from '@utils/get-role';
import { filter, tableChangeParams } from '@utils/table';
import { currentTime, formatTime } from '@utils/time';
import { Button, Divider, Form, Popconfirm, Switch, Table } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

interface IProps extends FormComponentProps {
  isFetching: boolean;
  fetchDataSource: (params?: any) => void;
  dataSource?: IUser[];
  updateUser: (code: string, body: IUser) => void;
  deleteUser: (code: string) => void;
  fetchRoleList: (params?: any) => void;
  roleList: IRole[];
  meta?: IMeta;
}

interface IState {
  editingKey: string;
  selectedRoles: number[];
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editingKey: '',
      selectedRoles: [],
      tableParams: defaultParams,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params, editingKey: '' });
    fetchDataSource(params);
  };
  public handleUpdateUser = async (code: string, user: IUser) => {
    const { fetchDataSource, updateUser } = this.props;
    await updateUser(code, user);
    await fetchDataSource(defaultParams);
    this.handleCancel();
  };
  public handleApprove = (user: IUser) => {
    const body: IUser = { ...user, approved_at: currentTime() };
    delete body.roles;
    if (user?.employee?.code) {
      this.handleUpdateUser(user.employee.code, body);
    }
  };
  public handleChangeRole = (rolesId: number[]) => {
    this.setState({ selectedRoles: rolesId });
  };
  public handleCancel = () => {
    this.setState({ editingKey: '', selectedRoles: [] });
  };
  public handleSave(user: IUser) {
    const { selectedRoles } = this.state;

    user.roles = selectedRoles;
    if (user?.employee?.code) {
      this.handleUpdateUser(user.employee.code, user);
    }
  }
  public handleEdit(user: IUser) {
    const { fetchRoleList } = this.props;
    const rolesId: number[] = [];
    (user.roles as IRole[]).map((item: IRole) => {
      rolesId.push(item.id);
    });
    this.setState({ editingKey: user?.id?.toString() || '', selectedRoles: rolesId });

    fetchRoleList();
  }

  public handleDelete = async (code: string) => {
    const { fetchDataSource, deleteUser } = this.props;
    const { tableParams } = this.state;
    await deleteUser(code);
    fetchDataSource(tableParams);
  };

  public isEditing = (id: number) => id.toString() === this.state.editingKey;

  public onWorking = (isChecked: boolean, user: IUser) => {
    const isDirection = this.isDirection(user.roles as IRole[]);
    const isWorking = isChecked
      ? isDirection
        ? workingType.AlwaysWork
        : workingType.Work
      : workingType.NotWork;

    const body: IUser = { ...user, isWorking };
    delete body.roles;
    if (user?.employee?.code) {
      this.handleUpdateUser(user.employee.code, body);
    }
  };

  public isDirection = (roles: IRole[] | undefined) => {
    if (roles) {
      const exist = roles.find((item: IRole) => item.name === roleType.DIR);
      return exist ? true : false;
    }
    return false;
  };

  public render() {
    const { dataSource, isFetching, roleList, meta } = this.props;
    const { editingKey } = this.state;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns: any = [
      {
        title: 'Bekerja',
        dataIndex: 'isWorking',
        sorter: true,
        render: (isWorking: number, record: IUser) => (
          <Switch
            defaultChecked={isWorking !== null && isWorking !== workingType.NotWork}
            onChange={(isChecked) => this.onWorking(isChecked, record)}
            disabled={Boolean(record?.deleted_at)}
            size="small"
          />
        ),
        fixed: 'left',
        width: 110,
      },
      {
        title: 'Nama Karyawan',
        dataIndex: 'fullName',
        ...filter('fullname'),
        sorter: true,
        render: (text: any, record: IUser) =>
          editingKey !== '' || Boolean(record?.deleted_at) ? (
            record?.employee?.fullName
          ) : (
            <Link to={`/employee/detail/${record?.employee?.code}`}>
              {record?.employee?.fullName}
            </Link>
          ),
        fixed: 'left',
        width: 180,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...filter('email'),
        sorter: true,
        width: 250,
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        render: (text: any, record: IUser) => getRoles(record?.roles as IRole[], true),
        editable: true,
        inputType: editableCellType.selectMultiple,
        data: roleList,
        mandatory: false,
        feedback: this.handleChangeRole,
      },
      {
        title: 'Tanggal Approval',
        dataIndex: 'approved_at',
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        sorter: true,
      },
      {
        title: 'Tanggal Habis Kontrak',
        dataIndex: 'contractEnd',
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        sorter: true,
      },
      {
        title: 'Action',
        render: (text: any, record: IUser) => {
          const editing = record?.id && this.isEditing(record.id);

          return editing ? (
            <Fragment>
              <EditableContext.Consumer>
                {() => (
                  <Popconfirm
                    title="Apakah Anda yakin untuk menyimpan perubahan ini?"
                    onConfirm={() => this.handleSave(record)}
                    okText="Iya"
                    cancelText="Tidak"
                  >
                    <Button type="link" size="small">
                      Simpan
                    </Button>
                  </Popconfirm>
                )}
              </EditableContext.Consumer>
              <Divider type="vertical" />
              <Button type="link" size="small" onClick={this.handleCancel}>
                Batal
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                type="link"
                onClick={() => this.handleEdit(record)}
                disabled={editingKey !== '' || Boolean(record?.deleted_at)}
              >
                Ubah
              </Button>
              <Divider type="vertical" />
              {!record.approved_at && (
                <Fragment>
                  <Button
                    type="link"
                    onClick={() => this.handleApprove(record)}
                    disabled={editingKey !== '' || Boolean(record?.deleted_at)}
                  >
                    Approve
                  </Button>
                  <Divider type="vertical" />
                </Fragment>
              )}
              <Popconfirm
                title="Apakah Anda yakin untuk menghapus karyawan ini?"
                onConfirm={() =>
                  record?.employee?.code && this.handleDelete(record?.employee?.code)
                }
                okText="Iya"
                cancelText="Tidak"
                disabled={editingKey !== '' || Boolean(record?.deleted_at)}
              >
                <Button
                  type="link"
                  size="small"
                  disabled={editingKey !== '' || Boolean(record?.deleted_at)}
                >
                  Hapus
                </Button>
              </Popconfirm>
            </Fragment>
          );
        },
        width: 150,
      },
    ];

    const renderedColumns = columns.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          rowKey: 'id',
          inputType: col.inputType,
          data: col.data,
          dataIndex: col.dataIndex,
          title: col.title,
          isMandatory: col.mandatory,
          editing: this.isEditing(record.id),
          feedback: col.feedback,
        }),
      };
    });

    return (
      <Fragment>
        <div className="bt-table">
          <EditableContext.Provider value={this.props.form}>
            <Table
              size="small"
              onChange={this.onChangeTable}
              components={components}
              columns={renderedColumns}
              dataSource={dataSource}
              bordered={true}
              pagination={
                meta && {
                  current: meta.current_page,
                  pageSize: meta.per_page,
                  pageSizeOptions,
                  showSizeChanger,
                  total: meta.total,
                }
              }
              locale={{
                emptyText: tableEmptyState,
              }}
              loading={isFetching}
              scroll={{ x: 1200 }}
              rowKey="id"
            />
          </EditableContext.Provider>
        </div>
      </Fragment>
    );
  }
}

export default Form.create<IProps>()(TableComponent);
