import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchWarehouseList } from './action';
import PageWarehouseComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  warehouseList: state.PageMasterDataReducer.warehouseList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchWarehouseList: (params: any) => dispatch(fetchWarehouseList(params)),
});

export const PageWarehouseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageWarehouseComponent);

export default PageWarehouseContainer;
