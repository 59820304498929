import { IRole } from '@interfaces/irole';
import { getProfile } from '@utils/request-api';
import React, { Fragment } from 'react';

export const getRoles = (roleList: IRole[] | undefined, isRender: boolean = false) => {
  const roles: string[] = [];
  if (roleList) {
    roleList.map((item: IRole) => {
      roles.push(item.description);
    });
  }
  if (!roles || roles.length <= 0) {
    return '-';
  }

  if (isRender) {
    const element: any[] = [];
    roles?.forEach((role, index: number) => {
      element.push(
        <Fragment key={index}>
          {index !== 0 && <br />}
          {role}
        </Fragment>,
      );
    });
    return element;
  } else {
    return roles.join(', ');
  }
};

export const isDirection = () => {
  const profile = getProfile();
  const canDelete = profile?.roles?.find((role: IRole) => role.name === 'direksi');
  return Boolean(canDelete);
};

export default getRoles;
