import { IRole } from '@interfaces/irole';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchRoleList, updateRole } from './action';
import PageRoleComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  roleList: state.PageAdministrationReducer.roleList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchRoleList: (params?: any) => dispatch(fetchRoleList(params)),
  updateRole: (roleId: number, body: IRole) => dispatch(updateRole(roleId, body)),
});

export const PageRoleContainer = connect(mapStateToProps, mapDispatchToProps)(PageRoleComponent);

export default PageRoleContainer;
