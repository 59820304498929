import { fetchTransitCost } from '@pages/page-account-payable/page-transit-cost/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageTransitCostDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  transitCost: state.PageAccountPayableReducer.transitCost,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchTransitCost: (code: string) => dispatch(fetchTransitCost(code)),
});

export const PageTransitCostDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageTransitCostDetailComponent);

export default PageTransitCostDetailContainer;
