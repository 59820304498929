import InvoiceReceipt from '@adaptors-api/invoice-receipt-api';
import { IMeta } from '@interfaces/imeta';
import { IInvoiceReceipt } from '@interfaces/iinvoice-receipt';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import RequestAPI from '@utils/request-api';
import { EnumLoading, DataName } from '@utils/constants';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.INVOICE_RECEIPT));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.INVOICE_RECEIPT));
};

export const setInvoiceReceipt = (invoiceReceipt?: IInvoiceReceipt) => ({
  invoiceReceipt,
  type: ActionTypes.PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT,
});

export const setInvoiceReceiptList = (invoiceReceiptList: IInvoiceReceipt[]) => ({
  invoiceReceiptList,
  type: ActionTypes.PAGE_INVOICE_RECEIPT_SET_INVOICE_RECEIPT_LIST,
});

export const fetchInvoiceReceipt = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setInvoiceReceipt(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(InvoiceReceipt.getInvoiceReceipt(code))
    .then((response: any) => {
      const { data } = response.data;
      const invoiceReceipt: IInvoiceReceipt = data;
      dispatch(setInvoiceReceipt(invoiceReceipt));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchInvoiceReceiptList = (params?: any, isSetMeta: boolean = true) => (
  dispatch: any,
) => {
  setLoading(dispatch);

  return RequestAPI(InvoiceReceipt.getInvoiceReceipts(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const invoiceReceiptList: IInvoiceReceipt[] = data;
      const metaData: IMeta = meta;
      dispatch(setInvoiceReceiptList(invoiceReceiptList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createInvoiceReceipt = (body: IInvoiceReceipt) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(InvoiceReceipt.createInvoiceReceipt(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Tanda Terima Invoice ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Tanda Terima Invoice Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateInvoiceReceipt = (code: string, body: IInvoiceReceipt) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(InvoiceReceipt.updateInvoiceReceipt(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Tanda Terima Invoice ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Tanda Terima Invoice Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteInvoiceReceipt = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(InvoiceReceipt.deleteInvoiceReceipt(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Tanda Terima Invoice ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Tanda Terima Invoice Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
