import { IMeta } from '@interfaces/imeta';
import { IRole } from '@interfaces/irole';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import TableComponent from './libraries/table';

interface IProps {
  fetchRoleList: (params?: any) => void;
  roleList: IRole[];
  isFetching: string[];
  updateRole: (roleId: number, body: IRole) => void;
  metaData: IMeta;
}

const title = 'Kelola Roles';
export class PageRoleComponent extends Component<IProps, any> {
  public render() {
    const { fetchRoleList, roleList, isFetching, updateRole, metaData } = this.props;
    const loading = isLoading(isFetching, DataName.ROLE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <TableComponent
          isFetching={loading}
          fetchDataSource={fetchRoleList}
          dataSource={roleList}
          updateRole={updateRole}
          meta={metaData}
        />
      </Fragment>
    );
  }
}
export default PageRoleComponent;
