import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import { IMeta } from '@interfaces/imeta';
import { DataName, dateFormat } from '@utils/constants';
import { isDirection } from '@utils/get-role';
import { isLoading } from '@utils/loading';
import { formatTime } from '@utils/time';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import { CSVLink } from 'react-csv';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchDaftarMuatanList: (params: any) => void;
  updateDaftarMuatan: (code: string, body: IDaftarMuatan) => void;
  deleteDaftarMuatan: (code: string) => void;
  daftarMuatanList: IDaftarMuatan[];
  isFetching: string[];
  metaData: IMeta;
}

const excelHeaders = [
  { label: 'Lunas', key: 'paid' },
  { label: 'No Daftar Muatan', key: 'code' },
  { label: 'Tanggal', key: 'date' },
  { label: 'Vendor', key: 'vendor' },
  { label: 'No Polisi', key: 'policeNo' },
  { label: 'Tipe Kendaraan', key: 'vehicleType' },
  { label: 'Nama Supir', key: 'driver' },
  { label: 'Harga Vendor', key: 'vendorPrice' },
  { label: 'Uang Jalan', key: 'downpayment' },
];

const title = 'Kelola Daftar Muatan';
const subTitle = 'Daftar Muatan';
const direksi = isDirection();
export class PageDaftarMuatanComponent extends Component<IProps, any> {
  public render() {
    const {
      fetchDaftarMuatanList,
      updateDaftarMuatan,
      deleteDaftarMuatan,
      daftarMuatanList,
      isFetching,
      metaData,
    } = this.props;
    const loading = isLoading(isFetching, DataName.DAFTAR_MUATAN);

    const excelSource = daftarMuatanList?.map((data) => ({
      paid: data.isPaidVendor ? 'Ya' : 'Belum',
      code: data.code,
      date: formatTime(data.dmDate, dateFormat),
      vendor: data.vehicle?.driver?.vendor?.name || '-',
      policeNo: data.vehicle?.policeNo || '-',
      vehicleType: data.vehicle?.vehicle_type?.name,
      driver: data.vehicle?.driver?.fullName || '-',
      vendorPrice: data.totalVendorPrice,
      downpayment: data.downPayment,
    }));

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Daftar Muatan"
          bordered={false}
          extra={[
            <Link key="1" to={`/daftar-muatan/form`}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Daftar Muatan
              </Button>
            </Link>,
            direksi && (
              <CSVLink
                key="2"
                data={excelSource}
                headers={excelHeaders}
                filename={`${subTitle}.xls`}
                target="_blank"
                style={{ marginLeft: 8 }}
              >
                <Button type="primary">
                  <Icon type="edit" />
                  Export to Excel
                </Button>
              </CSVLink>
            ),
            // <Button key="1" type="primary" onClick={this.handlePrint} style={{ marginLeft: 8 }}>
            //   <Icon type="printer" />
            //   Laporan Daftar Muatan
            // </Button>,
          ]}
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchDaftarMuatanList}
            updateData={updateDaftarMuatan}
            deleteData={deleteDaftarMuatan}
            dataSource={daftarMuatanList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageDaftarMuatanComponent;
