import { IParamsResetPassword } from '@interfaces/iparams';
import {
  resetPassword,
  resetPasswordFind,
  setErrorMessage,
  setSuccessMessage,
} from '@pages/page-auth/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageResetPasswordComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  errorMessage: state.PageAuthReducer.errorMessage,
  isSubmit: state.PageAuthReducer.isSubmit,
  successMessage: state.PageAuthReducer.successMessage,
  resetPasswordInfo: state.PageAuthReducer.resetPasswordInfo,
});

export const mapDispatchToProps = (dispatch: any) => ({
  resetPasswordFind: (token: string) => dispatch(resetPasswordFind(token)),
  resetPassword: (body: IParamsResetPassword) => dispatch(resetPassword(body)),
  setErrorMessage: (error: string) => dispatch(setErrorMessage(error)),
  setSuccessMessage: (success: string) => dispatch(setSuccessMessage(success)),
});

export const PageResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageResetPasswordComponent);

export default PageResetPasswordContainer;
