// Loading State
export enum EnumLoading {
  SET = 'SET',
  REMOVE = 'REMOVE',
}
export enum DataName {
  DASHBOARD = 'DSB',
  CUSTOMER = 'CST',
  RECIPIENT = 'RCP',
  WAREHOUSE = 'WHS',
  PRICELIST = 'PLS',
  VENDOR = 'VDR',
  DRIVER = 'DRV',
  VEHICLE = 'VHC',
  VEHICLE_TYPE = 'VHCT',
  PURCHASE_ORDER = 'PO',
  TANDA_TERIMA = 'TT',
  SURAT_MUATAN = 'SM',
  INVOICE = 'INV',
  INVOICE_RECEIPT = 'INVR',
  DAFTAR_MUATAN = 'DM',
  TRANSIT_COST = 'TC',
  CASH_FLOW = 'CF',
  CASH_FLOW_RECAP = 'CFR',
  EMPLOYEE = 'EMP',
  ROLE = 'ROLE',
}

// Form
export declare type FormType = 'add' | 'edit';

// Table
export const newRowKey = 'new-row-key';
export const defaultPageSize = 10;
export const pageSizeOptions = ['10', '25', '50', '100', '250', '500', '750', '1000'];
export const showSizeChanger = true;
export const defaultParams = {
  page: 1,
  limit: 10,
  orderBy: 'code',
  sort: 'desc',
};
export const emptyParams = {};

// Date
export const dateFormat = 'DD/MM/YYYY';
export const dateFormatSearch = 'YYYY-MM-DD';
export const dateTimeFormat = `${dateFormat} HH:mm`;

export enum editableCellType {
  select = 'select',
  selectSearch = 'select-search',
  selectMultiple = 'select-multiple',
  number = 'number',
  text = 'text',
}

// Transaction
export enum deliveryType {
  SEWA = 'Sewa',
  LCL = 'LCL',
}
export enum paymentType {
  C = 'Cash',
  F = 'Franco',
  L = 'Loco',
  K = 'Loco Tempo',
}
export enum paymentTypeInverse {
  Cash = 'C',
  Franco = 'F',
  Loco = 'L',
  'Loco Tempo' = 'K',
}
export enum quantityType {
  PCS = 'PCS',
  UNIT = 'UNIT',
  BOX = 'BOX',
  KOLI = 'KOLI',
  PETI = 'PETI',
  DUS = 'DUS',
  TRUK = 'TRUK',
  JR = 'JR',
  DRUM = 'DRUM',
  PAIL = 'PAIL',
  HARI = 'HARI',
  ROLL = 'ROLL',
  BALL = 'BALL',
  HSP = 'HSP',
  SACK = 'SACK',
  KARUNG = 'KARUNG',
  IKAT = 'IKAT',
  PALLET = 'PALLET',
  BAG = 'BAG',
  BUNDLE = 'BUNDLE',
  SET = 'SET',
}
export enum volumeType {
  KG = 'KG',
  M3 = 'M3',
  NIHIL = 'NIHIL',
}
export enum priceType {
  FIX = 'FIX',
  QTY = 'QTY',
  VOL = 'VOL',
}
export enum orderStatus {
  PurchaseOrder = 0,
  TandaTerima = 1,
  SuratMuatan = 2,
  DaftarMuatan = 3,
  Invoice = 4,
}
export enum costStatus {
  Unpaid = 0,
  Paid = 1,
}
export enum cashFlowStatus {
  DownPayment = 1,
  FullPayment = 2,
}
export enum paymentStatus {
  DownPayment = '1401',
  Repayment = '1402',
}
export enum paymentMethod {
  Cash = 0,
  Transfer = 1,
}
export enum invoiceStatus {
  'Belum Kirim' = 0,
  'Sedang Kirim' = 1,
  'Sudah Kembali' = 2,
}
export enum invoicePaymentStatus {
  'Belum Bayar' = 0,
  'Uang Muka' = 1,
  'Lunas' = 2,
}
export enum shortInvoicePaymentStatus {
  'UNPAID' = 0,
  'DP' = 1,
  'PAID' = 2,
}

// Customer && Vendor
export enum companyType {
  PT = 'PT',
  CV = 'CV',
  P = 'Personal',
}

// Employee
export enum maritalStatus {
  single = 'single',
  married = 'married',
  divorced = 'divorced',
  widow = 'widow',
  widower = 'widower',
}

export enum fileType {
  KTP = 'KTP',
  KK = 'KK',
  NPWP = 'NPWP',
  SIM = 'SIM',
  STNK = 'STNK',
  PO = 'PurchaseOrder',
  SJ = 'SuratJalan',
  FDM = 'FotoDaftarMuatan',
  MOU = 'MOU',
}

// Module
export enum moduleType {
  ALL = 'all',
  DIR = 'direksi',
  OPR = 'operasional',
  FIN = 'finance',
  COL = 'collector',
}

// Roles
export enum roleType {
  DIR = 'direksi',
  OPR_HEAD = 'kepala_operasional',
  OPR_ADM = 'admin_operasional',
  FIN_HEAD = 'kepala_keuangan',
  FIN_ADM = 'admin_keuangan',
  COL_HEAD = 'kepala_penagihan',
  COL_ADM = 'admin_penagihan',
}

// Is Working
export enum workingType {
  NotWork = 0,
  Work = 1,
  AlwaysWork = 2,
}

export const defaultUploadImage = ['image/jpeg', 'image/png'];
export const defaultUploadDocument = ['image/jpeg', 'image/png', 'application/pdf'];

// Tab
export enum TabType {
  All = 'all',
  Expired = 'expired',
  Pending = 'pending',
}
