import Auth from '@adaptors-api/auth-api';
import {
  IParamsChangePassword,
  IParamsLogin,
  IParamsRegister,
  IParamsResetPassword,
} from '@interfaces/iparams';
import { setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { keyAccessToken, keyProfile, keyRefreshToken, RequestAPI } from '@utils/request-api';

export const setErrorMessage = (errorMessage: string) => ({
  errorMessage,
  type: ActionTypes.PAGE_AUTH_SET_ERROR_MESSAGE,
});

export const setSuccessMessage = (successMessage: string) => ({
  successMessage,
  type: ActionTypes.PAGE_AUTH_SET_SUCCESS_MESSAGE,
});

export const setIsSubmit = (isSubmit: boolean) => ({
  isSubmit,
  type: ActionTypes.PAGE_AUTH_SET_LOADING,
});

export const setResetPasswordInfo = (resetPasswordInfo?: IParamsResetPassword) => ({
  resetPasswordInfo,
  type: ActionTypes.PAGE_AUTH_SET_RESET_PASSWORD_INFO,
});

export const login = (params: IParamsLogin) => (dispatch: any) => {
  const body = {
    grant_type: 'password',
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    password: params.password,
    username: params.email,
  };

  dispatch(setIsSubmit(true));
  return RequestAPI({
    ...Auth.login(body),
    isReturnCallback: true,
    callback: async (response: any) => {
      if (response.status === 200) {
        const credential = response.data;
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem(keyAccessToken, credential.access_token);
        localStorage.setItem(keyRefreshToken, credential.refresh_token);
        localStorage.setItem(keyProfile, JSON.stringify(credential.user));
        window.location.href = '/dashboard';
      } else {
        const errorMessage =
          response.error === 'invalid_grant' ? 'Email atau password salah!' : response.message;
        dispatch(setErrorMessage(errorMessage));
        const notif: any = {
          status: 'error',
          title: `Login Gagal`,
          message: 'Login Gagal!',
        };
        dispatch(setNotification(notif));
      }
      dispatch(setIsSubmit(false));
    },
  });
};

export const register = (body: IParamsRegister) => (dispatch: any) => {
  dispatch(setIsSubmit(true));
  return RequestAPI({
    ...Auth.register(body),
    isReturnCallback: true,
    callback: async (response: any) => {
      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.message));
      } else {
        const errorMessage = response.toString();
        dispatch(setErrorMessage(errorMessage));
        const notif: any = {
          status: 'error',
          title: `Registrasi Gagal`,
          message: 'Registrasi Gagal!',
        };
        dispatch(setNotification(notif));
      }
      dispatch(setIsSubmit(false));
    },
  });
};

export const changePassword = (body: IParamsChangePassword) => (dispatch: any) => {
  dispatch(setIsSubmit(true));
  return RequestAPI({
    ...Auth.changePassword(body),
    isReturnCallback: true,
    callback: async (response: any) => {
      if (response.status === 200) {
        const credential = response.data;
        localStorage.setItem(keyAccessToken, credential.access_token);
        const notif: any = {
          status: response.status === 200 ? 'success' : 'error',
          title: 'Ganti Password Berhasil',
          message: response.data.message,
        };
        dispatch(setNotification(notif));
      } else {
        const errorMessage = response || 'Password Salah!';
        dispatch(setErrorMessage(errorMessage));
        const notif: any = {
          status: 'error',
          title: `Ganti Password Gagal`,
          message: errorMessage,
        };
        dispatch(setNotification(notif));
      }
      dispatch(setIsSubmit(false));
    },
  });
};

export const resetPasswordRequest = (body: { email: string }) => (dispatch: any) => {
  dispatch(setIsSubmit(true));
  return RequestAPI({
    ...Auth.resetPasswordRequest(body),
    isReturnCallback: true,
    callback: async (response: any) => {
      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.message));
      } else {
        const errorMessage = response?.message;
        dispatch(setErrorMessage(errorMessage));
      }
      dispatch(setIsSubmit(false));
    },
  });
};

export const resetPasswordFind = (token: string) => (dispatch: any) => {
  dispatch(setIsSubmit(true));
  return RequestAPI({
    ...Auth.resetPasswordFind(token),
    isReturnCallback: true,
    callback: async (response: any) => {
      if (response.status === 200) {
        const { data } = response;
        const info: IParamsResetPassword = {
          email: data.email,
          token: data.token,
        };
        dispatch(setResetPasswordInfo(info));
      } else {
        const errorMessage = response.toString();
        dispatch(setResetPasswordInfo(undefined));
        dispatch(setErrorMessage(errorMessage));
      }
      dispatch(setIsSubmit(false));
    },
  });
};

export const resetPassword = (body: IParamsResetPassword) => (dispatch: any) => {
  dispatch(setIsSubmit(true));
  return RequestAPI({
    ...Auth.resetPassword(body),
    isReturnCallback: true,
    callback: async (response: any) => {
      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.message));
      } else {
        const errorMessage = response.toString();
        dispatch(setErrorMessage(errorMessage));
      }
      dispatch(setIsSubmit(false));
    },
  });
};
