import { ITandaTerima } from '@interfaces/itanda-terima';

const urlTandaTerima = 'tanda_terima';

export const getTandaTerima = (code: string) => ({
  method: 'GET',
  url: `${urlTandaTerima}/${code}`,
});

export const getTandaTerimas = (params: any) => ({
  method: 'GET',
  params,
  url: urlTandaTerima,
});

export const createTandaTerima = (body: ITandaTerima) => ({
  body,
  method: 'POST',
  url: `${urlTandaTerima}`,
});

export const updateTandaTerima = (code: string, body: ITandaTerima) => ({
  body,
  method: 'PUT',
  url: `${urlTandaTerima}/${code}`,
});

export default { getTandaTerima, getTandaTerimas, createTandaTerima, updateTandaTerima };
