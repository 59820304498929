import { IRecipient } from '@interfaces/irecipient';
import { DataName } from '@utils/constants';
import { goBack } from '@utils/history';
import { isLoading } from '@utils/loading';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import { isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

interface IProps {
  fetchRecipient: (code: string) => void;
  recipient?: IRecipient;
  isFetching: string[];
  match: any;
}

const title = 'Detail Penerima';
export class PageRecipientDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchRecipient } = this.props;
    fetchRecipient(match.params.code);
  };
  public render() {
    const { recipient, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.RECIPIENT);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/recipient/form${recipient && `/${recipient.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Penerima
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {recipient && (
            <Descriptions
              title={`Penerima: ${recipient.code}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Nama Penerima">{recipient.name}</Descriptions.Item>
              <Descriptions.Item label="Alamat" span={3}>
                {recipient.address || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Kota">{recipient.city || '-'}</Descriptions.Item>
              <Descriptions.Item label="Nama PIC">{recipient.picName || '-'}</Descriptions.Item>
              <Descriptions.Item label="Telepon PIC">{recipient.picPhone || '-'}</Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(recipient.remarks) ? 'warning' : ''}
                span={3}
              >
                {recipient.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageRecipientDetailComponent;
