import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchCustomerList } from './action';
import PageCustomerComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  customerList: state.PageMasterDataReducer.customerList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCustomerList: (params: any) => dispatch(fetchCustomerList(params)),
});

export const PageCustomerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCustomerComponent);

export default PageCustomerContainer;
