import { EmptyState } from '@components/molecules/empty-state';
import { volumeType, paymentTypeInverse, dateFormat } from '@utils/constants';
import { ISuratMuatan, ISuratMuatanDetail } from '@interfaces/isurat-muatan';
import { companyTypeCase, costStatusCase, currencyCase } from '@utils/string-case';
import { Table } from 'antd';
import React, { Component, Fragment } from 'react';
import { IDaftarMuatanDetail } from '@interfaces/idaftar-muatan';
import { IDriver } from '@interfaces/idriver';
import { ITransitCost } from '@interfaces/itransit-cost';
import { IVehicle } from '@interfaces/ivehicle';
import { formatTime } from '@utils/time';
import { Link } from 'react-router-dom';

interface IProps {
  tableType: 'surat_muatan' | 'transit_cost';
  dataSource?: ISuratMuatan[] | ITransitCost[];
  isPrint: boolean;
  isDaftarMuatan?: boolean;
  vendorPrice?: number;
}

export class DetailTableComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSource: props.dataSource,
    };
  }
  public render() {
    const { tableType, isPrint, isDaftarMuatan, vendorPrice, dataSource } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    let details: any[] = [];
    if (tableType === 'surat_muatan') {
      const dataSM = dataSource as ISuratMuatan[];
      let totalQuantity = 0;
      let totalVolume = 0;
      let totalPrice = 0;
      let totalLocoPrice = 0;

      dataSM.map((itemSM: ISuratMuatan) => {
        const { details: suratMuatan } = itemSM;
        suratMuatan?.map((detail: ISuratMuatanDetail, index: number) => {
          const total = isPrint
            ? itemSM.paymentType === paymentTypeInverse.Loco
              ? detail.vendorPrice > 0
                ? detail.vendorPrice
                : detail.total
              : '-'
            : detail.total;
          const items = {
            id: detail.id,
            key: `${index}-${itemSM.code}`,
            code: itemSM.code,
            customer: companyTypeCase(itemSM.customer?.type, itemSM.customer?.name),
            warehouse: itemSM.warehouse?.code,
            recipient: itemSM.recipient?.name,
            itemName: detail.itemName,
            quantity: detail.quantity,
            quantityType: detail.quantityType,
            volume: detail.volume,
            volumeType: detail.volumeType,
            total,
            paymentType: itemSM.paymentType,
          };
          details.push(items);
          totalQuantity += detail.quantity;
          totalVolume += detail.volume;
          totalPrice += detail.total;
          totalLocoPrice +=
            itemSM.paymentType === paymentTypeInverse.Loco
              ? detail.vendorPrice > 0
                ? detail.vendorPrice
                : detail.total
              : 0;
        });
      });

      const finalPrice = isPrint
        ? vendorPrice && vendorPrice > 0
          ? vendorPrice
          : totalPrice
        : totalPrice;
      const displayPrice = isPrint ? finalPrice - totalLocoPrice : finalPrice;

      if (!isPrint) {
        details.push({
          key: `totalRow`,
          itemName: 'TOTAL',
          quantity: totalQuantity,
          volume: totalVolume,
          total: displayPrice,
        });
      }
    } else {
      details = dataSource as ITransitCost[];
    }

    const columns: any[] =
      tableType === 'surat_muatan'
        ? [
            {
              title: 'Nomor SM',
              dataIndex: 'code',
              sorter: false,
              width: 60,
            },
            {
              title: 'Customer',
              dataIndex: 'customer',
              sorter: false,
              width: 130,
            },
            {
              title: 'Penerima',
              dataIndex: 'recipient',
              sorter: false,
              width: 130,
            },
            {
              title: 'Nama Barang',
              dataIndex: 'itemName',
              sorter: false,
              width: 110,
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              render: (text: string, record: any) =>
                `${text} ${record.quantityType ? record.quantityType : ''}`,
              sorter: false,
              width: 60,
            },
            {
              title: 'Volume',
              dataIndex: 'volume',
              render: (text: string, record: any) =>
                `${text} ${
                  record.volumeType && record.volumeType !== volumeType.NIHIL
                    ? record.volumeType
                    : ''
                }`,
              sorter: false,
              width: 60,
            },
            {
              title: 'Tipe',
              dataIndex: 'paymentType',
              sorter: false,
              width: 40,
            },
            {
              title: 'Harga',
              dataIndex: 'total',
              align: 'right',
              render: (text: any) => (
                <div className="bt-text-right">{text !== '-' ? currencyCase(text) : text}</div>
              ),
              sorter: false,
              width: 90,
            },
          ]
        : [
            {
              title: 'No Transit Cost',
              dataIndex: 'code',
              sorter: true,
              render: (text: string, record: ITransitCost) => (
                <Link to={`/transit-cost/detail/${record.code}`}>{text}</Link>
              ),
              fixed: 'left',
              width: 180,
            },
            {
              title: 'No Surat Muatan',
              dataIndex: 'suratMuatanIds',
              render: (text: string, record: ITransitCost) => {
                if (text) {
                  const suratMuatanIds = text?.split(`,`).map((x) => +x);
                  const dmDetails = record?.daftar_muatan?.details?.filter(
                    (item: IDaftarMuatanDetail) =>
                      item?.details?.id && suratMuatanIds?.includes(item?.details?.id),
                  );

                  const element: any[] = [];
                  dmDetails?.map((item: IDaftarMuatanDetail, index: number) => {
                    element.push(
                      <Fragment key={item?.details?.id}>
                        {dmDetails.length > 1 && index !== 0 && <br />}&bull;{' '}
                        <Link to={`/surat-muatan/detail/${item?.details?.code}`}>
                          {item?.details?.code}
                        </Link>
                      </Fragment>,
                    );
                  });
                  return element;
                } else {
                  return '-';
                }
              },
              ellipsis: true,
              width: 200,
            },
            {
              title: 'Tanggal',
              dataIndex: 'date',
              render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
            },
            {
              title: 'No Polisi',
              dataIndex: 'vehicle.policeNo',
              render: (text: string, record: IVehicle) => (
                <Link to={`/vehicle/detail/${record.code}`}>{text}</Link>
              ),
            },
            {
              title: 'Nama Supir',
              dataIndex: 'vehicle.driver',
              render: (record: IDriver) => (
                <Link to={`/driver/detail/${record.code}`}>{record.fullName}</Link>
              ),
            },
            {
              title: 'Biaya',
              dataIndex: 'cost',
              render: (text: any) => currencyCase(text),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              render: (text: number) => costStatusCase(text),
            },
          ];

    return (
      <div className={`bt-table small ${isDaftarMuatan && 'narrow'}`}>
        <Table
          size="small"
          columns={columns}
          dataSource={details}
          bordered={!isPrint}
          pagination={false}
          locale={{
            emptyText: tableEmptyState,
          }}
          scroll={!isPrint ? { x: 1500 } : undefined}
          rowKey={tableType === 'surat_muatan' ? 'key' : 'id'}
        />
      </div>
    );
  }
}

export default DetailTableComponent;
