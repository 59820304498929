import { DataName, dateFormat, paymentType } from '@utils/constants';
import { ITandaTerima } from '@interfaces/itanda-terima';
import { goBack } from '@utils/history';
import { companyTypeCase, currencyCase, orderStatusCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Divider, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import DetailTableComponent from './libraries/detail-table';
import ModalPrintComponent from './libraries/modal';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchTandaTerima: (code: string) => void;
  tandaTerima?: ITandaTerima;
  isFetching: string[];
  match: any;
}

interface IState {
  showModal: boolean;
}

const title = 'Detail Tanda Terima';
export class PageTandaTerimaDetailComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  public componentDidMount = () => {
    const { match, fetchTandaTerima } = this.props;
    fetchTandaTerima(match.params.code);
  };
  public handleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };
  public render() {
    const { tandaTerima, isFetching } = this.props;
    const { showModal } = this.state;
    const loading = isLoading(isFetching, DataName.TANDA_TERIMA);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        {tandaTerima && (
          <PageHeader
            title={title}
            onBack={goBack}
            extra={[
              <Button key="1" type="primary" onClick={this.handleModal}>
                <Icon type="printer" />
                Cetak Tanda Terima
              </Button>,
              <Link key="2" to={`/tanda-terima/form${tandaTerima && `/${tandaTerima.code}`}`}>
                <Button type="primary">
                  <Icon type="edit" />
                  Ubah Tanda Terima
                </Button>
              </Link>,
              <Fragment key="3">
                {tandaTerima.status && tandaTerima.status > 1 ? (
                  <Link to={`/surat-muatan/detail/${tandaTerima.code}`}>
                    <Button type="primary">
                      <Icon type="eye" />
                      Lihat Surat Muatan
                    </Button>
                  </Link>
                ) : (
                  <Link to={`/surat-muatan/form/${tandaTerima.code}`}>
                    <Button type="primary">
                      <Icon type="plus" />
                      Buat Surat Muatan
                    </Button>
                  </Link>
                )}
              </Fragment>,
            ]}
          />
        )}
        <Card bordered={false} loading={loading}>
          {tandaTerima && (
            <Fragment>
              <Descriptions
                title={`Tanda Terima: ${tandaTerima.code}`}
                layout="vertical"
                bordered={true}
                size="small"
              >
                <Descriptions.Item label="Tanggal">
                  {tandaTerima.orderDate
                    ? formatTime(moment(tandaTerima.orderDate), dateFormat)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Tipe Pembayaran">
                  {paymentType[tandaTerima.paymentType]}
                </Descriptions.Item>
                <Descriptions.Item label="Tipe Pengiriman">
                  {tandaTerima.deliveryType}
                </Descriptions.Item>
                <Descriptions.Item label="Customer">
                  {companyTypeCase(tandaTerima.customer?.type, tandaTerima.customer?.name)}
                </Descriptions.Item>
                <Descriptions.Item label="Penerima">
                  {tandaTerima.recipient?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Gudang">
                  {tandaTerima.warehouse
                    ? `${tandaTerima.warehouse.code} - ${tandaTerima.warehouse.address}, ${tandaTerima.warehouse.city}`
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Total Harga">
                  {tandaTerima.invoicePrice ? currencyCase(tandaTerima.invoicePrice) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Komisi">
                  {tandaTerima.commission ? currencyCase(tandaTerima.commission) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {orderStatusCase(tandaTerima.status)}
                </Descriptions.Item>
                <Descriptions.Item label="Surat Jalan / PO">
                  {tandaTerima.fileSuratJalan ? <a href="#">Lihat Surat Jalan</a> : '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Remarks"
                  className={!isEmpty(tandaTerima.remarks) ? 'warning' : ''}
                >
                  {tandaTerima.remarks || '-'}
                </Descriptions.Item>
              </Descriptions>
              <Divider orientation="left">Detail Muatan</Divider>
              <DetailTableComponent
                master={tandaTerima}
                dataSource={tandaTerima.details}
                showPrice={true}
                isPrint={false}
              />
            </Fragment>
          )}
        </Card>

        {tandaTerima && (
          <ModalPrintComponent
            title="Tanda Terima"
            show={showModal}
            hide={this.handleModal}
            data={tandaTerima}
          />
        )}
      </Fragment>
    );
  }
}
export default PageTandaTerimaDetailComponent;
