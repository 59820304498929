import PurchaseOrder from '@adaptors-api/purchase-order-api';
import { IMeta } from '@interfaces/imeta';
import { IPurchaseOrder } from '@interfaces/ipurchase-order';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.PURCHASE_ORDER));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.PURCHASE_ORDER));
};

export const setPurchaseOrderList = (purchaseOrderList: IPurchaseOrder[]) => ({
  purchaseOrderList,
  type: ActionTypes.PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER_LIST,
});

export const setPurchaseOrder = (purchaseOrder?: IPurchaseOrder) => ({
  purchaseOrder,
  type: ActionTypes.PAGE_PURCHASE_ORDER_SET_PURCHASE_ORDER,
});

export const fetchPurchaseOrderList = (params?: any, isSetMeta: boolean = true) => (
  dispatch: any,
) => {
  setLoading(dispatch);

  return RequestAPI(PurchaseOrder.getPurchaseOrders(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const purchaseOrderList: IPurchaseOrder[] = data;
      const metaData: IMeta = meta;
      dispatch(setPurchaseOrderList(purchaseOrderList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchPurchaseOrder = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setPurchaseOrder(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(PurchaseOrder.getPurchaseOrder(code))
    .then((response: any) => {
      const { data } = response.data;
      const purchaseOrder: IPurchaseOrder = data;
      dispatch(setPurchaseOrder(purchaseOrder));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createPurchaseOrder = (body: IPurchaseOrder) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(PurchaseOrder.createPurchaseOrder(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Purchase Order ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Purchase Order Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updatePurchaseOrder = (code: string, body: IPurchaseOrder) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(PurchaseOrder.updatePurchaseOrder(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Purchase Order ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Purchase Order Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
