import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchDashboard } from './action';
import PageDashboardComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  dashboard: state.PageDashboardReducer.dashboard,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchDashboard: () => dispatch(fetchDashboard()),
});

export const PageDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageDashboardComponent);

export default PageDashboardContainer;
