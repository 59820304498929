import React, { Component } from 'react';

interface IProps {
  LogoTitle: React.ReactNode;
  LogoImage: any;
  isCollapsed: boolean;
}

export class LogoSidebarComponent extends Component<IProps, any> {
  public render() {
    const { LogoTitle, LogoImage, isCollapsed } = this.props;
    return (
      <div className="bt-sidebar-logo" style={{ width: isCollapsed ? '80px' : '240px' }}>
        <img className="bt-sidebar-logo__icon" src={LogoImage} />
        <h3 className="bt-sidebar-logo__lg bt-sidebar-logo__text">{LogoTitle}</h3>
      </div>
    );
  }
}
export default LogoSidebarComponent;
