import { IPurchaseOrder } from '@interfaces/ipurchase-order';

const urlPurchaseOrder = 'purchase_orders';

export const getPurchaseOrder = (code: string) => ({
  method: 'GET',
  url: `${urlPurchaseOrder}/${code}`,
});

export const getPurchaseOrders = (params: any) => ({
  method: 'GET',
  params,
  url: urlPurchaseOrder,
});

export const createPurchaseOrder = (body: IPurchaseOrder) => ({
  body,
  method: 'POST',
  url: `${urlPurchaseOrder}`,
});

export const updatePurchaseOrder = (code: string, body: IPurchaseOrder) => ({
  body,
  method: 'PUT',
  url: `${urlPurchaseOrder}/${code}`,
});

export default { getPurchaseOrder, getPurchaseOrders, createPurchaseOrder, updatePurchaseOrder };
