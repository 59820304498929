import {
  dateFormat,
  emptyParams,
  FormType,
  orderStatus,
  paymentMethod,
  paymentStatus,
  cashFlowStatus,
  DataName,
} from '@utils/constants';
import { ICashFlow, ICoa } from '@interfaces/icash-flow';
import { IDaftarMuatan } from '@interfaces/idaftar-muatan';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { enumToArray } from '@utils/enum-to-array';
import TextArea from 'antd/lib/input/TextArea';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchDaftarMuatanList: (params: any) => void;
  daftarMuatanList: IDaftarMuatan[];
  fetchCashFlow: (code: string) => void;
  cashFlow?: ICashFlow;
  createCashFlow: (body: ICashFlow) => void;
  updateCashFlow: (code: string, body: ICashFlow) => void;
  fetchCoaList: (params?: any) => void;
  coaList: ICoa[];
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
  paymentMethodList: string[];
  selectedCoa?: ICoa;
  selectedDM?: IDaftarMuatan;
}
export class PageCashFlowFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      formType: code ? 'edit' : 'add',
      paymentMethodList: enumToArray(paymentMethod),
    };
  }
  public componentDidMount = () => {
    const { match, fetchCashFlow, fetchCoaList } = this.props;
    const { code } = match.params;
    fetchCashFlow(code);
    fetchCoaList();
  };
  public componentDidUpdate = (prevProps: IProps) => {
    const { cashFlow } = this.props;
    if (prevProps.cashFlow !== cashFlow) {
      this.setState({
        selectedDM: cashFlow?.daftar_muatan,
        selectedCoa: cashFlow?.coa,
      });
    }
  };
  public handleSubmit = () => {
    const { form, cashFlow, updateCashFlow, createCashFlow } = this.props;
    const { formType, selectedCoa } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataCashFlow: ICashFlow = {
          ...cashFlow,
          daftarMuatanId:
            selectedCoa && ['1401', '1402'].includes(selectedCoa?.code)
              ? values.daftarMuatan
              : null,
          paymentDate: formatTime(values.paymentDate, 'YYYY-MM-DD'),
          postingDate: formatTime(values.postingDate, 'YYYY-MM-DD'),
          voucherNo: values.voucherNo,
          coaId: values.CoA,
          description: values.description,
          subtotal: Number(values.subtotal),
          total: Number(values.total),
          miscCharge: Number(values.miscCharge),
          pph: Number(values.pph || 0),
          paymentMethod: values.paymentMethod === paymentMethod.Cash ? 0 : 1,
          paymentReceiver: values.paymentReceiver,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && cashFlow?.code) {
          await updateCashFlow(cashFlow.code, dataCashFlow);
        } else {
          await createCashFlow({ ...dataCashFlow, created_by: currentUser });
        }
        history.push('/cash-flow');
      }
    });
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public onSelectCOA = (value: any) => {
    const { coaList } = this.props;
    const coa = coaList.find((item: ICoa) => item.id === value);
    this.setState({ selectedCoa: coa });
    this.calculate();
  };
  public onSearchDaftarMuatan = (value: any) => {
    const { fetchDaftarMuatanList } = this.props;
    if (value.length === 6) {
      fetchDaftarMuatanList({
        ...emptyParams,
        status: `[${orderStatus.DaftarMuatan}]`,
        code: value,
      });
    }
  };
  public onSelectDaftarMuatan = (value: any) => {
    const { daftarMuatanList, form } = this.props;
    const { selectedCoa } = this.state;
    const { setFieldsValue } = form;
    const data = daftarMuatanList.find((item: IDaftarMuatan) => value === item.id);
    this.setState({ selectedDM: data });

    if (selectedCoa?.code === paymentStatus.DownPayment) {
      setFieldsValue({ subtotal: data?.downPayment });
    } else if (selectedCoa?.code === paymentStatus.Repayment) {
      const vendorPrice = data?.totalVendorPrice || 0;
      const downPayment = data?.downPayment || 0;
      const adminFee = data?.adminFee || 0;
      setFieldsValue({ subtotal: vendorPrice - downPayment - adminFee });
    } else {
      setFieldsValue({ subtotal: 0 });
    }

    this.calculate();
  };
  public calculate = (e?: any) => {
    const { form } = this.props;
    const { getFieldValue, setFieldsValue } = form;

    let id = '';
    let value = '';
    if (e?.target) {
      id = e.target.id;
      value = e.target.value;
    }

    const subtotal = Number(id === 'subtotal' ? value : getFieldValue(`subtotal`) || 0);
    const miscCharge = Number(id === 'miscCharge' ? value : getFieldValue(`miscCharge`) || 0);
    const pph = Number(id === 'pph' ? value : getFieldValue(`pph`) || 0);

    const total = subtotal - miscCharge - pph;

    setFieldsValue({ total });
  };
  public render() {
    const { isFetching, daftarMuatanList, cashFlow, coaList, form } = this.props;
    const { formType, paymentMethodList, selectedCoa } = this.state;
    const { getFieldDecorator } = form;
    const title = formType && formType === 'edit' ? 'Ubah Cash Flow' : 'Buat Cash Flow';
    const selectedCoaCode = selectedCoa?.code || '';
    const dmList = daftarMuatanList.filter(
      (item: IDaftarMuatan) =>
        item.cashFlowStatus === null ||
        item.cashFlowStatus === cashFlowStatus.DownPayment ||
        item.id === cashFlow?.daftar_muatan?.id,
    );
    const loading = isLoading(isFetching, DataName.CASH_FLOW);
    const loadingDM = isLoading(isFetching, DataName.DAFTAR_MUATAN);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Form.Item label="Nomor Voucher">
              {getFieldDecorator('voucherNo', {
                initialValue: cashFlow?.voucherNo ?? '',
                rules: [{ required: true, message: 'Nomor Voucher harus diisi!' }],
              })(<Input placeholder="Nomor Voucher" />)}
            </Form.Item>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Tanggal Pembayaran">
                  {getFieldDecorator('paymentDate', {
                    initialValue: cashFlow?.paymentDate ? moment(cashFlow?.paymentDate) : moment(),
                    rules: [{ required: true, message: 'Tanggal Pembayaran harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Cash on Account">
                  {getFieldDecorator('CoA', {
                    initialValue: cashFlow?.coa?.id ?? coaList[0]?.id,
                    rules: [{ message: 'Cash on Account harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      placeholder="Cash on Account"
                      showSearch={true}
                      optionFilterProp="children"
                      onChange={this.onSelectCOA}
                    >
                      {coaList &&
                        coaList.map((item: ICoa) => (
                          <Option key={item.code} value={item.id}>
                            {`${item.code} | ${item.accountName} | ${item.normalBalance}`}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Potongan PPH">
                  {getFieldDecorator('pph', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: cashFlow?.pph ?? '',
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Potongan PPH" onChange={this.calculate} />)}
                </Form.Item>
                <Form.Item label="Metode Pembayaran">
                  {getFieldDecorator('paymentMethod', {
                    initialValue: cashFlow?.paymentMethod ?? paymentMethodList[0],
                    rules: [{ message: 'Metode Pembayaran harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Metode Pembayaran">
                      {paymentMethodList &&
                        paymentMethodList.map((item: string) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Penerima Pembayaran">
                  {getFieldDecorator('paymentReceiver', {
                    initialValue: cashFlow?.paymentReceiver ?? '',
                    rules: [{ required: true, message: 'Penerima Pembayaran harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Penerima Pembayaran" />)}
                </Form.Item>
                <Form.Item label="Keterangan">
                  {getFieldDecorator('description', {
                    initialValue: cashFlow?.description ?? '',
                    rules: [
                      { required: true, message: 'Keterangan harus diisi!' },
                      { min: 3, message: 'Keterangan minimal 3 karakter!' },
                      { max: 250, message: 'Keterangan maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Deskripsi Cash Flow" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Tanggal Posting">
                  {getFieldDecorator('postingDate', {
                    initialValue: cashFlow?.postingDate ? moment(cashFlow?.postingDate) : moment(),
                    rules: [{ required: true, message: 'Tanggal Posting harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Daftar Muatan">
                  {getFieldDecorator('daftarMuatan', {
                    initialValue: cashFlow?.daftar_muatan?.id ?? '',
                    rules: [
                      {
                        message: 'Daftar Muatan harus dipilih!',
                        required: ['1401', '1402'].includes(selectedCoaCode),
                      },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Daftar Muatan"
                      optionFilterProp="children"
                      onSearch={this.onSearchDaftarMuatan}
                      onChange={this.onSelectDaftarMuatan}
                      disabled={!['1401', '1402'].includes(selectedCoaCode)}
                      loading={loadingDM}
                    >
                      {dmList &&
                        dmList.map((item: IDaftarMuatan) => (
                          <Option key={item.code} value={item.id}>
                            {item.code}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Subtotal">
                  {getFieldDecorator('subtotal', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: cashFlow?.subtotal ?? '',
                    rules: [{ required: true, message: 'Subtotal harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Input
                      prefix="Rp"
                      placeholder="Subtotal"
                      onChange={this.calculate}
                      disabled={['1401', '1402'].includes(selectedCoaCode)}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Misc Charge">
                  {getFieldDecorator('miscCharge', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: cashFlow?.description ?? '',
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Misc Charge" onChange={this.calculate} />)}
                </Form.Item>
                <Form.Item label="Total">
                  {getFieldDecorator('total', {
                    getValueFromEvent: this.onChangeNumber,
                    rules: [{ required: true, message: 'Total harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Input
                      prefix="Rp"
                      placeholder="Total"
                      onChange={this.calculate}
                      disabled={true}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: cashFlow?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}

export default Form.create<IProps>()(PageCashFlowFormComponent);
