import cityList from '@data/list_location.json';
import { DataName, defaultUploadDocument, emptyParams, FormType } from '@utils/constants';
import { ICity } from '@interfaces/icity';
import { ICustomer } from '@interfaces/icustomer';
import { IWarehouse } from '@interfaces/iwarehouse';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase } from '@utils/string-case';
import { Button, Card, Col, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchCustomerList: (params: any) => void;
  customerList: ICustomer[];
  fetchWarehouse: (code: string) => void;
  warehouse?: IWarehouse;
  createWarehouse: (body: IWarehouse) => void;
  updateWarehouse: (code: string, body: IWarehouse) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
}

export class PageWarehouseFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      formType: code ? 'edit' : 'add',
    };
  }
  public componentDidMount = () => {
    const { match, fetchWarehouse, fetchCustomerList } = this.props;
    const { code } = match.params;
    fetchWarehouse(code);
    fetchCustomerList(emptyParams);
  };
  public handleSubmit = () => {
    const { form, warehouse, createWarehouse, updateWarehouse } = this.props;
    const { formType } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataWarehouse: IWarehouse = {
          ...warehouse,
          customersId: values.customer,
          address: values.address,
          city: values.city,
          picName: values.picName,
          picPhone: values.picPhone,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && warehouse?.code) {
          await updateWarehouse(warehouse.code, dataWarehouse);
        } else {
          await createWarehouse({ ...dataWarehouse, created_by: currentUser });
        }
        history.push('/warehouse');
      }
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const isValidDocument: boolean = defaultUploadDocument.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG, PNG, or PDF file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { isFetching, form, warehouse, customerList } = this.props;
    const { formType } = this.state;
    const { getFieldDecorator } = form;
    const title = formType && formType === 'edit' ? 'Ubah Gudang' : 'Tambah Gudang';
    const loading = isLoading(isFetching, DataName.WAREHOUSE);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Customer">
                  {getFieldDecorator('customer', {
                    initialValue: warehouse?.customer?.id ?? '',
                    rules: [{ message: 'Customer harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Customer"
                      optionFilterProp="children"
                    >
                      {customerList &&
                        customerList.map((item: ICustomer) => (
                          <Option key={item.code} value={item.id}>
                            {companyTypeCase(item.type, item.name)}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Kota">
                  {getFieldDecorator('city', {
                    initialValue: warehouse?.city ?? '',
                    rules: [{ required: true, message: 'Kota harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Kota" showSearch={true}>
                      {cityList &&
                        cityList.data.map((item: ICity) => (
                          <Option key={item.kabko} value={item.kabko}>
                            {item.kabko}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Alamat">
                  {getFieldDecorator('address', {
                    initialValue: warehouse?.address ?? '',
                    rules: [
                      { min: 3, message: 'Alamat minimal 3 karakter!' },
                      { max: 250, message: 'Alamat maksimal 250 karakter!' },
                      { required: true, message: 'Alamat harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Alamat Kantor, Nama Jalan, Kode Pos, lain-lain." />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Nama PIC">
                  {getFieldDecorator('picName', {
                    initialValue: warehouse?.picName ?? '',
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nama PIC" />)}
                </Form.Item>
                <Form.Item label="Telepon PIC">
                  {getFieldDecorator('picPhone', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: warehouse?.picPhone ?? '',
                    rules: [
                      { min: 5, message: 'Telepon PIC minimal 5 digit!' },
                      { max: 15, message: 'Telepon PIC maksimal 15 digit!' },
                      { message: 'Telepon PIC hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon PIC" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: warehouse?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}

export default Form.create<IProps>()(PageWarehouseFormComponent);
