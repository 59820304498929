import { EmptyState } from '@components/molecules/empty-state';
import { EditableCell } from '@components/organisms/editable-cell';
import { EditableContext } from '@components/organisms/editable-cell/component';
import {
  defaultParams,
  editableCellType,
  newRowKey,
  pageSizeOptions,
  showSizeChanger,
  dateTimeFormat,
} from '@utils/constants';
import { IMeta } from '@interfaces/imeta';
import { IVehicleType } from '@interfaces/ivehicle';
import { getProfile } from '@utils/request-api';
import { filter, tableChangeParams } from '@utils/table';
import { currentTime, formatTime } from '@utils/time';
import { Button, Card, Divider, Form, Popconfirm, Table } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';

interface IProps extends FormComponentProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  dataSource?: IVehicleType[];
  createVehicleType: (body: IVehicleType) => void;
  updateVehicleType: (code: string, body: IVehicleType) => void;
  meta: IMeta;
}

interface IState {
  source: IVehicleType[];
  editingKey: string;
  isDisableAdd: boolean;
  tableParams: any;
}

export class TableVehicleTypeComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      source: [],
      editingKey: '',
      isDisableAdd: false,
      tableParams: defaultParams,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setState({ source: this.props.dataSource || [] });
    }
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params, editingKey: '', isDisableAdd: false });
    fetchDataSource(params);
  };
  public handleUpdateVehicleType = async (code: string, vehicleType: IVehicleType) => {
    const { fetchDataSource, createVehicleType, updateVehicleType } = this.props;
    const currentUser = getProfile().employee.fullName;
    if (code === newRowKey) {
      await createVehicleType({ ...vehicleType, updated_by: currentUser });
    } else {
      await updateVehicleType(code, { ...vehicleType, updated_by: currentUser });
    }
    this.handleCancel();
    await fetchDataSource(defaultParams);
  };
  public handleDelete = (vehicleType: IVehicleType) => {
    vehicleType.deleted_at = currentTime();
    this.handleUpdateVehicleType(vehicleType.code, vehicleType);
  };
  public handleCancel = () => {
    const { editingKey, source } = this.state;
    if (editingKey === newRowKey) {
      source.pop();
      this.setState({ source, isDisableAdd: false });
    }
    this.setState({ editingKey: '' });
  };
  public handleSave = (vehicleType: IVehicleType, form: any) => {
    form.validateFields((error: any, values: any) => {
      if (error) {
        return;
      }
      this.handleUpdateVehicleType(vehicleType.code, {
        ...vehicleType,
        name: values.name,
        description: values.description,
      });
    });
  };
  public handleAdd = () => {
    const { source } = this.state;
    source.push({
      code: newRowKey,
      name: '',
      description: '',
    });
    this.setState({ source, isDisableAdd: true, editingKey: newRowKey });
  };
  public handleEdit = (vehicleType: IVehicleType) => {
    this.setState({ editingKey: vehicleType.code.toString() });
  };
  public isEditing = (record: { code: string }) => record.code.toString() === this.state.editingKey;
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const { isDisableAdd } = this.state;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns: any = [
      {
        title: 'Nama Tipe Kendaraan',
        dataIndex: 'name',
        ...filter('name'),
        sorter: true,
        editable: true,
        inputType: editableCellType.text,
        mandatory: true,
      },
      {
        title: 'Deskripsi',
        dataIndex: 'description',
        ...filter('description'),
        sorter: true,
        render: (text: any) => text || '-',
        editable: true,
        inputType: editableCellType.text,
        mandatory: false,
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: IVehicleType) => {
          const { editingKey } = this.state;
          const editing = this.isEditing(record);

          return editing ? (
            <Fragment>
              <EditableContext.Consumer>
                {(form) => (
                  <Popconfirm
                    title="Apakah Anda yakin untuk menyimpan perubahan ini?"
                    onConfirm={() => this.handleSave(record, form)}
                    okText="Iya"
                    cancelText="Tidak"
                  >
                    <Button type="link">Simpan</Button>
                  </Popconfirm>
                )}
              </EditableContext.Consumer>
              <Divider type="vertical" />
              <Button type="link" size="small" onClick={this.handleCancel}>
                Batal
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                type="link"
                onClick={() => this.handleEdit(record)}
                disabled={editingKey !== ''}
                size="small"
              >
                Ubah
              </Button>
              <Divider type="vertical" />
              <Button
                type="link"
                onClick={() => this.handleDelete(record)}
                disabled={editingKey !== ''}
                size="small"
              >
                Hapus
              </Button>
            </Fragment>
          );
        },
      },
    ];

    const renderedColumns = columns.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          rowKey: 'code',
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          isMandatory: col.mandatory,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <Card
        title="Daftar Tipe Kendaraan"
        bordered={false}
        extra={
          <Button type="primary" onClick={this.handleAdd} disabled={isDisableAdd}>
            Tambah Tipe Kendaraan
          </Button>
        }
      >
        <div className="bt-table">
          <EditableContext.Provider value={this.props.form}>
            <Table
              size="small"
              onChange={this.onChangeTable}
              components={components}
              columns={renderedColumns}
              dataSource={dataSource}
              bordered={true}
              pagination={
                meta && {
                  current: meta.current_page,
                  pageSize: meta.per_page,
                  pageSizeOptions,
                  showSizeChanger,
                  total: meta.total,
                }
              }
              locale={{
                emptyText: tableEmptyState,
              }}
              loading={isFetching}
              rowKey="code"
            />
          </EditableContext.Provider>
        </div>
      </Card>
    );
  }
}

export default Form.create<IProps>()(TableVehicleTypeComponent);
