import { EmptyState } from '@components/molecules/empty-state';
import { dateFormat, invoiceStatus } from '@utils/constants';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { Table, Select } from 'antd';
import React, { Component } from 'react';
import { formatTime } from '@utils/time';
import { IInvoice } from '@interfaces/iinvoice';
import { enumToArray } from '@utils/enum-to-array';
import { Link } from 'react-router-dom';
const { Option } = Select;

interface IProps {
  dataSource?: IInvoice[];
  handleChangeStatus?: (invoiceId: number, value: any) => void;
}

interface IState {
  invoiceStatusList: string[];
}

export class DetailTableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      invoiceStatusList: enumToArray(invoiceStatus),
    };
  }
  public render() {
    const { dataSource, handleChangeStatus } = this.props;
    const { invoiceStatusList } = this.state;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const details: any[] = [];
    if (dataSource) {
      dataSource.map((item: IInvoice, index: number) => {
        const items = {
          id: item.id,
          key: `${index}-${item.code}`,
          code: item.code,
          date: formatTime(item.invoiceDate, dateFormat),
          customer: companyTypeCase(item.customer?.type, item.customer?.name),
          billedTo: item.billedTo,
          pph: currencyCase(item.pph),
          total: currencyCase(item.total),
          status: item.status || 0,
        };
        details.push(items);
      });
    }

    const columns: any[] = [
      {
        title: 'Nomor Invoice',
        dataIndex: 'code',
        sorter: false,
        render: (text: string) => <Link to={`/invoice/detail/${text}`}>{text}</Link>,
        width: 160,
      },
      {
        title: 'Tanggal Invoice',
        dataIndex: 'date',
        sorter: false,
        width: 160,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        sorter: false,
        width: 245,
      },
      {
        title: 'Ditagih Kepada',
        dataIndex: 'billedTo',
        sorter: false,
        width: 245,
      },
      {
        title: 'PPH',
        dataIndex: 'pph',
        sorter: false,
        width: 200,
      },
      {
        title: 'Total',
        dataIndex: 'total',
        sorter: false,
        width: 140,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: false,
        render: (status: number, record: IInvoice) =>
          handleChangeStatus ? (
            <Select
              placeholder="Status"
              onChange={(values: any) =>
                handleChangeStatus && record.id && handleChangeStatus(record.id, values)
              }
              value={invoiceStatus[status]}
              disabled={!handleChangeStatus}
            >
              {invoiceStatusList &&
                invoiceStatusList.map((item: string) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          ) : (
            invoiceStatus[status]
          ),
        width: 200,
      },
    ];

    return (
      <div className="bt-table small">
        <Table
          size="small"
          columns={columns}
          dataSource={details}
          pagination={false}
          locale={{
            emptyText: tableEmptyState,
          }}
          rowKey="key"
        />
      </div>
    );
  }
}

export default DetailTableComponent;
