import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchPriceLists } from './action';
import PagePriceListComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  priceLists: state.PageMasterDataReducer.priceLists,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchPriceLists: (params: any) => dispatch(fetchPriceLists(params)),
});

export const PagePriceListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PagePriceListComponent);

export default PagePriceListContainer;
