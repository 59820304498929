import { EmptyState } from '@components/molecules/empty-state';
import { dateFormat, invoicePaymentStatus, volumeType } from '@utils/constants';
import { ISuratMuatan, ISuratMuatanDetail } from '@interfaces/isurat-muatan';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Table } from 'antd';
import React, { Component } from 'react';
import { IInvoice } from '@interfaces/iinvoice';

interface IProps {
  invoice?: IInvoice;
  dataSource?: ISuratMuatan[];
}

export class DetailTableComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { invoice, dataSource } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const details: any[] = [];
    if (dataSource) {
      dataSource.map((itemSM: ISuratMuatan) => {
        const { details: suratMuatan } = itemSM;
        suratMuatan?.map((detail: ISuratMuatanDetail, index: number) => {
          const items = {
            key: `${index}-${itemSM.code}`,
            code: itemSM.code,
            policeNo: itemSM.vehicle && itemSM.vehicle.policeNo,
            orderDate: itemSM.orderDate,
            customer: companyTypeCase(itemSM.customer?.type, itemSM.customer?.name),
            recipient: itemSM.recipient?.name,
            itemName: detail.itemName,
            quantity: `${detail.quantity} ${detail.quantityType}`,
            quantityType: detail.quantityType,
            volume: detail.volume,
            volumeType: volumeType[detail.volumeType],
            total: currencyCase(detail.total),
          };
          details.push(items);
        });
      });
    }

    if (invoice) {
      const { code, statusPayment, downPayment, dateDp, customer } = invoice;
      if (statusPayment === invoicePaymentStatus['Uang Muka']) {
        const items = {
          key: 'DP Invoice',
          code: 'DP Invoice',
          policeNo: '-',
          orderDate: dateDp,
          customer: companyTypeCase(customer?.type, customer?.name),
          recipient: '-',
          itemName: `DP Invoice ${code}`,
          quantity: '-',
          quantityType: '-',
          volume: '-',
          volumeType: '-',
          total: `- ${currencyCase(downPayment)}`,
        };
        details.push(items);
      }
    }

    const columns: any[] = [
      {
        title: 'Nomor SM',
        dataIndex: 'code',
        sorter: false,
        width: 80,
      },
      {
        title: 'No Polisi',
        dataIndex: 'policeNo',
        sorter: false,
        width: 80,
      },
      {
        title: 'Tanggal',
        dataIndex: 'orderDate',
        sorter: false,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
        width: 65,
      },
      {
        title: 'Pengirim',
        dataIndex: 'customer',
        sorter: false,
        width: 120,
      },
      {
        title: 'Penerima',
        dataIndex: 'recipient',
        sorter: false,
        width: 120,
      },
      {
        title: 'Nama Barang',
        dataIndex: 'itemName',
        sorter: false,
        width: 90,
      },
      {
        title: 'Kuantum',
        dataIndex: 'quantity',
        sorter: false,
        width: 70,
      },
      {
        title: 'Jumlah',
        dataIndex: 'total',
        align: 'right',
        sorter: false,
        width: 120,
      },
    ];

    return (
      <div className="bt-table hide small">
        <Table
          size="small"
          columns={columns}
          dataSource={details}
          bordered={true}
          pagination={false}
          locale={{
            emptyText: tableEmptyState,
          }}
          rowKey="key"
        />
      </div>
    );
  }
}

export default DetailTableComponent;
