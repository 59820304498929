import loginImageUrl from '@assets/images/login-background.png';
import LogoImage from '@assets/images/logo.svg';
import { LayoutLogin } from '@components/templates/page-login';
import { IParamsLogin, IParamsRegister } from '@interfaces/iparams';
import { isLoggedIn } from '@routes/index';
import history from '@utils/history';
import { titleCase } from '@utils/string-case';
import { Alert, Button, Col, Form, Icon, Input, Row, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import FormItem from 'antd/es/form/FormItem';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';

interface IProps extends FormComponentProps {
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  successMessage: string;
  setSuccessMessage: (success: string) => void;
  login: (params: IParamsLogin) => any;
  register: (params: IParamsRegister) => any;
  resetPasswordRequest: (body: { email: string }) => any;
  isSubmit: boolean;
}

interface IState {
  authType: 'login' | 'register' | 'forgot';
  confirmDirty: boolean;
}

export class PageAuthComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      authType: 'login',
      confirmDirty: false,
    };
  }
  public componentDidMount() {
    if (isLoggedIn === 'true') {
      history.push('/dashboard');
    }
  }
  public handleCloseAlert = () => {
    const { setErrorMessage, errorMessage, setSuccessMessage, successMessage } = this.props;
    if (errorMessage) {
      setErrorMessage('');
    }
    if (successMessage) {
      setSuccessMessage('');
    }
  };
  public handleChangeAuthType = (auth: 'login' | 'register' | 'forgot') => {
    const { form } = this.props;

    this.handleCloseAlert();
    form.resetFields();
    this.setState({
      authType: auth,
    });
  };
  public validateToNextPassword = (rule: any, value: any, callback: () => void) => {
    const { form } = this.props;
    const { confirmDirty } = this.state;

    if (value && confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };
  public compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Konfirmasi password tidak cocok!');
    } else {
      callback();
    }
  };
  public handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const {
      form: { validateFields, resetFields },
      login,
      register,
      resetPasswordRequest,
    } = this.props;
    const { authType } = this.state;
    validateFields(async (error: any, values: any) => {
      if (!error) {
        if (authType === 'login') {
          const params: IParamsLogin = {
            email: values.email,
            isRemember: values.remember,
            password: values.password,
          };
          await login(params);
        } else if (authType === 'register') {
          const params: IParamsRegister = {
            email: values.email,
            name: values.name,
            password: values.password,
            password_confirmation: values.confirmPassword,
          };
          await register(params);
          resetFields();
        } else {
          const body: any = {
            email: values.email,
          };
          await resetPasswordRequest(body);
          resetFields();
        }
      }
    });
  };
  public render() {
    const { errorMessage, successMessage, isSubmit } = this.props;
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { authType } = this.state;

    return (
      <LayoutLogin
        loginLogo={LogoImage}
        loginTitleName="Minofa System"
        loginTitleDesc=""
        loginImageUrl={loginImageUrl}
      >
        <Spin spinning={isSubmit}>
          <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${titleCase(authType)}`} />
          {errorMessage && errorMessage !== '' && (
            <Alert
              message={errorMessage}
              type="error"
              closable={true}
              afterClose={this.handleCloseAlert}
            />
          )}
          {successMessage && successMessage !== '' && (
            <Alert
              message={successMessage}
              type="success"
              closable={true}
              afterClose={this.handleCloseAlert}
            />
          )}
          <Form onSubmit={this.handleSubmit} className="login-form">
            {authType === 'register' && (
              <Fragment>
                <FormItem>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Nama lengkap harus diisi!' }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="text"
                      placeholder="Nama Lengkap"
                    />,
                  )}
                </FormItem>
              </Fragment>
            )}
            <FormItem>
              {getFieldDecorator('email', {
                rules: [
                  { message: 'Email tidak valid!', type: 'email' },
                  { message: 'Email harus diisi!', required: true },
                ],
              })(
                <Input
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />,
              )}
            </FormItem>
            {authType !== 'forgot' && (
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Password harus diisi!' },
                    { validator: this.validateToNextPassword },
                  ],
                })(
                  <Input.Password
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                  />,
                )}
              </FormItem>
            )}
            {authType === 'register' && (
              <Fragment>
                <FormItem>
                  {getFieldDecorator('confirmPassword', {
                    rules: [
                      { message: 'Konfirmasi password harus diisi!', required: true },
                      { validator: this.compareToFirstPassword },
                    ],
                  })(
                    <Input.Password
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Konfirmasi Password"
                    />,
                  )}
                </FormItem>
              </Fragment>
            )}
            {authType === 'login' && (
              <FormItem>
                <Button
                  type="link"
                  style={{ float: 'right' }}
                  onClick={() => this.handleChangeAuthType('forgot')}
                >
                  Lupa Password
                </Button>
                <Button type="primary" htmlType="submit" className="login-form-button" block={true}>
                  Login
                </Button>
                Belum punya akun?
                <Button type="link" onClick={() => this.handleChangeAuthType('register')}>
                  Daftar
                </Button>
              </FormItem>
            )}
            {authType === 'register' && (
              <FormItem>
                <Button type="primary" htmlType="submit" className="login-form-button" block={true}>
                  Daftar
                </Button>
                Sudah punya akun?
                <Button type="link" onClick={() => this.handleChangeAuthType('login')}>
                  Login
                </Button>
              </FormItem>
            )}
            {authType === 'forgot' && (
              <FormItem>
                <Row gutter={16}>
                  <Col lg={12}>
                    <Button onClick={() => this.handleChangeAuthType('login')} block={true}>
                      Kembali Ke Login
                    </Button>
                  </Col>
                  <Col lg={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      block={true}
                    >
                      Reset Password
                    </Button>
                  </Col>
                </Row>
              </FormItem>
            )}
          </Form>
        </Spin>
      </LayoutLogin>
    );
  }
}

export default Form.create<IProps>()(PageAuthComponent);
