import { fetchSuratMuatan } from '@pages/page-account-receivable/page-surat-muatan/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageSuratMuatanDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  suratMuatan: state.PageAccountReceivableReducer.suratMuatan,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchSuratMuatan: (code: string) => dispatch(fetchSuratMuatan(code)),
});

export const PageSuratMuatanDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageSuratMuatanDetailComponent);

export default PageSuratMuatanDetailContainer;
