import { IDaftarMuatan } from '@interfaces/idaftar-muatan';

const urlDaftarMuatan = 'daftar_muatan';

export const getDaftarMuatan = (code: string) => ({
  method: 'GET',
  url: `${urlDaftarMuatan}/${code}`,
});

export const getDaftarMuatans = (params: any) => ({
  method: 'GET',
  params,
  url: urlDaftarMuatan,
});

export const createDaftarMuatan = (body: IDaftarMuatan) => ({
  body,
  method: 'POST',
  url: `${urlDaftarMuatan}`,
});

export const updateDaftarMuatan = (code: string, body: IDaftarMuatan) => ({
  body,
  method: 'PUT',
  url: `${urlDaftarMuatan}/${code}`,
});

export const deleteDaftarMuatan = (code: string) => ({
  method: 'DELETE',
  url: `${urlDaftarMuatan}/${code}`,
});

export default {
  getDaftarMuatan,
  getDaftarMuatans,
  createDaftarMuatan,
  updateDaftarMuatan,
  deleteDaftarMuatan,
};
