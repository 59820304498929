import { ICashFlow } from '@interfaces/icash-flow';
import {
  createCashFlow,
  fetchCashFlow,
  updateCashFlow,
  fetchCoaList,
} from '@pages/page-account-payable/page-cash-flow/action';
import { fetchDaftarMuatanList } from '@pages/page-account-payable/page-daftar-muatan/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageCashFlowFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  cashFlow: state.PageAccountPayableReducer.cashFlow,
  daftarMuatanList: state.PageAccountPayableReducer.daftarMuatanList,
  coaList: state.PageAccountPayableReducer.coaList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchDaftarMuatanList: (params: any) => dispatch(fetchDaftarMuatanList(params)),
  fetchCashFlow: (code: string) => dispatch(fetchCashFlow(code)),
  createCashFlow: (body: ICashFlow) => dispatch(createCashFlow(body)),
  updateCashFlow: (code: string, body: ICashFlow) => dispatch(updateCashFlow(code, body)),
  fetchCoaList: (params?: any) => dispatch(fetchCoaList(params)),
});

export const PageCashFlowFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCashFlowFormComponent);

export default PageCashFlowFormContainer;
