import { dateFormat, emptyParams, FormType, costStatus, DataName } from '@utils/constants';
import { ITransitCost } from '@interfaces/itransit-cost';
import { IVehicle } from '@interfaces/ivehicle';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase, costStatusCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { IDaftarMuatan, IDaftarMuatanDetail } from '@interfaces/idaftar-muatan';
import { enumToArray } from '@utils/enum-to-array';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchVehicleList: (params: any) => void;
  vehicleList: IVehicle[];
  fetchDaftarMuatanList: (params: any) => void;
  daftarMuatanList: IDaftarMuatan[];
  fetchTransitCostList: () => void;
  transitCostList: ITransitCost[];
  createTransitCost: (body: ITransitCost) => void;
  updateTransitCost: (code: string, body: ITransitCost) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
  daftarMuatan?: IDaftarMuatan;
  transitCost?: ITransitCost;
  totalTransitCost: number;
  costStatusList: string[];
}
export class PageTransitCostFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      formType: code ? 'edit' : 'add',
      totalTransitCost: 0,
      costStatusList: enumToArray(costStatus),
    };
  }
  public componentDidMount = () => {
    const { fetchTransitCostList, fetchVehicleList } = this.props;
    fetchTransitCostList();
    fetchVehicleList({ ...emptyParams, isMinofaVendor: true });
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.transitCostList !== this.props.transitCostList) {
      const { transitCostList, match } = this.props;
      const { code } = match.params;
      const transitCost = transitCostList.find((item: ITransitCost) => item.code === code);
      const sameDM = transitCostList.filter(
        (item: ITransitCost) =>
          item.daftarMuatanId === transitCost?.daftarMuatanId && item.code !== code,
      );
      let totalTransitCost = 0;
      sameDM.map((item: ITransitCost) => {
        totalTransitCost += item.cost;
      });

      this.setState({ transitCost, daftarMuatan: transitCost?.daftar_muatan, totalTransitCost });
    }
  };
  public handleSubmit = () => {
    const { form, createTransitCost, updateTransitCost } = this.props;
    const { formType, transitCost } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataTransitCost: ITransitCost = {
          ...transitCost,
          vehiclesId: values.vehicle,
          daftarMuatanId: values.daftarMuatan,
          suratMuatanIds: values.suratMuatan?.toString(),
          date: formatTime(values.date, 'YYYY-MM-DD'),
          cost: Number(values.cost),
          updated_by: currentUser,
        };

        if (formType === 'edit' && transitCost?.code) {
          await updateTransitCost(transitCost?.code, {
            ...dataTransitCost,
            status: Number(costStatus[values.status]),
          });
        } else {
          await createTransitCost({
            ...dataTransitCost,
            status: costStatus.Unpaid,
            created_by: currentUser,
          });
        }
        history.push('/transit-cost');
      }
    });
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public onSearchDaftarMuatan = (value: any) => {
    const { fetchDaftarMuatanList } = this.props;
    if (value.length === 6) {
      fetchDaftarMuatanList({
        ...emptyParams,
        code: value,
      });
    }
  };
  public onSelectDaftarMuatan = (value: any) => {
    const { daftarMuatanList, form, transitCostList, match } = this.props;
    const { setFieldsValue } = form;
    const { code } = match.params;

    const data = daftarMuatanList.find((item: IDaftarMuatan) => value === item.id);
    const sameDM = transitCostList.filter(
      (item: ITransitCost) => item.daftarMuatanId === data?.id && item.code !== code,
    );

    let totalTransitCost = 0;
    sameDM.map((item: ITransitCost) => {
      totalTransitCost += item.cost;
    });

    this.setState({ daftarMuatan: data, totalTransitCost });
    setFieldsValue({ invoicePrice: data?.totalPrice, suratMuatan: [] });
  };
  public handleValidateCost = (rule: any, value: string, callback: any) => {
    const { form } = this.props;
    const { getFieldValue } = form;
    const invoicePrice = Number(getFieldValue('invoicePrice'));
    const otherCost = Number(getFieldValue('totalTransitCost'));
    const cost = Number(value) + otherCost;
    if (cost >= invoicePrice) {
      callback('Biaya Transit harus lebih kecil dari harga invoice!');
    }
    callback();
  };
  public render() {
    const { isFetching, match, form, transitCostList, daftarMuatanList, vehicleList } = this.props;
    const { formType, daftarMuatan, totalTransitCost, costStatusList } = this.state;
    const { getFieldDecorator } = form;

    const { code } = match.params;
    const transitCost = transitCostList.find((item: ITransitCost) => item.code === code);

    const title = formType && formType === 'edit' ? 'Ubah Transit Cost' : 'Buat Transit Cost';
    const loading = isLoading(isFetching, DataName.TRANSIT_COST);
    const loadingDM = isLoading(isFetching, DataName.DAFTAR_MUATAN);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Daftar Muatan">
                  {getFieldDecorator('daftarMuatan', {
                    initialValue: transitCost?.daftar_muatan?.id,
                    rules: [{ message: 'Daftar Muatan harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Daftar Muatan"
                      optionFilterProp="children"
                      onSearch={this.onSearchDaftarMuatan}
                      onChange={this.onSelectDaftarMuatan}
                      loading={loadingDM}
                    >
                      {daftarMuatanList &&
                        daftarMuatanList.map((item: IDaftarMuatan) => (
                          <Option key={item.code} value={item.id}>
                            {item.code} | {item.vehicle?.policeNo} |{' '}
                            {companyTypeCase(
                              item.vehicle?.driver?.vendor?.type,
                              item.vehicle?.driver?.vendor?.name,
                            )}{' '}
                            | {item.vehicle?.driver?.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Total Harga (Invoice)">
                  {getFieldDecorator('invoicePrice', {
                    initialValue: transitCost?.daftar_muatan?.totalPrice ?? '',
                  })(<Input prefix="Rp" placeholder="Total Harga" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Biaya Transit">
                  {getFieldDecorator('cost', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: transitCost?.cost ?? '',
                    rules: [
                      { message: 'Biaya Transit hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { validator: this.handleValidateCost },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Biaya Transit" />)}
                </Form.Item>
                <Form.Item label="Tanggal">
                  {getFieldDecorator('date', {
                    initialValue: transitCost?.date ? moment(transitCost.date) : moment(),
                    rules: [{ required: true, message: 'Tanggal harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Surat Muatan">
                  {getFieldDecorator('suratMuatan', {
                    initialValue: transitCost
                      ? transitCost?.suratMuatanIds?.split(`,`).map((x) => +x)
                      : [],
                    rules: [{ message: 'Surat Muatan harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      mode="multiple"
                      showSearch={true}
                      placeholder="Pilih Surat Muatan"
                      optionFilterProp="children"
                    >
                      {daftarMuatan &&
                        daftarMuatan.details?.map((item: IDaftarMuatanDetail) => (
                          <Option key={item.details.id} value={item.details.id}>
                            {item.details.code}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Total Biaya Transit Lain (Daftar Muatan Sama)">
                  {getFieldDecorator('totalTransitCost', {
                    initialValue: totalTransitCost ?? '',
                  })(<Input prefix="Rp" placeholder="Total Biaya Transit" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Kendaraan Minofa">
                  {getFieldDecorator('vehicle', {
                    initialValue: transitCost?.vehicle?.id ?? '',
                    rules: [{ message: 'Kendaraan Minofa harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Kendaraan Minofa"
                      optionFilterProp="children"
                    >
                      {vehicleList &&
                        vehicleList.map((item: IVehicle) => (
                          <Option key={item.code} value={item.id}>
                            {item.policeNo} | {item.vehicle_type?.name} |{' '}
                            {companyTypeCase(item.driver?.vendor?.type, item.driver?.vendor?.name)}{' '}
                            | {item.driver?.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                {formType === 'edit' && (
                  <Form.Item label="Status">
                    {getFieldDecorator('status', {
                      initialValue: costStatus[transitCost?.status ?? 0],
                      rules: [{ message: 'Status harus dipilih!', required: formType === 'edit' }],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Select placeholder="Status">
                        {costStatusList &&
                          costStatusList.map((item: string, index: number) => (
                            <Option key={item} value={item}>
                              {costStatusCase(index)}
                            </Option>
                          ))}
                      </Select>,
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
            <br />
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageTransitCostFormComponent);
