import { dateFormat, dateFormatSearch, defaultPageSize } from '@utils/constants';
import { Button, DatePicker, Icon, Input, Select } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import { startCase } from 'lodash';
import moment from 'moment';
import React from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export const tableChangeParams = (
  pagination: PaginationConfig,
  filters: any,
  sorter: any,
  tableParams: any,
) => {
  const page: number = (pagination && pagination.current) || 1;
  const limit: number = (pagination && pagination.pageSize) || defaultPageSize;
  const sortOrder: string = sorter && sorter.order === 'descend' ? 'desc' : 'asc';
  const orderBy: string | undefined = sorter && sorter.field ? sorter.field : tableParams?.orderBy;
  const sort: string | undefined = sorter && sorter.field ? sortOrder : tableParams?.sort;

  const filterData = {};
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key][0]) {
        if (
          [
            'date',
            'orderDate',
            'smDate',
            'dmDate',
            'invoiceDate',
            'oneYearTaxDate',
            'fiveYearTaxDate',
            'updated_at',
          ].includes(key)
        ) {
          filterData[key] = filters[key][0].format(dateFormatSearch);
        } else if (key === 'dateRange') {
          const data = filters[key][0];
          filterData['dateFrom'] = data[0].format(dateFormatSearch);
          filterData['dateTo'] = data[1].format(dateFormatSearch);
        } else if (key === 'status') {
          filterData[key] = `[${filters[key][0]}]`;
        } else {
          filterData[key] = filters[key][0];
        }
      }
    });
  }

  const params = {
    ...tableParams,
    page,
    limit,
    orderBy,
    sort,
    ...filterData,
  };

  return params;
};
export const filter = (key: string) => filterProps(key, searchFilter, resetFilter);
const searchFilter = (confirm: () => void) => {
  confirm();
};
const resetFilter = (clearFilters: () => void) => {
  clearFilters();
};
let searchInput: Input | null;
const filterProps = (
  key: any,
  handleSearch: (confirm: () => void) => void,
  handleReset: (clearFilters: () => void) => void,
) => ({
  key,
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
    let content;
    if (
      [
        'date',
        'orderDate',
        'smDate',
        'dmDate',
        'invoiceDate',
        'oneYearTaxDate',
        'fiveYearTaxDate',
        'updated_at',
      ].includes(key)
    ) {
      content = (
        <DatePicker
          value={selectedKeys[0] || null}
          onChange={(date) => {
            setSelectedKeys(date ? [date] : []);
            handleSearch(confirm);
          }}
          format={dateFormat}
          placeholder={`Search ${startCase(key)}`}
          style={{ width: 220, marginBottom: 8, display: 'block' }}
        />
      );
    } else if (key === 'dateRange') {
      content = (
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('year')],
          }}
          onChange={(date) => {
            setSelectedKeys(date ? [date] : []);
            handleSearch(confirm);
          }}
          format={dateFormat}
          style={{ width: 220, marginBottom: 8, display: 'block' }}
        />
      );
    } else if (key === 'status') {
      content = (
        <Select
          placeholder={`Search ${startCase(key)}`}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            handleSearch(confirm);
          }}
          style={{ width: 220, marginBottom: 8, display: 'block' }}
        >
          <Option value="0">Purchase Order</Option>
          <Option value="1">Tanda Terima</Option>
          <Option value="2">Surat Muatan</Option>
          <Option value="3">Daftar Muatan</Option>
          <Option value="4">Invoice</Option>
        </Select>
      );
    } else if (key === 'statusPayment') {
      content = (
        <Select
          placeholder={`Search ${startCase(key)}`}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            handleSearch(confirm);
          }}
          style={{ width: 220, marginBottom: 8, display: 'block' }}
        >
          <Option value="0">Belum Bayar</Option>
          <Option value="1">Uang Muka</Option>
          <Option value="2">Lunas</Option>
        </Select>
      );
    } else {
      content = (
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          placeholder={`Search ${startCase(key)}`}
          style={{ width: 220, marginBottom: 8, display: 'block' }}
        />
      );
    }

    return (
      <div style={{ padding: 8 }}>
        {content}
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon="search"
          size="small"
          style={{ width: 106, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 106 }}>
          Reset
        </Button>
      </div>
    );
  },
  filterIcon: (filtered: any) => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilterDropdownVisibleChange: (visible: any) => {
    if (visible) {
      setTimeout(() => searchInput && searchInput.select());
    }
  },
});

export const generateTable = (isPrint: boolean, data?: any[]) => {
  if (!isPrint || !data) {
    return data;
  }

  const dataLength = data.length;
  const minData = 7;
  if (dataLength >= minData) {
    return data;
  }

  const emptyData = minData - dataLength;
  return [...data, ...Array(emptyData).fill({})];
};
