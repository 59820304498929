import { EmptyState } from '@components/molecules/empty-state';
import { defaultParams, pageSizeOptions, showSizeChanger, dateTimeFormat } from '@utils/constants';
import { IMeta } from '@interfaces/imeta';
import { IVendor } from '@interfaces/ivendor';
import { companyTypeCase } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { formatTime } from '@utils/time';

interface IProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  dataSource?: any;
  meta: IMeta;
}

interface IState {
  tableParams: any;
}

export class TableComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParams: defaultParams,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;
    fetchDataSource(tableParams);
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params });
    fetchDataSource(params);
  };
  public render() {
    const { dataSource, isFetching, meta } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: 'Kode Vendor',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        fixed: 'left',
        render: (text: string) => <Link to={`/vendor/detail/${text}`}>{text}</Link>,
        width: 180,
      },
      {
        title: 'Nama Vendor',
        dataIndex: 'name',
        ...filter('name'),
        sorter: true,
        render: (text: any, record: IVendor) => companyTypeCase(record.type, text),
        width: 250,
      },
      {
        title: 'Telepon',
        dataIndex: 'phone',
        ...filter('phone'),
        sorter: true,
        width: 150,
      },
      {
        title: 'Nama PIC',
        dataIndex: 'picName',
        ...filter('picName'),
        sorter: true,
        render: (text: any) => text || '-',
      },
      {
        title: 'Telepon PIC',
        dataIndex: 'picPhone',
        ...filter('picPhone'),
        sorter: true,
        render: (text: any) => text || '-',
      },
      {
        title: 'PIC Operasional',
        dataIndex: 'picOperational',
        ...filter('picOperational'),
        sorter: true,
        width: 180,
      },
      {
        title: 'File MOU',
        dataIndex: 'fileMOU',
        sorter: true,
        render: (text: any) =>
          text ? (
            <a target="_blank" href={text}>
              File MOU
            </a>
          ) : (
            '-'
          ),
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
        width: 200,
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
        width: 200,
      },
      {
        title: 'Action',
        render: (text: any, record: IVendor) => (
          <Fragment>
            <Link to={`/vendor/form/${record.code}`}>Ubah</Link>
            {/* <Divider type="vertical" />
            <Button type="link">Hapus</Button> */}
          </Fragment>
        ),
        width: 100,
      },
    ];

    return (
      <div className="bt-table">
        <Table
          size="small"
          onChange={this.onChangeTable}
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={
            meta && {
              current: meta.current_page,
              pageSize: meta.per_page,
              pageSizeOptions,
              showSizeChanger,
              total: meta.total,
            }
          }
          locale={{
            emptyText: tableEmptyState,
          }}
          loading={isFetching}
          scroll={{ x: 1700 }}
          rowKey="id"
        />
      </div>
    );
  }
}

export default TableComponent;
