import Recipient from '@adaptors-api/recipient-api';
import { IMeta } from '@interfaces/imeta';
import { IRecipient } from '@interfaces/irecipient';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import { EnumLoading, DataName } from '@utils/constants';
import RequestAPI from '@utils/request-api';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.RECIPIENT));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.RECIPIENT));
};

export const setRecipient = (recipient?: IRecipient) => ({
  recipient,
  type: ActionTypes.PAGE_RECIPIENT_SET_RECIPIENT,
});

export const setRecipientList = (recipientList: IRecipient[]) => ({
  recipientList,
  type: ActionTypes.PAGE_RECIPIENT_SET_RECIPIENT_LIST,
});

export const fetchRecipient = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setRecipient(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(Recipient.getRecipient(code))
    .then((response: any) => {
      const { data } = response.data;
      const recipient: IRecipient = data;
      dispatch(setRecipient(recipient));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchRecipientList = (params?: any, isSetMeta: boolean = true) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Recipient.getRecipients(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const recipientList: IRecipient[] = data;
      const metaData: IMeta = meta;
      dispatch(setRecipientList(recipientList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createRecipient = (body: IRecipient) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Recipient.createRecipient(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Penerima ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Penerima Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateRecipient = (code: string, body: IRecipient) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Recipient.updateRecipient(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Penerima ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Penerima Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteRecipient = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(Recipient.deleteRecipient(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Penerima ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Penerima Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
