import { IMeta } from '@interfaces/imeta';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchSuratMuatanList: (params: any) => void;
  deleteSuratMuatan: (code: string) => void;
  suratMuatanList: ISuratMuatan[];
  isFetching: string[];
  metaData: IMeta;
}

const title = 'Kelola Surat Muatan';
export class PageSuratMuatanComponent extends Component<IProps, any> {
  public render() {
    const {
      fetchSuratMuatanList,
      deleteSuratMuatan,
      suratMuatanList,
      isFetching,
      metaData,
    } = this.props;
    const loading = isLoading(isFetching, DataName.SURAT_MUATAN);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Daftar Surat Muatan"
          bordered={false}
          extra={
            <Link to={`/surat-muatan/form`}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Surat Muatan
              </Button>
            </Link>
          }
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchSuratMuatanList}
            deleteData={deleteSuratMuatan}
            dataSource={suratMuatanList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PageSuratMuatanComponent;
