import { EmptyState } from '@components/molecules/empty-state';
import {
  dateFormat,
  defaultParams,
  pageSizeOptions,
  roleType,
  showSizeChanger,
  dateTimeFormat,
} from '@utils/constants';
import { ICashFlow, ICashFlowRecap } from '@interfaces/icash-flow';
import { IMeta } from '@interfaces/imeta';
import { getModule, getProfile } from '@utils/request-api';
import { currencyCase } from '@utils/string-case';
import { filter, tableChangeParams } from '@utils/table';
import { formatTime } from '@utils/time';
import {
  Button,
  Divider,
  Popconfirm,
  Table,
  Modal,
  DatePicker,
  Card,
  Icon,
  Input,
  List,
} from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Form, { FormComponentProps } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import history from '@utils/history';
const { RangePicker } = DatePicker;

enum enumFormAction {
  create = 'create',
  recap = 'recap',
}

interface IProps extends FormComponentProps {
  isFetching: boolean;
  fetchDataSource: (params: any) => void;
  fetchDetail: (params: any, isDetail: boolean) => void;
  createData: (body: ICashFlowRecap) => void;
  updateData: (code: string, body: ICashFlowRecap) => void;
  deleteData: (code: string) => void;
  dataSource?: any;
  dataDetail?: any;
  meta: IMeta;
}

interface IState {
  tableParams: any;
  showForm: boolean;
  formAction: string;
  selectedDate: string;
  fromDate: string;
  toDate: string;
  total: number;
  selectedRecap?: string;
}

export class TableRecapComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableParams: defaultParams,
      showForm: false,
      formAction: enumFormAction.create,
      selectedDate: formatTime(moment(), 'YYYY-MM-DD'),
      fromDate: formatTime(moment(), 'YYYY-MM-DD'),
      toDate: formatTime(moment().add('days', 1), 'YYYY-MM-DD'),
      total: 0,
    };
  }
  public componentDidMount = () => {
    const { fetchDataSource, fetchDetail } = this.props;
    const { tableParams, selectedDate } = this.state;
    fetchDataSource(tableParams);
    fetchDetail({ postingDate: selectedDate }, true);
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.dataDetail !== this.props.dataDetail) {
      const { dataDetail } = this.props;
      let total = 0;
      if (dataDetail) {
        dataDetail.map((item: ICashFlow) => {
          total += item.total;
        });
      }
      this.setState({ total });
    }
  };
  public onChangeTable = (pagination: PaginationConfig, filters: any, sorter: any) => {
    const { fetchDataSource } = this.props;
    const { tableParams } = this.state;

    const params = tableChangeParams(pagination, filters, sorter, tableParams);
    this.setState({ tableParams: params });
    fetchDataSource(params);
  };
  public handleDeleteData = (code: string | undefined) => {
    const { fetchDataSource, deleteData } = this.props;
    const { tableParams } = this.state;
    if (code) {
      deleteData(code);
      fetchDataSource(tableParams);
    }
  };
  public handleSubmit = () => {
    const { form, createData, updateData, fetchDataSource, dataSource } = this.props;
    const { selectedRecap, tableParams, formAction, fromDate, toDate } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        if (formAction === enumFormAction.create) {
          const currentUser = getProfile().employee.fullName;
          const dataCashFlowRecap: ICashFlowRecap = {
            postingDate: formatTime(values.postingDate, 'YYYY-MM-DD'),
            total: Number(values.total),
            updated_by: currentUser,
          };
          const availableRecap: ICashFlowRecap = dataSource?.find(
            (item: ICashFlowRecap) =>
              formatTime(item.postingDate, 'YYYY-MM-DD') ===
              formatTime(values.postingDate, 'YYYY-MM-DD'),
          );
          const code = selectedRecap ? selectedRecap : availableRecap?.code;
          if (code) {
            await updateData(code, dataCashFlowRecap);
          } else {
            await createData({ ...dataCashFlowRecap, created_by: currentUser });
          }
          this.setState({
            selectedRecap: undefined,
            showForm: false,
          });
          fetchDataSource(tableParams);
        } else {
          history.push(`/cash-flow-recap/detail?from=${fromDate}&to=${toDate}`);
        }
      }
    });
  };
  public handleChangeDate = (date: Moment | null) => {
    const { fetchDetail } = this.props;
    const selectedDate = formatTime(date || moment(), 'YYYY-MM-DD');

    this.setState({ selectedDate });
    fetchDetail({ postingDate: selectedDate }, true);
  };
  public handleChangeDateRange = (date: RangePickerValue) => {
    const fromDate = formatTime(date[0] || moment(), 'YYYY-MM-DD');
    const toDate = formatTime(date[1] || moment(), 'YYYY-MM-DD');

    this.setState({ fromDate, toDate });
  };
  public handleAdd = async () => {
    const { fetchDetail } = this.props;
    await fetchDetail({ postingDate: formatTime(moment(), 'YYYY-MM-DD') }, true);
    this.setState({
      selectedRecap: undefined,
      selectedDate: formatTime(moment(), 'YYYY-MM-DD'),
      showForm: true,
      formAction: enumFormAction.create,
    });
  };
  public handleRecap = async () => {
    this.setState({
      fromDate: formatTime(moment(), 'YYYY-MM-DD'),
      toDate: formatTime(moment().add('days', 1), 'YYYY-MM-DD'),
      showForm: true,
      formAction: enumFormAction.recap,
    });
  };
  public handleUpdate = async (recap: ICashFlowRecap) => {
    const { fetchDetail } = this.props;
    await fetchDetail({ postingDate: recap.postingDate }, true);
    this.setState({
      selectedRecap: recap.code,
      selectedDate: recap.postingDate,
      showForm: true,
    });
  };
  public render() {
    const { dataSource, dataDetail, isFetching, meta, form } = this.props;
    const { showForm, formAction, total, selectedDate, fromDate, toDate, selectedRecap } =
      this.state;
    const { getFieldDecorator } = form;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any = [
      {
        title: 'Kode Rekap',
        dataIndex: 'code',
        ...filter('code'),
        sorter: true,
        render: (text: string, record: ICashFlowRecap) => (
          <Link to={`/cash-flow-recap/detail/${record.code}`}>{text}</Link>
        ),
      },
      {
        title: 'Tanggal Posting',
        dataIndex: 'postingDate',
        ...filter('postingDate'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateFormat) : '-'),
      },
      {
        title: 'Total',
        dataIndex: 'total',
        sorter: true,
        render: (text: any) => currencyCase(text),
      },
      {
        title: 'Diupdate Oleh',
        dataIndex: 'updated_by',
        ...filter('updated_by'),
        sorter: true,
        render: (text: string) => text || '-',
      },
      {
        title: 'Diupdate Pada',
        dataIndex: 'updated_at',
        ...filter('updated_at'),
        sorter: true,
        render: (text: any) => (text ? formatTime(text, dateTimeFormat) : '-'),
      },
      {
        title: 'Action',
        render: (text: any, record: ICashFlowRecap) => (
          <Fragment>
            <Button type="link" onClick={() => this.handleUpdate(record)}>
              Ubah
            </Button>
            {getModule(roleType.DIR) && (
              <Fragment>
                <Divider type="vertical" />
                <Popconfirm
                  title="Apakah Anda yakin untuk menghapus rekap cash flow ini?"
                  onConfirm={() => this.handleDeleteData(record.code)}
                  okText="Iya"
                  cancelText="Tidak"
                >
                  <Button type="link">Hapus</Button>
                </Popconfirm>
              </Fragment>
            )}
          </Fragment>
        ),
        width: 200,
      },
    ];

    const formLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return (
      <Card
        title="Rekap Cash Flow"
        bordered={false}
        extra={[
          <Button key={1} type="primary" onClick={this.handleRecap} style={{ marginRight: 8 }}>
            <Icon type="calendar" />
            Rekap Cash Flow
          </Button>,
          <Button key={2} type="primary" onClick={this.handleAdd}>
            <Icon type="plus" />
            Buat Rekap Cash Flow Harian
          </Button>,
        ]}
      >
        <div className="bt-table">
          <Table
            size="small"
            onChange={this.onChangeTable}
            columns={columns}
            dataSource={dataSource}
            bordered={true}
            pagination={
              meta && {
                current: meta.current_page,
                pageSize: meta.per_page,
                pageSizeOptions,
                showSizeChanger,
                total: meta.total,
              }
            }
            locale={{
              emptyText: tableEmptyState,
            }}
            loading={isFetching}
            rowKey="id"
          />
        </div>
        <Modal
          title={
            formAction === enumFormAction.create ? 'Buat Rekap Cash Flow' : 'Lihat Rekap Cash Flow'
          }
          visible={showForm}
          width={formAction === enumFormAction.create ? 700 : 300}
          okText={formAction === enumFormAction.create ? 'Simpan' : 'Lihat'}
          cancelText="Batal"
          onCancel={() => this.setState({ showForm: false })}
          onOk={this.handleSubmit}
        >
          <Form layout="horizontal">
            {formAction === enumFormAction.create ? (
              <Fragment>
                <Form.Item label="Tanggal Rekap" {...formLayout}>
                  {getFieldDecorator('postingDate', {
                    initialValue: moment(selectedDate) || moment(),
                    rules: [{ required: true, message: 'Tanggal Rekap harus dipilih!' }],
                  })(
                    <DatePicker
                      format={dateFormat}
                      onChange={this.handleChangeDate}
                      disabled={selectedRecap ? true : false}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Total" {...formLayout}>
                  {getFieldDecorator('total', {
                    initialValue: total,
                    rules: [{ required: true, message: 'Total harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Total" disabled={true} />)}
                </Form.Item>
                <List
                  itemLayout="horizontal"
                  dataSource={dataDetail}
                  renderItem={(item: ICashFlow) => (
                    <List.Item>
                      <List.Item.Meta
                        title={`${item.code} - ${currencyCase(item.total)}`}
                        description={`${item.description} ${item.dmCode ? `(${item.dmCode})` : ''}`}
                      />
                      <div>
                        <b>{`${item.coa?.code} | ${item.coa?.accountName} | ${item.coa?.normalBalance}`}</b>
                      </div>
                    </List.Item>
                  )}
                />
              </Fragment>
            ) : (
              <Fragment>
                <Form.Item label="Tanggal Rekap">
                  {getFieldDecorator('postingDateFrom', {
                    initialValue: [
                      moment(fromDate) || moment(),
                      moment(toDate) || moment().add('days', 1),
                    ],
                    rules: [{ required: true, message: 'Tanggal Rekap Awal harus dipilih!' }],
                  })(<RangePicker format={dateFormat} onChange={this.handleChangeDateRange} />)}
                </Form.Item>
              </Fragment>
            )}
          </Form>
        </Modal>
      </Card>
    );
  }
}

export default Form.create<IProps>()(TableRecapComponent);
