import { IParamsLogin, IParamsRegister } from '@interfaces/iparams';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import {
  login,
  register,
  resetPasswordRequest,
  setErrorMessage,
  setSuccessMessage,
} from './action';
import PageAuthComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  errorMessage: state.PageAuthReducer.errorMessage,
  isSubmit: state.PageAuthReducer.isSubmit,
  successMessage: state.PageAuthReducer.successMessage,
});

export const mapDispatchToProps = (dispatch: any) => ({
  login: (params: IParamsLogin) => dispatch(login(params)),
  register: (params: IParamsRegister) => dispatch(register(params)),
  resetPasswordRequest: (body: { email: string }) => dispatch(resetPasswordRequest(body)),
  setErrorMessage: (error: string) => dispatch(setErrorMessage(error)),
  setSuccessMessage: (success: string) => dispatch(setSuccessMessage(success)),
});

export const PageAuthContainer = connect(mapStateToProps, mapDispatchToProps)(PageAuthComponent);

export default PageAuthContainer;
