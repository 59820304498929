import { DataName, dateFormat } from '@utils/constants';
import { IDriver } from '@interfaces/idriver';
import { goBack } from '@utils/history';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchDriver: (code: string) => void;
  driver?: IDriver;
  isFetching: string[];
  match: any;
}

const title = 'Detail Supir';
export class PageDriverDetailComponent extends Component<IProps, any> {
  public componentDidMount = () => {
    const { match, fetchDriver } = this.props;
    fetchDriver(match.params.code);
  };
  public render() {
    const { driver, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.DRIVER);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <Link key="1" to={`/driver/form${driver && `/${driver.code}`}`}>
              <Button type="primary">
                <Icon type="edit" />
                Ubah Supir
              </Button>
            </Link>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {driver && (
            <Descriptions
              title={`Supir: ${driver.code}`}
              layout="vertical"
              bordered={true}
              size="small"
            >
              <Descriptions.Item label="Nama Driver">{driver.fullName}</Descriptions.Item>
              <Descriptions.Item label="Vendor">
                {driver.vendor && companyTypeCase(driver.vendor.type, driver.vendor.name)}
              </Descriptions.Item>
              <Descriptions.Item label="Telepon">{driver.phone || '-'}</Descriptions.Item>
              <Descriptions.Item label="Alamat" span={3}>
                {driver.address || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="KTP">
                {driver.imageKTP ? (
                  <a target="_blank" href={driver.imageKTP}>
                    {driver.KTP}
                  </a>
                ) : (
                  driver.KTP || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="SIM">
                {driver.imageSIM ? (
                  <a target="_blank" href={driver.imageSIM}>
                    {driver.SIM}
                  </a>
                ) : (
                  driver.SIM || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="NPWP">
                {driver.imageNPWP ? (
                  <a target="_blank" href={driver.imageNPWP}>
                    {driver.NPWP}
                  </a>
                ) : (
                  driver.NPWP || '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Masa Berlaku SIM">
                {formatTime(driver.expiryDateSIM, dateFormat) || '-'}
              </Descriptions.Item>
              <Descriptions.Item
                label="Remarks"
                className={!isEmpty(driver.remarks) ? 'warning' : ''}
                span={3}
              >
                {driver.remarks || '-'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageDriverDetailComponent;
