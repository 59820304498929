import {
  DataName,
  dateFormat,
  defaultUploadImage,
  fileType,
  maritalStatus,
} from '@utils/constants';
import { IEmployee } from '@interfaces/iemployee';
import { IRole } from '@interfaces/irole';
import { IUser } from '@interfaces/iuser';
import { enumToArray } from '@utils/enum-to-array';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { titleCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Col, DatePicker, Icon, Input, PageHeader, Row, Select, Upload } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import InputMask from 'react-input-mask';
import { getBase64, getUploadedFile } from '@utils/upload';
import { isLoading } from '@utils/loading';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IProps extends FormComponentProps {
  fetchUser: (code: string) => void;
  user?: IUser;
  fetchRoleList: (params?: any) => void;
  updateUser: (code: string, body: IUser) => void;
  roleList: IRole[];
  isFetching: string[];
  match: any;
}

interface IState {
  maritalStatusList: string[];
  imageKTP: string;
  imageKK: string;
  imageNPWP: string;
}

export class PageEmployeeFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      maritalStatusList: enumToArray(maritalStatus),
      imageKTP: '',
      imageKK: '',
      imageNPWP: '',
    };
  }
  public componentDidMount = () => {
    const { match, fetchUser, fetchRoleList } = this.props;
    const { code } = match.params;
    fetchUser(code);
    fetchRoleList();
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.user !== this.props.user) {
      const { user } = this.props;
      this.setState({
        imageKTP: user?.employee?.imageKTP || '',
        imageKK: user?.employee?.imageKK || '',
        imageNPWP: user?.employee?.imageNPWP || '',
      });
    }
  };
  public handleSubmit = () => {
    const { form, user, updateUser } = this.props;
    const { imageKTP, imageKK, imageNPWP } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const dataUser: IUser = {
          ...user,
          roles: values.roles,
        };
        const dataEmployee: IEmployee = {
          ...dataUser.employee,
          contractStart: formatTime(moment(values.contract[0])),
          contractEnd: formatTime(moment(values.contract[1])),
          KTP: values.ktp,
          imageKTP,
          KK: values.kk,
          imageKK,
          NPWP: values.npwp ? values.npwp.toString().replace(/-|\./g, '') : undefined,
          imageNPWP,
          phone: values.phoneNumber,
          maritalStatus: values.maritalStatus,
          numOfDependence: values.numOfDependence,
          debt: values.debt,
          tenor: values.tenor,
          address: values.address,
          updated_by: getProfile().employee.fullName,
        };

        dataUser.employee = dataEmployee;
        if (dataEmployee?.code) {
          await updateUser(dataEmployee?.code, dataUser);
          history.push(`/employee`);
        }
      }
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const isValidDocument: boolean = defaultUploadImage.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG or PNG file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public handleUpload = async (option: any, type: string) => {
    try {
      getBase64(option.file, (fileBase64) => {
        switch (type) {
          case fileType.KTP:
            this.setState({ imageKTP: fileBase64 });
            break;
          case fileType.KK:
            this.setState({ imageKK: fileBase64 });
            break;
          case fileType.NPWP:
            this.setState({ imageNPWP: fileBase64 });
            break;
        }
        option.onSuccess(fileBase64, option.file);
      });
    } catch (e) {
      option.onError(e, option.file);
    }
  };
  public handleNPWPValidation = (rule: any, value: any, callback: any) => {
    if (value) {
      const fomattedValue: string = value.replace(/[^\d\s]/g, '');
      if (fomattedValue.length < 15) {
        callback('NPWP number minimal 15 digits!');
      }
    }
    callback();
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public render() {
    const { match, isFetching, form, user, roleList } = this.props;
    const { maritalStatusList } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const title = match && match.params.code ? 'Ubah Karyawan' : 'Tambah Karyawan';
    const loading = isLoading(isFetching, DataName.EMPLOYEE);

    const { employee } = user || {};
    const roleId: number[] = [];
    if (user) {
      (user.roles as IRole[]).map((item: IRole) => {
        roleId.push(item.id);
      });
    }

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Role">
                  {getFieldDecorator('roles', {
                    initialValue: roleId,
                    rules: [{ message: 'Role harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      mode="multiple"
                      placeholder="Pilih Role"
                      optionFilterProp="children"
                    >
                      {roleList &&
                        roleList.map((item: IRole) => (
                          <Option key={item.name} value={item.id}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Telepon">
                  {getFieldDecorator('phoneNumber', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: employee?.phone ?? '',
                    rules: [
                      { min: 5, message: 'Telepon minimal 5 digit!' },
                      { max: 15, message: 'Telepon maksimal 15 digit!' },
                      { message: 'Telepon hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Telepon harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Telepon" maxLength={15} />)}
                </Form.Item>
                <Form.Item label="Status Pernikahan">
                  {getFieldDecorator('maritalStatus', {
                    initialValue: employee?.maritalStatus ?? '',
                    rules: [{ required: true, message: 'Status pernikahan harus diipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Status Pernikahan">
                      {maritalStatusList &&
                        maritalStatusList.map((item: string) => (
                          <Option key={item} value={item}>
                            {titleCase(item)}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Jumlah Tanggungan">
                  {getFieldDecorator('numOfDependence', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: employee?.numOfDependence ?? '',
                    rules: [
                      { message: 'Jumlah tanggungan hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input type="number" placeholder="Jumlah Tanggungan" />)}
                </Form.Item>

                <Form.Item label="Hutang Pada Perusahaan">
                  {getFieldDecorator('debt', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: employee?.debt ?? '',
                    rules: [{ message: 'Hutang hanya berupa angka!', pattern: /^[0-9]+$/ }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Jumlah Hutang" />)}
                </Form.Item>
                <Form.Item label="Pembayaran Cicilan Hutang">
                  {getFieldDecorator('tenor', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: employee?.tenor ?? '',
                    rules: [{ message: 'Tenor hanya berupa angka!', pattern: /^[0-9]+$/ }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input type="number" placeholder="Jumlah Pembayaran Cicilan Hutang" />)}
                </Form.Item>
                <Form.Item label="Alamat">
                  {getFieldDecorator('address', {
                    initialValue: employee?.address ?? '',
                    rules: [
                      { min: 3, message: 'Alamat minimal 3 karakter!' },
                      { max: 250, message: 'Alamat maksimal 250 karakter!' },
                      { required: true, message: 'Alamat harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Alamat Kantor, Nama Jalan, Kode Pos, lain-lain." />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Kontrak">
                  {getFieldDecorator('contract', {
                    initialValue:
                      employee?.contractStart && employee?.contractEnd
                        ? [
                            employee.contractStart ? moment(employee.contractStart) : moment(),
                            employee.contractEnd ? moment(employee.contractEnd) : moment(),
                          ]
                        : [moment(), moment()],
                  })(<RangePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Nomor KTP">
                  {getFieldDecorator('ktp', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: employee?.KTP ?? '',
                    rules: [
                      { min: 5, message: 'Nomor KTP minimal 5 digit!' },
                      { max: 16, message: 'Nomor KTP maksimal 16 digit!' },
                      { message: 'Nomor KTP hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nomor KTP" maxLength={16} />)}
                </Form.Item>
                <Form.Item label="KTP File">
                  {getFieldDecorator('imageKTP', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.KTP, employee?.imageKTP),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png"
                      customRequest={(option) => this.handleUpload(option, fileType.KTP)}
                      onRemove={() => {
                        this.setState({ imageKTP: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageKTP') !== undefined &&
                          getFieldValue('imageKTP').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Nomor Kartu Keluarga">
                  {getFieldDecorator('kk', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: employee?.KK ?? '',
                    rules: [
                      { min: 5, message: 'Nomor kartu keluarga minimal 5 digit!' },
                      { max: 16, message: 'Nomor kartu keluarga maksimal 16 digit!' },
                      { message: 'Nomor kartu keluarga hanya berupa angka!', pattern: /^[0-9]+$/ },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Nomor Kartu Keluarga" maxLength={16} />)}
                </Form.Item>
                <Form.Item label="KK File">
                  {getFieldDecorator('imageKK', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.KK, employee?.imageKK),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png"
                      customRequest={(option) => this.handleUpload(option, fileType.KK)}
                      onRemove={() => {
                        this.setState({ imageKK: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageKK') !== undefined &&
                          getFieldValue('imageKK').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
                <Form.Item label="Nomor NPWP">
                  {getFieldDecorator('npwp', {
                    initialValue: employee?.NPWP ?? '',
                    rules: [{ validator: this.handleNPWPValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <InputMask
                      mask="99.999.999.9-999.999"
                      className="ant-input"
                      placeholder="Nomor NPWP"
                    />,
                  )}
                </Form.Item>
                <Form.Item label="NPWP File">
                  {getFieldDecorator('imageNPWP', {
                    getValueFromEvent: (e: any) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    },
                    initialValue: getUploadedFile(fileType.NPWP, employee?.imageNPWP),
                    valuePropName: 'fileList',
                    rules: [{ validator: this.handleUploadValidation }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Upload
                      accept=".jpg, .jpeg, .png"
                      customRequest={(option) => this.handleUpload(option, fileType.NPWP)}
                      onRemove={() => {
                        this.setState({ imageNPWP: '' });
                      }}
                      listType="picture"
                    >
                      <Button
                        disabled={
                          getFieldValue('imageNPWP') !== undefined &&
                          getFieldValue('imageNPWP').length > 0
                        }
                      >
                        <Icon type="upload" /> Upload File
                      </Button>
                    </Upload>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageEmployeeFormComponent);
