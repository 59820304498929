import { IRecipient } from '@interfaces/irecipient';

const urlRecipient = 'recipients';

export const getRecipient = (code: string) => ({
  method: 'GET',
  url: `${urlRecipient}/${code}`,
});

export const getRecipients = (params: any) => ({
  method: 'GET',
  params,
  url: urlRecipient,
});

export const createRecipient = (body: IRecipient) => ({
  body,
  method: 'POST',
  url: `${urlRecipient}`,
});

export const updateRecipient = (code: string, body: IRecipient) => ({
  body,
  method: 'PUT',
  url: `${urlRecipient}/${code}`,
});

export const deleteRecipient = (code: string) => ({
  method: 'DELETE',
  url: `${urlRecipient}/${code}`,
});

export default { getRecipient, getRecipients, createRecipient, updateRecipient, deleteRecipient };
