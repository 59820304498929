import { IRecipient } from '@interfaces/irecipient';
import {
  createRecipient,
  fetchRecipient,
  updateRecipient,
} from '@pages/page-master/page-recipient/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageRecipientFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  recipient: state.PageMasterDataReducer.recipient,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchRecipient: (code: string) => dispatch(fetchRecipient(code)),
  createRecipient: (body: IRecipient) => dispatch(createRecipient(body)),
  updateRecipient: (code: string, body: IRecipient) => dispatch(updateRecipient(code, body)),
});

export const PageRecipientFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageRecipientFormComponent);

export default PageRecipientFormContainer;
