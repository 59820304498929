import TransitCost from '@adaptors-api/transit-cost-api';
import { ITransitCost } from '@interfaces/itransit-cost';
import { IMeta } from '@interfaces/imeta';
import { isFetchingData, setMetaData, setNotification } from '@pages/base/action';
import ActionTypes from '@store/action-types';
import RequestAPI from '@utils/request-api';
import { DataName, EnumLoading } from '@utils/constants';

const setLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.SET, DataName.TRANSIT_COST));
};
const unsetLoading = (dispatch: any) => {
  dispatch(isFetchingData(EnumLoading.REMOVE, DataName.TRANSIT_COST));
};

export const setTransitCost = (transitCost?: ITransitCost) => ({
  transitCost,
  type: ActionTypes.PAGE_TRANSIT_COST_SET_TRANSIT_COST,
});

export const setTransitCostList = (transitCostList: ITransitCost[]) => ({
  transitCostList,
  type: ActionTypes.PAGE_TRANSIT_COST_SET_TRANSIT_COST_LIST,
});

export const fetchTransitCost = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  if (!code) {
    dispatch(setTransitCost(undefined));
    unsetLoading(dispatch);
    return;
  }

  return RequestAPI(TransitCost.getTransitCost(code))
    .then((response: any) => {
      const { data } = response.data;
      const transitCost: ITransitCost = data;
      dispatch(setTransitCost(transitCost));
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const fetchTransitCostList = (params?: any, isSetMeta: boolean = true) => (
  dispatch: any,
) => {
  setLoading(dispatch);

  return RequestAPI(TransitCost.getTransitCosts(params))
    .then((response: any) => {
      const { data, meta } = response.data;
      const transitCostList: ITransitCost[] = data;
      const metaData: IMeta = meta;
      dispatch(setTransitCostList(transitCostList));
      if (isSetMeta) {
        dispatch(setMetaData(metaData));
      }
      unsetLoading(dispatch);
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
    });
};

export const createTransitCost = (body: ITransitCost) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(TransitCost.createTransitCost(body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Buat Transit Cost ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Buat Transit Cost Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const updateTransitCost = (code: string, body: ITransitCost) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(TransitCost.updateTransitCost(code, body))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Ubah Transit Cost ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Ubah Transit Cost Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};

export const deleteTransitCost = (code: string) => (dispatch: any) => {
  setLoading(dispatch);

  return RequestAPI(TransitCost.deleteTransitCost(code))
    .then((response: any) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: response.status === 200 ? 'success' : 'error',
        title: `Hapus Transit Cost ${response.status === 200 ? 'Sukses' : 'Gagal'}`,
        message: response.data.message,
      };
      dispatch(setNotification(notif));
    })
    .catch((e: Error) => {
      unsetLoading(dispatch);
      const notif: any = {
        status: 'error',
        title: `Hapus Transit Cost Gagal`,
        message: e.message,
      };
      dispatch(setNotification(notif));
    });
};
