import { DataName, dateFormat } from '@utils/constants';
import { ICashFlowRecap, ICashFlow } from '@interfaces/icash-flow';
import { goBack } from '@utils/history';
import { currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Card, PageHeader, Table, Button, Icon } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { EmptyState } from '@components/molecules/empty-state';
import { CSVLink } from 'react-csv';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchCashFlowRecap: (code: string) => void;
  cashFlowRecap?: ICashFlowRecap;
  fetchCashFlowList: (params: any) => void;
  cashFlowRecapDetail?: ICashFlow[];
  isFetching: string[];
  match: any;
  location: any;
}

export class PageCashFlowRecapDetailComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public componentDidMount = () => {
    const { match, fetchCashFlowRecap, fetchCashFlowList, location } = this.props;

    if (match.params.code) {
      fetchCashFlowRecap(match.params.code);
    } else {
      const params = new URLSearchParams(location.search);
      const from = params?.get('from');
      const to = params?.get('to');
      fetchCashFlowList({ from, to });
    }
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.cashFlowRecap !== this.props.cashFlowRecap) {
      const { fetchCashFlowList, cashFlowRecap } = this.props;
      fetchCashFlowList({ postingDate: cashFlowRecap?.postingDate });
    }
  };

  public newSource = (data: any) => {
    return data
      .reduce((result: any, item: any) => {
        if (result.indexOf(item.postingDate) < 0) {
          result.push(item.postingDate);
        }
        return result;
      }, [])
      .reduce((result: any, postingDate: string) => {
        const children = data.filter((item: any) => item.postingDate === postingDate);
        result = result.concat(
          children.map((item: any, index: number) => ({
            ...item,
            rowSpan: index === 0 ? children.length : 0,
          })),
        );
        return result;
      }, []);
  };
  public generateCOAText = (cashFlow: ICashFlow) => {
    const { dmCode, coa, description } = cashFlow || {};
    return `${coa?.code} - ${coa?.accountName}:\n${
      dmCode ? `${dmCode} - ${description}` : description
    }`;
  };

  public render() {
    const { isFetching, cashFlowRecapDetail, location } = this.props;
    const loading = isLoading(isFetching, DataName.CASH_FLOW_RECAP);

    const params = new URLSearchParams(location.search);
    const from = params?.get('from');
    const to = params?.get('to');
    const title =
      from && to ? `Detail Rekap Cash Flow (${from} s.d. ${to})` : 'Detail Rekap Cash Flow';

    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const dataSource = [];
    let total = 0;
    cashFlowRecapDetail?.map((item: ICashFlow, index: number) => {
      dataSource.push({
        key: index + 1,
        postingDate: item.postingDate ? formatTime(item.postingDate, dateFormat) : '-',
        code: item.code,
        coa: this.generateCOAText(item),
        debit: item.coa?.normalBalance === 'D' ? currencyCase(item.subtotal) : '',
        kredit: item.coa?.normalBalance === 'K' ? currencyCase(item.subtotal) : '',
        pph: item.pph ? currencyCase(item.pph) : '',
        miscCharge: item.miscCharge ? currencyCase(item.miscCharge) : '',
        subtotal: currencyCase(item.total),
      });
      total += item.total;
    });
    dataSource.push({
      key: 0,
      postingDate: 'Total Saldo',
      subtotal: currencyCase(total),
    });

    const renderContent = (value: any, row: any, index: number) => {
      const obj = {
        children: value,
        props: { colSpan: 1 },
      };
      if (index === dataSource.length - 1) {
        obj.props.colSpan = 0;
      }
      return obj;
    };

    const columns: any = [
      {
        title: 'Tanggal',
        dataIndex: 'postingDate',
        render: (text: any, row: any, index: number) => {
          const obj = {
            children: text,
            props: { rowSpan: row.rowSpan, colSpan: 1 },
          };

          if (index === dataSource.length - 1) {
            obj.props.colSpan = 7;
          }
          return obj;
        },
        width: 120,
      },
      {
        title: 'Kode Cash Flow',
        dataIndex: 'code',
        render: (text: string, record: ICashFlow, index: number) =>
          renderContent(<Link to={`/cash-flow/detail/${record.code}`}>{text}</Link>, record, index),
      },
      {
        title: 'COA',
        dataIndex: 'coa',
        render: (text: string, record: ICashFlow, index: number) =>
          renderContent(
            <div dangerouslySetInnerHTML={{ __html: text?.replace('\n', '<br/>') }} />,
            record,
            index,
          ),
        ellipsis: true,
        width: 400,
      },
      {
        title: 'Debit',
        dataIndex: 'debit',
        render: renderContent,
        align: 'right',
        key: 'debit',
      },
      {
        title: 'Kredit',
        dataIndex: 'kredit',
        render: renderContent,
        align: 'right',
        key: 'kredit',
      },
      {
        title: 'PPh',
        dataIndex: 'pph',
        render: renderContent,
        align: 'right',
      },
      {
        title: 'Misc',
        dataIndex: 'miscCharge',
        render: renderContent,
        align: 'right',
      },
      {
        title: 'Subtotal',
        dataIndex: 'subtotal',
        align: 'right',
      },
    ];

    const headers = [
      { label: 'No', key: 'key' },
      { label: 'Tanggal', key: 'postingDate' },
      { label: 'Kode Cash Flow', key: 'code' },
      { label: 'Cash on Account', key: 'coa' },
      { label: 'Debit', key: 'debit' },
      { label: 'Kredit', key: 'kredit' },
      { label: 'PPh', key: 'pph' },
      { label: 'Misc', key: 'miscCharge' },
      { label: 'Subtotal', key: 'subtotal' },
    ];

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader
          title={title}
          onBack={goBack}
          extra={[
            <CSVLink
              key="1"
              data={dataSource}
              headers={headers}
              filename={`${title}.xls`}
              target="_blank"
            >
              <Button type="primary">
                <Icon type="edit" />
                Export to Excel
              </Button>
            </CSVLink>,
          ]}
        />
        <Card bordered={false} loading={loading}>
          {cashFlowRecapDetail && (
            <div className="bt-table">
              <Table
                size="small"
                columns={columns}
                dataSource={this.newSource(dataSource)}
                bordered={true}
                pagination={false}
                locale={{
                  emptyText: tableEmptyState,
                }}
                loading={loading}
              />
            </div>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageCashFlowRecapDetailComponent;
