import { DataName, dateFormat, deliveryType, emptyParams, FormType } from '@utils/constants';
import { IPriceList } from '@interfaces/iprice-list';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { AutoComplete, Button, Card, Col, DatePicker, Input, PageHeader, Row, Select } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { isLoading } from '@utils/loading';
import { IVehicleType } from '@interfaces/ivehicle';
import { IVendor } from '@interfaces/ivendor';
import { ICustomer } from '@interfaces/icustomer';
import moment from 'moment';
import { enumToArray } from '@utils/enum-to-array';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchVehicleTypeList: (params: any) => void;
  vehicleTypeList: IVehicleType[];
  fetchVendorList: (params: any) => void;
  vendorList: IVendor[];
  fetchCustomerList: (params: any) => void;
  customerList: ICustomer[];
  fetchPriceList: (id: number) => void;
  priceList?: IPriceList;
  createPriceList: (body: IPriceList) => void;
  updatePriceList: (id: number, body: IPriceList) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  deliveryTypeList: string[];
  formType: FormType;
  searchVendor: string[];
  searchCustomer: string[];
}

export class PagePriceListFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { id } = props.match.params;
    this.state = {
      deliveryTypeList: enumToArray(deliveryType),
      formType: id ? 'edit' : 'add',
      searchVendor: [],
      searchCustomer: [],
    };
  }
  public componentDidMount = () => {
    const { match, fetchPriceList, fetchVehicleTypeList, fetchVendorList, fetchCustomerList } =
      this.props;
    const { id } = match.params;
    fetchPriceList(id);
    fetchVehicleTypeList(emptyParams);
    fetchVendorList(emptyParams);
    fetchCustomerList(emptyParams);
  };
  public handleSubmit = () => {
    const { form, priceList, createPriceList, updatePriceList } = this.props;
    const { formType } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataPriceList: IPriceList = {
          ...priceList,
          date: formatTime(values.date, 'YYYY-MM-DD'),
          deliveryFrom: values.deliveryFrom,
          deliveryTo: values.deliveryTo,
          deliveryType: values.deliveryType,
          vehicleTypesId: values.vehicleType,
          price: Number(values.price),
          cost: Number(values.cost),
          vendor: values.vendor,
          customer: values.customer,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && priceList?.id) {
          await updatePriceList(priceList.id, dataPriceList);
        } else {
          await createPriceList({ ...dataPriceList, created_by: currentUser });
        }
        history.push('/price-list');
      }
    });
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public onSearchVendor = (value: any) => {
    const { vendorList } = this.props;
    let search: string[] = [];
    search = vendorList.map((vendor) => companyTypeCase(vendor.type, vendor.name) || '');
    search = search.filter(
      (vendor, index) =>
        vendor?.toLowerCase().includes(value?.toLowerCase()) && search?.indexOf(vendor) === index,
    );
    this.setState({ searchVendor: search });
  };
  public onSearchCustomer = (value: any) => {
    const { customerList } = this.props;
    let search: string[] = [];
    search = customerList.map((customer) => companyTypeCase(customer.type, customer.name) || '');
    search = search.filter(
      (customer, index) =>
        customer?.toLowerCase().includes(value?.toLowerCase()) &&
        search?.indexOf(customer) === index,
    );
    this.setState({ searchCustomer: search });
  };
  public render() {
    const { isFetching, form, priceList, vehicleTypeList } = this.props;
    const { deliveryTypeList, formType, searchVendor, searchCustomer } = this.state;
    const { getFieldDecorator } = form;
    const title = formType && formType === 'edit' ? 'Ubah Daftar Harga' : 'Tambah Daftar Harga';
    const loading = isLoading(isFetching, DataName.PRICELIST);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Tanggal">
                  {getFieldDecorator('date', {
                    initialValue: priceList ? moment(priceList.date) : moment(),
                    rules: [{ required: true, message: 'Tanggal harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Asal">
                  {getFieldDecorator('deliveryFrom', {
                    initialValue: priceList?.deliveryFrom ?? '',
                    rules: [
                      { min: 3, message: 'Asal minimal 3 karakter!' },
                      { max: 250, message: 'Asal maksimal 250 karakter!' },
                      { required: true, message: 'Asal harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Asal Pengiriman" />)}
                </Form.Item>
                <Form.Item label="Harga">
                  {getFieldDecorator('price', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: priceList?.price ?? '',
                    rules: [{ required: true, message: 'Harga harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Harga" />)}
                </Form.Item>
                <Form.Item label="Vendor">
                  {getFieldDecorator('vendor', {
                    initialValue: priceList?.vendor ?? '',
                    rules: [{ message: 'Vendor harus diisi!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <AutoComplete
                      showSearch={true}
                      placeholder="Nama Vendor"
                      dataSource={searchVendor}
                      onSearch={this.onSearchVendor}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: priceList?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Jenis Pengiriman">
                  {getFieldDecorator('deliveryType', {
                    initialValue: priceList?.deliveryType ?? '',
                    rules: [{ required: true, message: 'Jenis Pengiriman harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Jenis Pengiriman" showSearch={true}>
                      {deliveryTypeList &&
                        deliveryTypeList.map((item: string) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Tujuan">
                  {getFieldDecorator('deliveryTo', {
                    initialValue: priceList?.deliveryTo ?? '',
                    rules: [
                      { min: 3, message: 'Tujuan minimal 3 karakter!' },
                      { max: 250, message: 'Tujuan maksimal 250 karakter!' },
                      { required: true, message: 'Tujuan harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Tujuan Pengiriman" />)}
                </Form.Item>
                <Form.Item label="Biaya">
                  {getFieldDecorator('cost', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: priceList?.cost ?? '',
                    rules: [{ required: true, message: 'Biaya harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Biaya" />)}
                </Form.Item>
                <Form.Item label="Customer">
                  {getFieldDecorator('customer', {
                    initialValue: priceList?.customer ?? '',
                    rules: [{ message: 'Customer harus diisi!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <AutoComplete
                      showSearch={true}
                      placeholder="Nama Customer"
                      dataSource={searchCustomer}
                      onSearch={this.onSearchCustomer}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Armada">
                  {getFieldDecorator('vehicleType', {
                    initialValue: priceList?.vehicleTypesId ?? '',
                    rules: [{ message: 'Armada harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Armada"
                      optionFilterProp="children"
                    >
                      {vehicleTypeList &&
                        vehicleTypeList.map((item: IVehicleType) => (
                          <Option key={item.code} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}

export default Form.create<IProps>()(PagePriceListFormComponent);
