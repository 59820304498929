import { IMeta } from '@interfaces/imeta';
import INotification from '@interfaces/inotification';
import ActionTypes from '@store/action-types';

export type loadingType = 'SET' | 'REMOVE';

export interface IDefaultReducer {
  isFetching: string[];
  notification: INotification;
  loader: boolean;
  metaData: IMeta;
  metaDataSecondary: IMeta;
}

const defaultMeta = {
  current_page: 1,
  first_page_url: '',
  from: 1,
  last_page: 1,
  last_page_url: '',
  next_page_url: '',
  path: '',
  per_page: 10,
  prev_page_url: '',
  to: 1,
  total: 1,
};

const initialState: IDefaultReducer = {
  isFetching: [],
  loader: false,
  notification: {
    message: '',
    status: '',
    title: '',
  },
  metaData: defaultMeta,
  metaDataSecondary: defaultMeta,
};

export const DefaultReducer = (state: IDefaultReducer = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_IS_FETCH_DATA: {
      if (action.command === 'SET') {
        return { ...state, isFetching: [...state.isFetching, action.data] };
      } else {
        const fetching = state.isFetching?.filter((fetch: string) => fetch !== action.data);
        return { ...state, isFetching: fetching };
      }
    }
    case ActionTypes.SET_BASE_NOTIFICATION: {
      return { ...state, notification: action.notification };
    }
    case ActionTypes.SET_BASE_LOADER: {
      return { ...state, loader: action.loader };
    }
    case ActionTypes.CLEAR_BASE_NOTIFICATION: {
      return { ...state, notification: { status: '', message: '', title: '' } };
    }
    case ActionTypes.SET_META_DATA: {
      return { ...state, metaData: action.metaData };
    }
    case ActionTypes.SET_META_DATA_SECONDARY: {
      return { ...state, metaDataSecondary: action.metaDataSecondary };
    }
    default:
      return state;
  }
};

export default DefaultReducer;
