import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import { fetchTandaTerimaList } from './action';
import PageTandaTerimaComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  tandaTerimaList: state.PageAccountReceivableReducer.tandaTerimaList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchTandaTerimaList: (params: any) => dispatch(fetchTandaTerimaList(params)),
});

export const PageTandaTerimaContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageTandaTerimaComponent);

export default PageTandaTerimaContainer;
