import { IParamsResetPassword } from '@interfaces/iparams';
import ActionTypes from '@store/action-types';

export interface IAuthReducer {
  errorMessage: string;
  successMessage: string;
  isSubmit: boolean;
  resetPasswordInfo?: IParamsResetPassword;
}

const initialState: IAuthReducer = {
  errorMessage: '',
  isSubmit: false,
  successMessage: '',
};

export const PageAuthReducer = (state: IAuthReducer = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.PAGE_AUTH_SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ActionTypes.PAGE_AUTH_SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.successMessage,
      };
    case ActionTypes.PAGE_AUTH_SET_LOADING:
      return { ...state, isSubmit: action.isSubmit };
    case ActionTypes.PAGE_AUTH_SET_RESET_PASSWORD_INFO:
      return {
        ...state,
        resetPasswordInfo: action.resetPasswordInfo,
      };
    default:
      return state;
  }
};

export default PageAuthReducer;
