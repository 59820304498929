import { EmptyState } from '@components/molecules/empty-state';
import { paymentTypeInverse, volumeType } from '@utils/constants';
import { ITandaTerima, ITandaTerimaDetail } from '@interfaces/itanda-terima';
import { currencyCase } from '@utils/string-case';
import { Table } from 'antd';
import React, { Component } from 'react';

interface IProps {
  dataSource?: ITandaTerimaDetail[];
  master: ITandaTerima;
  showPrice: boolean;
  description?: string;
  isPrint: boolean;
}

export class DetailTableComponent extends Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const { master, dataSource, isPrint, description, showPrice } = this.props;
    const tableEmptyState = <EmptyState emptyText="Data Tidak Ditemukan" />;

    const columns: any[] = [
      {
        title: 'Kuantum',
        dataIndex: 'quantity',
        sorter: false,
        width: 70,
        align: 'center',
      },
      {
        title: 'Unit',
        dataIndex: 'quantityType',
        sorter: false,
        width: 70,
      },
      {
        title: 'Jenis Barang',
        dataIndex: 'itemName',
        sorter: false,
        width: 200,
      },
      {
        title: 'Volume',
        dataIndex: 'volume',
        render: (text: string, record: ITandaTerimaDetail) =>
          text &&
          `${text} ${
            record.volumeType && record.volumeType !== volumeType.NIHIL ? record.volumeType : ''
          }`,
        sorter: false,
        align: 'center',
        width: 80,
      },
      {
        title: 'Biaya',
        dataIndex: 'price',
        align: 'right',
        sorter: false,
        render: (text: number, record: ITandaTerimaDetail) => {
          return showPrice || master.paymentType === paymentTypeInverse.Cash ? (
            <div className="bt-text-right">{currencyCase(text)}</div>
          ) : (
            record.id && '-'
          );
        },
        width: 140,
      },
      {
        title: 'Jumlah ',
        dataIndex: 'total',
        align: 'right',
        sorter: false,
        render: (text: number, record: ITandaTerimaDetail) => {
          return showPrice || master.paymentType === paymentTypeInverse.Cash ? (
            <div className="bt-text-right">{currencyCase(text)}</div>
          ) : (
            '-'
          );
        },
        width: 140,
      },
    ];

    return (
      <div className="bt-table hide small medium-font">
        <Table
          size="small"
          columns={columns}
          dataSource={dataSource}
          bordered={true}
          pagination={false}
          locale={{
            emptyText: tableEmptyState,
          }}
          rowKey="id"
        />
        {isPrint && (
          <span style={{ color: '#000', fontSize: '10px', maxWidth: '500px' }}>{description}</span>
        )}
      </div>
    );
  }
}

export default DetailTableComponent;
