import { fetchCashFlow } from '@pages/page-account-payable/page-cash-flow/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageCashFlowDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  cashFlow: state.PageAccountPayableReducer.cashFlow,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCashFlow: (code: string) => dispatch(fetchCashFlow(code)),
});

export const PageCashFlowDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCashFlowDetailComponent);

export default PageCashFlowDetailContainer;
