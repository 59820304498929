import {
  fetchCashFlowRecap,
  fetchCashFlowList,
} from '@pages/page-account-payable/page-cash-flow/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageCashFlowRecapDetailComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  cashFlowRecap: state.PageAccountPayableReducer.cashFlowRecap,
  cashFlowRecapDetail: state.PageAccountPayableReducer.cashFlowRecapDetail,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCashFlowRecap: (code: string) => dispatch(fetchCashFlowRecap(code)),
  fetchCashFlowList: (params: any) => dispatch(fetchCashFlowList(params, true)),
});

export const PageCashFlowRecapDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCashFlowRecapDetailComponent);

export default PageCashFlowRecapDetailContainer;
