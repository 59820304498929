import { IMeta } from '@interfaces/imeta';
import { IPurchaseOrder } from '@interfaces/ipurchase-order';
import { DataName } from '@utils/constants';
import { isLoading } from '@utils/loading';
import { Button, Card, Icon, PageHeader } from 'antd';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import TableComponent from './libraries/table';

interface IProps {
  fetchPurchaseOrderList: (params: any) => void;
  purchaseOrderList: IPurchaseOrder[];
  isFetching: string[];
  metaData: IMeta;
}

const title = 'Kelola Purchase Order';
export class PagePurchaseOrderComponent extends Component<IProps, any> {
  public render() {
    const { fetchPurchaseOrderList, purchaseOrderList, isFetching, metaData } = this.props;
    const loading = isLoading(isFetching, DataName.PURCHASE_ORDER);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} />
        <Card
          title="Daftar Purchase Order"
          bordered={false}
          extra={
            <Link to={`/purchase-order/form`}>
              <Button type="primary">
                <Icon type="plus" />
                Buat Purchase Order
              </Button>
            </Link>
          }
        >
          <TableComponent
            isFetching={loading}
            fetchDataSource={fetchPurchaseOrderList}
            dataSource={purchaseOrderList}
            meta={metaData}
          />
        </Card>
      </Fragment>
    );
  }
}
export default PagePurchaseOrderComponent;
