import { ICustomer } from '@interfaces/icustomer';
import { fetchEmployeeList } from '@pages/page-administration/page-employee/action';
import {
  createCustomer,
  fetchCustomer,
  updateCustomer,
} from '@pages/page-master/page-customer/action';
import { IStateReducer } from '@reducers/state';
import { connect } from 'react-redux';
import PageCustomerFormComponent from './component';

export const mapStateToProps = (state: IStateReducer) => ({
  isFetching: state.DefaultReducer.isFetching,
  metaData: state.DefaultReducer.metaData,
  customer: state.PageMasterDataReducer.customer,
  employeeList: state.PageAdministrationReducer.employeeList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchEmployeeList: (params: any) => dispatch(fetchEmployeeList(params)),
  fetchCustomer: (code: string) => dispatch(fetchCustomer(code)),
  createCustomer: (body: ICustomer) => dispatch(createCustomer(body)),
  updateCustomer: (code: string, body: ICustomer) => dispatch(updateCustomer(code, body)),
});

export const PageCustomerFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageCustomerFormComponent);

export default PageCustomerFormContainer;
