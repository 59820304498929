import LogoImage from '@assets/images/logo.svg';
import { LogoSidebar } from '@components/atoms/logo-sidebar';
import { UserMenu } from '@components/atoms/user-menu';
import { MenuSidebar } from '@components/organisms/menu-sidebar';
import { getProfile } from '@utils/request-api';
import settings from '@utils/settings';
import { Icon, Layout } from 'antd';
import React, { Component } from 'react';

interface IProps {
  children?: any;
  classCustomPage?: string;
  layoutMode?: any;
  location?: any;
}

const { Header, Footer, Sider, Content } = Layout;
export class LayoutCMSComponent extends Component<IProps, any> {
  public static defaultProps: { classCustomPage: string };
  constructor(props: IProps) {
    super(props);
    this.state = {
      collapsed: false,
      layoutMode: this.getLayoutMode(),
      openMenu: false,
      userEmail: '',
      userName: '',
      userRole: '',
    };
    this.onResize = this.onResize.bind(this);
  }

  public componentDidMount() {
    const profile = getProfile();
    const userName = profile.employee ? profile.employee.fullName : '';
    const userEmail = profile.email;
    const userRole = profile.roles;
    this.setState({ userName, userEmail, userRole });
    window.addEventListener('resize', this.onResize);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
  public onResize() {
    this.setState({
      layoutMode: this.getLayoutMode(),
    });
  }

  public getLayoutMode() {
    return window.innerWidth > 992 ? 'desktop' : 'mobile';
  }

  public onCollapse = (collapsed: any) => {
    this.setState({ collapsed });
  };

  public actionLogout = () => {
    localStorage.clear();
    window.location.href = '/auth';
  };
  public toggle = () => this.setState({ collapsed: !this.state.collapsed });

  public render() {
    const { children, classCustomPage, location } = this.props;
    const LogoTitle = 'Minofa System';
    const { userName, userEmail, userRole, collapsed } = this.state;
    return (
      <Layout
        className={`bt-cms ${classCustomPage} ` + (this.state.openMenu ? ' menu-open' : '')}
        style={{ minHeight: '100vh' }}
      >
        <Sider
          breakpoint="md"
          collapsedWidth="80"
          className="bt-sidebar"
          collapsible={true}
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          width="240"
          trigger={null}
        >
          <LogoSidebar LogoTitle={LogoTitle} LogoImage={LogoImage} isCollapsed={collapsed} />
          <MenuSidebar location={location} />
        </Sider>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 2,
              width: '100%',
              display: 'block',
              background: '#fff',
              paddingLeft: 10,
              paddingRight: 10,
              marginLeft: collapsed ? '80px' : '240px',
            }}
          >
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
            <UserMenu
              userName={userName}
              userEmail={userEmail}
              userRole={userRole}
              userLogout={this.actionLogout}
            />
          </Header>
          <Content
            className="bt-content"
            style={{ paddingLeft: collapsed ? '112px' : '272px', transition: 'all 0.2s' }}
          >
            {children}
          </Content>
          <Footer
            style={{
              paddingLeft: collapsed ? '112px' : '272px',
              transition: 'all 0.2s',
              textAlign: 'center',
            }}
          >
            <small>
              &copy; {new Date().getFullYear()} PT. Minofa Multi Sukses | v{settings.version}
            </small>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
LayoutCMSComponent.defaultProps = {
  classCustomPage: '',
};

export default LayoutCMSComponent;
