import {
  dateFormat,
  emptyParams,
  FormType,
  orderStatus,
  paymentTypeInverse,
  fileType,
  defaultUploadDocument,
  defaultUploadImage,
  DataName,
} from '@utils/constants';
import { IDaftarMuatan, IDaftarMuatanDetail } from '@interfaces/idaftar-muatan';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { IVehicle } from '@interfaces/ivehicle';
import DetailTableComponent from '@pages/page-account-payable/page-daftar-muatan/libraries/detail/libraries/detail-table';
import history, { goBack } from '@utils/history';
import { getProfile } from '@utils/request-api';
import { companyTypeCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  PageHeader,
  Row,
  Select,
  Upload,
  Icon,
} from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import TextArea from 'antd/lib/input/TextArea';
import { getBase64, getMultipleFile, getUploadedFile } from '@utils/upload';
import { isLoading } from '@utils/loading';
const { Option } = Select;

interface IProps extends FormComponentProps {
  fetchVehicleList: (params: any) => void;
  vehicleList: IVehicle[];
  fetchSuratMuatanList: (params: any) => void;
  suratMuatanList: ISuratMuatan[];
  fetchDaftarMuatan: (code: string) => void;
  daftarMuatan?: IDaftarMuatan;
  createDaftarMuatan: (body: IDaftarMuatan) => void;
  updateDaftarMuatan: (code: string, body: IDaftarMuatan) => void;
  isFetching: string[];
  match: any;
}

interface IState {
  formType: FormType;
  suratMuatanList: ISuratMuatan[];
  suratMuatans: ISuratMuatan[];
  locoFee: number;
  grandTotal: number;
  fileSuratJalan: string;
  fileFoto: string[];
}

export class PageDaftarMuatanFormComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { code } = props.match.params;
    this.state = {
      formType: code ? 'edit' : 'add',
      suratMuatanList: [],
      suratMuatans: [],
      locoFee: 0,
      grandTotal: 0,
      fileSuratJalan: '',
      fileFoto: [],
    };
  }
  public componentDidMount = () => {
    const { match, fetchDaftarMuatan, fetchVehicleList, fetchSuratMuatanList } = this.props;
    const { code } = match.params;
    fetchDaftarMuatan(code);
    fetchVehicleList(emptyParams);
    fetchSuratMuatanList({
      ...emptyParams,
      status: `[${orderStatus.SuratMuatan}]`,
    });
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.daftarMuatan !== this.props.daftarMuatan) {
      const { daftarMuatan } = this.props;
      if (daftarMuatan) {
        const suratMuatans: ISuratMuatan[] = [];
        daftarMuatan.details?.map((item: IDaftarMuatanDetail) => {
          suratMuatans.push(item.details);
        });

        this.setState({
          suratMuatans,
          locoFee: daftarMuatan.locoFee,
          fileSuratJalan: daftarMuatan?.fileSuratJalan || '',
          fileFoto: daftarMuatan?.fileFoto?.split('|') || [],
        });
      }
    }
  };
  public handleSubmit = () => {
    const { form, daftarMuatan, createDaftarMuatan, updateDaftarMuatan } = this.props;
    const { formType, suratMuatans, fileSuratJalan, fileFoto } = this.state;
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const currentUser = getProfile().employee.fullName;
        const dataDaftarMuatan: IDaftarMuatan = {
          ...daftarMuatan,
          fileSuratJalan,
          fileFoto: fileFoto?.join('|'),
          vehiclesId: values.vehicle,
          dmDate: formatTime(values.dmDate, 'YYYY-MM-DD'),
          expeditionCommission: Number(values.expeditionCommission),
          totalPrice: Number(values.totalPrice),
          totalVendorPrice: Number(values.totalVendorPrice),
          adminFee: Number(values.adminFee),
          downPayment: Number(values.downPayment),
          locoFee: Number(values.locoFee),
          unpaidFee: Number(values.unpaidFee),
          miscCharge: Number(values.miscCharge),
          checker: values.checker,
          suratMuatans,
          status: orderStatus.DaftarMuatan,
          remarks: values.remarks,
          updated_by: currentUser,
        };

        if (formType === 'edit' && daftarMuatan?.code) {
          await updateDaftarMuatan(daftarMuatan.code, dataDaftarMuatan);
        } else {
          await createDaftarMuatan({ ...dataDaftarMuatan, created_by: currentUser });
        }
        history.push('/daftar-muatan');
      }
    });
  };
  public handleUploadValidation = (rule: any, value: any, callback: any, source: any) => {
    if (value && value.length > 0) {
      if (value[0].type && value[0].size) {
        const documentType = value[0].type;
        const allowedType =
          Object.keys(source)[0] === 'fileFoto' ? defaultUploadImage : defaultUploadDocument;
        const isValidDocument: boolean = allowedType.includes(documentType);
        const isFileSize: boolean = value[0].size / 1024 / 1024 <= 5;
        if (!isValidDocument) {
          callback('Only accept JPG, PNG, or PDF file!');
        }
        if (!isFileSize) {
          callback(`File size must less than ${5}MB!`);
        }
      }
    }
    callback();
  };
  public handleUpload = async (option: any, field: string) => {
    const { fileFoto } = this.state;
    try {
      getBase64(option.file, (fileBase64) => {
        if (field === 'surat_jalan') {
          this.setState({ fileSuratJalan: fileBase64 });
        } else {
          this.setState({ fileFoto: fileFoto ? [...fileFoto, fileBase64] : [fileBase64] });
        }
        option.onSuccess(fileBase64, option.file);
      });
    } catch (e) {
      option.onError(e, option.file);
    }
  };
  public onChangeNumber = (e: any) => {
    return e.target.value.replace(/\D/, '');
  };
  public onSelectVehicle = (vehiclesId: any) => {
    const { suratMuatanList, form } = this.props;
    const { setFieldsValue } = form;
    const suratMuatans = suratMuatanList.filter(
      (item: ISuratMuatan) => vehiclesId === item.vehicle?.id,
    );
    this.setState({ suratMuatanList: suratMuatans, suratMuatans: [] });
    setFieldsValue({ suratMuatan: [] });
  };
  public onSelectSuratMuatan = (value: any) => {
    const { suratMuatanList, form } = this.props;
    const { setFieldsValue } = form;
    const data = suratMuatanList.filter((item: ISuratMuatan) => value.includes(item.id));

    let locoFee = 0;
    let grandTotal = 0;
    data.map((item: ISuratMuatan) => {
      grandTotal += item.invoicePrice || 0;
      if (item.paymentType === paymentTypeInverse.Loco) {
        locoFee += item.invoicePrice || 0;
      }
    });

    setFieldsValue({ locoFee });
    this.setState({ suratMuatans: data, locoFee, grandTotal });
    this.calculate();
  };
  public handleValidateCommission = (rule: any, value: string, callback: any) => {
    const { grandTotal } = this.state;
    const minCommission = (10 * grandTotal) / 100;
    const commission = Number(value);
    if (commission !== 0 && commission < minCommission) {
      callback('Komisi minimal 10% dari Total Harga!');
    }
    callback();
  };
  public handleValidateVendorPrice = (rule: any, value: string, callback: any) => {
    const { grandTotal } = this.state;
    const vendorPrice = Number(value);
    if (grandTotal > 0 && vendorPrice > grandTotal) {
      callback('Harga vendor harus lebih kecil dari harga invoice!');
    }
    callback();
  };
  public calculate = (e?: any) => {
    const { form } = this.props;
    const { grandTotal } = this.state;
    const { getFieldValue, setFieldsValue } = form;

    let id = '';
    let value = '';
    if (e) {
      if (e.target) {
        id = e.target.id;
        value = e.target.value;
      } else {
        id = 'adminFee';
        value = e;
      }
    }

    const expeditionCommission = Number(
      id === 'expeditionCommission' ? value : getFieldValue(`expeditionCommission`) || 0,
    );
    const adminFee = Number(id === 'adminFee' ? value : getFieldValue(`adminFee`) || 0);
    const downPayment = Number(id === 'downPayment' ? value : getFieldValue(`downPayment`) || 0);
    const locoFee = Number(id === 'locoFee' ? value : getFieldValue(`locoFee`) || 0);
    const vendorPrice = Number(getFieldValue(`totalVendorPrice`) || 0);

    const unpaidFee = grandTotal - expeditionCommission - adminFee - downPayment - locoFee;
    const unpaidVendor = vendorPrice - expeditionCommission - adminFee - downPayment - locoFee;

    setFieldsValue({ unpaidFee, unpaidVendor });
  };
  public render() {
    const { isFetching, form, daftarMuatan, vehicleList } = this.props;
    const { formType, suratMuatanList, suratMuatans, locoFee, grandTotal } = this.state;
    const { getFieldDecorator, getFieldValue } = form;
    const title = formType && formType === 'edit' ? 'Ubah Daftar Muatan' : 'Buat Daftar Muatan';
    const loading = isLoading(isFetching, DataName.DAFTAR_MUATAN);

    let unpaidVendor;
    const suratMuatanCodes: string[] = [];
    if (daftarMuatan) {
      const { totalVendorPrice, expeditionCommission, adminFee, downPayment } = daftarMuatan;
      unpaidVendor = totalVendorPrice - expeditionCommission - adminFee - downPayment - locoFee;

      daftarMuatan.details?.map(
        (item: IDaftarMuatanDetail) =>
          item.details?.code && suratMuatanCodes.push(item.details?.code),
      );
    }

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        <PageHeader title={title} onBack={goBack} />
        <Card bordered={false} loading={loading}>
          <Form>
            <Row gutter={24}>
              <Col lg={12}>
                <Form.Item label="Kendaraan dan Vendor">
                  {getFieldDecorator('vehicle', {
                    initialValue: daftarMuatan?.vehicle?.id,
                    rules: [{ message: 'Kendaraan dan Vendor harus dipilih!', required: true }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Pilih Kendaraan dan Vendor"
                      optionFilterProp="children"
                      onChange={this.onSelectVehicle}
                    >
                      {vehicleList &&
                        vehicleList.map((item: IVehicle) => (
                          <Option key={item.code} value={item.id}>
                            {item.policeNo} | {item.vehicle_type?.name} |{' '}
                            {companyTypeCase(item.driver?.vendor?.type, item.driver?.vendor?.name)}{' '}
                            | {item.driver?.fullName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Total Harga (Invoice)">
                  {getFieldDecorator('totalPrice', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: daftarMuatan?.totalPrice ?? grandTotal,
                    rules: [{ required: true, message: 'Total Harga harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Input
                      prefix="Rp"
                      placeholder="Total Harga"
                      onChange={this.calculate}
                      disabled={true}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Biaya Admin">
                  {getFieldDecorator('adminFee', {
                    initialValue: daftarMuatan?.adminFee ?? '',
                    rules: [{ required: true, message: 'Biaya Admin harus dipilih!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Select placeholder="Pilih Biaya Admin" onChange={this.calculate}>
                      <Option key={1} value={1000}>
                        Rp. 1.000
                      </Option>
                      <Option key={2} value={10000}>
                        Rp. 10.000
                      </Option>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Biaya Loco">
                  {getFieldDecorator('locoFee', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: daftarMuatan?.locoFee ?? '',
                    rules: [{ message: 'Biaya Loco hanya berupa angka!', pattern: /^[0-9]+$/ }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Input
                      prefix="Rp"
                      placeholder="Biaya Loco"
                      onChange={this.calculate}
                      disabled={true}
                    />,
                  )}
                </Form.Item>
                <Form.Item label="Sisa Biaya">
                  {getFieldDecorator('unpaidFee', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: daftarMuatan?.unpaidFee ?? '',
                    rules: [{ required: true, message: 'Sisa Biaya harus diisi!' }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Sisa Biaya" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Misc Charge">
                  {getFieldDecorator('miscCharge', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: daftarMuatan?.miscCharge ?? '',
                    rules: [{ message: 'Misc Charge hanya berupa angka!', pattern: /^[0-9]+$/ }],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Misc Charge" />)}
                </Form.Item>
                {formType === 'edit' && (
                  <Form.Item label="Surat Jalan">
                    {getFieldDecorator('fileSuratJalan', {
                      getValueFromEvent: (e: any) => {
                        if (Array.isArray(e)) {
                          return e;
                        }
                        return e && e.fileList;
                      },
                      initialValue: getUploadedFile(fileType.SJ, daftarMuatan?.fileSuratJalan),
                      valuePropName: 'fileList',
                      rules: [{ validator: this.handleUploadValidation }],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Upload
                        accept=".jpg, .jpeg, .png, .pdf"
                        customRequest={(option) => this.handleUpload(option, 'surat_jalan')}
                        onRemove={() => {
                          this.setState({ fileSuratJalan: '' });
                        }}
                        listType="picture"
                      >
                        <Button
                          disabled={
                            getFieldValue('fileSuratJalan') !== undefined &&
                            getFieldValue('fileSuratJalan').length > 0
                          }
                        >
                          <Icon type="upload" /> Upload File
                        </Button>
                      </Upload>,
                    )}
                  </Form.Item>
                )}
                <Form.Item label="Remarks">
                  {getFieldDecorator('remarks', {
                    initialValue: daftarMuatan?.remarks ?? '',
                    rules: [
                      { min: 3, message: 'Remarks minimal 3 karakter!' },
                      { max: 250, message: 'Remarks maksimal 250 karakter!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<TextArea placeholder="Informasi Tambahan" />)}
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Tanggal">
                  {getFieldDecorator('dmDate', {
                    initialValue: daftarMuatan?.dmDate ? moment(daftarMuatan?.dmDate) : moment(),
                    rules: [{ required: true, message: 'Tanggal harus dipilih!' }],
                  })(<DatePicker format={dateFormat} />)}
                </Form.Item>
                <Form.Item label="Harga Vendor">
                  {getFieldDecorator('totalVendorPrice', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: daftarMuatan?.totalVendorPrice ?? '',
                    rules: [
                      { message: 'Harga Vendor hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { validator: this.handleValidateVendorPrice },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Harga Vendor" />)}
                </Form.Item>
                <Form.Item label="Komisi Ekspedisi (Minimal 10% dari Total Harga)">
                  {getFieldDecorator('expeditionCommission', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: daftarMuatan?.expeditionCommission ?? '',
                    rules: [
                      { message: 'Komisi Ekspedisi hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: locoFee > 0, message: 'Komisi Ekspedisi harus diisi!' },
                      { validator: this.handleValidateCommission },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(
                    <Input prefix="Rp" placeholder="Komisi Ekspedisi" onChange={this.calculate} />,
                  )}
                </Form.Item>
                <Form.Item label="Uang Jalan (Down Payment)">
                  {getFieldDecorator('downPayment', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: daftarMuatan?.downPayment ?? '',
                    rules: [
                      { message: 'Uang Jalan hanya berupa angka!', pattern: /^[0-9]+$/ },
                      { required: true, message: 'Uang Jalan harus diisi!' },
                    ],
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Uang Jalan" onChange={this.calculate} />)}
                </Form.Item>
                <Form.Item label="Sisa Biaya Vendor">
                  {getFieldDecorator('unpaidVendor', {
                    getValueFromEvent: this.onChangeNumber,
                    initialValue: unpaidVendor ?? '',
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input prefix="Rp" placeholder="Sisa Biaya Vendor" disabled={true} />)}
                </Form.Item>
                <Form.Item label="Pemeriksa">
                  {getFieldDecorator('checker', {
                    initialValue: daftarMuatan?.checker ?? '',
                    validateTrigger: ['onChange', 'onBlur'],
                  })(<Input placeholder="Pemeriksa" />)}
                </Form.Item>
                {formType === 'edit' && (
                  <Form.Item label="Foto">
                    {getFieldDecorator('fileFoto', {
                      getValueFromEvent: (e: any) => {
                        if (Array.isArray(e)) {
                          return e;
                        }
                        return e && e.fileList;
                      },
                      initialValue: getMultipleFile(daftarMuatan?.fileFoto, fileType.FDM),
                      valuePropName: 'fileList',
                      rules: [{ validator: this.handleUploadValidation }],
                      validateTrigger: ['onChange', 'onBlur'],
                    })(
                      <Upload
                        accept=".jpg, .jpeg, .png"
                        customRequest={(option) => this.handleUpload(option, 'foto')}
                        onRemove={(file) => {
                          this.setState({ fileFoto: [] });
                        }}
                        listType="picture-card"
                        multiple={true}
                      >
                        <div>
                          <Icon type="plus" />
                          <div className="ant-upload-text">Upload</div>
                        </div>
                      </Upload>,
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Divider orientation="left">Detail Daftar Muatan</Divider>
            <Form.Item label="Surat Muatan">
              {getFieldDecorator('suratMuatan', {
                initialValue: suratMuatanCodes,
                rules: [{ message: 'Surat Muatan harus dipilih!', required: true }],
                validateTrigger: ['onChange', 'onBlur'],
              })(
                <Select
                  mode="multiple"
                  showSearch={true}
                  placeholder="Pilih Surat Muatan"
                  optionFilterProp="children"
                  onChange={this.onSelectSuratMuatan}
                  disabled={formType === 'edit'}
                >
                  {suratMuatanList &&
                    suratMuatanList.map((item: ISuratMuatan) => (
                      <Option key={item.code} value={item.id}>
                        {item.code}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            {suratMuatans.length > 0 && (
              <DetailTableComponent
                tableType="surat_muatan"
                dataSource={suratMuatans}
                isPrint={false}
              />
            )}
            <br />
            <Button className="action-btn" type="default" htmlType="reset" onClick={goBack}>
              Batal
            </Button>
            <Button className="action-btn" type="primary" onClick={this.handleSubmit}>
              Simpan
            </Button>
          </Form>
        </Card>
      </Fragment>
    );
  }
}
export default Form.create<IProps>()(PageDaftarMuatanFormComponent);
