import { DataName, dateFormat } from '@utils/constants';
import { ITransitCost } from '@interfaces/itransit-cost';
import { goBack } from '@utils/history';
import { currencyCase, costStatusCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Divider, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchTransitCost: (code: string) => void;
  transitCost?: ITransitCost;
  isFetching: string[];
  match: any;
}

interface IState {
  showModal: boolean;
}

const title = 'Detail Transit Cost';
export class PageTransitCostDetailComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  public componentDidMount = () => {
    const { match, fetchTransitCost } = this.props;
    fetchTransitCost(match.params.code);
  };
  public handleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };
  public render() {
    const { transitCost, isFetching } = this.props;
    const loading = isLoading(isFetching, DataName.TRANSIT_COST);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        {transitCost && (
          <PageHeader
            title={title}
            onBack={goBack}
            extra={[
              <Link key="1" to={`/transit-cost/form${transitCost && `/${transitCost.code}`}`}>
                <Button type="primary">
                  <Icon type="edit" />
                  Ubah Transit Cost
                </Button>
              </Link>,
            ]}
          />
        )}

        <Card bordered={false} loading={loading}>
          {transitCost && (
            <Fragment>
              <Descriptions
                title={`Transit Cost: ${transitCost.code}`}
                layout="vertical"
                bordered={true}
                size="small"
              >
                <Descriptions.Item label="Nomor Daftar Muatan">
                  <Link to={`/daftar-muatan/detail/${transitCost.daftar_muatan?.code}`}>
                    {transitCost.daftar_muatan?.code}
                  </Link>
                </Descriptions.Item>
                <Descriptions.Item label="Nomor Surat Muatan">
                  {transitCost.suratMuatanIds}
                </Descriptions.Item>
                <Descriptions.Item label="Tanggal">
                  {transitCost.date ? formatTime(moment(transitCost.date), dateFormat) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Kendaraan">
                  {`${transitCost.vehicle?.policeNo} ${transitCost.vehicle?.vehicle_type?.name}`}
                </Descriptions.Item>
                <Descriptions.Item label="Nama Supir">
                  {transitCost.vehicle?.driver?.fullName}
                </Descriptions.Item>
                <Descriptions.Item label="Telepon Supir">
                  {transitCost.vehicle?.driver?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Total Harga (Invoice)">
                  {currencyCase(transitCost.daftar_muatan?.totalPrice || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Biaya Transit">
                  {currencyCase(transitCost.cost)}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {costStatusCase(transitCost.status || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Dibuat Oleh">{transitCost.created_by}</Descriptions.Item>
                <Descriptions.Item label="Diubah Oleh">
                  {transitCost.created_by || '-'}
                </Descriptions.Item>
              </Descriptions>
              <Divider orientation="left">Detail Muatan</Divider>
            </Fragment>
          )}
        </Card>
      </Fragment>
    );
  }
}
export default PageTransitCostDetailComponent;
