import { DataName, dateFormat, defaultParams } from '@utils/constants';
import { IDaftarMuatan, IDaftarMuatanDetail } from '@interfaces/idaftar-muatan';
import { ISuratMuatan } from '@interfaces/isurat-muatan';
import { goBack } from '@utils/history';
import { companyTypeCase, currencyCase } from '@utils/string-case';
import { formatTime } from '@utils/time';
import { Button, Card, Descriptions, Divider, Icon, PageHeader } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import DetailTableComponent from './libraries/detail-table';
import ModalPrintComponent from './libraries/modal';
import { ITransitCost } from '@interfaces/itransit-cost';
import { isEmpty } from 'lodash';
import { isLoading } from '@utils/loading';

interface IProps {
  fetchDaftarMuatan: (code: string) => void;
  daftarMuatan?: IDaftarMuatan;
  fetchTransitCostList: (params: any) => void;
  transitCostList: ITransitCost[];
  isFetching: string[];
  match: any;
}

interface IState {
  showModal: boolean;
  suratMuatans: ISuratMuatan[];
}

const title = 'Detail Daftar Muatan';
export class PageDaftarMuatanDetailComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      suratMuatans: [],
    };
  }
  public componentDidMount = () => {
    const { match, fetchDaftarMuatan, fetchTransitCostList } = this.props;
    fetchDaftarMuatan(match.params.code);
    fetchTransitCostList({ ...defaultParams, dmCode: match.params.code });
  };
  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps !== this.props) {
      const { daftarMuatan } = this.props;
      if (daftarMuatan) {
        const suratMuatans: ISuratMuatan[] =
          daftarMuatan.details?.map((item: IDaftarMuatanDetail) => item.details) || [];
        this.setState({ suratMuatans });
      }
    }
  };
  public handleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };
  public render() {
    const { daftarMuatan, transitCostList, isFetching } = this.props;
    const { showModal, suratMuatans } = this.state;
    const unpaidVendor = daftarMuatan
      ? daftarMuatan.totalVendorPrice -
        daftarMuatan.expeditionCommission -
        daftarMuatan.adminFee -
        daftarMuatan.downPayment -
        daftarMuatan.locoFee
      : 0;
    const loading = isLoading(isFetching, DataName.DAFTAR_MUATAN);

    return (
      <Fragment>
        <DocumentTitle title={`${process.env.REACT_APP_PROJECT} - ${title}`} />
        {daftarMuatan && (
          <PageHeader
            title={title}
            onBack={goBack}
            extra={[
              <Button key="1" type="primary" onClick={this.handleModal}>
                <Icon type="printer" />
                Cetak Daftar Muatan
              </Button>,
              <Link key="2" to={`/daftar-muatan/form${daftarMuatan && `/${daftarMuatan.code}`}`}>
                <Button type="primary">
                  <Icon type="edit" />
                  Ubah Daftar Muatan
                </Button>
              </Link>,
            ]}
          />
        )}

        <Card bordered={false} loading={loading}>
          {daftarMuatan && (
            <Fragment>
              <Descriptions
                title={`Daftar Muatan: ${daftarMuatan.code}`}
                layout="vertical"
                bordered={true}
                size="small"
              >
                <Descriptions.Item label="Tanggal">
                  {daftarMuatan.dmDate ? formatTime(moment(daftarMuatan.dmDate), dateFormat) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor" span={2}>
                  {companyTypeCase(
                    daftarMuatan.vehicle?.driver?.vendor?.type,
                    daftarMuatan.vehicle?.driver?.vendor?.name,
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Kendaraan">
                  {`${daftarMuatan.vehicle?.policeNo} ${daftarMuatan.vehicle?.vehicle_type?.name}`}
                </Descriptions.Item>
                <Descriptions.Item label="Nama Supir">
                  {daftarMuatan.vehicle?.driver?.fullName}
                </Descriptions.Item>
                <Descriptions.Item label="Telepon Supir">
                  {daftarMuatan.vehicle?.driver?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Total Harga (Invoice)">
                  {currencyCase(daftarMuatan.totalPrice)}
                </Descriptions.Item>
                <Descriptions.Item label="Total Harga Vendor">
                  {currencyCase(daftarMuatan.totalVendorPrice)}
                </Descriptions.Item>
                <Descriptions.Item label="Komisi Ekspedisi">
                  {currencyCase(daftarMuatan.expeditionCommission)}
                </Descriptions.Item>
                <Descriptions.Item label="Biaya Admin">
                  {currencyCase(daftarMuatan.adminFee)}
                </Descriptions.Item>
                <Descriptions.Item label="Uang Jalan (Down Payment)">
                  {currencyCase(daftarMuatan.downPayment)}
                </Descriptions.Item>
                <Descriptions.Item label="Biaya Loco">
                  {currencyCase(daftarMuatan.locoFee)}
                </Descriptions.Item>
                <Descriptions.Item label="Sisa Biaya">
                  {currencyCase(daftarMuatan.unpaidFee)}
                </Descriptions.Item>
                <Descriptions.Item label="Sisa Biaya Vendor">
                  {currencyCase(unpaidVendor)}
                </Descriptions.Item>
                <Descriptions.Item label="Misc Charge">
                  {currencyCase(daftarMuatan.miscCharge)}
                </Descriptions.Item>
                <Descriptions.Item label="Pemeriksa">
                  {daftarMuatan.checker || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Surat Jalan / PO">
                  {daftarMuatan.fileSuratJalan ? (
                    <a target="_blank" href={daftarMuatan.fileSuratJalan}>
                      Lihat Surat Jalan
                    </a>
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Foto Daftar Muatan">
                  {daftarMuatan.fileFoto
                    ? daftarMuatan.fileFoto.split('|').map((foto, index) => (
                        <>
                          {index !== 0 && <br />}&bull;{' '}
                          <a target="_blank" href={foto}>
                            Lihat Foto {index + 1}
                          </a>
                        </>
                      ))
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Remarks"
                  className={!isEmpty(daftarMuatan.remarks) ? 'warning' : ''}
                >
                  {daftarMuatan.remarks || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Dibuat Oleh">{daftarMuatan.created_by}</Descriptions.Item>
                <Descriptions.Item label="Diubah Oleh">
                  {daftarMuatan.created_by || '-'}
                </Descriptions.Item>
              </Descriptions>

              <Divider orientation="left">Detail Muatan</Divider>
              <DetailTableComponent
                tableType="surat_muatan"
                dataSource={suratMuatans}
                isPrint={false}
              />

              {transitCostList.length > 0 && (
                <>
                  <Divider orientation="left">Transit Cost</Divider>
                  <DetailTableComponent
                    tableType="transit_cost"
                    dataSource={transitCostList}
                    isPrint={false}
                  />
                </>
              )}
            </Fragment>
          )}
        </Card>

        {daftarMuatan && (
          <ModalPrintComponent
            title="Daftar Muatan"
            show={showModal}
            hide={this.handleModal}
            data={daftarMuatan}
            dataDetails={suratMuatans}
          />
        )}
      </Fragment>
    );
  }
}
export default PageDaftarMuatanDetailComponent;
